import {Injectable} from "@angular/core";
import {Headers, Response, Http} from "@angular/http";
import {Environment} from "../../environment.model";
import {map} from "rxjs/operator/map";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";

/**
 * Created by hirenparekh on 16/11/16.
 */


@Injectable()
export class ConnectorsService {

    headers: Headers;

    constructor(private http: Http,private router: Router) {

    }

    getHeaders(): Headers {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', localStorage.getItem('sessionId'));
        return this.headers;
    }

    getConnectors() {
        return this.http.get(Environment.apiUrl + 'connectors', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getConnectorComponents(id:string):Observable<any>{
        return this.http.get(Environment.apiUrl + 'connectors/' + id + '/components', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getConnectorMetrics(id:string):Observable<any> {
        return this.http.get(Environment.apiUrl + 'metrics/getMetricsByConnectorId/' + id , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricsByKey(key:string):Observable<any> {
        return this.http.get(Environment.apiUrl + 'metrics/' + key +'?field=metric_key' , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getServiceProviders() {
        return this.http.get(Environment.apiUrl + 'serviceProviders', {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewConnector(body): Observable<any> {
        return Observable.create(observer => {
            let xhr:XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.apiUrl + 'connectors', true);
            xhr.setRequestHeader('Accept', "*");
            xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
            xhr.setRequestHeader('token', localStorage.getItem('sessionId'));
            xhr.send(body);
        });
    }

    addNewComponent(id:string,body): Observable<any> {
        return this.http.post(Environment.apiUrl + 'connectors/' + id + '/components/add' ,body, {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewMetric(body): Observable<any> {
        return this.http.post(Environment.apiUrl + 'metrics/' ,body, {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteConnector(id:string):Observable<any>{
        return this.http.delete(Environment.apiUrl+'connectors/'+id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getConnectorById(id:string):Observable<any>{
        return this.http.get(Environment.apiUrl+'connectors/'+id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteComponent(id:string):Observable<any>{
        return this.http.delete(Environment.apiUrl+'connectorComponents/' + id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteMetric(id:string):Observable<any>{
        console.log("delete metric",id);
        return this.http.delete(Environment.apiUrl+'metrics/' + id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateComponent(id:string,body):Observable<any>{
        return this.http.put(Environment.apiUrl+'connectorComponents/' + id,body ,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateMetric(id:string,body):Observable<any>{
        return this.http.put(Environment.apiUrl+'metrics/' + id,body ,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateConnector(id:string,body):Observable<any>{
        return Observable.create(observer => {
            let xhr:XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.apiUrl + 'connectors/update/'+id, true);
            xhr.setRequestHeader('Accept', "*");
            xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
            xhr.setRequestHeader('token', localStorage.getItem('sessionId'));
            xhr.send(body);
        });
    }

    authorizedUserService(response){
        if (response.flag == false) {
            if(response.code == 401){
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }
}
