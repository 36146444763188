import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";

import {Message} from "primeng/components/common/message";
import {EventsService} from "../../services/events.service";

declare var jQuery: any;

@Component({
    selector: 'events-view',
    templateUrl: './view-events.component.html',
    styleUrls: ['./view-events.component.css']
})
export class ViewEvent {

    msgs: Message[] = [];
    event: any;


    constructor(private eventService: EventsService, private activeRoute: ActivatedRoute, private router: Router) {

    }

    getEventById(eventId: string): void {
        this.eventService.getEventById(eventId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.event = data;
                        console.log("this.event.tags", data.tags)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log("error--", error);

                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    ngOnInit() {
        let id: string;
        this.activeRoute.params.forEach(param => {
            id = param['id']
        });

        this.getEventById(id);
    }

    goToEvent() {

        this.router.navigate([AppRoutingConstants.events]);

    }

}