import {Component, AfterViewInit} from '@angular/core';

import {Title} from '@angular/platform-browser';

import {LoginService} from "../login/services/login.service";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";

@Component({
    selector: 'connectly-pro',
    templateUrl: './connectly-pro.component.html',
    styleUrls: ['./connectly-pro.component.scss'],
})
export class ConnectlyProComponent implements AfterViewInit {

    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;
    constructor(private _titleService: Title, private loginService: LoginService,
                 private route: ActivatedRoute,
                private router: Router) {
    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page

        this._titleService.setTitle('Connectly pro');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
            })
    }


    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }
}
