/**
 * Created by hirenparekh on 10/11/16.
 */

import {Component, OnInit, HostBinding, AfterViewInit} from "@angular/core";
import {trigger,state,style,animate,transition} from '@angular/animations';
import {OrganizationService} from "../services/organization.service";
import {Organization} from "../models/organization.model";
import {AppRoutingConstants} from "../../app-routing.constants";
import {NgModel} from "@angular/forms";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {LoginService} from "../../login/services/login.service";

@Component({
  selector: 'organization-view',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationComponent implements OnInit {
    searchStr: string = "";
    routes: Object[] = [{
        title: 'Overview',
        route: '/organizations',
        icon: 'dashboard',
    }
    ];
    activeroute:string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;
    organizations: Organization[];
  pageName:string = "Organizations";

  constructor(private _titleService: Title, private route: ActivatedRoute,
              private orgServices: OrganizationService,private router:Router) {

  }
    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page


        this._titleService.setTitle('Dashboard');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
            })
    }

    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }

    ngOnInit() {

    }
}
