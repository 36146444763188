import {Injectable} from "@angular/core";
import {Headers, Response, Http} from "@angular/http";
import {Environment} from "../../environment.model";
import {map} from "rxjs/operator/map";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";


@Injectable()
export class EventsService {

    headers: Headers;

    constructor(private http: Http,private router: Router) {

    }

    getHeaders(): Headers {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', localStorage.getItem('sessionId'));
        return this.headers;
    }

    getEvents() {
        return this.http.get(Environment.apiUrl + 'events', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getEventById(id:string):Observable<any>{
        return this.http.get(Environment.apiUrl + 'events/get/' + id , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getConnectorMetrics(id:string):Observable<any> {
        return this.http.get(Environment.apiUrl + 'metrics/getMetricsByConnectorId/' + id , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricsByKey(key:string):Observable<any> {
        return this.http.get(Environment.apiUrl + 'metrics/' + key +'?field=metric_key' , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getServiceProviders() {
        return this.http.get(Environment.apiUrl + 'serviceProviders', {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewConnector(body): Observable<any> {
        return Observable.create(observer => {
            let xhr:XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.apiUrl + 'connectors', true);
            xhr.setRequestHeader('Accept', "*");
            xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
            xhr.setRequestHeader('token', localStorage.getItem('sessionId'));
            xhr.send(body);
        });
    }

    addNewComponent(id:string,body): Observable<any> {
        return this.http.post(Environment.apiUrl + 'connectors/' + id + '/components/add' ,body, {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewEvent(body) {
        return this.http.post(Environment.apiUrl + 'events/' ,body, {headers: this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }


    editNewEvent(id,body) {
        return this.http.put(Environment.apiUrl + 'events/' + id , body,{headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }


    deleteEvent(id:string):Observable<any>{
        return this.http.delete(Environment.apiUrl+'events/'+id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getConnectorById(id:string):Observable<any>{
        return this.http.get(Environment.apiUrl+'connectors/'+id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteComponent(id:string):Observable<any>{
        return this.http.delete(Environment.apiUrl+'connectorComponents/' + id,{headers:this.getHeaders()})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }



    authorizedUserService(response){
        if (response.flag == false) {
            if(response.code == 401){
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }
}
