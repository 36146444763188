import {Component, Input, EventEmitter, Output, Inject, forwardRef} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AddRole} from "../add-role/add-role.component";
import {AppRoutingConstants} from "../../../../../app-routing.constants";


@Component({
    selector: 'role-form',
    templateUrl: './role-form.component.html',
    styleUrls: ['./role-form.component.css']
})
export class RoleForm {
    error: any = [];
    roleForm: FormGroup;
    private _roleComponent: any;

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    formCancelEvent: EventEmitter<any> = new EventEmitter<any>();


    getFormData() {
        let m_data = {
            name: this.roleForm.value['name'],
            description: this.roleForm.value['description'],
        };
        return m_data;
    }

    get roleComponent() {
        return this._roleComponent;
    }

    @Input()
    set roleComponent(value) {
        console.log("component:", value);
        this._roleComponent = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
    }

    ngOnInit() {

        this.roleForm = new FormGroup({
            name: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
        });
    }


    resetForm() {
        //this.roleForm.reset();
        this.formCancelEvent.emit();
    }

    setValues() {

        let value = {
            name: this._roleComponent.name,
            description: this._roleComponent.description,
        };
        console.log("value", value);
        this.roleForm.reset(value)
    }

    addNewRole(event) {
        this.error = [];

        if (this.roleForm.value['name'].length > 30) this.error.push("Maximum length of role name name is 30");

        if (this.error && this.error.length == 0) {
            this.formSubmitEvent.emit(this.getFormData());
        }
    }


}