import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";

import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {RoleForm} from "../role-form/role-form.component";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'add-role',
    templateUrl: './add-role.component.html',
    styleUrls: ['./add-role.component.css']
})
export class AddRole {
    //@Output() componentAddedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('addRole') addRole: ElementRef;
    @ViewChild('roleForm') roleForm: RoleForm;
    role: any;
    groupId: any;
    activeBlock: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
        this.activeBlock =this.connectlyProService.activeBlock;
    }

    ngOnInit() {
        this.groupId=this.connectlyProService.groupId;
        console.log("gorupid--", this.activeRoute.params)
    }

    onFormSubmit(event: any) {
        console.log("event.connector_id", event)
        event.appId = this.groupId;
        this.addNewrole(event);
    }

    addNewrole(data: any) {
        this.connectlyProService.addNewRole(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        // this.componentAddedEvent.emit(new ConnectorComponent().getObject(response.data));
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }

    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId, 'roles']);
    }

    resetView() {

    }


}