import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {ConnectorsService} from "../../../../services/connectors.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Connector} from "../../../../models/connector.model";
import {MetricForm} from "../metric-form/metric-form.component";
import {ConnectorMetric} from "../../../../models/connectorMetric.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";


declare var jQuery: any;

@Component({
    selector: 'add-metric',
    templateUrl: './add-metric.component.html',
    styleUrls: ['./add-metric.component.css', '../../view-connector.component.css']
})
export class AddMetric {
    @Output() metricAddedEvent:EventEmitter<ConnectorMetric> = new EventEmitter<ConnectorMetric>();
    @ViewChild('addMetric') addMetric: ElementRef;
    @ViewChild('metricForm') metricForm: MetricForm;
    connector: Connector;
    msgs: Message[] = [];

    constructor(private connectorService: ConnectorsService, private router: Router, private activeRoute: ActivatedRoute,) {

    }


    onFormSubmit(event: any) {
        event.connector_id=this.connector.uniqueId;
       this.addNewMetric(event);
    }

    addNewMetric(data: any) {
        this.connectorService.addNewMetric(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.metricAddedEvent.emit(new ConnectorMetric().getObject(response.data));
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }

    openModal(connector :Connector) {
        this.resetView();
        //jQuery(this.addMetric.nativeElement).modal('show');
        this.connector=connector;
        console.log(this.connector.uniqueId);


    }

    closeModal() {
       // jQuery(this.addMetric.nativeElement).modal('hide');
        if(this.metricForm){
            this.metricForm.resetForm();
        }
        this.onFormReset();
    }

    resetView() {

    }

    onFormReset(){
        this.router.navigate([AppRoutingConstants.connectors_view_id ,this.connector.uniqueId]);
    }

    ngOnInit() {
        let connectorId: string;
        this.activeRoute.params.forEach(param => {connectorId = param['id']});
        this.getConnectorById(connectorId);
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if(response.flag)
                    {
                        let data = response.data;
                        console.log("data",data);

                        this.connector = new Connector().getObject(data);
                        // jQuery(this.viewConnector.nativeElement).modal('show');
                        // console.log("this.connector.uniqueId",this.connector.unique_id);

                    }else{
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }
}