import {Component, ViewChild,AfterViewInit, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Message, MenuItem} from "primeng/components/common/api";
import {SubscribeOrgForm} from "../subscribed-org-form/subscribed-org-form.component";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {SubscribeOrgConnectlyProService} from "../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../app-routing.constants";

declare var jQuery: any;

@Component({
    selector: 'edit-subscribed-org',
    templateUrl: './edit-subscribed-org.component.html',
    styleUrls: ['./edit-subscribed-org.component.css']
})
export class EditSubscribeOrgComponent implements AfterViewInit {
    // @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('editSubscribeGroup') editSubscribeGroup: ElementRef;
    @ViewChild('subscribeOrgForm') subscribeOrgForm: SubscribeOrgForm;

    items: MenuItem[];

    msgs: Message[] = [];
    activeBlock:any;
    activeroute:any;

    activeIndex: number = 0;
    groupId: string;
    group: any;

    constructor(private _titleService: Title, private router: Router,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

    }

    ngOnInit() {
        this.items = [
            {label: 'Group'},
            {label: 'Android'},
            {label: 'IOS'},
            {label: 'Deploy'}
        ];
        this.activeRoute.params.forEach(param => {
            this.groupId = param['id']
        });

        this.activeroute = this.router.url;
        console.log('this.activeBlock', this.activeroute);

        this.manageRouting();
    }

    ngAfterViewInit() {
        this.getGroupById(this.groupId);
    }

    manageRouting() {
        if (this.activeroute.indexOf('/connectly/proApps/All/edit') != -1) {
            this.activeBlock = 'All'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Provisioning/edit') != -1) {
            this.activeBlock = 'Provisioning'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Live/edit') != -1) {
            this.activeBlock = 'Live'
        }

        if (this.subscribeOrgForm) {
            this.subscribeOrgForm.activeBlock = this.activeBlock;
        }
    }

    getGroupById(groupId: string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.group = response.data;
                        this.subscribeOrgForm.subscribeGroup =this.group;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    onFormSubmit(event: FormData) {
        this.editNewSubscribedOrg(event);
    }

    editNewSubscribedOrg(formData) {
        formData.id=this.groupId;
        formData.status=this.group.status;
        formData.parentId=this.group.parentId;
        this.subscribeOrgConnectlyProService.editNewSubscribedOrg(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                   // this.closeModal();
                }
            );
    }

    closeModal() {
        //jQuery(this.addConnector.nativeElement).modal('hide');
        if (this.subscribeOrgForm) {
            this.subscribeOrgForm.resetForm();
        }
    }

}