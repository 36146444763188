declare var jQuery:any;
export class BasicFunctions {

    insertionSort(array, attrToSortBy) {
    for (var k = 1; k < array.length; k++) {
        for (var i = k; i > 0 && new Date(array[i][attrToSortBy]) >
        new Date(array[i - 1][attrToSortBy]); i--) {
            var tmpFile = array[i];
            array[i] = array[i - 1];
            array[i - 1] = tmpFile;
        }
    }
}


    refreshPage(){
        location.reload();
    }

    openPopupScrollFix(){
        jQuery('.background-scroll-disabled').on('click', showModal);
        jQuery('.fa-close').click(hideModal);
        jQuery('.ui-button').on('click', hideModal);
        jQuery(document).keyup(function (e) {
            if (e.keyCode == 27) {
                hideModal();
            }
        });
        function showModal() {
            jQuery('html').css('overflow', 'hidden');
        }
        function hideModal() {
            jQuery('html').css('overflow', 'scroll');
        }
    }

}