import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {ConnectorsService} from '../../../../services/connectors.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ComponentForm} from '../component-form/component-form.component';
import {Connector} from '../../../../models/connector.model';
import {ConnectorComponent} from '../../../../models/connectorComponent.model';


declare var jQuery: any;

@Component({
    selector: 'add-component',
    templateUrl: './add-component.component.html',
    styleUrls: ['./add-component.component.css', '../../view-connector.component.css']
})
export class AddComponent {
    @Output() componentAddedEvent: EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('addComponent') addComponent: ElementRef;
    @ViewChild('componentForm') componentForm: ComponentForm;
    connector: Connector;

    constructor(private connectorService: ConnectorsService) {

    }


    onFormSubmit(event: any) {
        console.log('event.connector_id', event)
        event.connector_id = this.connector.uniqueId;
        this.addNewComponent(event);
    }

    addNewComponent(data: any) {
        this.connectorService.addNewComponent(this.connector.uniqueId, JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.componentAddedEvent.emit(new ConnectorComponent().getObject(response.data));
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.closeModal();
                }
            );
    }

    openModal(connector: Connector) {
        this.resetView();
        // jQuery(this.addComponent.nativeElement).modal('show');
//        this.connector=connector;
        this.connector = new Connector().getObject(connector);

        console.log(this.connector.uniqueId);
    }

    closeModal() {
        // jQuery(this.addComponent.nativeElement).modal('hide');
        if (this.componentForm) {
            this.componentForm.resetForm();
        }
    }

    resetView() {

    }

    ngOnInit() {
    }

}