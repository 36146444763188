import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {commonConstants} from '../../common/models/constants';
import {ConfirmationService, Message} from 'primeng/components/common/api';
import {AppRoutingConstants} from '../../app-routing.constants';
import {Router} from '@angular/router';
import {ThingsService} from '../services/things.service';


@Component({
    selector: 'app-things-inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['./inventory.component.scss'],
})
export class InventoryThingsComponent implements AfterViewInit {
    constants = commonConstants;
    inventories: any;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router, private confirmationService: ConfirmationService,
                private thingsService: ThingsService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Things');
    }

    getInventory() {

        this.thingsService.getThingsInventory()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.inventories = response.data;
                    }
                    else {

                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    deleteSelectedInventory(inventoryObj: any) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this Inventory?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.thingsService.deleteInventory(inventoryObj.id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.inventories = this.inventories.filter(val => val.id !== inventoryObj.id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: inventoryObj.productName + ' Inventory Deleted Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

    ngOnInit() {
        this.getInventory();
    }
}
