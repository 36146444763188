import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConnectorsService} from "../../../../services/connectors.service";
import {Connector} from "../../../../models/connector.model";
import {MetricForm} from "../metric-form/metric-form.component";
import {ConnectorMetric} from "../../../../models/connectorMetric.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";

declare var jQuery: any;

@Component({
    selector: 'edit-metric',
    templateUrl: './edit-metric.component.html',
    styleUrls: ['./edit-metric.component.css']
})
export class EditMetric {
    @Output() metricEditedEvent: EventEmitter<ConnectorMetric> = new EventEmitter<ConnectorMetric>();
    @ViewChild('editMetric') editMetric: ElementRef;
    @ViewChild('metricForm') metricForm: MetricForm;
    @ViewChild('snapshotTimeForm') snapshotTimeForm: MetricForm;
    @ViewChild('downsamplingTimeForm') downsamplingTimeForm: MetricForm;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    connector: Connector;
    connectorMetric: ConnectorMetric;
    msgs: Message[] = [];
    constructor(private connectorService: ConnectorsService,private router: Router, private activeRoute: ActivatedRoute,) {

    }

    openModal(connectorMetric: ConnectorMetric, connector: Connector) {
        this.resetView();
        //jQuery(this.editMetric.nativeElement).modal('show');
        this.connector = connector;
        this.connectorMetric = connectorMetric;
        this.metricForm.metric = connectorMetric;

    }

    closeModal() {
        //jQuery(this.editMetric.nativeElement).modal('hide');
        this.onFormReset();
    }

    resetView() {
        this.connectorMetric = null;
        this.selectedFile = "";
    }

    ngOnInit() {
        let connectorId: string;
        let metrickey: string;
        this.activeRoute.params.forEach(param => {
            connectorId = param['id']
        });
        this.activeRoute.params.forEach(param => {
            metrickey = param['key']
        });
        this.getConnectorById(connectorId);
        this.getMetricsByKey(metrickey);
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        console.log("data", data);

                        this.connector = new Connector().getObject(data);
                        // jQuery(this.viewConnector.nativeElement).modal('show');
                        // console.log("this.connector.uniqueId",this.connector.unique_id);

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    getMetricsByKey(metric_key: string): void {
        let m_data = {
            "field": "metric_key"
        }
        this.connectorService.getMetricsByKey(metric_key)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;

                        this.connectorMetric = new ConnectorMetric().getObject(data);
                        console.log("this.connectorMetric", this.connectorMetric)
                        this.metricForm.metric = this.connectorMetric;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    onFormReset(){
        this.router.navigate([AppRoutingConstants.connectors_view_id ,this.connector.uniqueId]);
    }

    onFormSubmit(data: any) {
        data.connector_id = this.connector.uniqueId;
        this.connectorService.updateMetric(this.connectorMetric.metricId, data)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log("updated", response.data);
                        this.metricEditedEvent.emit(new ConnectorMetric().getObject(response.data))
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }

}