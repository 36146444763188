/**
 * Created by SonalPaghdal on 20/07/17.
 */
import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {OverviewViewSubscribeOrgComponent} from "./overview/overview.component";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {Message, MenuItem, ConfirmationService} from "primeng/components/common/api";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {commonConstants} from "../../../common/models/constants";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'view-subscribe-org-connectly-pro',
    templateUrl: './view-subscribe-org.component.html',
})
export class ViewSubscribeOrgConnectlyProComponent implements AfterViewInit {

    groupId: string;
    msgs: Message[] = [];
    constants = commonConstants;

    activeroute: string;
    activerouteData: string;
    activerouteSubData: string;
    activeTabIndex: number;
    title: string;
    data: Object = {name: ''};
    group: any;
    GroupProfile: string;
    items: MenuItem[];

    displayProcessStage: boolean = false;
    processStageData: any;
    refreshIntervalId: number;
    loader: boolean = true;
    processStart: boolean = true;

    activeBlock: any;


    @ViewChild('overviewViewSubscribeOrg') overviewViewSubscribeOrg: OverviewViewSubscribeOrgComponent;

    constructor(private _titleService: Title, private router: Router, private confirmationService: ConfirmationService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

    }

    ngAfterViewInit(): void {

        this._titleService.setTitle('Subscribe Organization');


    }

    getTabIndexAndData() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'overview']);
        this.activeTabIndex = 0;
        this.activerouteData = 'Overview';
        clearInterval(this.refreshIntervalId);

    }

    gotoConnectly() {
        this.router.navigate(['connectly']);
        clearInterval(this.refreshIntervalId);
    }

    gotoProApp() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);
        clearInterval(this.refreshIntervalId);
    }

    gotoApps() {
        this.displayProcessStage = false;
        clearInterval(this.refreshIntervalId);
        // this.router.navigate([AppRoutingConstants.subscribeOrganization]);
    }


    handleChange(e) {
        let index = e.index;
        let link;
        switch (index) {
            case 0:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'overview']);
                this.activeTabIndex = 0;
                this.activerouteData = 'Overview';
                break;
            case 1:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'members']);
                this.activeTabIndex = 1;
                this.activerouteData = 'Members';
                break;
            case 2:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'subGroups']);
                this.activeTabIndex = 2;
                this.activerouteData = 'SubGroups';
                break;
            case 4:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'modules']);
                this.activeTabIndex = 4;
                this.activerouteData = 'Modules';
                break;
            case 3:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'roles']);
                this.activeTabIndex = 3;
                this.activerouteData = 'Roles';
                break;
            case 5:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'joiningform']);
                this.activeTabIndex = 5;
                this.activerouteData = 'Joining Form';
                break;
            case 6:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'menu']);
                this.activeTabIndex = 6;
                this.activerouteData = 'Menu';
                break;
            case 7:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'license']);
                this.activeTabIndex = 7;
                this.activerouteData = 'License';
                break;
            case 8:
                this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'apps']);
                this.activeTabIndex = 8;
                this.activerouteData = 'Apps';
                break;
        }

    }

    ngOnDestroy() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }

    }

    ngOnInit() {
        this.activeroute = this.router.url;
        console.log("this.activeroute", this.activeroute)
        this.manageRouting();

        let groupId: string;
        this.activeRoute.params.forEach(param => {
            this.groupId = param['id']
        });
        console.log("this.groupId", this.groupId)
        this.getGroupById(this.groupId);
        this.items = [
            {
                label: 'Update', icon: 'fa-check', command: () => {
                    //this.updateSubscribeOrg();
                }
            },
            {
                label: 'Delete', icon: 'fa-close', command: () => {
                    //this.deleteSubscribedOrg(this.groupId);
                }
            },

        ];

        this.getActiveTabData();

        if (this.processStageData) {
            if (this.processStageData.status == 'Live' || this.processStageData.status == 'Deployment Fail')
                clearInterval(this.refreshIntervalId);
        }

    }

    manageRouting() {
        if (this.activeroute.indexOf('/connectly/proApps/All') != -1) {
            this.activeBlock = 'All'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Provisioning') != -1) {
            this.activeBlock = 'Provisioning'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Live') != -1) {
            this.activeBlock = 'Live'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Suspended') != -1) {
            this.activeBlock = 'Suspended'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Terminated') != -1) {
            this.activeBlock = 'Terminated'
        }
        this.connectlyProService.activeBlock = this.activeBlock;
        console.log("this.activeBlock", this.activeBlock);
    }


    updateSubscribeOrg() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'edit', this.groupId]);
    }

    deleteSubscribedOrg(group) {
        let temp_data = {
            groupId: group.id
        }
        this.confirmationService.confirm({
            message: 'Do you want to delete this group?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectlyProService.deleteSubscribedOrg(temp_data)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: group.name + ' Subscribed Organization Delete Successfully'
                                });
                                this.goSubscribeGroups()
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

    goSubscribeGroups() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);
    }

    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w200';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    getGroupById(groupId: string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        // console.log("data.applicationSettings-",data.applicationSettings)
                        //console.log("data.applicationSettings.provisioningProcessId-",data.applicationSettings.provisioningProcessId)

                        if (data.applicationSettings) {
                            if (data.applicationSettings.provisioningProcessId) {

                                this.refreshIntervalId = setInterval(() => {
                                    this.getProcessStage(data.applicationSettings.provisioningProcessId);
                                }, 2000);
                            } else {
                                this.loader = false;
                            }
                        } else {
                            this.loader = false;
                        }


                        this.group = response.data;
                        this.GroupProfile = this.convertUrlToImageUrl(this.group.imageUrl);
                        //this.GroupProfile = this.group.imageUrl;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getActiveTabData() {

        if (this.activeroute.indexOf('overview') != -1) {
            this.activerouteData = 'Overview';
            this.activeTabIndex = 0;
        }
        if (this.activeroute.indexOf('members') != -1) {
            this.activerouteData = 'Members';
            this.activeTabIndex = 1;
        }
        if (this.activeroute.indexOf('subGroups') != -1) {
            this.activerouteData = 'SubGroups';
            this.activeTabIndex = 2;
            /* if (this.activeroute.indexOf('subGroups/add') != -1){
             this.activerouteSubData='Add'
             }*/
        }
        if (this.activeroute.indexOf('modules') != -1) {
            this.activerouteData = 'Modules';
            this.activeTabIndex = 4;
        }
        if (this.activeroute.indexOf('roles') != -1) {
            this.activerouteData = 'Roles';
            this.activeTabIndex = 3;
        }
        if (this.activeroute.indexOf('joiningform') != -1) {
            this.activerouteData = 'Joining Form';
            this.activeTabIndex = 5;
        }
        if (this.activeroute.indexOf('menu') != -1) {
            this.activerouteData = 'Menu';
            this.activeTabIndex = 6;
        }

        if (this.activeroute.indexOf('license') != -1) {
            this.activerouteData = 'License';
            this.activeTabIndex = 7;
        }
        if (this.activeroute.indexOf('apps') != -1) {
            this.activerouteData = 'Apps';
            this.activeTabIndex = 8;

        }

    }

    hidepopup() {
        // alert("hii")
        clearInterval(this.refreshIntervalId);
    }

    getProcessStage(id) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        var data = response.data;
                        this.processStageData = {};
                        this.processStageData = data;
                        console.log("processStageData", this.processStageData)
                        if (this.processStageData) {
                            if (this.processStageData.status == 'Live' || this.processStageData.status == 'Deployment Fail' || this.processStageData.status == 'Terminated'
                                || this.processStageData.status == 'Suspended' || this.processStageData.status == 'Resumed') {
                                clearInterval(this.refreshIntervalId);
                                this.displayProcessStage = false;

                                if (this.processStageData.status == 'Terminated') {
                                    this.group.applicationSettings.provisionStatus = 5;
                                }
                                if (this.processStageData.status == 'Suspended') {
                                    this.group.applicationSettings.provisionStatus = 7;
                                }
                                if (this.processStageData.status == 'Resumed') {
                                    this.group.applicationSettings.provisionStatus = 9;
                                }
                                if (this.processStageData.status == 'Live' || this.processStageData.status == 'Deployment Fail') {
                                    this.group.applicationSettings.provisionStatus = 2;
                                }

                            }


                        }
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    openProcessPopup(event, data) {
        console.log("event-----", event);
        clearInterval(this.refreshIntervalId);

        //console.log("process id ", id)

        if (data && data != undefined && data != null) {
            if (data.provisioningProcessId && data.provisioningProcessId != undefined && data.provisioningProcessId != null) {
                this.refreshIntervalId = setInterval(() => {
                    this.getProcessStage(data.provisioningProcessId);
                }, 700);
            } else {
                this.processStart = false;
            }
        }


        this.displayProcessStage = true;
        //this.getProcessStage(id,event,overlaypanel);
        // overlaypanel.toggle(event);
    }

    suspendApp() {

        var data = {
            "appId": this.group.id
        }

        this.connectlyProService.suspendApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.getGroupById(this.group.id);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Suspending process started for App ' + this.group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }

    terminateApp() {
        var data = {
            "appId": this.group.id
        }

        this.connectlyProService.terminateApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.getGroupById(this.group.id);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Terminating process started for App ' + this.group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }

                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

    resumeApp() {
        var data = {
            "appId": this.group.id
        }

        this.connectlyProService.resumeApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.getGroupById(this.group.id);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Resuming process started for App ' + this.group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

}