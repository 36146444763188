import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {commonConstants} from '../../common/models/constants';
import {ConfirmationService, Message} from 'primeng/components/common/api';
import {AppRoutingConstants} from '../../app-routing.constants';
import {Router} from '@angular/router';
import {ThingsService} from '../services/things.service';


@Component({
    selector: 'app-things-products',
    templateUrl: './products.component.html',
    styleUrls: ['./products.component.scss'],
})
export class ProductsThingsComponent implements AfterViewInit {
    constants = commonConstants;
    products: any;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router, private confirmationService: ConfirmationService,
                private thingsService: ThingsService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Things');
    }

    getProducts() {
        this.thingsService.getThingsProducts()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.products = response.data;
                    }
                    else {

                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    deleteSelectedProduct(productObj: any) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this Product?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.thingsService.deleteProduct(productObj.id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.products = this.products.filter(val => val.id !== productObj.id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: productObj.name + ' Product Deleted Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

    ngOnInit() {
        this.getProducts();
    }
}
