import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {ConnectorsService} from "../../services/connectors.service";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Connector} from "../../models/connector.model";
import {ConnectorForm} from "../connector-form/connector-form.component";
import {Router, ActivatedRoute} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";

declare var jQuery: any;

@Component({
    selector: 'edit-connector',
    templateUrl: './edit-connector.component.html',
    styleUrls: ['./edit-connector.component.css', '../connectors.component.scss']
})
export class EditConnectorComponent {
    @Output() connectorEditedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('editConnector') editConnector: ElementRef;
    @ViewChild('connectorForm') connectorForm: ConnectorForm ;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    connector: Connector;
    msgs: Message[] = [];

    constructor(private connectorService: ConnectorsService, private router: Router, private activeRoute: ActivatedRoute) {

    }

    openModal(connector: Connector) {
        this.resetView();
        // jQuery(this.editConnector.nativeElement).modal('show');
        this.connector = connector;
        this.connectorForm.connector = connector;
        this.connectorForm.selectedFileData = connector.icon;
        this.connectorService.getServiceProviders()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.serviceProviders = response.data;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.connectors]);
    }

    resetView() {
        this.connector = null;
        this.selectedFile = "";
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if(response.flag)
                    {
                        let data = response.data;
                        console.log("data",data);
                        this.connector = new Connector().getObject(data);
                        this.connectorForm.connector = data;
                        this.connectorForm.selectedFileData = data.icon;
                        this.connectorService.getServiceProviders()
                            .subscribe(
                                response => {
                                    if (response.flag) {
                                        this.serviceProviders = response.data;
                                    } else {
                                        console.log(response.message);
                                        this.msgs = [];
                                        this.msgs.push({
                                            severity: 'error',
                                            summary: 'Error Message',
                                            detail: response.message
                                        });
                                    }
                                },
                                error => {
                                    console.log(error);
                                    this.msgs = [];
                                    this.msgs.push({
                                        severity: 'error',
                                        summary: 'Error Message',
                                        detail: ' Oops! Something Went Wrong'
                                    });
                                }
                            );
                    }else{
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    ngOnInit() {
        let connectorId: string;
        this.activeRoute.params.forEach(param => {
            connectorId = param['id']});
        this.getConnectorById(connectorId);

    }

    onFormCancel(data:any){
        this.router.navigate([AppRoutingConstants.connectors]);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedFile = file;
            this.selectedFileData = reader.result;
        };
        reader.readAsDataURL(file);
    }

    onFormSubmit(event:FormData){
        this.connectorService.updateConnector(this.connector.uniqueId,event)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                    this.connectorEditedEvent.emit(new Connector().getObject(response.data));
                    this.router.navigate([AppRoutingConstants.connectors]);

                }else{
                    console.log(response.message);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: response.message
                    });
                }
            },
            error => {
                console.log(error);
                this.msgs = [];
                this.msgs.push({
                    severity: 'error',
                    summary: 'Error Message',
                    detail: ' Oops! Something Went Wrong'
                });
            },
            () => {
                this.closeModal();
            }
        );
    }

}