/**
 * Created by krishnaPipalia on 07/08/18.
 */

import {Injectable} from '@angular/core';
import {Http, Headers, Response} from '@angular/http';
import {Environment} from '../../environment.model';
import {Observable} from 'rxjs';
import {AppRoutingConstants} from '../../app-routing.constants';
import {Router} from '@angular/router';

@Injectable()
export class TallyService {

    headers: Headers;

    constructor(private http: Http, private router: Router) {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        // this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('token', localStorage.getItem('sessionId'));
        console.log('localStorage.getItem(\'sessionId\')', localStorage.getItem('sessionId'))
    }


    getCustomers(skip, limit): Observable<any> {
        return this.http.get(Environment.apiUrl + 'tallyOrganization/getCustomer/' + skip + '/' + limit, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    getCustomerCount(): Observable<any> {
        return this.http.get(Environment.apiUrl + 'tallyOrganization/getCustomerCount', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    resentKey(orgId, clientId): Observable<any> {
        return this.http.get(Environment.apiUrl + 'tallyOrganization/resendClientIdEmail/' + orgId + '/' + clientId,
            {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    getTallyOrganizationById(id: string): Observable<any> {
        return this.http.get(Environment.apiUrl + 'tallyOrganization/installedAgent/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    getOrganizationUserDevices(orgId: string): Observable<any> {
        return this.http.get(Environment.apiUrl + 'tallyOrganization/deviceList/' + orgId, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    authorizedUserService(response) {
        if (response.flag == false) {
            if (response.code == 401) {
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
