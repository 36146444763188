/**
 * Created by SonalPaghdal on 20/07/17.
 */
import {Injectable} from "@angular/core";
import {Http, Headers, Response} from "@angular/http";
import {Environment} from "../../environment.model";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";

@Injectable()
export class SubscribeOrgConnectlyProService {
    groupOrgId:any;
    headers:Headers;
    groupId:any;

    constructor(private http:Http, private router:Router) {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        //this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('token', '8251bf8ee837865754c4782504dca8bd');

    }

    getHeaders():Headers {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        //this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', '8251bf8ee837865754c4782504dca8bd');
        return this.headers;
    }

    getSubscribeGroups(skip, limit):Observable<any> {
        return this.http.get(Environment.connectlyApiUrl + 'groups/premiumGroups/' + skip + '/' + limit, {headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupById(id) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/' + id, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }


    getGroupTheme(id) {
        return this.http.get(Environment.connectlyApiUrl + 'themes/' + id, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupMember(id, skip, limit) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/subscribers/basic/' + '?groupId=' + id + '&skip=' + skip + '&limit=' + limit, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupMemberCount(id) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/subscribers/count/' + '?groupId=' + id, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupTopics(id, skip, limit) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/' + id + '/topicsPaginate/' + skip + '/' + limit, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupTopicsCount(id) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/' + id + '/topicsCount', {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getThemes() {
        return this.http.get(Environment.connectlyApiUrl + 'themes', {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateGroupTheme(id, body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/' + id + '/theme', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewSubscribedOrg(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/addPremiumApp', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewSubgroup(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/add', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editNewSubscribedOrg(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/update', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteNewSubscribedOrg(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/dissolve', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getPremiumAppBuildSettings(id) {
        return this.http.get(Environment.connectlyApiUrl + 'premiumAppSettings/' + id, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addBuildSettings(body) {
        return this.http.post(Environment.connectlyApiUrl + 'premiumAppSettings', body, {headers: this.headers})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editBuildSettings(id, body) {

        return this.http.put(Environment.connectlyApiUrl + 'premiumAppSettings/' + id, body, {headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    searchMember(body) {
        return this.http.post(Environment.connectlyApiUrl + 'users/search', body, {headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    generatemanualBuild(body) {
        return this.http.post(Environment.connectlyApiUrl + 'triggerApplicationManualBuildProcess', body, {headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    uploadApk(body) {
        return this.http.post(Environment.connectlyApiUrl + 'buildProcess/uploadAPK', body, {headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }


    authorizedUserService(response) {
        if (response.flag == false) {
            if (response.code == 401) {
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
