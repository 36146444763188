/**
 * Created by hirenparekh on 12/11/16.
 */

import {Component} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {User} from "../../../user/models/user.model";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {Organization} from "../../models/organization.model";
import {BizBrainService} from "../../../bizbrain-services/models/bizbrain-service.model";

@Component({
  selector: 'add-org-user',
  templateUrl: './add-organization-user.component.html',
  styleUrls: ['./add-organization-user.component.css', '../organizations.component.scss']
})
export class AddOrganizationUserComponent {

  user: User;
  error: string;
  maxDate: Date;
  organization: Organization;
  users: User[] = [];
  service: BizBrainService[];
  constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute, private router: Router) {
    this.user = new User();
    this.maxDate = new Date();
  }

  addNewOrganizationUser(value: User, isValid: boolean) {

    if (isValid) {

      let orgId:string;

      this.activatedRoute.params.forEach((params:Params) => { orgId = params['id']});

      let body = {
        "org_id": orgId,
        "fname": this.user.fname,
        "lname": this.user.lname,
        "mname": this.user.mname,
        "email": this.user.email,
        "mobile": this.user.mobile,
        "username": this.user.userName,
        "password": this.user.password,
        "user_type": this.user.userType,
        "gender": this.user.gender,
        "birth_date": this.user.birthDate,
      };
      this.orgServices.addNewOrganizationUser(JSON.stringify(body))
        .subscribe(
          response => {
            if (response.json().flag) {
              this.viewOrganizationUsers();
            } else {
              this.error = response.json().message;
            }
          },
          error => {
            console.log(error);
          }
        );
    } else {

    }
  }
  viewOrganizationUsers() {
    let orgId:string;
    this.activatedRoute.params.forEach((params:Params) => { orgId = params['id']});
    this.router.navigate([AppRoutingConstants.organizations_view_id,orgId,'users']);
  }

  btnCancelClicked(){
    this.viewOrganizationUsers();
  }

  ngOnInit() {
    var orgId: string;

    this.activatedRoute.params.forEach((params: Params) => {
      orgId = params['id']
    });

    console.log("orgId",orgId);

    this.orgServices.getOrganizationById(orgId)
        .subscribe(
            response => {
              if (response.flag) {
                let data: any = response.data;
                this.organization = new Organization().getObject(data);

              } else {
                console.log(response.message);
              }
            },
            error => {
              console.log(error)
            }
        );
  }

}
