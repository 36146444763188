import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {RoleForm} from "../role-form/role-form.component";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'edit-role',
    templateUrl: './edit-role.component.html',
    styleUrls: ['./edit-role.component.css']
})
export class EditRole {
    //@Output() componentEditedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('editRole') editRole: ElementRef;
    @ViewChild('roleForm') roleForm: RoleForm;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    role: any;
    activeBlock: any;
    groupId: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
        this.activeBlock=this.connectlyProService.activeBlock;
    }



    ngOnInit() {
        let id='';
        this.groupId=this.connectlyProService.groupId;
       // let id = this.activeRoute.snapshot.parent.params['roleId'];
        this.activeRoute.params.subscribe(param => {
                id = param['roleId'];
            }
        );
        console.log("roleId",id);
        this.getRoleById(id);
    }

    getRoleById(id){

            this.connectlyProService.getRoleById(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data: any = response.data;
                            this.role = response.data;
                            this.roleForm.roleComponent =this.role;
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );

    }

    onFormSubmit(data:any){
        data.appId = this.groupId;
        this.connectlyProService.editNewRole(this.role.id,data)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                    this.closeModal();
                }else{
                    console.log(response.message);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: response.message
                    });
                }
            },
            error => {
                console.log(error);
                this.msgs = [];
                this.msgs.push({
                    severity: 'error',
                    summary: 'Error Message',
                    detail: ' Oops! Something Went Wrong'
                });
            },
            () => {

            }
        );
    }


    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId, 'roles']);
    }


}