import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {SubscribeOrgConnectlyProService} from "../../../services/subscribed-org.service";
import {commonAppsForm} from "./common/common-form.component";
import {AndroidAppsForm} from "./android/android-form.component";
import {IOSAppsForm} from "./ios/ios-form.component";
import {MenuItem} from "primeng/components/common/menuitem";
import {OnjectLengthFilter} from "../../../../ng-pipes/objectLength.pipe";
import {ConnectlyProService} from "../../../services/connectly-pro.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'apps-view-subscribe-org',
    templateUrl: './apps.component.html',
    styleUrls: ['./apps.component.scss'],
})
export class AppsViewSubscribeOrgComponent implements AfterViewInit {
    groupId:string;
    GroupProfile:string;

    selectedThemeId:string;
    GroupTheme:string;
    submitBtnVal:string = 'Add';

    groupMember:any;
    theme:any;
    group:any;
    loaderBlock = false;
    displayAddCommonForm = false;
    displayAddAndroidForm = false;
    displayAddIOSForm = false;
    displayAddAndroidCard = false;
    displayAddIosCard = false;
    constants = commonConstants;
    msgs:Message[] = [];
    items:MenuItem[];

    buildSettings:any = {};
    commonSettings:any = {};
    androidSettings:any = {};
    iosSettings:any = {};
    buildType:any;

    systemBuildVersionAndroidSettings:any;
    systemBuildVersionIosSettings:any;
    applicationBuildVersionAndroidSettings:any;
    applicationBuildVersionIosSettings:any;

    systemBuildProductionVersionAndroid:any;
    systemBuildProductionVersionIos:any;
    systemBuildBetaVersionIos:any;
    systemBuildBetaVersionAndroid:any;

    applicationBuildProductionVersionAndroid:any;
    applicationBuildProductionVersionIos:any;
    applicationBuildBetaVersionAndroid:any;
    applicationBuildBetaVersionIos:any;

    systemProductionAndroidId:any;
    systemBetaAndroidId:any;
    systemProductionIosId:any;
    systemBetaIosId:any;


    ProductionAndroidProcessId:any;
    BetaAndroidProcessId:any;
    ProductionIosProcessId:any;
    BetaIosProcessId:any;

    AndroidProductionLoader:boolean = false;
    AndroidBetaLoader:boolean = false;
    IosProductionLoader:boolean = false;
    IosBetaLoader:boolean = false;

    refreshIntervalId1:any;
    refreshIntervalId2:any;
    refreshIntervalId3:any;
    refreshIntervalId4:any;

    productionAndroidAppDownloadUrl:any;
    betaAndroidAppDownloadUrl:any;
    productionIosAppDownloadUrl:any;
    betaIosAppDownloadUrl:any;

    displayProcessStage1:boolean = false;
    displayProcessStage2:boolean = false;
    displayProcessStage3:boolean = false;
    displayProcessStage4:boolean = false;
    displayErrorCard:boolean = false;

    androidProductionStatusData:any;
    iosProductionStatusData:any;

    androidBetaStatusData:any;
    iosBetaStatusData:any;

    processStatusData1:any;
    processStatusData2:any;
    processStatusData3:any;
    processStatusData4:any;

    toggleAndroidProduction:boolean =false;
    toggleAndroidBeta:boolean =false;
    toggleIosProduction:boolean =false;
    toggleIosBeta:boolean =false;

    @ViewChild('addIOSModal') addIOSModal:IOSAppsForm;
    @ViewChild('addCommonModal') addCommonModal:commonAppsForm;
    @ViewChild('addAndroidModal') addAndroidModal:AndroidAppsForm;


    objectLength = new OnjectLengthFilter();

    constructor(private _titleService:Title, private router:Router,
                private activeRoute:ActivatedRoute,
                private connectlyProService:ConnectlyProService,
                private subscribeOrgConnectlyProService:SubscribeOrgConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
        this.buildType = {
            2: 'Beta',
            1: 'Production'
        }
    }

    ngAfterViewInit():void {
        // this._titleService.setTitle('Home Dashboard');
        this.toggleAndroidProduction=false;
        this.toggleAndroidBeta=false;

        this.toggleIosProduction=false;
        this.toggleIosBeta=false;

    }


    ngOnInit() {
        var value = true;
        console.log("--this.groupId", this.groupId);
        this.getGroupById(this.groupId);
        this.getPremiumAppBuildSettings(value);
    }

    openCommonFrom() {
        this.displayAddCommonForm = true;

        if (this.commonSettings != null || this.objectLength.transform(this.commonSettings) != 0) {
            this.addCommonModal.commonVal = this.commonSettings;
            this.submitBtnVal = 'Update';
        }
    }

    openAndroidFrom() {
        this.displayAddAndroidForm = true;
        if (this.addAndroidModal != null || this.objectLength.transform(this.androidSettings) != 0) {
            //this.addAndroidModal.androidVal = this.androidSettings;
            this.submitBtnVal = 'Update';
        }
    }

    openIOSFrom() {
        this.displayAddIOSForm = true;
        if (this.iosSettings != null || this.objectLength.transform(this.iosSettings) != 0) {
            // this.addIOSModal.iosVal = this.iosSettings;
            this.submitBtnVal = 'Update';
        }

    }

    displayMessage(msg) {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error Message', detail: msg});
    }

    getGroupById(groupId:string) {

        this.subscribeOrgConnectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:any = response.data;
                        this.group = response.data;
                       // console.log("provisioning---", this.group.applicationSettings.provisionStatus)

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    onNewIOScancel() {
        this.displayAddIOSForm = false;
        this.displayAddIosCard = false;
    }

    onNewAndroidcancel() {
        this.displayAddAndroidForm = false;
        this.displayAddAndroidCard = false;
    }

    getPremiumAppBuildSettings(value) {
        console.log(value);
        this.subscribeOrgConnectlyProService.getPremiumAppBuildSettings(this.groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:any = response.data;
                        if (data.length == 0) {
                            this.buildSettings = {};
                        }
                        if (data.length != 0) {
                            this.buildSettings = data['buildSettings'];


                            this.systemBuildVersionAndroidSettings = data['system'].androidBuildSettings;
                            this.systemBuildVersionIosSettings = data['system'].iosBuildSettings;
                            this.applicationBuildVersionAndroidSettings = data['application'].androidBuildSettings;
                            this.applicationBuildVersionIosSettings = data['application'].iosBuildSettings;
                            //console.log("this.systemBuildVersionSettings",this.systemBuildVersionAndroidSettings);

                            this.setVersionOfSettings(value);

                            if (this.buildSettings != null) {
                                this.commonSettings = this.buildSettings['commonSettings'];
                                this.androidSettings = this.buildSettings['androidSettings'];
                                this.iosSettings = this.buildSettings['iosSettings'];

                                if(this.buildSettings['androidSettings']){
                                    if(this.buildSettings['androidSettings'].stagingBuild){
                                        if(this.buildSettings['androidSettings'].stagingBuild == 1) this.toggleAndroidBeta=true;
                                    }
                                    if(this.buildSettings['androidSettings'].productionBuild){
                                        if(this.buildSettings['androidSettings'].productionBuild == 1) this.toggleAndroidProduction=true;
                                    }
                                }
                                if(this.buildSettings['iosSettings']){
                                    if(this.buildSettings['iosSettings'].stagingBuild){
                                        if(this.buildSettings['iosSettings'].stagingBuild == 1) this.toggleIosBeta=true;
                                    }
                                    if(this.buildSettings['iosSettings'].productionBuild){
                                        if(this.buildSettings['iosSettings'].productionBuild == 1) this.toggleIosProduction=true;
                                    }
                                }
                            } else {
                                this.commonSettings = null;
                                this.androidSettings = null;
                                this.iosSettings = null;
                            }

                        } else {
                            this.buildSettings = null;
                            this.commonSettings = null;
                            this.androidSettings = null;
                            this.iosSettings = null;
                        }


                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    toggleAndroidBetaDataChange(e) {
        //this.loaderBlock=true;
        //console.log(e);
        console.log(e.checked);
        //var toggle:boolean =false;
        var that=this;

        if (this.androidSettings && this.androidSettings != '') {
            if(this.androidSettings.playStoreFileName && this.androidSettings.playStoreFileName !=''){

                if(e.checked){
                    this.androidSettings['stagingBuild']=1;
                }else {
                    this.androidSettings['stagingBuild']=2;
                }
                let allSettings = {
                    appId: this.groupId,
                    buildSettings: {
                        commonSettings: this.commonSettings,
                        androidSettings: this.androidSettings,
                        iosSettings: this.iosSettings
                    }
                }
                this.editBuildSettings(allSettings, 'androidSettings');
            }else{

                if(e.checked){
                    //this.androidSettings['stagingBuild']=2;
                    //that.toggleAndroidBeta=false;
                    this.displayErrorCard=true;
                }


            }

        }

    }

    toggleAndroidProductionDataChange(e) {
        //this.loaderBlock=true;
        //var toggle:boolean =false;
        console.log(e.checked);
        if (this.androidSettings && this.androidSettings != '') {
            if(this.androidSettings.playStoreFileName && this.androidSettings.playStoreFileName !=''){
                if(e.checked){
                    this.androidSettings['productionBuild']=1;
                }else {
                    this.androidSettings['productionBuild']=2;
                }

                let allSettings = {
                    appId: this.groupId,
                    buildSettings: {
                        commonSettings: this.commonSettings,
                        androidSettings: this.androidSettings,
                        iosSettings: this.iosSettings
                    }
                }
                this.editBuildSettings(allSettings, 'androidSettings');
            }else{
                //this.androidSettings['productionBuild']=2;

                this.displayErrorCard=true;

            }
        }

    }

    toggleIosBetaDataChange(e) {
        //this.loaderBlock=true;
        //console.log(e);
        console.log(e.checked);
        //var toggle:boolean =false;
        var that=this;

        if (this.iosSettings && this.iosSettings != '') {
            /*if(this.iosSettings.playStoreFileName && this.iosSettings.playStoreFileName !=''){*/

                if(e.checked){
                    this.iosSettings['stagingBuild']=1;
                }else {
                    this.iosSettings['stagingBuild']=2;
                }
                let allSettings = {
                    appId: this.groupId,
                    buildSettings: {
                        commonSettings: this.commonSettings,
                        androidSettings: this.androidSettings,
                        iosSettings: this.iosSettings
                    }
                }
                this.editBuildSettings(allSettings, 'iosSettings');
            /*}else{

                if(e.checked){
                    //this.androidSettings['stagingBuild']=2;
                    //that.toggleAndroidBeta=false;
                    this.displayErrorCard=true;
                }


            }*/

        }

    }

    toggleIosProductionDataChange(e) {
        //this.loaderBlock=true;
        //var toggle:boolean =false;
        console.log(e.checked);
        if (this.iosSettings && this.iosSettings != '') {
            /*if(this.iosSettings.playStoreFileName && this.iosSettings.playStoreFileName !=''){*/
                if(e.checked){
                    this.iosSettings['productionBuild']=1;
                }else {
                    this.iosSettings['productionBuild']=2;
                }

                let allSettings = {
                    appId: this.groupId,
                    buildSettings: {
                        commonSettings: this.commonSettings,
                        androidSettings: this.androidSettings,
                        iosSettings: this.iosSettings
                    }
                }
                this.editBuildSettings(allSettings, 'iosSettings');
           /* }else{
                //this.androidSettings['productionBuild']=2;

                this.displayErrorCard=true;

            }*/
        }

    }

    errorBlockClose(){
        this.toggleAndroidProduction=false;
        this.toggleAndroidBeta=false;
        this.displayErrorCard=false;
    }

    setVersionOfSettings(value) {
        if (this.systemBuildVersionAndroidSettings.length == 1) {
            if (this.systemBuildVersionAndroidSettings[0].environment == 1) {
                this.systemBuildBetaVersionAndroid = this.systemBuildVersionAndroidSettings[0].buildVersionNumber
                this.systemBetaAndroidId = this.systemBuildVersionAndroidSettings[0].id
            }
            if (this.systemBuildVersionAndroidSettings[0].environment == 2) {
                this.systemBuildProductionVersionAndroid = this.systemBuildVersionAndroidSettings[0].buildVersionNumber
                this.systemProductionAndroidId = this.systemBuildVersionAndroidSettings[0].id
            }

        }

        if (this.systemBuildVersionIosSettings.length == 1) {
            if (this.systemBuildVersionIosSettings[0].environment == 1) {
                this.systemBuildBetaVersionIos = this.systemBuildVersionIosSettings[0].buildVersionNumber
                this.systemBetaIosId = this.systemBuildVersionIosSettings[0].id
            }
            if (this.systemBuildVersionIosSettings[0].environment == 2) {
                this.systemBuildProductionVersionIos = this.systemBuildVersionIosSettings[0].buildVersionNumber
                this.systemProductionIosId = this.systemBuildVersionIosSettings[0].id
            }

        }

        if (this.systemBuildVersionAndroidSettings.length == 2) {
            if (this.systemBuildVersionAndroidSettings[0].environment == 1) {
                this.systemBuildBetaVersionAndroid = this.systemBuildVersionAndroidSettings[0].buildVersionNumber
                this.systemBetaAndroidId = this.systemBuildVersionAndroidSettings[0].id
            } else {
                this.systemBuildProductionVersionAndroid = this.systemBuildVersionAndroidSettings[0].buildVersionNumber
                this.systemProductionAndroidId = this.systemBuildVersionAndroidSettings[0].id
            }

            if (this.systemBuildVersionAndroidSettings[1].environment == 1) {
                this.systemBuildBetaVersionAndroid = this.systemBuildVersionAndroidSettings[1].buildVersionNumber
                this.systemBetaAndroidId = this.systemBuildVersionAndroidSettings[1].id
            } else {
                this.systemBuildProductionVersionAndroid = this.systemBuildVersionAndroidSettings[1].buildVersionNumber
                this.systemProductionAndroidId = this.systemBuildVersionAndroidSettings[1].id
            }

        }

        if (this.systemBuildVersionIosSettings.length == 2) {
            if (this.systemBuildVersionIosSettings[0].environment == 1) {
                this.systemBuildBetaVersionIos = this.systemBuildVersionIosSettings[0].buildVersionNumber
                this.systemBetaIosId = this.systemBuildVersionIosSettings[0].id
            } else {
                this.systemBuildProductionVersionIos= this.systemBuildVersionIosSettings[0].buildVersionNumber
                this.systemProductionIosId = this.systemBuildVersionIosSettings[0].id
            }

            if (this.systemBuildVersionIosSettings[1].environment == 1) {
                this.systemBuildBetaVersionIos = this.systemBuildVersionIosSettings[1].buildVersionNumber
                this.systemBetaIosId = this.systemBuildVersionIosSettings[1].id
            } else {
                this.systemBuildProductionVersionIos = this.systemBuildVersionIosSettings[1].buildVersionNumber
                this.systemProductionIosId = this.systemBuildVersionIosSettings[1].id
            }

        }

        if (this.applicationBuildVersionAndroidSettings.length == 1) {
            if (this.applicationBuildVersionAndroidSettings[0].environment == 1) {
                this.applicationBuildBetaVersionAndroid = this.applicationBuildVersionAndroidSettings[0].buildVersionNumber
                this.BetaAndroidProcessId = this.applicationBuildVersionAndroidSettings[0].processId;


                if (this.BetaAndroidProcessId && value == true) {
                    this.AndroidBetaLoader = true;
                    this.refreshIntervalId2 = setInterval(() => {
                        this.checkBuildProcessOnSecond2(this.BetaAndroidProcessId, 'BetaAndroidProcessId');
                    }, 1000);
                }

                this.betaAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[0].androidAppDownloadURL;
            }
            if (this.applicationBuildVersionAndroidSettings[0].environment == 2) {
                this.applicationBuildProductionVersionAndroid = this.applicationBuildVersionAndroidSettings[0].buildVersionNumber
                this.ProductionAndroidProcessId = this.applicationBuildVersionAndroidSettings[0].processId


                if (this.ProductionAndroidProcessId && value == true) {
                    this.AndroidProductionLoader = true;
                    this.refreshIntervalId1 = setInterval(() => {
                        this.checkBuildProcessOnSecond1(this.ProductionAndroidProcessId, 'ProductionAndroidProcessId');
                    }, 1000);
                }
                this.productionAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[0].androidAppDownloadURL;
            }

        }

        if (this.applicationBuildVersionIosSettings.length == 1) {
            if (this.applicationBuildVersionIosSettings[0].environment == 1) {
                this.applicationBuildBetaVersionIos = this.applicationBuildVersionIosSettings[0].buildVersionNumber
                this.BetaIosProcessId = this.applicationBuildVersionIosSettings[0].processId;


                if (this.BetaIosProcessId && value == true) {
                    this.IosBetaLoader = true;
                    this.refreshIntervalId4 = setInterval(() => {
                        this.checkBuildProcessOnSecond4(this.BetaIosProcessId, 'BetaIosProcessId');
                    }, 1000);
                }

                this.betaIosAppDownloadUrl = this.applicationBuildVersionIosSettings[0].iosAppDownloadURL;
            }
            if (this.applicationBuildVersionIosSettings[0].environment == 2) {
                this.applicationBuildProductionVersionIos = this.applicationBuildVersionIosSettings[0].buildVersionNumber
                this.ProductionIosProcessId = this.applicationBuildVersionIosSettings[0].processId


                if (this.ProductionIosProcessId && value == true) {
                    this.IosProductionLoader = true;
                    this.refreshIntervalId3 = setInterval(() => {
                        this.checkBuildProcessOnSecond3(this.ProductionIosProcessId, 'ProductionIosProcessId');
                    }, 1000);
                }
                this.productionIosAppDownloadUrl = this.applicationBuildVersionIosSettings[0].iosAppDownloadURL;
            }

        }

        if (this.applicationBuildVersionAndroidSettings.length == 2) {
            if (this.applicationBuildVersionAndroidSettings[0].environment == 1) {
                this.applicationBuildBetaVersionAndroid = this.applicationBuildVersionAndroidSettings[0].buildVersionNumber
                this.BetaAndroidProcessId = this.applicationBuildVersionAndroidSettings[0].processId;


                if (this.BetaAndroidProcessId && value == true) {
                    this.AndroidBetaLoader = true;
                    this.refreshIntervalId2 = setInterval(() => {
                        this.checkBuildProcessOnSecond2(this.BetaAndroidProcessId, 'BetaAndroidProcessId');
                    }, 1000);
                }

                this.betaAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[0].androidAppDownloadURL;

            } else {
                this.applicationBuildProductionVersionAndroid = this.applicationBuildVersionAndroidSettings[0].buildVersionNumber
                this.ProductionAndroidProcessId = this.applicationBuildVersionAndroidSettings[0].processId


                if (this.ProductionAndroidProcessId && value == true) {
                    this.AndroidProductionLoader = true;
                    this.refreshIntervalId1 = setInterval(() => {
                        this.checkBuildProcessOnSecond1(this.ProductionAndroidProcessId, 'ProductionAndroidProcessId');
                    }, 1000);
                }

                this.productionAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[0].androidAppDownloadURL;
            }

            if (this.applicationBuildVersionAndroidSettings[1].environment == 1) {
                this.applicationBuildBetaVersionAndroid = this.applicationBuildVersionAndroidSettings[1].buildVersionNumber
                this.BetaAndroidProcessId = this.applicationBuildVersionAndroidSettings[1].processId;


                if (this.BetaAndroidProcessId && value == true) {
                    this.AndroidBetaLoader = true;
                    this.refreshIntervalId2 = setInterval(() => {
                        this.checkBuildProcessOnSecond2(this.BetaAndroidProcessId, 'BetaAndroidProcessId');
                    }, 1000);
                }

                this.betaAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[1].androidAppDownloadURL;
            } else {
                this.applicationBuildProductionVersionAndroid = this.applicationBuildVersionAndroidSettings[1].buildVersionNumber
                this.ProductionAndroidProcessId = this.applicationBuildVersionAndroidSettings[1].processId;


                if (this.ProductionAndroidProcessId && value == true) {
                    this.AndroidProductionLoader = true;
                    this.refreshIntervalId1 = setInterval(() => {
                        this.checkBuildProcessOnSecond1(this.ProductionAndroidProcessId, 'ProductionAndroidProcessId');
                    }, 1000);
                }
                this.productionAndroidAppDownloadUrl = this.applicationBuildVersionAndroidSettings[1].androidAppDownloadURL;
            }

        }

        if (this.applicationBuildVersionIosSettings.length == 2) {
            if (this.applicationBuildVersionIosSettings[0].environment == 1) {
                this.applicationBuildBetaVersionIos = this.applicationBuildVersionIosSettings[0].buildVersionNumber
                this.BetaIosProcessId = this.applicationBuildVersionIosSettings[0].processId;


                if (this.BetaIosProcessId && value == true) {
                    this.IosBetaLoader = true;
                    this.refreshIntervalId4 = setInterval(() => {
                        this.checkBuildProcessOnSecond4(this.BetaIosProcessId, 'BetaIosProcessId');
                    }, 1000);
                }

                this.betaIosAppDownloadUrl = this.applicationBuildVersionIosSettings[0].iosAppDownloadURL;

            } else {
                this.applicationBuildProductionVersionIos = this.applicationBuildVersionIosSettings[0].buildVersionNumber
                this.ProductionIosProcessId = this.applicationBuildVersionIosSettings[0].processId


                if (this.ProductionIosProcessId && value == true) {
                    this.IosProductionLoader = true;
                    this.refreshIntervalId3 = setInterval(() => {
                        this.checkBuildProcessOnSecond3(this.ProductionIosProcessId, 'ProductionIosProcessId');
                    }, 1000);
                }

                this.productionIosAppDownloadUrl = this.applicationBuildVersionIosSettings[0].iosAppDownloadURL;
            }

            if (this.applicationBuildVersionIosSettings[1].environment == 1) {
                this.applicationBuildBetaVersionIos = this.applicationBuildVersionIosSettings[1].buildVersionNumber
                this.BetaIosProcessId = this.applicationBuildVersionIosSettings[1].processId;


                if (this.BetaIosProcessId && value == true) {
                    this.IosBetaLoader = true;
                    this.refreshIntervalId4 = setInterval(() => {
                        this.checkBuildProcessOnSecond4(this.BetaIosProcessId, 'BetaIosProcessId');
                    }, 1000);
                }

                this.betaIosAppDownloadUrl = this.applicationBuildVersionIosSettings[1].iosAppDownloadURL;
            } else {
                this.applicationBuildProductionVersionIos = this.applicationBuildVersionIosSettings[1].buildVersionNumber
                this.ProductionIosProcessId = this.applicationBuildVersionIosSettings[1].processId;


                if (this.ProductionIosProcessId && value == true) {
                    this.IosProductionLoader = true;
                    this.refreshIntervalId3 = setInterval(() => {
                        this.checkBuildProcessOnSecond3(this.ProductionIosProcessId, 'ProductionIosProcessId');
                    }, 1000);
                }
                this.productionIosAppDownloadUrl = this.applicationBuildVersionIosSettings[1].iosAppDownloadURL;
            }

        }

        //this.loaderBlock=false;
    }

    ngOnDestroy() {
        if (this.refreshIntervalId1) {
            clearInterval(this.refreshIntervalId1);
        }
        if (this.refreshIntervalId2) {
            clearInterval(this.refreshIntervalId2);
        }
        if (this.refreshIntervalId3) {
            clearInterval(this.refreshIntervalId3);
        }
        if (this.refreshIntervalId4) {
            clearInterval(this.refreshIntervalId4);
        }
    }

    generatemanualBuild(id, value) {
        let tempdata = {
            "appId": this.groupId,
            "buildId": id
        }

        if (value == 'AndroidProductionBuild') {
            this.AndroidProductionLoader = true;
        }
        if (value == 'AndroidBetaBuild') {
            this.AndroidBetaLoader = true;
        }
        if (value == 'IosProductionBuild') {
            this.IosProductionLoader = true;
        }
        if (value == 'IosBetaBuild') {
            this.IosBetaLoader = true;
        }
        this.subscribeOrgConnectlyProService.generatemanualBuild(tempdata)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:any = response.data;
                        if (data.environment == 2 && data.platform == 1) {
                         this.ProductionAndroidProcessId = data.processId;
                         this.refreshIntervalId1 = setInterval(() => {
                         this.checkBuildProcessOnSecond1(this.ProductionAndroidProcessId, 'ProductionAndroidProcessId');
                         }, 1000);
                         }
                         if (data.environment == 1 && data.platform == 1) {
                         this.BetaAndroidProcessId = data.processId;
                         this.refreshIntervalId2 = setInterval(() => {
                         this.checkBuildProcessOnSecond2(this.BetaAndroidProcessId, 'BetaAndroidProcessId');
                         }, 1000);
                         }

                        if (data.environment == 2 && data.platform == 2) {
                         this.ProductionIosProcessId = data.processId;
                         this.refreshIntervalId3 = setInterval(() => {
                         this.checkBuildProcessOnSecond3(this.ProductionIosProcessId, 'ProductionIosProcessId');
                         }, 1000);
                         }
                         if (data.environment == 1 && data.platform == 2) {
                         this.BetaIosProcessId = data.processId;
                         this.refreshIntervalId4 = setInterval(() => {
                         this.checkBuildProcessOnSecond4(this.BetaIosProcessId, 'BetaIosProcessId');
                         }, 1000);
                         }
                       // var value = true;
                        //this.getPremiumAppBuildSettings(value)

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }


    addBuildSettings(allSettings, setting) {
        this.subscribeOrgConnectlyProService.addBuildSettings(allSettings)
            .subscribe(
                response => {
                    if (response.flag) {
                        //console.log(response.message);
                        //console.log(response.data);
                        /*let data:any = response.data;
                         this.buildSettings = data['buildSettings'];
                         this.commonSettings = this.buildSettings['commonSettings'];
                         this.androidSettings = this.buildSettings['androidSettings'];
                         this.iosSettings = this.buildSettings['iosSettings'];*/
                        this.getPremiumAppBuildSettings(true);

                        if (setting == 'commonSettings') {
                            this.addCommonModal.resetForm();
                        }

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeCommonModal();
                }
            );
    }

    editBuildSettings(allSettings, setting) {
        this.subscribeOrgConnectlyProService.editBuildSettings(this.groupId, allSettings)
            .subscribe(
                response => {

                    if (response.flag) {
                        //console.log(response.data);
                        let data:any = response.data;
                         this.buildSettings = data['buildSettings'];
                         this.commonSettings = this.buildSettings['commonSettings'];
                         this.androidSettings = this.buildSettings['androidSettings'];
                         this.iosSettings = this.buildSettings['iosSettings'];
                        //this.getPremiumAppBuildSettings(true);

                        if (setting == 'commonSettings') {
                            this.addCommonModal.resetForm();
                        }
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }


                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeCommonModal();
                }
            );
    }

    onNewCommonAdded(formData) {
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                commonSettings: formData,
                androidSettings: this.androidSettings,
                iosSettings: this.iosSettings
            }
        }
        this.addBuildSettings(allSettings, 'commonSettings');
        // this.addCommonModal.resetForm();

    }

    onNewIOSAdded(formData) {
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                commonSettings: this.commonSettings,
                androidSettings: this.androidSettings,
                iosSettings: formData
            }
        }
        this.addBuildSettings(allSettings, 'iosSettings');
        this.displayAddIosCard = false;
    }

    onNewAndroidAdded(formData) {
        formData.stagingBuild=2;
        formData.productionBuild=2;
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                commonSettings: this.commonSettings,
                androidSettings: formData,
                iosSettings: this.iosSettings
            }
        }
        this.addBuildSettings(allSettings, 'androidSettings');
        this.displayAddAndroidCard = false;
    }

    onNewCommonEdit(formData) {
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                commonSettings: formData,
                androidSettings: this.androidSettings,
                iosSettings: this.iosSettings
            }
        }
        console.log("allSettings", allSettings)
        this.editBuildSettings(allSettings, 'commonSettings');
        //this.addCommonModal.resetForm();
    }

    onNewIosEdit(formData) {
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                iosSettings: formData
            }
        }
        console.log("allSettings", allSettings)
        this.editBuildSettings(allSettings, 'iosSettings');
    }

    onNewAndroidEdit(formData) {
        let allSettings = {
            appId: this.groupId,
            buildSettings: {
                androidSettings: formData
            }
        }
        console.log("allSettings", allSettings)
        this.editBuildSettings(allSettings, 'androidSettings');
    }

    closeCommonModal() {
        this.displayAddCommonForm = false;
        this.displayAddAndroidForm = false;
        this.displayAddIOSForm = false;
    }

    resetCommonForm() {
        if(this.addCommonModal){
            this.addCommonModal.resetForm();  
        }
       
        this.displayAddCommonForm = false;
    }

    openAndroidCard() {
        this.displayAddAndroidCard = true;
        this.displayAddAndroidForm = false;
    }

    openIosCard() {
        this.displayAddIosCard = true;
        this.displayAddIOSForm = false;
    }

    viewProcessData(val) {
        console.log("val--", val)
        console.log("this.androidProductionStatusData--", this.androidProductionStatusData)
        console.log("this.androidProductionStatusData--", this.iosProductionStatusData)

        this.processStatusData1 = {};
        this.processStatusData2 = {};
        this.processStatusData3 = {};
        this.processStatusData4 = {};
        if (val == 'refreshIntervalId1') {
            this.displayProcessStage1 = true;
            this.processStatusData1 = this.androidProductionStatusData;
        }

        if (val == 'refreshIntervalId2') {
            this.displayProcessStage2 = true;
            this.processStatusData2 = this.androidBetaStatusData;
        }

        if (val == 'refreshIntervalId3') {
            this.displayProcessStage3 = true;
            this.processStatusData3 = this.iosProductionStatusData;
        }

        if (val == 'refreshIntervalId4') {
            this.displayProcessStage4 = true;
            this.processStatusData4 = this.iosBetaStatusData;
        }

    }

    closePopup(){
        this.displayProcessStage1 = false;
        this.displayProcessStage2 = false;
        this.displayProcessStage3 = false;
        this.displayProcessStage4 = false;
    }

    checkBuildProcessOnSecond1(id, value) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {

                        this.processStatusData1 ={}

                        let data = response.data;
                        if (value == 'ProductionAndroidProcessId') {
                            this.androidProductionStatusData = data;
                            this.processStatusData1 = data;
                        }

                        console.log("processData------", data)

                        if (data.status == 'succeed' || data.status == 'failed') {
                            var values = false
                            this.getPremiumAppBuildSettings(values);
                            if (value == 'ProductionAndroidProcessId') {
                                clearInterval(this.refreshIntervalId1);
                                this.AndroidProductionLoader = false;
                            }

                            //this.displayProcessStage1 = false;
                        }

                        if (data.status == 'failed') {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: 'Build Failed'
                            });
                        }


                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }
    checkBuildProcessOnSecond2(id, value) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {

                        this.processStatusData2 ={}

                        let data = response.data;

                        if (value == 'BetaAndroidProcessId') {
                            this.androidBetaStatusData = data;
                            this.processStatusData2 = data;
                        }
                        console.log("processData------", data)

                        if (data.status == 'succeed' || data.status == 'failed') {
                            var values = false
                            this.getPremiumAppBuildSettings(values);

                            if (value == 'BetaAndroidProcessId') {
                                clearInterval(this.refreshIntervalId2);
                                this.AndroidBetaLoader = false;
                            }
                            //this.displayProcessStage1 = false;
                        }

                        if (data.status == 'failed') {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: 'Build Failed'
                            });
                        }


                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }
    checkBuildProcessOnSecond3(id, value) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {

                        this.processStatusData3 ={}

                        let data = response.data;
                        if (value == 'ProductionIosProcessId') {
                            this.iosProductionStatusData = data;
                            this.processStatusData3 = data;
                        }

                        console.log("processData------", data)

                        if (data.status == 'succeed' || data.status == 'failed') {
                            var values = false
                            this.getPremiumAppBuildSettings(values);
                            if (value == 'ProductionIosProcessId') {
                                clearInterval(this.refreshIntervalId3);
                                this.IosProductionLoader = false;
                            }

                            //this.displayProcessStage1 = false;
                        }

                        if (data.status == 'failed') {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: 'Build Failed'
                            });
                        }


                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }
    checkBuildProcessOnSecond4(id, value) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {

                        this.processStatusData4 ={}

                        let data = response.data;

                        if (value == 'BetaIosProcessId') {
                            this.iosBetaStatusData = data;
                            this.processStatusData4 = data;
                        }
                        console.log("processData------", data)

                        if (data.status == 'succeed' || data.status == 'failed') {
                            var values = false
                            this.getPremiumAppBuildSettings(values);

                            if (value == 'BetaIosProcessId') {
                                clearInterval(this.refreshIntervalId4);
                                this.IosBetaLoader = false;
                            }
                            //this.displayProcessStage1 = false;
                        }

                        if (data.status == 'failed') {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: 'Build Failed'
                            });
                        }


                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }
}
