import {Component, ViewChild, ElementRef, EventEmitter, Output, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';

declare var jQuery: any;

@Component({
    selector: 'app-agent-detail',
    templateUrl: './agent-detail.component.html',
    styleUrls: ['./agent-detail.component.css']
})
export class ShowAgentDetailComponent {
    @Output() showAgentDetailEvent: EventEmitter<any> = new EventEmitter<any>();
    agentDetail: any;
    activeTabIndex: number;

    constructor(private router: Router, private activeRoute: ActivatedRoute) {

    }

    ngOnInit() {
        this.activeTabIndex = 0;
    }


}
