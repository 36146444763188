import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Connector} from "../../../connectors/models/connector.model";
import {ConnectorsService} from "../../../connectors/services/connectors.service";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {Title} from "@angular/platform-browser";
import {SubscribeOrgConnectlyProService} from "../../services/subscribed-org.service";
import {Organization} from "../../../organization/models/organization.model";
import {OrganizationService} from "../../../organization/services/organization.service";

@Component({
    selector: 'theme-form',
    templateUrl: './theme-form.component.html',
    styleUrls: ['./theme-form.component.css']
})
export class ThemeForm {
    error:any;
    themeForm: FormGroup;
    scheduleOptForm: FormGroup;
    serviceProviders: any;
    is_active: boolean;
    selectedImageUrlFile: any;
    selectedImageUrlFileData: any;
    selectedOverviewImageUrlFile: any;
    selectedOverviewImageUrlFileData: any;
    categoryTypes: SelectItem[];
    color2: string = '#1976D2';

    private _theme: any;
    valueSchedule: string[] = [];
    uploadImageUrl: string;
    uploadImageUrl2: string;


    organizations: Organization[];

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();


    get theme() {
        return this._theme;
    }

    @Input()
    set theme(value) {
        console.log("subscribeGroup:", value);

        this._theme = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router, private connectlyProService: ConnectlyProService,
                private activeRoute: ActivatedRoute, private orgServices: OrganizationService) {

    }

    ngOnInit() {
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.themeForm = new FormGroup({
            name: new FormControl("", Validators.required),
            category: new FormControl("", Validators.required),
            toolbarStartColor: new FormControl("", Validators.required),
            toolbarEndColor: new FormControl("", Validators.required),
            datePanelColor: new FormControl("", Validators.required),
            pinColor: new FormControl("", Validators.required),
            pinBackgroundColor: new FormControl("", Validators.required),
            defaultUserAvatarColor: new FormControl("", Validators.required),
            backgroundMessageOursColor: new FormControl("", Validators.required),
            backgroundMessageOursDarkColor: new FormControl("", Validators.required),
            backgroundMessageOthersColor: new FormControl("", Validators.required),
            backgroundMessageOthersDarkColor: new FormControl("", Validators.required),
            progressbarDefaultColor: new FormControl("", Validators.required),
            progressbarStartColor: new FormControl("", Validators.required),
            progressbarEndColor: new FormControl("", Validators.required),
            messageTopLineDarkColor: new FormControl("", Validators.required),
            messageTopLineMiddleColor: new FormControl("", Validators.required),
            messageIconBackgroundTopColor: new FormControl("", Validators.required),
            messageIconBackgroundBottomColor: new FormControl("", Validators.required),
            messageResponseButtonColor: new FormControl("", Validators.required),
            pollMessageButtonColor: new FormControl("", Validators.required),
            pollMessageSelectedButtonColor: new FormControl("", Validators.required),
            attachmentDownloadProgressColor: new FormControl("", Validators.required),
            voiceButtonColor: new FormControl("", Validators.required),
            panelColor: new FormControl("", Validators.required),
            unreadMessagePanelColor: new FormControl("", Validators.required),
            datePanelTextColor: new FormControl("", Validators.required),
            newMessageBackgroundColor: new FormControl("", Validators.required),
            newMessageForegroundColor: new FormControl("", Validators.required),


            toolbarStartColor1: new FormControl('1976D2', Validators.required),
            toolbarEndColor1: new FormControl("#5e736d", Validators.required),
            datePanelColor1: new FormControl("#5e736d", Validators.required),
            pinColor1: new FormControl("#5e736d", Validators.required),
            pinBackgroundColor1: new FormControl("#5e736d", Validators.required),
            defaultUserAvatarColor1: new FormControl("#5e736d", Validators.required),
            backgroundMessageOursColor1: new FormControl("#5e736d", Validators.required),
            backgroundMessageOursDarkColor1: new FormControl("#5e736d", Validators.required),
            backgroundMessageOthersColor1: new FormControl("#5e736d", Validators.required),
            backgroundMessageOthersDarkColor1: new FormControl("#5e736d", Validators.required),
            progressbarDefaultColor1: new FormControl("#5e736d", Validators.required),
            progressbarStartColor1: new FormControl("#5e736d", Validators.required),
            progressbarEndColor1: new FormControl("#5e736d", Validators.required),
            messageTopLineDarkColor1: new FormControl("#5e736d", Validators.required),
            messageTopLineMiddleColor1: new FormControl("#5e736d", Validators.required),
            messageIconBackgroundTopColor1: new FormControl("#5e736d", Validators.required),
            messageIconBackgroundBottomColor1: new FormControl("#5e736d", Validators.required),
            messageResponseButtonColor1: new FormControl("#5e736d", Validators.required),
            pollMessageButtonColor1: new FormControl("#5e736d", Validators.required),
            pollMessageSelectedButtonColor1: new FormControl("#5e736d", Validators.required),
            attachmentDownloadProgressColor1: new FormControl("#5e736d", Validators.required),
            voiceButtonColor1: new FormControl("#5e736d", Validators.required),
            panelColor1: new FormControl("#5e736d", Validators.required),
            unreadMessagePanelColor1: new FormControl("#5e736d", Validators.required),
            datePanelTextColor1: new FormControl("#5e736d", Validators.required),
            newMessageBackgroundColor1: new FormControl("#5e736d", Validators.required),
            newMessageForegroundColor1: new FormControl("#5e736d", Validators.required),
        });

        this.categoryTypes = [];
        this.categoryTypes.push({label: 'Select Category Type', value: null});
        this.categoryTypes.push({value: 1, label: 'Friends'});
        this.categoryTypes.push({value: 2, label: 'Family'});
        this.categoryTypes.push({value: 3, label: 'Neighbours'});
        this.categoryTypes.push({value: 4, label: 'Business'});
        this.categoryTypes.push({value: 5, label: 'Classmetes'});
        this.categoryTypes.push({value: 6, label: 'Association'});
        this.categoryTypes.push({value: 7, label: 'Project'});
        this.categoryTypes.push({value: 8, label: 'Community'});
        this.categoryTypes.push({value: 9, label: 'Interest'});
        this.categoryTypes.push({value: 10, label: 'Locality'});
        this.categoryTypes.push({value: 11, label: 'Smart City'});
        this.categoryTypes.push({value: 12, label: 'Custom'});
    }

    getFormData() {

        let formData = {
            name: this.themeForm.value['name'],
            category: this.themeForm.value['category'],
            toolbarStartColor: this.themeForm.value['toolbarStartColor'],
            toolbarEndColor: this.themeForm.value['toolbarEndColor'],
            datePanelColor: this.themeForm.value['datePanelColor'],
            pinColor: this.themeForm.value['pinColor'],
            pinBackgroundColor: this.themeForm.value['pinBackgroundColor'],
            defaultUserAvatarColor: this.themeForm.value['defaultUserAvatarColor'],
            backgroundMessageOursColor: this.themeForm.value['backgroundMessageOursColor'],
            backgroundMessageOursDarkColor: this.themeForm.value['backgroundMessageOursDarkColor'],
            backgroundMessageOthersColor: this.themeForm.value['backgroundMessageOthersColor'],
            backgroundMessageOthersDarkColor: this.themeForm.value['backgroundMessageOthersDarkColor'],
            progressbarDefaultColor: this.themeForm.value['progressbarDefaultColor'],
            progressbarStartColor: this.themeForm.value['progressbarStartColor'],
            progressbarEndColor: this.themeForm.value['progressbarEndColor'],
            messageTopLineDarkColor: this.themeForm.value['messageTopLineDarkColor'],
            messageTopLineMiddleColor: this.themeForm.value['messageTopLineMiddleColor'],
            messageIconBackgroundTopColor: this.themeForm.value['messageIconBackgroundTopColor'],
            messageIconBackgroundBottomColor: this.themeForm.value['messageIconBackgroundBottomColor'],
            messageResponseButtonColor: this.themeForm.value['messageResponseButtonColor'],
            pollMessageButtonColor: this.themeForm.value['pollMessageButtonColor'],
            pollMessageSelectedButtonColor: this.themeForm.value['pollMessageSelectedButtonColor'],
            attachmentDownloadProgressColor: this.themeForm.value['attachmentDownloadProgressColor'],
            voiceButtonColor: this.themeForm.value['voiceButtonColor'],
            panelColor: this.themeForm.value['panelColor'],
            unreadMessagePanelColor: this.themeForm.value['unreadMessagePanelColor'],
            datePanelTextColor: this.themeForm.value['datePanelTextColor'],
            newMessageBackgroundColor: this.themeForm.value['newMessageBackgroundColor'],
            newMessageForegroundColor: this.themeForm.value['newMessageForegroundColor'],
        }

        console.log("getter : ", formData);
        return formData;

    }

    setValues() {
        console.log("setvalue : ",);
        let value = {
            name: this._theme.name,
            category: this._theme.category,
            toolbarStartColor: this._theme.toolbarStartColor.toUpperCase(),
            toolbarEndColor: this._theme.toolbarEndColor.toUpperCase(),
            datePanelColor: this._theme.datePanelColor.toUpperCase(),
            pinColor: this._theme.pinColor.toUpperCase(),
            pinBackgroundColor: this._theme.pinBackgroundColor.toUpperCase(),
            defaultUserAvatarColor: this._theme.defaultUserAvatarColor.toUpperCase(),
            backgroundMessageOursColor: this._theme.backgroundMessageOursColor.toUpperCase(),
            backgroundMessageOursDarkColor: this._theme.backgroundMessageOursDarkColor.toUpperCase(),
            backgroundMessageOthersColor: this._theme.backgroundMessageOthersColor.toUpperCase(),
            backgroundMessageOthersDarkColor: this._theme.backgroundMessageOthersDarkColor.toUpperCase(),
            progressbarDefaultColor: this._theme.progressbarDefaultColor.toUpperCase(),
            progressbarStartColor: this._theme.progressbarStartColor.toUpperCase(),
            progressbarEndColor: this._theme.progressbarEndColor.toUpperCase(),
            messageTopLineDarkColor: this._theme.messageTopLineDarkColor.toUpperCase(),
            messageTopLineMiddleColor: this._theme.messageTopLineMiddleColor.toUpperCase(),
            messageIconBackgroundTopColor: this._theme.messageIconBackgroundTopColor.toUpperCase(),
            messageIconBackgroundBottomColor: this._theme.messageIconBackgroundBottomColor.toUpperCase(),
            messageResponseButtonColor: this._theme.messageResponseButtonColor.toUpperCase(),
            pollMessageButtonColor: this._theme.pollMessageButtonColor.toUpperCase(),
            pollMessageSelectedButtonColor: this._theme.pollMessageSelectedButtonColor.toUpperCase(),
            attachmentDownloadProgressColor: this._theme.attachmentDownloadProgressColor.toUpperCase(),
            voiceButtonColor: this._theme.voiceButtonColor.toUpperCase(),
            panelColor: this._theme.panelColor.toUpperCase(),
            unreadMessagePanelColor: this._theme.unreadMessagePanelColor.toUpperCase(),
            datePanelTextColor: this._theme.datePanelTextColor.toUpperCase(),
            newMessageBackgroundColor: this._theme.newMessageBackgroundColor.toUpperCase(),
            newMessageForegroundColor: this._theme.newMessageForegroundColor.toUpperCase(),

        };

        this.themeForm.reset(value);

        console.log(" this.themeForm-", this.themeForm)

    }

    resetForm() {
        this.themeForm.reset();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.themeForm = new FormGroup({
            name: new FormControl("", Validators.required),
            category: new FormControl("", Validators.required),
            toolbarStartColor: new FormControl("", Validators.required),
            toolbarEndColor: new FormControl("", Validators.required),
            datePanelColor: new FormControl("", Validators.required),
            pinColor: new FormControl("", Validators.required),
            pinBackgroundColor: new FormControl("", Validators.required),
            defaultUserAvatarColor: new FormControl("", Validators.required),
            backgroundMessageOursColor: new FormControl("", Validators.required),
            backgroundMessageOursDarkColor: new FormControl("", Validators.required),
            backgroundMessageOthersColor: new FormControl("", Validators.required),
            backgroundMessageOthersDarkColor: new FormControl("", Validators.required),
            progressbarDefaultColor: new FormControl("", Validators.required),
            progressbarStartColor: new FormControl("", Validators.required),
            progressbarEndColor: new FormControl("", Validators.required),
            messageTopLineDarkColor: new FormControl("", Validators.required),
            messageTopLineMiddleColor: new FormControl("", Validators.required),
            messageIconBackgroundTopColor: new FormControl("", Validators.required),
            messageIconBackgroundBottomColor: new FormControl("", Validators.required),
            messageResponseButtonColor: new FormControl("", Validators.required),
            pollMessageButtonColor: new FormControl("", Validators.required),
            pollMessageSelectedButtonColor: new FormControl("", Validators.required),
            attachmentDownloadProgressColor: new FormControl("", Validators.required),
            voiceButtonColor: new FormControl("", Validators.required),
            panelColor: new FormControl("", Validators.required),
            unreadMessagePanelColor: new FormControl("", Validators.required),
            datePanelTextColor: new FormControl("", Validators.required),
            newMessageBackgroundColor: new FormControl("", Validators.required),
            newMessageForegroundColor: new FormControl("", Validators.required),
        });
        this.router.navigate([AppRoutingConstants.themes]);


    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedImageUrlFile);
            this.selectedImageUrlFileData = reader.result;
            this.uploadImage(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }


    onFileSelected2(event) {
        this.showMyImage2(event);
    }

    showMyImage2(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedOverviewImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedOverviewImageUrlFile);
            this.selectedOverviewImageUrlFileData = reader.result;
            this.uploadImage2(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }

    uploadImage(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl = response.data;
                        this.selectedImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );

    }

    uploadImage2(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl2 = response.data;
                        this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );

    }

    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w200';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    selectColor(e,from,to) {
        let value = '#' + this.themeForm.value[from].toUpperCase();

        this.themeForm.controls[to]= new FormControl(value);

    }

    addNewTheme(event) {
        //this.formSubmitEvent.emit(this.getFormData());

    }

}