import {Component, AfterViewInit, ElementRef, ViewChild, Renderer2} from '@angular/core';
import {LoginService} from './login/services/login.service';
import {MenuItem} from 'primeng/components/common/api';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

enum MenuOrientation {
    STATIC,
    OVERLAY,
    HORIZONTAL
}

declare var jQuery: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements AfterViewInit {

    fixMenuPinned = false;

    overlayMenu = false;

    layoutCompact = false;

    layoutMode: MenuOrientation = MenuOrientation.STATIC;

    darkMenu = false;

    profileMode = 'inline';

    rotateMenuButton: boolean;

    topbarMenuActive: boolean;

    overlayMenuActive: boolean;

    staticMenuDesktopInactive: boolean;

    staticMenuMobileActive: boolean;

    layoutContainer: HTMLDivElement;

    layoutMenuScroller: HTMLDivElement;

    menuClick: boolean;

    topbarItemClick: boolean;

    activeTopbarItem: any;

    documentClickListener: Function;

    resetMenu: boolean;
    model: Array<any> = [];

    data: Object = {name: ''};
    menu: any;
    activeroute: string;

    @ViewChild('layoutContainer') layourContainerViewChild: ElementRef;

    @ViewChild('layoutMenuScroller') layoutMenuScrollerViewChild: ElementRef;
    isLogin: boolean;
    sessionId: string;

    constructor(public renderer: Renderer2, private loginService: LoginService,
                private _router: Router, private route: ActivatedRoute) {
        this.sessionId = localStorage.getItem('sessionId');
        if (this.sessionId && this.sessionId != '') {
            this.isLogin = true;
        } else {
            this.isLogin = false;
        }
        loginService.loginEvent.subscribe(data => {
            this.isLogin = data
        });


    }

    onOverlayMenuSelect(e) {
        this.model = [];
        this.model.push(e);
        this.overlayMenu = false;

    }

    ngAfterViewInit() {

        this._router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot;
                this.activeroute = this._router.url;
                // console.log("this.activeroute", this.activeroute)
                // console.log("this.model",this.model);
                this.manageRouting();

                /* var arr = this.activeroute.split("/");
                 this.activeMainroute = "/" + arr[1];*/

            })
        if (this.isLogin) {
            this.layoutContainer = <HTMLDivElement> this.layourContainerViewChild.nativeElement;
            this.layoutMenuScroller = <HTMLDivElement> this.layoutMenuScrollerViewChild.nativeElement;

            //hides the horizontal submenus or top menu if outside is clicked
            this.documentClickListener = this.renderer.listen('body', 'click', (event) => {
                if (!this.topbarItemClick) {
                    this.activeTopbarItem = null;
                    this.topbarMenuActive = false;
                }

                if (!this.menuClick && this.isHorizontal()) {
                    this.resetMenu = true;
                }

                this.topbarItemClick = false;
                this.menuClick = false;
            });

            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
            }, 10);
        }

        jQuery('.fix-menu-mouse-hover').mouseenter(() => {
            jQuery('.fix-menu-mouse-hover').addClass('fix-sidebar-menu');
        })

        jQuery('.fix-menu-mouse-hover').mouseleave(() => {
            jQuery('.fix-menu-mouse-hover').removeClass('fix-sidebar-menu');
        })

    }

    manageRouting() {
        if (this.model.length == 0) {
            //console.log("this.activeroute",this.activeroute)
            if (this.activeroute.indexOf('/') != -1) {
                this.model = [];
                this.model = [{
                    label: 'Home', routerLink: ['/'], icon: 'biz-home',
                    items: [{label: 'Overview', icon: 'biz-overview', routerLink: ['/']}]
                }]
            }
            if (this.activeroute.indexOf('/organizations') != -1) {
                this.model = [];
                this.model = [{
                    label: 'Organizations', icon: 'biz-org', routerLink: ['/organizations'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/organizations']}]
                }]
            }
            if (this.activeroute.indexOf('/products') != -1) {
                this.model = [];
                this.model = [{
                    label: 'Products', icon: 'biz-product', routerLink: ['/products'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/products']}]
                }]
            }
            if (this.activeroute.indexOf('/connectors') != -1) {
                this.model = [{
                    label: 'Connectors', icon: 'biz-connector', routerLink: ['/connectors'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/connectors']}]
                }]
            }
            if (this.activeroute.indexOf('/metrics') != -1) {
                this.model = [{
                    label: 'Metrics', icon: 'biz-metrics', routerLink: ['/metrics'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/metrics']}]
                }]
            }

            if (this.activeroute.indexOf('/events') != -1) {
                this.model = [{
                    label: 'Events', icon: 'biz-events', routerLink: ['/events'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/events']}]
                }]
            }

            if (this.activeroute.indexOf('/connectly') != -1) {
                this.model = [
                    {
                        label: 'Connectly', icon: 'biz-connectly-pro', routerLink: ['/connectly'],
                        items: [
                            {label: 'Overview', icon: 'biz-overview', routerLink: ['/connectly']},
                            {label: 'Pro Apps', icon: 'biz-subscribe-organization', routerLink: ['connectly/proApps/Live']},
                            {label: 'Build Management', icon: 'biz-connectly-community', routerLink: ['connectly/buildManagement']},
                            {label: 'Free Groups', icon: 'biz-connectly-community', routerLink: ['connectly/freeGroup']},
                            {label: 'Themes', icon: 'biz-theme', routerLink: ['connectly/themes']}
                        ]
                    }]
            }

            if (this.activeroute.indexOf('/tally') != -1) {
                this.model = [
                    {
                        label: 'Tally App', icon: 'biz-connectly-pro', routerLink: ['/tally'],
                        items: [
                            {label: 'Overview', icon: 'biz-overview', routerLink: ['/tally']},
                            {label: 'Customers', icon: 'biz-overview', routerLink: ['tally/customers']}
                        ]
                    }]
            }

            if (this.activeroute.indexOf('/things') != -1) {
                this.model = [
                    {
                        label: 'Things App', icon: 'biz-connectly-pro', routerLink: ['/things'],
                        items: [
                            {label: 'Overview', icon: 'biz-overview', routerLink: ['/things']},
                            {label: 'Products', icon: 'biz-overview', routerLink: ['things/products']},
                            {label: 'Inventory', icon: 'biz-overview', routerLink: ['things/inventory']},
                            /*{label: 'SKUs', icon: 'biz-overview', routerLink: ['things/SKUs']},
                            {label: 'Customers', icon: 'biz-overview', routerLink: ['things/customers']},*/
                        ]
                    }]
            }
        }
    }

    onMenuButtonClick(event) {
        this.rotateMenuButton = !this.rotateMenuButton;
        this.topbarMenuActive = false;

        if (this.layoutMode === MenuOrientation.OVERLAY) {
            this.overlayMenuActive = !this.overlayMenuActive;
        }
        else {
            if (this.isDesktop())
                this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
            else
                this.staticMenuMobileActive = !this.staticMenuMobileActive;
        }

        event.preventDefault();
    }

    onMenuClick($event) {
        this.menuClick = true;
        this.resetMenu = false;

        if (!this.isHorizontal()) {
            setTimeout(() => {
                jQuery(this.layoutMenuScroller).nanoScroller();
            }, 500);
        }
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.topbarMenuActive = !this.topbarMenuActive;

        if (this.overlayMenuActive || this.staticMenuMobileActive) {
            this.rotateMenuButton = false;
            this.overlayMenuActive = false;
            this.staticMenuMobileActive = false;
        }
        if (!this.fixMenuPinned) {
            jQuery('.layout-menu').removeClass('fix-sidebar-menu');
        }

        //console.log("onTopbarMenuButtonClick");
        event.preventDefault();
    }

    onTopbarItemClick(event, item) {
        this.topbarItemClick = true;

        if (this.activeTopbarItem === item)
            this.activeTopbarItem = null;
        else
            this.activeTopbarItem = item;

        event.preventDefault();
    }

    isTablet() {
        const width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.layoutMode === MenuOrientation.OVERLAY;
    }

    isHorizontal() {
        return this.layoutMode === MenuOrientation.HORIZONTAL;
    }

    changeToStaticMenu() {
        this.layoutMode = MenuOrientation.STATIC;
    }

    changeToOverlayMenu() {
        this.layoutMode = MenuOrientation.OVERLAY;
    }

    changeToHorizontalMenu() {
        this.layoutMode = MenuOrientation.HORIZONTAL;
    }

    openOverlayMenu() {
        this.overlayMenu = !this.overlayMenu;
    }

    overlayMenuBackdropClickHandler() {
        this.overlayMenu = false;
    }

    ngOnDestroy() {
        if (this.documentClickListener) {
            this.documentClickListener();
        }

        jQuery(this.layoutMenuScroller).nanoScroller({flash: true});
    }

    currTheme = 'dark-blue';

    changeTheme(theme) {
        const themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        const layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');

        themeLink.href = 'assets/css/theme-' + theme + '.css';
        layoutLink.href = 'assets/css/layout-' + theme + '.css';
        this.currTheme = (this.currTheme == 'dark-blue') ? 'purple-cyan' : 'dark-blue';
    }
}
