import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {commonConstants} from "../../../common/models/constants";
import {BasicFunctions} from "../../../functions";
import {ConfirmationService, Message} from "primeng/components/common/api";
import {FormGroup, FormControl, Validators} from "@angular/forms";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class AppBuildConnectlyProOverviewComponent implements AfterViewInit {
    allThemes:any;
    constants = commonConstants;
    msgs:Message[] = [];
    buildSettings:any;

    androidBuildInfo1:any;
    androidBuildInfo2:any;

    refreshIntervalId1:any;
    refreshIntervalId2:any;

    refreshIntervalId3:any;
    refreshIntervalId4:any;

    androidBuildProcess1Data:any;
    androidBuildProcess2Data:any;
    iosBuildProcess1Data:any;
    iosBuildProcess2Data:any;

    androidBuildInfoId1:any;
    androidBuildInfoId2:any;

    iosBuildInfo1:any;
    iosBuildInfo2:any;

    iosBuildInfoId1:any;
    iosBuildInfoId2:any;

    selectedBuildSetting:any;
    displayCheckBuildPopup:boolean = false;
    displayNoBuildPopup:boolean = false;
    displayCheckBuildProcessPopup:boolean = false;
    displayCreateBuildPopup:boolean = false;
    buildForm:FormGroup;
    error:any = [];

    selectedTag:any;
    allTags:any;
    oldTag:string = '';
    selectedPlatform:any;
    selectedEnvironment:any;

    user:any;
    processValue:number = 0;
    totalApp:number = 0;
    complatedApp:number = 0;
    loader1:boolean = true;
    loader2:boolean = true;
    loader3:boolean = true;
    loader4:boolean = true;


    constructor(private _titleService:Title, private router:Router, private confirmationService:ConfirmationService,
                private activeRoute:ActivatedRoute, private connectlyProService:ConnectlyProService) {
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    ngAfterViewInit():void {
        //this._titleService.setTitle('Home Dashboard');

    }

    ngOnDestroy() {
        if (this.refreshIntervalId1) {
            clearInterval(this.refreshIntervalId1);
        }
        if (this.refreshIntervalId2) {
            clearInterval(this.refreshIntervalId2);
        }
        if (this.refreshIntervalId3) {
            clearInterval(this.refreshIntervalId3);
        }
        if (this.refreshIntervalId4) {
            clearInterval(this.refreshIntervalId4);
        }
    }

    ngOnInit() {
        this.getBuildVersion(true);
        this.buildForm = new FormGroup({
            buildNumber1: new FormControl("", Validators.required),
            buildNumber2: new FormControl("", Validators.required),
            buildNumber3: new FormControl("", Validators.required),
            changeLog: new FormControl('', Validators.required),
        });
    }

    getBuildVersion(value) {
        this.connectlyProService.getBuildVersion()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:Array<any> = response.data;
                        if (data != null) {
                            this.buildSettings = data;
                            this.setBuildSettings(value);

                        }
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

    setBuildSettings(value) {
        if (this.buildSettings.androidBuildInfo.length > 0) {
            if (this.buildSettings.androidBuildInfo.length == 1) {
                let data1 = this.buildSettings.androidBuildInfo[0];
                if (data1.environment == 1) {
                    this.androidBuildInfo1 = data1;
                    if (this.androidBuildInfo1.processId && this.androidBuildInfo1.processId != '') {
                        this.androidBuildInfoId1 = this.androidBuildInfo1.processId;
                        if (value == true) {


                            this.refreshIntervalId1 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId1, 'androidBuildInfoId1');
                            }, 2000);
                        }
                    }

                } else {
                    this.androidBuildInfo2 = data1;
                    if (this.androidBuildInfo2.processId && this.androidBuildInfo2.processId != '') {
                        this.androidBuildInfoId2 = this.androidBuildInfo2.processId;

                        if (value == true) {


                            this.refreshIntervalId2 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId2, 'androidBuildInfoId2');
                            }, 2000);
                        }
                    }
                }
            }

            if (this.buildSettings.androidBuildInfo.length == 2) {
                let data1 = this.buildSettings.androidBuildInfo[0];
                let data2 = this.buildSettings.androidBuildInfo[1];
                if (data1.environment == 1) {
                    this.androidBuildInfo1 = data1;
                    this.androidBuildInfo2 = data2;

                    if (this.androidBuildInfo1.processId) {
                        this.androidBuildInfoId1 = this.androidBuildInfo1.processId;
                        if (value == true) {


                            this.refreshIntervalId1 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId1, 'androidBuildInfoId1');
                            }, 2000);
                        }
                    }
                    if (this.androidBuildInfo2.processId) {
                        this.androidBuildInfoId2 = this.androidBuildInfo2.processId;
                        if (value == true) {


                            this.refreshIntervalId2 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId2, 'androidBuildInfoId2');
                            }, 2000);
                        }
                    }
                } else {
                    this.androidBuildInfo1 = data2;
                    this.androidBuildInfo2 = data1;
                    if (this.androidBuildInfo1.processId) {
                        this.androidBuildInfoId1 = this.androidBuildInfo1.processId;
                        if (value == true) {


                            this.refreshIntervalId1 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId1, 'androidBuildInfoId1');
                            }, 2000);
                        }
                    }
                    if (this.androidBuildInfo2.processId) {
                        this.androidBuildInfoId2 = this.androidBuildInfo2.processId;
                        if (value == true) {


                            this.refreshIntervalId2 = setInterval(() => {
                                this.checkBuildProcessOnSecond(this.androidBuildInfoId2, 'androidBuildInfoId2');
                            }, 2000);
                        }
                    }

                }


            }

            if(!this.androidBuildInfo1){
                this.loader1 =false;
            }
            if(!this.androidBuildInfo2){
                this.loader2 =false;
                console.log('loader2 stop-->');
            }
            /* this.refreshIntervalId2 = setInterval(() => {
             this.checkBuildProcessOnSecond(this.androidBuildInfoId2,'androidBuildInfoId2');
             }, 10000);
             this.refreshIntervalId1 = setInterval(() => {
             this.checkBuildProcessOnSecond(this.androidBuildInfoId1,'androidBuildInfoId1');
             }, 10000);*/
        }else {
            this.loader1 =false;
            this.loader2 =false;
            console.log('loader2 stop-->>');
        }

        if (this.buildSettings.iosBuildInfo) {
            if (this.buildSettings.iosBuildInfo.length > 0) {
                if (this.buildSettings.iosBuildInfo.length == 1) {
                    let data1 = this.buildSettings.iosBuildInfo[0];
                    if (data1.environment == 1) {
                        this.iosBuildInfo1 = data1;

                        if (this.iosBuildInfo1.processId && this.iosBuildInfo1.processId != '') {
                            this.iosBuildInfoId1 = this.iosBuildInfo1.processId;
                            if (value == true) {


                                this.refreshIntervalId3 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId1, 'iosBuildInfoId1');
                                }, 2000);
                            }
                        }
                    } else {
                        this.iosBuildInfo2 = data1;
                        if (this.iosBuildInfo2.processId && this.iosBuildInfo2.processId != '') {
                            this.iosBuildInfoId2 = this.iosBuildInfo2.processId;

                            if (value == true) {


                                this.refreshIntervalId4 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId2, 'iosBuildInfoId2');
                                }, 2000);
                            }
                        }
                    }
                }

                /*if (this.buildSettings.iosBuildInfo.length == 2) {
                 let data1 = this.buildSettings.iosBuildInfo[0];
                 let data2 = this.buildSettings.iosBuildInfo[1];
                 if (data1.environment == 1) {
                 this.iosBuildInfo1 = data1;
                 this.iosBuildInfo2 = data2;
                 } else {
                 this.iosBuildInfo1 = data2;
                 this.iosBuildInfo2 = data1;
                 }
                 }*/


                if (this.buildSettings.iosBuildInfo.length == 2) {
                    let data1 = this.buildSettings.iosBuildInfo[0];
                    let data2 = this.buildSettings.iosBuildInfo[1];
                    if (data1.environment == 1) {
                        this.iosBuildInfo1 = data1;
                        this.iosBuildInfo2 = data2;

                        if (this.iosBuildInfo1.processId) {
                            this.iosBuildInfoId1 = this.iosBuildInfo1.processId;
                            if (value == true) {


                                this.refreshIntervalId3 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId1, 'iosBuildInfoId1');
                                }, 2000);
                            }
                        }
                        if (this.iosBuildInfo2.processId) {
                            this.iosBuildInfoId2 = this.iosBuildInfo2.processId;
                            if (value == true) {


                                this.refreshIntervalId4 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId2, 'iosBuildInfoId2');
                                }, 2000);
                            }
                        }
                    } else {
                        this.iosBuildInfo1 = data2;
                        this.iosBuildInfo2 = data1;
                        if (this.iosBuildInfo1.processId) {
                            this.iosBuildInfoId1 = this.iosBuildInfo1.processId;
                            if (value == true) {


                                this.refreshIntervalId3 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId1, 'iosBuildInfoId1');
                                }, 2000);
                            }
                        }
                        if (this.iosBuildInfo2.processId) {
                            this.iosBuildInfoId2 = this.iosBuildInfo2.processId;
                            if (value == true) {


                                this.refreshIntervalId4 = setInterval(() => {
                                    this.checkBuildProcessOnSecond(this.iosBuildInfoId2, 'iosBuildInfoId2');
                                }, 2000);
                            }
                        }

                    }


                }

                if (!this.iosBuildInfo1) {
                    this.loader3 = false;
                }
                if (!this.iosBuildInfo2) {
                    this.loader4 = false;
                }


            } else {
                this.loader3 = false;
                this.loader4 = false;
            }
        }else{
            this.loader3 = false;
            this.loader4 = false;
        }

        console.log("androidBuildInfo1", this.androidBuildInfo1)
        console.log("androidBuildInfo2", this.androidBuildInfo2)
        console.log("iosBuildInfo1", this.iosBuildInfo1)
        console.log("iosBuildInfo2", this.iosBuildInfo2)
    }

    checkBuildAvailibility(data, platform, environment) {
        this.selectedBuildSetting = data;
        this.selectedEnvironment = environment;
        this.selectedPlatform = platform;
        let temp_data;
        console.log("this.selectedBuildSetting", this.selectedBuildSetting)
        if (!this.selectedBuildSetting || this.selectedBuildSetting == undefined) {
            temp_data = {
                environment: this.selectedEnvironment,
                platform: this.selectedPlatform,
                tag: ''
            }
            console.log("temp_data1", temp_data)
        } else {
            this.oldTag = this.selectedBuildSetting.tag;

            temp_data = {
                environment: this.selectedEnvironment,
                platform: this.selectedPlatform,
                tag: this.oldTag
            }
            console.log("temp_data2", temp_data)
        }


        this.connectlyProService.checkBuildAvailibility(temp_data)
            .subscribe(
                response => {

                    if (response.flag) {
                        let data:Array<any> = response.data;
                        if (data != null) {
                            this.allTags = [];
                            this.allTags = data;

                            if(this.allTags.length > 0){
                                this.displayCheckBuildPopup = true;
                            }else {
                                this.displayNoBuildPopup = true;
                            }

                        }
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
        this.selectedTag = '';


        console.log(this.selectedBuildSetting)
    }

    closePopupOfTag(){
        console.log("hii")
        this.allTags = [];
        this.displayCheckBuildPopup = false;
        this.displayNoBuildPopup = false;
    }
    selecteTag(tag) {
        this.selectedTag = '';
        this.selectedTag = tag;
        console.log("this.selectedThemeId ", this.selectedTag);
    }

    closeCheckBuildPopup() {
        this.displayCheckBuildPopup = false;
        //this.selectedTag = '';
    }

    createBuild() {
        let value = {
            buildNumber1: '',
            buildNumber2: '',
            buildNumber3:'',
            changeLog: ''
        }
        this.buildForm.reset(value);

        if (this.selectedBuildSetting) {

            var string = this.selectedTag.substr(1)
            var mdata =string.split(".");

            let value = {
                buildNumber1: mdata[0],
                buildNumber2: mdata[1],
                buildNumber3: mdata[2],
                changeLog: this.selectedBuildSetting.changeLog
            }

            this.buildForm.reset(value);
            // this.selectedTag = '';
        }else{
            var string = this.selectedTag.substr(1)
            var mdata =string.split(".");

            let value = {
                buildNumber1: mdata[0],
                buildNumber2: mdata[1],
                buildNumber3: mdata[2],
                changeLog: ''
            }

            this.buildForm.reset(value);
        }

        this.displayCheckBuildPopup = false;
        this.displayCreateBuildPopup = true;

    }

    getVersion(buildNumber) {

        let v1 = Math.floor(buildNumber / 10000);

        let modulo1 = buildNumber % 10000;

        let v2 = Math.floor(modulo1 / 1000);

        let modulo2 = buildNumber % 1000;

        let v3 = Math.floor(modulo2 / 100);

        let data = {
            v1: v1,
            v2: v2,
            v3: v3
        }
        return data;
    }

    getBuildCode(v1, v2, v3) {
        let major = v1 * 10000;
        let minor = v2 * 1000;
        let patch = v3 * 100;
        let number = major + minor + patch;
        return number;
    }


    pushTheBuild(data:any) {


        let buildNumber = this.getBuildCode(this.buildForm.value['buildNumber1'], this.buildForm.value['buildNumber2'], this.buildForm.value['buildNumber3'])
        let versionCode = this.buildForm.value['buildNumber1'] + '.' + this.buildForm.value['buildNumber2'] + '.' + this.buildForm.value['buildNumber3']
        /*if (this.selectedBuildSetting.buildVersionNumber ){
         if (this.selectedBuildSetting.buildVersionNumber >= buildNumber) {
         this.msgs = [];
         this.msgs.push({
         severity: 'error',
         summary: 'Error Message',
         detail: 'The version you have provided must be grater then the old version'
         });
         }
         else {
         let tmp_data = {
         platform: this.selectedPlatform,
         environment: this.selectedEnvironment,
         buildVersionNumber: buildNumber,
         versionCode: versionCode,
         changeLog: this.buildForm.value['changeLog'],
         userId: this.user.unique_id,
         tag: this.selectedTag,
         }

         this.connectlyProService.generateBuild(tmp_data)
         .subscribe(
         response => {
         if (response.flag) {
         var data:any = response.data;
         this.displayCreateBuildPopup = false;
         this.getBuildVersion(true);
         //this.getProcessId(this.selectedPlatform,this.selectedEnvironment,data.processId);

         } else {
         this.msgs = [];
         this.msgs.push({
         severity: 'error',
         summary: 'Error Message',
         detail: response.message
         });

         }
         },
         error => {
         console.log(error);
         this.msgs = [];
         this.msgs.push({
         severity: 'error',
         summary: 'Error Message',
         detail: ' Oops! Something Went Wrong'
         });
         });
         }
         }else{*/
        let tmp_data = {
            platform: this.selectedPlatform,
            environment: this.selectedEnvironment,
            buildVersionNumber: buildNumber,
            versionCode: versionCode,
            changeLog: this.buildForm.value['changeLog'],
            userId: this.user.unique_id,
            tag: this.selectedTag,
        }

        this.connectlyProService.generateBuild(tmp_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        var data:any = response.data;
                        this.displayCreateBuildPopup = false;
                        this.getBuildVersion(true);
                        //this.getProcessId(this.selectedPlatform,this.selectedEnvironment,data.processId);

                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });

                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
        /*  }*/


    }


    getProcessId(platform, environment, id) {
        if (platform == 1 && environment == 1) {
            this.androidBuildInfoId1 = id;

            this.refreshIntervalId1 = setInterval(() => {
                this.checkBuildProcessOnSecond(this.androidBuildInfoId1, 'androidBuildInfoId1');
            }, 10000);

        }
        if (platform == 1 && environment == 2) {
            this.androidBuildInfoId2 = id;
            this.refreshIntervalId2 = setInterval(() => {
                this.checkBuildProcessOnSecond(this.androidBuildInfoId2, 'androidBuildInfoId2');
            }, 10000);

        }

        if (platform == 2 && environment == 1) {
            this.iosBuildInfoId1 = id;

            this.refreshIntervalId3 = setInterval(() => {
                this.checkBuildProcessOnSecond(this.iosBuildInfoId1, 'iosBuildInfoId1');
            }, 10000);
        }
        if (platform == 2 && environment == 2) {
            this.iosBuildInfoId2 = id;

            this.refreshIntervalId4 = setInterval(() => {
                this.checkBuildProcessOnSecond(this.iosBuildInfoId2, 'iosBuildInfoId2');
            }, 10000);

        }
    }

    checkBuildProcessOnSecond(id, value) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        console.log("processData", data);
                        if (value == 'androidBuildInfoId1') {
                            this.loader1 = true;
                            this.androidBuildProcess1Data = data;
                            if (this.androidBuildProcess1Data.status == 'Deployment Fail' || this.androidBuildProcess1Data.status == 'Expired' || this.androidBuildProcess1Data.status == 'Partial succeeded' || this.androidBuildProcess1Data.status == 'Live') {
                                clearInterval(this.refreshIntervalId1);
                                this.loader1 = false;
                                this.getBuildVersion(false);

                               /* if (this.androidBuildProcess1Data.status == 'Partial succeeded' || this.androidBuildProcess1Data.status == 'Live') {
                                    this.msgs = [];

                                    this.msgs.push({
                                        severity: 'success',
                                        summary: 'Success Message',
                                        detail: 'Android Production-' + this.androidBuildProcess1Data.status
                                    });
                                }
                                if (this.androidBuildProcess1Data.status == 'Deployment Fail') {
                                    this.msgs = [];

                                    this.msgs.push({
                                        severity: 'error',
                                        summary: 'Error Message',
                                        detail: 'Android Production-' + this.androidBuildProcess1Data.status
                                    });
                                }*/
                            }
                        }
                        if (value == 'androidBuildInfoId2') {
                            this.loader2 = true;
                            console.log('loader2 start--');
                            this.androidBuildProcess2Data = data;
                            if (this.androidBuildProcess2Data.status == 'Deployment Fail'|| this.androidBuildProcess2Data.status == 'Expired' || this.androidBuildProcess2Data.status == 'Partial succeeded' || this.androidBuildProcess2Data.status == 'Live') {
                                clearInterval(this.refreshIntervalId2);
                                this.loader2 = false;
                                console.log('loader2 stop--');
                                this.getBuildVersion(false);

                               /* if (this.androidBuildProcess2Data.status == 'Partial succeeded' || this.androidBuildProcess2Data.status == 'Live') {
                                    this.msgs = [];

                                    this.msgs.push({
                                        severity: 'success',
                                        summary: 'Success Message',
                                        detail: 'Android Production-' + this.androidBuildProcess2Data.status
                                    });
                                }
                                if (this.androidBuildProcess2Data.status == 'Deployment Fail') {
                                    this.msgs = [];

                                    this.msgs.push({
                                        severity: 'error',
                                        summary: 'Error Message',
                                        detail: 'Android Production-' + this.androidBuildProcess2Data.status
                                    });
                                }*/

                            }
                        }

                        if (value == 'iosBuildInfoId1') {
                            this.loader3 = true;
                            this.iosBuildProcess1Data = data;
                            if (this.iosBuildProcess1Data.status == 'Deployment Fail' || this.iosBuildProcess1Data.status == 'Partial succeeded' || this.iosBuildProcess1Data.status == 'Live') {
                                clearInterval(this.refreshIntervalId3);
                                this.loader3 = false;
                                this.getBuildVersion(false);

                            }
                        }
                        if (value == 'iosBuildInfoId2') {
                            this.loader4 = true;
                            this.iosBuildProcess2Data = data;
                            if (this.iosBuildProcess2Data.status == 'Deployment Fail' || this.iosBuildProcess2Data.status == 'Partial succeeded' || this.iosBuildProcess2Data.status == 'Live') {
                                clearInterval(this.refreshIntervalId4);
                                this.loader4 = false;
                                this.getBuildVersion(false);

                            }
                        }


                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }


}
