import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {SubscribeOrgConnectlyProService} from "../../../services/subscribed-org.service";
import {BasicFunctions} from "../../../../functions";
import {ConnectlyProService} from "../../../services/connectly-pro.service";

//import {ItemsService, UsersService} from '../../../services';
declare var jQuery: any;
@Component({
    selector: 'joiningForm-subscribe-org',
    templateUrl: './joiningForm.component.html',
    styleUrls: ['./joiningForm.component.scss'],
})
export class JoiningFormViewSubscribeOrgComponent implements AfterViewInit {
    subscribeGroups:any;
    groupId: string;
    GroupProfile: string;

    selectedThemeId: string;
    GroupTheme: string;

    groupTopics: any;
    theme: any;

    constants = commonConstants;
    msgs: Message[] = [];

    availableCars: any;

    selectedCars:any;

    draggedCar: any;


    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute,private connectlyProService: ConnectlyProService,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
    }

    ngAfterViewInit(): void {
       // this._titleService.setTitle('Home Dashboard');
    }


    ngOnInit() {
        this.selectedCars = [];
        //this.getSubscribeGroups();
    }

    

    dragStart(event,car) {
        this.draggedCar = car;
    }

    drop(event) {
        if(this.draggedCar) {
           // let draggedCarIndex = this.findIndex(this.draggedCar);
            this.selectedCars = [...this.selectedCars, this.draggedCar];
            this.availableCars = this.availableCars;
            this.draggedCar = null;
        }
    }

    dragEnd(event) {
        this.draggedCar = null;
    }

    findIndex(car) {
        let index = -1;
        for(let i = 0; i < this.availableCars.length; i++) {
            if(car.name === this.availableCars[i].name) {
                index = i;
                break;
            }
        }
        return index;
    }
}
