import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {SubscribeOrgConnectlyProService} from "../../../services/subscribed-org.service";
import {ConnectlyProService} from "../../../services/connectly-pro.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'member-view-subscribe-org',
    templateUrl: './member.component.html',
    styleUrls: ['./member.component.scss'],
})
export class MemberViewSubscribeOrgComponent implements AfterViewInit {
    groupId: string;
    GroupProfile: string;

    selectedThemeId: string;
    GroupTheme: string;

    groupMember: any;
    theme: any;

    constants = commonConstants;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService, private connectlyProService: ConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
        this.subscribeOrgConnectlyProService.groupId=this.groupId;
        this.connectlyProService.groupId=this.groupId;
    }

    ngAfterViewInit(): void {
       // this._titleService.setTitle('Home Dashboard');

    }

    ngOnInit() {

    }



    displayMessage(msg) {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error Message', detail: msg});
    }


}
