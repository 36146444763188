import {ISerializable} from "../../common/interface/serializable.interface";
import {ProductSubscriptionService} from "./service";
import {BizBrainService} from "../../bizbrain-services/models/bizbrain-service.model";
/**
 * Created by hirenparekh on 11/11/16.
 */

export class ProductSubscription implements ISerializable<ProductSubscription> {

    orgId: string;
    serviceId: string;
    uniqueId: string;
    updatedAt: string;
    createdAt: string;
    service: BizBrainService;

    getObject(data: any): ProductSubscription {

        let subscription = new ProductSubscription();

        subscription.orgId = data['org_id'];
        subscription.serviceId = data['service_id'];
        subscription.uniqueId = data['unique_id'];
        subscription.updatedAt = data['updated_at'];
        subscription.createdAt = data['created_at'];
        subscription.service = (new BizBrainService().getObject(data['service']));


        return subscription;
    }

}
