import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {NgModel} from "@angular/forms";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {BizBrainServicesService} from "../../services/bizbrain-service.service";
import {BizBrainService} from "../../models/bizbrain-service.model";
import {ConfirmationService, Message} from "primeng/components/common/api";


@Component({
    selector: 'connector-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class BizBrainServicesOverviewComponent implements AfterViewInit {
    services: BizBrainService[];
    pageName: string = "Services";
    msgs: Message[] = [];
    constructor(private bizBrainServiceServices: BizBrainServicesService,
                private router: Router, private activeRoute: ActivatedRoute,private _titleService: Title,
                private route: ActivatedRoute, private confirmationService: ConfirmationService) {
        // Chart Single

        // Chart Multi
    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Overview');

    }

    onRowSelect(event) {
        //alert(event.data.productId);
        this.viewServices(event.data.productId);
    }

    getServices() {
        this.bizBrainServiceServices.getServices()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.services = [];
                        data.forEach(service => this.services.push(new BizBrainService().getObject(service)))
                    }
                },
                error => {
                    console.log(error)
                });
    }

    viewServices(serviceId: string): void {
        this.router.navigate([AppRoutingConstants.services_view_id, serviceId]);
    }

    addServices(){
        this.router.navigate([AppRoutingConstants.services_add]);
    }

    editServices(serviceId:string){
        this.router.navigate([AppRoutingConstants.services_edit_id,serviceId]);
    }

    deleteService(service):void{
        this.confirmationService.confirm({
            message: 'Do you want to delete this product?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.bizBrainServiceServices.deleteServices(service.productId)
                    .subscribe(
                        response => {

                            if(response.flag)
                            {
                                this.services = this.services.filter(val => val.productId !== service.productId);
                                /*for(let i = 0; i < this.services.length ; i++)
                                {
                                    if(this.services[i].productId === service.productId)
                                    {
                                        this.services.splice(i,1);
                                        break;
                                    }
                                }*/

                                this.msgs = [];
                                this.msgs.push({severity:'success', summary:'Success Message', detail: service.name + ' Service Delete Successfully'});
                            }else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({severity:'error', summary:'Error Message', detail: response.message});
                            }
                        },
                        error => {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });

                        }
                    )
            },
            reject: () => {
            }
        });

    }

    ngOnInit() {
        this.getServices();
    }


}
