import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Message} from 'primeng/components/common/api';
import {SubscribeOrgForm} from '../subscribed-org-form/subscribed-org-form.component';
import {ActivatedRoute, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {ConnectlyProService} from '../../services/connectly-pro.service';
import {SubscribeOrgConnectlyProService} from '../../services/subscribed-org.service';
import {AppRoutingConstants} from '../../../app-routing.constants';
import {commonConstants} from '../../../common/models/constants';

declare var jQuery: any;

@Component({
    selector: 'add-subscribed-org',
    templateUrl: './add-subscribed-org.component.html',
    styleUrls: ['./add-subscribed-org.component.css']
})
export class AddSubscribeOrgComponent {
    // @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('addSubscribeGroup') addSubscribeGroup: ElementRef;
    @ViewChild('subscribeOrgForm') subscribeOrgForm: SubscribeOrgForm;

    msgs: Message[] = [];
    displayProcessStage: boolean = false;
    processStageData: any;
    activeBlock: any;
    activeroute: any;
    constants = commonConstants;
    refreshIntervalId: number;

    constructor(private _titleService: Title, private router: Router,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

    }

    ngOnInit() {
        /* this.activeRoute.params.forEach(param => {
         this.activeBlock = param['block'];

         });*/

        this.activeroute = this.router.url;
        console.log('this.activeBlock', this.activeroute);
        this.manageRouting();


    }

    manageRouting() {
        if (this.activeroute.indexOf('/connectly/proApps/All/add') != -1) {
            this.activeBlock = 'All'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Provisioning/add') != -1) {
            this.activeBlock = 'Provisioning'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Terminated/add') != -1) {
            this.activeBlock = 'Terminated'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Suspended/add') != -1) {
            this.activeBlock = 'Suspended'
        }
        if (this.activeroute.indexOf('/connectly/proApps/Live/add') != -1) {
            this.activeBlock = 'Live'
        }

        if (this.subscribeOrgForm) {
            this.subscribeOrgForm.activeBlock = this.activeBlock;
        }
    }

    ngOnDestroy() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    }

    onFormSubmit(event: FormData) {

        this.addNewSubscribedOrg(event);
        // this.displayProcessStage=true;
    }

    addNewSubscribedOrg(formData) {

        this.subscribeOrgConnectlyProService.addNewSubscribedOrg(formData)
            .subscribe(
                response => {
                    if (response.flag) {

                        console.log(response.data);
                        var data = response.data;

                        /*if (this.displayProcessStage) {
                            this.refreshIntervalId = setInterval(() => {
                                this.getProcessStage(data.applicationSettings.provisioningProcessId);
                            }, 2000);
                        }*/

                        // this.addNewSubscribedOrg(event);
                        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    // this.closeModal();
                }
            );
    }

    getProcessStage(id) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        var data = response.data;
                        this.processStageData = {};
                        this.processStageData = data;

                        if (this.processStageData.status == 'Live' || this.processStageData.status == 'Deployment Fail') {
                            clearInterval(this.refreshIntervalId);
                            this.addNewSubscribedOrg(event);
                            this.displayProcessStage = false;
                        }
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    gotoApps() {
        this.displayProcessStage = false;
        clearInterval(this.refreshIntervalId);
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);
    }

    closeModal() {
        // jQuery(this.addConnector.nativeElement).modal('hide');
        if (this.subscribeOrgForm) {
            this.subscribeOrgForm.resetForm();
        }
    }

}