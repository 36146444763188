import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {commonConstants} from "../../../common/models/constants";
import {BasicFunctions} from "../../../functions";
import {ConfirmationService, Message} from "primeng/components/common/api";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class SubscribeOrgConnectlyProOverviewComponent implements AfterViewInit {

    subscribeGroupsCount:any;
    constants = commonConstants;
    appName:any;

    subscribeGroups:any;

    msgs:Message[] = [];

    displayProcessStage:boolean = false;
    processStageData:any;
    refreshIntervalId:number;
    stage:number;
    pageFirst:any;
    pageRows:any;
    activeBlock:any;
    activeTabIndex=0;
    selectedGroup:any;

    processStart:boolean=true;
    loader:boolean=false;


    constructor(private _titleService:Title, private router:Router, private confirmationService:ConfirmationService,
                private activeRoute:ActivatedRoute, private connectlyProService:ConnectlyProService) {

    }

    ngAfterViewInit():void {
        this._titleService.setTitle('Home Dashboard');

    }

    ngOnDestroy() {
        if (this.refreshIntervalId) {
            clearInterval(this.refreshIntervalId);
        }
    }

    ngOnInit() {
        this.pageFirst = 0;
       // this.stage = 2;
        this.pageRows = this.constants.pageRows;
        //this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
        //this.getSubscribeGroupsCount(this.stage);

        this.activeRoute.params.forEach(param => {
            this.activeBlock = param['block']});
        console.log(this.activeBlock);
        this.getActiveData();
    }

    getActiveData(){

        if(this.activeBlock =='Live'){
            this.stage = 2;
            this.activeTabIndex = 0;
            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
            this.getSubscribeGroupsCount(this.stage);
        }
        if(this.activeBlock =='Provisioning'){
            this.stage = 1;
            this.activeTabIndex = 1;
            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
            this.getSubscribeGroupsCount(this.stage);
        }
        if(this.activeBlock =='Terminated'){
            this.stage = 5;
            this.activeTabIndex = 2;
            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
            this.getSubscribeGroupsCount(this.stage);
        }
        if(this.activeBlock =='Suspended'){
            this.stage = 7;
            this.activeTabIndex = 3;
            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
            this.getSubscribeGroupsCount(this.stage);
        }
        if(this.activeBlock =='All'){
            this.stage = 0;
            this.activeTabIndex = 4;
            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
            this.getSubscribeGroupsCount(this.stage);
        }

    }

    paginate(event) {
        //console.log(event);
        this.getSubscribeGroups(event.first, event.rows, this.stage);
        this.pageFirst = event.first;
        this.pageRows = event.rows;

    }

    startProvisionCloneApp(group){
        var temp_data={
            id:group.id
        }
        
        this.connectlyProService.cloneSubscribedOrgProvisioning(temp_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        console.log("data",data);
                        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                        console.log(this.subscribeGroups);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: 'Success Message',
                            detail: 'Provision started for '+group.name
                        });

                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }); 
    }

    handleChange(e) {
        let index = e.index;
        let link;
        switch (index) {
            case 0:
                this.stage = 2;
                this.loader=true;
                this.activeTabIndex = 0;
                this.router.navigate([AppRoutingConstants.subscribeOrganization,'Live']);
                this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                this.getSubscribeGroupsCount(this.stage);
                break;
            case 1:

                this.stage = 1;
                this.loader=true;
                this.activeTabIndex = 1;
                this.router.navigate([AppRoutingConstants.subscribeOrganization,'Provisioning']);
                this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                this.getSubscribeGroupsCount(this.stage);
                break;

            case 2:

                this.stage = 5;
                this.loader=true;
                this.activeTabIndex = 2;
                this.router.navigate([AppRoutingConstants.subscribeOrganization,'Terminated']);
                this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                this.getSubscribeGroupsCount(this.stage);
                break;

            case 3:

                this.stage = 7;
                this.loader=true;
                this.activeTabIndex = 3;
                this.router.navigate([AppRoutingConstants.subscribeOrganization,'Suspended']);
                this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                this.getSubscribeGroupsCount(this.stage);
                break;

            case 4:

                this.stage = 0;
                this.loader=true;
                this.activeTabIndex = 4;
                this.router.navigate([AppRoutingConstants.subscribeOrganization,'All']);
                this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                this.getSubscribeGroupsCount(this.stage);
                break;
        }

    }


    openProcessPopup(event, id, group) {
        console.log("event-----", event);
        this.selectedGroup=group;
        clearInterval(this.refreshIntervalId);

        console.log("process id ",id)

        if(id && id != 'undefined'){
            this.refreshIntervalId = setInterval(() => {
                this.getProcessStage(id);
            }, 700);
        }else {
            this.processStart =false;
        }

        this.displayProcessStage = true;
        //this.getProcessStage(id,event,overlaypanel);
        // overlaypanel.toggle(event);
    }


    hideModel() {
        // alert("hii")
        clearInterval(this.refreshIntervalId);
    }

    getProcessStage(id) {
        this.connectlyProService.getProcessStage(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        var data = response.data;
                        this.processStageData = {};
                        this.processStageData = data;

                        if (this.processStageData.status == 'Live' || this.processStageData.status == 'Deployment Fail') {
                            clearInterval(this.refreshIntervalId);
                            this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                            //this.displayProcessStage = false;

                            if (this.processStageData.status == 'Live' && !this.displayProcessStage) {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: '',
                                    detail: this.selectedGroup.name + ' Provisioned Successfully'
                                });
                            }

                            if ( this.processStageData.status == 'Deployment Fail' && !this.displayProcessStage) {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: this.processStageData.status + ' of ' + this.selectedGroup.name
                                });
                            }


                        }
                    } else {
                        clearInterval(this.refreshIntervalId);
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    clearInterval(this.refreshIntervalId);
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    gotoApps() {
        this.displayProcessStage = false;
        clearInterval(this.refreshIntervalId);
        // this.router.navigate([AppRoutingConstants.subscribeOrganization]);
    }

    gotoLiveApps(){
        this.stage = 2;
        this.loader=true;
        this.router.navigate([AppRoutingConstants.subscribeOrganization,'Live']);
        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
        this.getSubscribeGroupsCount(this.stage);
        this.activeTabIndex = 0;
    }


    getSubscribeGroupsCount(stage) {

        if (stage == 0) {
            this.connectlyProService.getAllSubscribeGroupsCount()
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data:Array<any> = response.data;
                            this.subscribeGroupsCount = response.data;
                            //console.log("this.subscribeGroupsCount", this.subscribeGroupsCount);
                        } else {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    });
        } else {
            this.connectlyProService.getSubscribeGroupsCount(stage)
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data:Array<any> = response.data;
                            this.subscribeGroupsCount = response.data;
                            //console.log("this.subscribeGroupsCount", this.subscribeGroupsCount);
                        } else {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    });
        }
    }

    getSubscribeGroups(skip, limit, stage) {

        if (stage == 0) {
            this.connectlyProService.getSubscribeAllGroups(skip, limit)
                .subscribe(
                    response => {
                        this.loader=false;
                        if (response.flag) {
                            let data:Array<any> = response.data;
                            this.subscribeGroups = [];
                            this.subscribeGroups = response.data;
                            console.log("this.subscribeGroups", this.subscribeGroups.length)
                            //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                        } else {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    });
        } else {
            this.connectlyProService.getSubscribeGroups(skip, limit, stage)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.loader=false;
                            let data:Array<any> = response.data;
                            this.subscribeGroups = [];
                            this.subscribeGroups = response.data;
                            console.log("this.subscribeGroups", this.subscribeGroups)
                            //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                        } else {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    });
        }
    }

    onRowSelect(event) {

        this.viewSubscribeOrg(event.data.id);
    }

    viewSubscribeOrg(groupId:string):void {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', groupId, 'overview']);
    }

    addSubcsribeOrg() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock ,'add']);
    }

    editSubscribedOrg(groupId) {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'edit', groupId]);
    }

    cloneSubscribedOrg(group) {
        var temp_data = {
            appId: group.id
        }
        this.connectlyProService.cloneSubscribedOrg(temp_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        //let data: Array<any> = response.data;

                        //this.subscribeGroups.push(response.data);
                        //console.log("this.subscribeGroups",this.subscribeGroups)
                        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                        this.getSubscribeGroupsCount(this.stage);
                        //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: 'Success Message',
                            detail: group.name + ' App Clone Successfully'
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

    deleteSubscribedOrg(group) {
        let temp_data = {
            groupId: group.id
        }
        this.confirmationService.confirm({
            message: 'Do you want to delete this group?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectlyProService.deleteSubscribedOrg(temp_data)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.subscribeGroups = this.subscribeGroups.filter(val => val.id !== group.id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: group.name + ' Subscribed Organization Deleted Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

    suspendApp(group){
        
     var data={
            "appId" : group.id
        }

        this.connectlyProService.suspendApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        //let data: Array<any> = response.data;

                        //this.subscribeGroups.push(response.data);
                        //console.log("this.subscribeGroups",this.subscribeGroups)
                        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                        this.getSubscribeGroupsCount(this.stage);
                        //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Suspending process started for App ' + group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
        
    }

    terminateApp(group){
     var data={
            "appId" : group.id
        }

        this.connectlyProService.terminateApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        //let data: Array<any> = response.data;

                        //this.subscribeGroups.push(response.data);
                        //console.log("this.subscribeGroups",this.subscribeGroups)
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Terminating process started for App ' + group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                        this.getSubscribeGroupsCount(this.stage);
                        //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

    resumeApp(group){
     var data={
            "appId" : group.id
        }

        this.connectlyProService.resumeApp(data)
            .subscribe(
                response => {
                    if (response.flag) {
                        //let data: Array<any> = response.data;

                        //this.subscribeGroups.push(response.data);
                        //console.log("this.subscribeGroups",this.subscribeGroups)
                        this.getSubscribeGroups(this.pageFirst, this.pageRows, this.stage);
                        this.getSubscribeGroupsCount(this.stage);
                        //new BasicFunctions().insertionSort(this.subscribeGroups, 'created_on');
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: '',
                            detail: 'Resuming process started for App ' + group.name
                        });
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });
    }

}
