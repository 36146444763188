import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {ConnectorsService} from "../../services/connectors.service";
import {AddConnectorComponent} from "../add-connector/add-connector.component";
import {EditConnectorComponent} from "../edit-connector/edit-connector.component";
import {ViewConnectorComponent} from "../view-connector/view-connector.component";
import {Connector} from "../../models/connector.model";
import {BasicFunctions} from "../../../functions";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class ConnectorOverviewComponent implements AfterViewInit {

    msgs: Message[] = [];

    pageName = "Connectors";
    connectors: Connector[];
    searchStr: string = "";
    connectorsLength: number = 0;
    displayAddConnector: boolean = false;
    displayEditConnector: boolean = false;
    displayViewConnector: boolean = false;


    activeroute: string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;

    @ViewChild('addConnectorModal') addConnectorModal: AddConnectorComponent;
    @ViewChild('editConnectorModal') editConnectorModal: EditConnectorComponent;
    @ViewChild('viewConnectorModal') viewConnectorModal: ViewConnectorComponent;
    //  @ViewChildren('filterLength') filterCount;

    constructor(private _titleService: Title, private router: Router, private connectorService: ConnectorsService,
                private route: ActivatedRoute, private confirmationService: ConfirmationService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Overview');

    }


    openAddConnectorPopup() {
        this.displayAddConnector = true;
        //this.addConnectorModal.openModal();
        this.router.navigate([AppRoutingConstants.connectors_add]);
    }

    openViewConnectors(connector: Connector) {
        this.displayViewConnector = true;
        this.router.navigate([AppRoutingConstants.connectors_view_id, connector.uniqueId]);

        // this.viewConnectorModal.openModal(connector);
    }

    deleteConnectors(connector) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this connector?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectorService.deleteConnector(connector.uniqueId)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.connectors = this.connectors.filter(val => val.uniqueId !== connector.uniqueId);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: connector.name + ' Connector Delete Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })


    }

    openEditConnectors(connector: Connector) {
        this.displayEditConnector = true;

        //this.editConnectorModal.openModal(connector);
        this.router.navigate([AppRoutingConstants.connectors_edit_id, connector.uniqueId]);
    }

    onNewConnectorAdded(connector: Connector) {
        if (!this.connectors) {
            this.connectors = [];
        }
        this.connectors.push(connector);
        new BasicFunctions().insertionSort(this.connectors, 'createdAt');

        this.msgs = [];
        this.msgs.push({severity: 'success', summary: 'Success Message', detail: 'Connector Added Successfully'});

    }

    onNewConnectorEdited(connector: Connector) {
        if (!this.connectors) {
            this.connectors = [];
        }
        for (let i = 0; this.connectors, i < this.connectors.length; i++) {
            let currConnector = this.connectors[i];
            if (currConnector.uniqueId == connector.uniqueId) {
                this.connectors.splice(i, 1, connector);
                break;
            }
        }

        new BasicFunctions().insertionSort(this.connectors, 'createdAt');
        this.router.navigate([AppRoutingConstants.connectors]);
    }

    ngOnInit() {
        this.connectorService.getConnectors()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connectors = response.data.map(val => new Connector().getObject(val));
                        this.connectorsLength = this.connectors.length;
                        //insertionSort(this.connectors, 'created_at');
                        new BasicFunctions().insertionSort(this.connectors, 'createdAt');
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }
}
