import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {SubGroupForm} from "../subGroup-form/subGroup-form.component";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'edit-subGroup',
    templateUrl: './edit-subGroup.component.html',
    styleUrls: ['./edit-subGroup.component.css']
})
export class EditSubGroup {
    //@Output() componentEditedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('editSubGroup') editSubGroup: ElementRef;
    @ViewChild('subGroupForm') subGroupForm: SubGroupForm;
    activeBlock: any;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    subGroup: any;
    groupId: any;
    groupOrgId: any;
    subGroupId: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private connectlyProService: ConnectlyProService) {
        this.activeBlock=this.connectlyProService.activeBlock;
    }



    ngOnInit() {
        let id='';
        this.groupId=this.connectlyProService.groupId;
        this.groupOrgId=this.connectlyProService.groupOrgId;
       // let id = this.activeRoute.snapshot.parent.params['subGroupId'];
        this.activeRoute.params.subscribe(param => {
                this.subGroupId = param['subGroupId'];
            }
        );
        console.log("subGroupId", this.subGroupId);
        this.getSubGroupById( this.subGroupId);
    }

    getSubGroupById(id){

            this.subscribeOrgConnectlyProService.getGroupById(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data: any = response.data;
                            this.subGroup = response.data;
                            this.subGroupForm.subGroupComponent =this.subGroup;
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );

    }

    onFormSubmit(formData:any){
        formData.id= this.subGroupId;
        formData.status=this.subGroup.status;
        formData.parentId=this.groupId;
        formData.groupOrgId=this.groupOrgId;
        this.subscribeOrgConnectlyProService.editNewSubscribedOrg(formData)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                    this.closeModal();
                   // this.componentEditedEvent.emit(new ConnectorComponent().getObject(response.data))
                }else{
                    console.log(response.message);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: response.message
                    });
                }
            },
            error => {
                console.log(error);
                this.msgs = [];
                this.msgs.push({
                    severity: 'error',
                    summary: 'Error Message',
                    detail: ' Oops! Something Went Wrong'
                });
            },
            () => {

            }
        );
    }


    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId, 'subGroups']);
    }


}