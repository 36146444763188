/**
 * Created by hirenparekh on 28/12/16.
 */

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'dataFilter',
    pure:false
})
export class FilterPipe implements PipeTransform {

    transform(items: any[], field: string, value: string){
        if (!items)
            return [];
        if(!value)
            value = "";
        return items.filter(
            currItem => {
                if (currItem.hasOwnProperty(field)) {
                    return (currItem[field].toLowerCase().indexOf(value.toLowerCase()) > -1);
                }
                else {
                    return false;
                }
            });
    }
}