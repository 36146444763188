import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 10/11/16.
 */


export class Organization implements ISerializable<Organization> {

  name: string;
  domain: string;
  userScope: number;
  hashType: number;
  updatedAt: string;
  createdAt: string;
  uniqueId: string;
  dbName: string;

  getObject(data: any): Organization {

    let organization = new Organization();

    if(!data)
    {
      return organization;
    }

    organization.name = data['name'];
    organization.domain = data['domain'];
    organization.userScope = data['user_scope'];
    organization.hashType = data['hash_type'];
    organization.updatedAt = data['updated_at'];
    organization.createdAt = data['created_at'];
    organization.uniqueId = data['unique_id'];
    organization.dbName = data['db_name'];

    return organization;
  }

}
