import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MetricsService} from "../../services/metrics.service";
import {Metric} from "../../models/metrics.model";
import {MetricsComponent} from "../metrics.component";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {MetricTag} from "../../models/metrics-tags.model";
import {Message} from "primeng/components/common/message";

declare var jQuery: any;

@Component({
    selector: 'metrics-view',
    templateUrl: './view-metrics.component.html',
    styleUrls: ['./view-metrics.component.css', '../metrics.component.scss']
})
export class ViewMetrics {
    @ViewChild('viewMetric') viewMetric: ElementRef;
    refreshPageVal: boolean = false;
    msgs: Message[] = [];
    metric: Metric;
    type: string;
    connectorId: string;

    constructor(private metricService: MetricsService, private activeRoute: ActivatedRoute, private router: Router) {

    }

    getMetricById(metricId: string): void {
        this.metricService.getmetricsById(metricId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.metric = new Metric().getObject(data);
                        console.log("this.metric.tags", data.tags)
                        /*this.metric.tags = new MetricTag().getObject(data.tags);*/
                        this.metric.tags = data.tags;
                        this.metric.cachedreports = data.cached_reports;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    ngOnInit() {
        let id: string;
        this.activeRoute.params.forEach(param => {
            id = param['id']
        });
        this.activeRoute.params.forEach(param => {
            this.type = param['type']
        });
        this.getMetricById(id);
    }

    goToMetrics() {

        this.activeRoute.params
            .subscribe((params: Params) => {
                if (params.hasOwnProperty('connectorid')) {
                    this.connectorId = params['connectorid'];
                    this.router.navigate([AppRoutingConstants.connectors_view_id, this.connectorId]);
                } else {
                    this.router.navigate([AppRoutingConstants.metrics, this.type]);
                }
            });
    }

}