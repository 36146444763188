import {Component, Input, Output, EventEmitter, ViewChild} from '@angular/core';
import {AppMenuComponent} from '../app-menu.component';

/**
 * Created by hirenparekh on 13/06/17.
 */

@Component({
    selector: 'app-overlay-menu',
    templateUrl: './overlay-menu.component.html',
    styleUrls: ['./overlay-menu.component.css']
})
export class OverlayMenuComponent {

    @Output() backDropClickedEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() overlayMenu: boolean;

    @Output() overlayMenuEvent: EventEmitter<any> = new EventEmitter<any>();

    @ViewChild('appMenu') appMenu: AppMenuComponent;

    onBackDropClicked() {
        this.appMenu.closePopupMenu();
        this.backDropClickedEvent.emit(true);
    }

    onOverlayMenuClickEvent(e) {
        // console.log("event",e);
        this.overlayMenuEvent.emit(e)
    }

    model = [
        /* {label: 'Dashboard', routerLink: ['/']},*/
        /*{
         label: 'Themes', icon: 'view_quilt', routerLink: ['/'],
         items: [
         {
         label: 'Dark - Blue', icon: 'brush', command: (event) => {
         this.changeTheme('dark-blue')
         }
         },
         {
         label: 'Purple - Cyan', icon: 'brush', command: (event) => {
         this.changeTheme('purple-cyan')
         }
         }
         ]
         },*/
        {
            label: 'BizBrain',
            items: [
                {
                    label: 'Organizations', icon: 'biz-org', routerLink: ['/organizations'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/organizations']}]
                },
                {
                    label: 'Products', icon: 'biz-product', routerLink: ['/products'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/products']}]
                },
                {
                    label: 'Connectors', icon: 'biz-connector', routerLink: ['/connectors'], isPined: false,
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/connectors']}]
                },
            ]
        },
        {
            label: 'BigData',
            items: [
                {
                    label: 'Pipes', icon: 'biz-pipe', routerLink: ['/pipes'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/']}]
                },
                {
                    label: 'Metrics', icon: 'biz-metrics', routerLink: ['/metrics'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/metrics']}]
                },
                {
                    label: 'Events', icon: 'biz-events', routerLink: ['/events'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/events']}]
                },
            ]
        },
        {
            label: 'Product Management',
            items: [
                {
                    label: 'Connectly', icon: 'biz-connectly-pro', routerLink: ['/connectly'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/connectly']},
                        {
                            label: 'Pro Apps',
                            icon: 'biz-subscribe-organization',
                            routerLink: ['connectly/proApps/Live']
                        },
                        {label: 'Build Management', icon: 'biz-connectly-community', routerLink: ['connectly/buildManagement']},
                        {label: 'Free Groups', icon: 'biz-connectly-community', routerLink: ['connectly/freeGroup']},
                        {label: 'Themes', icon: 'biz-theme', routerLink: ['connectly/themes']}
                    ]
                },
                /*{label: 'Connectly Community', icon: 'biz-connectly-community',routerLink: ['/'],
                 items: [
                 {label: 'Overview', icon: 'biz-overview', routerLink: ['/']}]
                 },*/
                {
                    label: 'Mera CRM', icon: 'biz-meracrm', routerLink: ['/meraCrm'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/']}]
                },
                {
                    label: 'Tally App', icon: 'biz-meracrm', routerLink: ['/tally'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/tally']},
                        {label: 'Customers', icon: 'biz-overview', routerLink: ['tally/customers']}
                    ]
                },
                {
                    label: 'Things App', icon: 'biz-meracrm', routerLink: ['/things'],
                    items: [
                        {label: 'Overview', icon: 'biz-overview', routerLink: ['/things']},
                        {label: 'Products', icon: 'biz-overview', routerLink: ['things/products']},
                        {label: 'SKUs', icon: 'biz-overview', routerLink: ['things/SKUs']},
                        {label: 'Inventory', icon: 'biz-overview', routerLink: ['things/inventory']},
                        {label: 'Customers', icon: 'biz-overview', routerLink: ['things/customers']},
                    ]
                }
            ]
        }
    ];

    changeTheme(theme) {
        let themeLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('theme-css');
        let layoutLink: HTMLLinkElement = <HTMLLinkElement> document.getElementById('layout-css');

        themeLink.href = 'assets/css/theme-' + theme + '.css';
        layoutLink.href = 'assets/css/layout-' + theme + '.css';
    }

}
