/**
 * Created by SonalPaghdal on 20/07/17.
 */
import {Injectable} from "@angular/core";
import {Http, Headers, Response} from "@angular/http";
import {Environment} from "../../environment.model";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";

@Injectable()
export class ConnectlyProService {
    groupId: any;
    groupOrgId: any;
    headers: Headers;
    activeBlock: any;

    constructor(private http: Http, private router: Router) {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        //this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', '8251bf8ee837865754c4782504dca8bd');
    }

    getHeaders(): Headers {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        //this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', '8251bf8ee837865754c4782504dca8bd');
        return this.headers;
    }

    getSubscribeGroupsCount(stage): Observable<any> {
        return this.http.get(Environment.connectlyApiUrl + 'groups/premiumGroupsCount' + '?stage='+stage, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getAllSubscribeGroupsCount(): Observable<any> {
        return this.http.get(Environment.connectlyApiUrl + 'groups/premiumGroupsCount', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }



    getProcessStage(id) {
        return this.http.get('http://processes.bizbrain.in/' + 'process/get/'+id)
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    cloneSubscribedOrg(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/clonePremiumApp', body,{headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    cloneSubscribedOrgProvisioning(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/migratePremiumApp', body,{headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    suspendApp(body) {
        return this.http.post(Environment.connectlyApiUrl + 'suspendPremiumApplication', body,{headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    terminateApp(body) {
        return this.http.post(Environment.connectlyApiUrl + 'terminatePremiumApplication', body,{headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    resumeApp(body) {
        return this.http.post(Environment.connectlyApiUrl + 'resumePremiumApplication', body,{headers: this.getHeaders()})
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getBuildProcessId() {
        return this.http.get('http://processes.bizbrain.in/' + 'process/get/')
            .map((response:Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getSubscribeGroups(skip,limit,stage): Observable<any> {
        return this.http.get(Environment.connectlyApiUrl + 'groups/premiumGroups/'+skip+'/'+limit + '?stage='+stage, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }
    getSubscribeAllGroups(skip,limit): Observable<any> {
        return this.http.get(Environment.connectlyApiUrl + 'groups/premiumGroups/'+skip+'/'+limit , {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupById(id) {
        return this.http.get(Environment.connectlyApiUrl + 'groups/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getSmsDetail(id) {
        return this.http.get(Environment.connectlyApiUrl + 'smsBrainCredential/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editSmsDetail(data) {
        return this.http.post(Environment.connectlyApiUrl + 'smsBrainCredential' ,data, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getGroupTheme(id) {
        return this.http.get(Environment.connectlyApiUrl + 'themes/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getThemes() {
        return this.http.get(Environment.connectlyApiUrl + 'themes', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getThemesByCategory(id) {
        return this.http.get(Environment.connectlyApiUrl + 'themes' +'/get?category=' +id, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateGroupTheme(id, body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/' + id + '/theme', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    uploadImage(formData) {
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.connectlyApiUrl + 'uploadAttachment', true);
            xhr.setRequestHeader('Accept', "*");
            this.headers.append('Access-Control-Allow-Origin', '*');
            xhr.setRequestHeader('token', '8251bf8ee837865754c4782504dca8bd');
            xhr.send(formData);
        });
    }

    uploadAppIcons(formData) {
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.connectlyApiUrl + 'uploadAppIcons', true);
            xhr.setRequestHeader('Accept', "*");
            this.headers.append('Access-Control-Allow-Origin', '*');
            xhr.setRequestHeader('token', '8251bf8ee837865754c4782504dca8bd');
            xhr.send(formData);
        });
    }

    uploadBWIcons(formData) {
        return Observable.create(observer => {
            let xhr: XMLHttpRequest = new XMLHttpRequest();

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            xhr.open('POST', Environment.connectlyApiUrl + 'uploadBWImage', true);
            xhr.setRequestHeader('Accept', "*");
            this.headers.append('Access-Control-Allow-Origin', '*');
            xhr.setRequestHeader('token', '8251bf8ee837865754c4782504dca8bd');
            xhr.send(formData);
        });
    }

    deleteSubscribedOrg(body) {
        return this.http.post(Environment.connectlyApiUrl + 'groups/dissolve', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }
    addNewTheme(body) {
        return this.http.post(Environment.connectlyApiUrl + 'themes', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editNewTheme(body,id) {
        return this.http.post(Environment.connectlyApiUrl + 'themes/'+id, body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteTheme(id) {
        return this.http.delete(Environment.connectlyApiUrl + 'themes/' +id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getRoleById(id) {
        return this.http.get(Environment.connectlyApiUrl + 'userRoles/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getRoles(id) {
        return this.http.get(Environment.connectlyApiUrl + 'userRoles/byApp/'+id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getBuildVersion() {
        return this.http.get(Environment.connectlyApiUrl + 'buildSettings', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    checkBuildAvailibility(body) {
        return this.http.post(Environment.connectlyApiUrl + 'checkForNewBuildAvailability',body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    generateBuild(body) {
        return this.http.post(Environment.connectlyApiUrl + 'triggerApplicationAutoBuildProcess', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    checkBuildProcess(id) {
        return this.http.get(Environment.connectlyApiUrl + 'appBuildSetting/progress/'+ id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }
    checkBuildProcessOnSecond(id) {
        return this.http.get(Environment.connectlyApiUrl + 'appBuildSetting/progress/'+ id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewRole(body) {
        return this.http.post(Environment.connectlyApiUrl + 'userRoles', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editNewRole(id,body) {
        return this.http.put(Environment.connectlyApiUrl + 'userRoles/'+id, body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteRole(id) {
        return this.http.delete(Environment.connectlyApiUrl + 'userRoles/' +id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewMember(body) {
        return this.http.post(Environment.connectlyApiUrl + 'userMembers', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editNewMember(body,id) {
        return this.http.post(Environment.connectlyApiUrl + 'userMembers/'+id, body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteMember(id) {
        return this.http.delete(Environment.connectlyApiUrl + 'userMembers/' +id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }




    authorizedUserService(response) {
        if (response.flag == false) {
            if (response.code == 401) {
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
