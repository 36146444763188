import {Component, AfterViewInit, Input} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router, Params, NavigationEnd} from "@angular/router";
import {Message} from "primeng/components/common/api";
import {commonConstants} from "../../../../../common/models/constants";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'view-subGroup',
    templateUrl: './view-subGroup.component.html',
    // styleUrls: ['./view-subGroup.component.css']
})
export class ViewSubGroup implements AfterViewInit {
    groupId:string;
    subGroupId:string;
    GroupProfile:string;
    GroupOverview:string;
    selectedThemeId:string;
    GroupTheme:string;
    displayThemeBlock:boolean = false;
    group:any;
    theme:any;

    activeBlock:any;
    allThemes:any;
    allThemesImages:any = [];
    constants = commonConstants;
    msgs:Message[] = [];

    activeroute:string;
    activerouteData:string;

    constructor(private _titleService:Title, private router:Router,
                private activeRoute:ActivatedRoute, private connectlyProService:ConnectlyProService) {
        this.activeBlock = this.connectlyProService.activeBlock
    }

    ngAfterViewInit():void {
        //this._titleService.setTitle('Home Dashboard');

        this.activeroute = this.router.url;

    }

    closeView() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock, 'view', this.groupId, 'subGroups']);
    }

    ngOnInit() {
        let id = '';
        this.groupId = this.connectlyProService.groupId;
        // let id = this.activeRoute.snapshot.parent.params['subGroupId'];
        this.activeRoute.params.subscribe(param => {
                this.subGroupId = param['subGroupId'];
            }
        );
        console.log("subGroupId", this.subGroupId);
        this.getSubGroupById(this.subGroupId);
        this.getThemes();

    }

    getSubGroupById(groupId:string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:any = response.data;
                        this.group = response.data;
                        this.GroupOverview = this.convertUrlToImageUrl(this.group.groupOverviewImage);
                        this.GroupProfile = this.convertUrlToImageUrl(this.group.imageUrl);
                        if (this.group.themeId != '') {
                            this.getGroupTheme(this.group.themeId);
                        }
                        console.log("this.GroupProfile", this.GroupProfile);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w400';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substr(0, index) + chr + str.substr(index + 1);
    }

    getGroupTheme(id:string) {
        this.connectlyProService.getGroupTheme(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.theme = response.data;
                        this.GroupTheme = this.convertUrlToImageUrl(this.theme.previewImage);
                        console.log("this.GroupTheme", this.GroupTheme);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getThemes() {
        this.connectlyProService.getThemes()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.allThemes = response.data;
                        console.log("themes--", this.allThemes);
                        this.getThemeObject(this.allThemes);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getThemeObject(themes) {
        this.allThemesImages = [];
        themes.forEach(currTheme => {
            let theme = {
                theme: this.convertUrlToImageUrl(currTheme.previewImage),
                id: currTheme.id,
                name: currTheme.name
            }
            this.allThemesImages.push(theme)

        })
    }

    changeGroupTheme() {
        this.displayThemeBlock = true;

    }

    selectedTheme(id) {
        this.selectedThemeId = '';
        this.selectedThemeId = id;
        console.log("this.selectedThemeId ", this.selectedThemeId);
    }

    updateGroupTheme() {
        let tempData = {
            themeId: this.selectedThemeId
        }
        this.connectlyProService.updateGroupTheme(this.subGroupId, tempData)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.displayThemeBlock = false;
                        //this.theme=data;
                        this.getGroupTheme(data.themeId);
                        //this.GroupTheme=this.theme.previewImage;
                    } else {
                        this.displayThemeBlock = false;
                        console.log(response.message);
                        this.displayMessage(response.message);
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    displayMessage(msg) {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error Message', detail: msg});
    }

}
