import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConnectorsService} from "../../../../services/connectors.service";
import {Connector} from "../../../../models/connector.model";
import {ConnectorComponent} from "../../../../models/connectorComponent.model";
import {ComponentForm} from "../component-form/component-form.component";

/**
 * Created by hirenparekh on 26/12/16.
 */

declare var jQuery: any;

@Component({
    selector: 'edit-component',
    templateUrl: './edit-component.component.html',
    styleUrls: ['./edit-component.component.css']
})
export class EditComponent {
    @Output() componentEditedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('editComponent') editComponent: ElementRef;
    @ViewChild('componentForm') componentForm: ComponentForm;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    connector: Connector;
    connectorComponent: ConnectorComponent;

    constructor(private connectorService: ConnectorsService) {

    }

    openModal(connectorComponent: ConnectorComponent ,connector :Connector) {
        this.resetView();
       // jQuery(this.editComponent.nativeElement).modal('show');
        this.connector =connector;
        this.connectorComponent = connectorComponent;
        this.componentForm.component = connectorComponent;
    }

    closeModal() {
        //jQuery(this.editComponent.nativeElement).modal('hide');
    }

    resetView() {
        this.connectorComponent = null;
        this.selectedFile = "";
    }

    ngOnInit() {

    }

    onFormSubmit(data:any){
        data.connector_id=this.connector.uniqueId;
        this.connectorService.updateComponent(this.connectorComponent.uniqueId,data)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                    this.componentEditedEvent.emit(new ConnectorComponent().getObject(response.data))
                }else{
                    console.log(response.message);
                }
            },
            error => {
                console.log(error);
            },
            () => {
                this.closeModal();
            }
        );
    }

}