import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";

import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";

import {Message} from "primeng/components/common/message";
import {EventsService} from "../../services/events.service";
import {EventForm} from "../event-form/event-form.component";
import {AppRoutingConstants} from "../../../app-routing.constants";


declare var jQuery: any;

@Component({
    selector: 'add-events',
    templateUrl: './add-event.component.html',
    styleUrls: ['./add-event.component.css']
})
export class AddEvent {
    //@Output() componentAddedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('addEvent') addEvent: ElementRef;
    @ViewChild('eventForm') eventForm: EventForm;
    events: any;
    groupId: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private eventsService: EventsService) {
    }

    ngOnInit() {

    }

    onFormSubmit(event: any) {
        console.log("event.connector_id", event)

        this.addNewevents(event);
    }

    addNewevents(data: any) {
        this.eventsService.addNewEvent(data)
            .subscribe(
                response => {
                    if (response.flag) {
                       // console.log(response.message);
                        //console.log(response.data);
                        this.closeModal();
                    } else {
                       // console.log(response.message);
                        if(response.code == 422){
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: 'The event key has been already taken.'
                            });
                        }else {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }

                    }
                },
                error => {
                   // console.log(error);
                    if(error.code == 422){
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: 'The event key has been already taken.'
                        });
                    }else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                },
                () => {

                }
            );
    }

    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.events]);
    }

    resetView() {

    }


}