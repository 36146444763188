import {Component, AfterViewInit, Input} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router, Params, NavigationEnd} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {ConnectlyProService} from "../../../services/connectly-pro.service";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {FormGroup, FormControl} from "@angular/forms";
import {OrganizationService} from "../../../../organization/services/organization.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'overview-view-subscribe-org',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewViewSubscribeOrgComponent implements AfterViewInit {
    groupId: string;
    GroupProfile: string;
    GroupOverview: string;
    selectedThemeId: string;
    GroupTheme: string;
    displayThemeBlock: boolean = false;
    group: any;
    theme: any;
    allThemes: any;
    allThemesImages: any = [];
    constants = commonConstants;
    msgs: Message[] = [];

    smsDetail: any;
    smsDetailForm: FormGroup;
    displayEditNameBlock: boolean = false;
    displayEditPasswordBlock: boolean = false;
    displayEditSenderIdBlock: boolean = false;
    displayEditBaseURLBlock: boolean = false;
    displayEditBlock: boolean = false;
    editSmsBrainDetail: boolean = false;

    activeroute: string;
    activerouteData: string;
    activeBlock: string;

    error: any = [];
    organization: OrganizationService;

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService,
              private orgServices: OrganizationService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
        this.activeBlock=this.connectlyProService.activeBlock;

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Home Dashboard');
        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                this.activeroute = this.router.url;
                console.log("this.activeroute", this.activeroute)

            })
    }

    ngOnInit() {
        this.getGroupById(this.groupId);
        this.smsDetailForm = new FormGroup({
            name: new FormControl(''),
            password: new FormControl(''),
            senderId: new FormControl(""),
            baseURL: new FormControl(""),
        });
        //this.getThemes();
        this.getSmsDetail(this.groupId);
    }

    getOrgById(orgId: string) {

        this.orgServices.getOrganizationById(orgId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.organization = data;
                        console.log("this.organization",this.organization)

                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getThemesByCategory(id) {

        if (id != '' || id != null) {
            this.connectlyProService.getThemesByCategory(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.allThemes = response.data;
                            this.getThemeObject(this.allThemes);

                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        }
    }


    getGroupById(groupId: string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;

                        this.group = response.data;
                        console.log("this.group",this.group)
                        if(this.group.orgId){
                            this.getOrgById(this.group.orgId)
                        }

                        this.GroupOverview = this.convertUrlToImageUrl(this.group.groupOverviewImage);
                        this.GroupProfile = this.convertUrlToImageUrl(this.group.imageUrl);
                        if (this.group.themeId != '') {
                            this.getGroupTheme(this.group.themeId);
                        }
                        console.log("this.GroupProfile", this.GroupProfile);

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getSmsDetail(groupId: string) {

        this.connectlyProService.getSmsDetail(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.smsDetail = response.data;
                        //this.smsDetail = null;
                        if (this.smsDetail != null && this.smsDetail != '') {
                            this.setValues();
                        } else {
                            this.displayEditBlock = true;
                        }
                        console.log("this.smsDetail", this.smsDetail)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    gotoMemberTab() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId, 'members']);
    }

    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w400';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    setCharAt(str, index, chr) {
        if (index > str.length - 1) return str;
        return str.substr(0, index) + chr + str.substr(index + 1);
    }

    getGroupTheme(id: string) {
        this.connectlyProService.getGroupTheme(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.theme = response.data;
                        this.GroupTheme = this.convertUrlToImageUrl(this.theme.previewImage);
                        console.log("this.GroupTheme", this.GroupTheme);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getThemes() {
        this.connectlyProService.getThemes()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.allThemes = response.data;
                        console.log("themes--", this.allThemes);
                        this.getThemeObject(this.allThemes);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getThemeObject(themes) {
        this.allThemesImages = [];
        if (themes.length > 0) {
            themes.forEach(currTheme => {
                let theme = {
                    theme: this.convertUrlToImageUrl(currTheme.previewImage),
                    id: currTheme.id,
                    name: currTheme.name
                }
                this.allThemesImages.push(theme)

            })
        } else {
            this.allThemesImages = [];
        }

    }

    changeGroupTheme() {
        this.displayThemeBlock = true;
        this.getThemesByCategory(this.group.category)
    }

    selectedTheme(id) {
        this.selectedThemeId = '';
        this.selectedThemeId = id;
        console.log("this.selectedThemeId ", this.selectedThemeId);
    }

    updateGroupTheme() {
        let tempData = {
            themeId: this.selectedThemeId
        }
        this.connectlyProService.updateGroupTheme(this.groupId, tempData)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.displayThemeBlock = false;
                        //this.theme=data;
                        this.getGroupTheme(data.themeId);
                        //this.GroupTheme=this.theme.previewImage;
                    } else {
                        this.displayThemeBlock = false;
                        console.log(response.message);
                        this.displayMessage(response.message);
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    displayMessage(msg) {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error Message', detail: msg});
    }

    setValues() {

        let value = {
            name: this.smsDetail.username,
            password: this.smsDetail.password,
            senderId: this.smsDetail.senderId,
            baseURL: this.smsDetail.baseURL,
        };
        this.smsDetailForm.reset(value);

    }

    editName() {
        this.displayEditNameBlock = true;
    }

    editedName() {
        //console.log(this.groupForm.value['type'])
        let temp_data = {
            username: this.smsDetailForm.value['name'],
            password: this.smsDetail.password,
            senderId: this.smsDetail.senderId,
            baseURL: this.smsDetail.baseURL,
        }

        let flag: boolean = false;
        if (this.smsDetailForm.value['name'] == null || this.smsDetailForm.value['name'] == '') {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'User name cannot blank'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['name'] != '' && this.smsDetailForm.value['name'].length > 32) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Maximum length of user name is 32 '
            });
        }

        if (this.smsDetailForm.value['name'] != '' && this.smsDetailForm.value['name'].length < 3) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Minimum length of user name is 3 '
            });
        }

        if (this.smsDetailForm.value['name'] != '' && !this.constants.smsUserNamePattern.test(this.smsDetailForm.value['name'])) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Only letters and digits allowed'
            });
            //this.setValues();
        }

        if (!flag) {
            this.editSmsDetail(temp_data, 'displayEditNameBlock');
            //this.displayEditPasswordBlock = false
        }
    }

    cancelEditName() {
        this.displayEditNameBlock = false;
        this.setValues();
    }

    editPassword() {
        this.displayEditPasswordBlock = true;
    }

    editedPassword() {
        //console.log(this.groupForm.value['type'])
        let temp_data = {
            username: this.smsDetail.username,
            password: this.smsDetailForm.value['password'],
            senderId: this.smsDetail.senderId,
            baseURL: this.smsDetail.baseURL,
        }
        let flag: boolean = false;
        if (this.smsDetailForm.value['password'] == null || this.smsDetailForm.value['password'] == '') {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Password cannot be blank'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['password'] != '' && this.smsDetailForm.value['password'].length > 32) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Maximum length of password 32'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['password'] != '' && this.smsDetailForm.value['password'].length < 5) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Minimum length of password 5'
            });
            //this.setValues();
        }

        if (!flag) {
            this.editSmsDetail(temp_data, 'displayEditPasswordBlock');
            //this.displayEditPasswordBlock = false
        }
    }

    cancelEditPassword() {
        this.displayEditPasswordBlock = false;
        this.setValues();
    }

    editSenderId() {
        this.displayEditSenderIdBlock = true;
    }

    editedSenderId() {
        //console.log(this.groupForm.value['type'])
        let temp_data = {
            username: this.smsDetail.username,
            password: this.smsDetail.password,
            senderId: this.smsDetailForm.value['senderId'],
            baseURL: this.smsDetail.baseURL,
        }
        let flag: boolean = false;
        if (this.smsDetailForm.value['senderId'] == '') {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Sender ID cannot be blank'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['senderId'] != '' && this.smsDetailForm.value['senderId'].length != 6) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Length of sender Id must be 6'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['senderId'] != '' && !this.constants.senderIdPattern.test(this.smsDetailForm.value['senderId'])) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Only letters allowed!'
            });
            //this.setValues();
        }

        if (!flag) {
            this.editSmsDetail(temp_data, 'displayEditSenderIdBlock');
            //this.displayEditSenderIdBlock = false
        }
    }

    cancelEditSenderId() {
        this.displayEditSenderIdBlock = false;
        this.setValues();
    }


    editBaseURL() {
        this.displayEditBaseURLBlock = true;
    }

    editedBaseURL() {
        //console.log(this.groupForm.value['type'])
        let temp_data = {
            username: this.smsDetail.username,
            password: this.smsDetail.password,
            senderId: this.smsDetail.senderId,
            baseURL: this.smsDetailForm.value['baseURL'],
        }
        let flag: boolean = false;
        if (this.smsDetailForm.value['baseURL'] == null || this.smsDetailForm.value['baseURL'] == '') {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'BaseURL cannot be blank'
            });
            //this.setValues();
        }

        if (this.smsDetailForm.value['baseURL'] != '' && !this.constants.URLPattern.test(this.smsDetailForm.value['baseURL'])) {
            flag = true;
            this.msgs = [];
            this.msgs.push({
                severity: 'error',
                summary: 'Error Message',
                detail: 'Please enter valid URL'
            });
            //this.setValues();
        }

        if (!flag) {
            this.editSmsDetail(temp_data, 'displayEditBaseURLBlock');
            //this.displayEditBaseURLBlock = false
        }
    }

    cancelEditBaseURL() {
        this.displayEditBaseURLBlock = false;
        this.setValues();
    }

    editSmsDetail(formData, block) {
        formData.appId = this.groupId;
        this.connectlyProService.editSmsDetail(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.smsDetail = response.data;
                        if (block == 'displayEditNameBlock') {
                            this.displayEditNameBlock = false
                        }
                        if (block == 'displayEditPasswordBlock') {
                            this.displayEditPasswordBlock = false
                        }
                        if (block == 'displayEditSenderIdBlock') {
                            this.displayEditSenderIdBlock = false
                        }
                        if (block == 'displayEditBaseURLBlock') {
                            this.displayEditBaseURLBlock = false
                        }
                        if (block == 'displayEditBlock') {
                            this.displayEditBlock = false
                        }
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'success',
                            summary: 'Success Message',
                            detail: response.message
                        });
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {

                }
            );
    }

    AddSmsBrainDetail() {
        this.editSmsBrainDetail = true;
    }

    resetSmsBrainForm() {
        this.editSmsBrainDetail = false;

        this.smsDetailForm = new FormGroup({
            name: new FormControl(''),
            password: new FormControl(''),
            senderId: new FormControl(""),
            baseURL: new FormControl(""),
        });
    }

    addNewsmsBrainDetail() {
        this.error = [];
        let temp_data = {
            username: this.smsDetailForm.value['name'],
            password: this.smsDetailForm.value['password'],
            senderId: this.smsDetailForm.value['senderId'],
            baseURL: this.smsDetailForm.value['baseURL'],
        }

        if (this.smsDetailForm.value['name'] != '' && !this.constants.smsUserNamePattern.test(this.smsDetailForm.value['name'])) {
            this.error.push("Only letters and digits allowed in user name!");
        }
        if (this.smsDetailForm.value['name'] != '' && this.smsDetailForm.value['name'].length > 32) {
            this.error.push("Maximum length of user name is 32 ");
        }

        if (this.smsDetailForm.value['name'] != '' && this.smsDetailForm.value['name'].length < 3) {
            this.error.push("'Minimum length of user name is 3 ");
        }

        if (this.smsDetailForm.value['password'].length > 32) {
            this.error.push("Maximum length of password 32");
        }
        if (this.smsDetailForm.value['password'].length < 5) {
            this.error.push("Minimum length of password 5");
        }

        if (this.smsDetailForm.value['senderId'] != '' && this.smsDetailForm.value['senderId'].length != 6) {
            this.error.push("Length of sender Id must be 6");
        }
        if (this.smsDetailForm.value['senderId'] != '' && !this.constants.senderIdPattern.test(this.smsDetailForm.value['senderId'])) {
            this.error.push("Only letters allowed in sender ID!");
        }
        if (this.smsDetailForm.value['baseURL'] != '' && !this.constants.URLPattern.test(this.smsDetailForm.value['baseURL'])) {
            this.error.push("Please enter valid URL");
        }
        if (this.error && this.error.length == 0) this.editSmsDetail(temp_data, 'displayEditBlock');
    }
}
