import {ISerializable} from "../../common/interface/serializable.interface";
import {MetricTag} from "../../metrics/models/metrics-tags.model";
import {MetricCacheReport} from "../../metrics/models/metrics-cache-report-model";


export class ConnectorMetric implements ISerializable<ConnectorMetric> {

    connectorId: string;
    name: string;
    description: string;
    metricKey: string;
    dataType: number;
    unit: string;
    unitValue: string;
    preferredvalue: number;
    metricId: string;
    snapshotdefs: string[];
    downsamplingdefs: string[];
    updatedAt: string;
    createdAt: string;


    metricType:number;
    metricUnit:number;
    tags:MetricTag[];
    data:{};
    cachedreports:MetricCacheReport[];
    organizations:Array<any>[];

    getObject(data: any): ConnectorMetric {

        let connectorMetric = new ConnectorMetric();

        if (!data)
            return connectorMetric;

        connectorMetric.connectorId = data['connector_id'];
        connectorMetric.name = data['name'];
        connectorMetric.description = data['description'];
        connectorMetric.metricKey = data['metric_key'];
        connectorMetric.dataType = data['data_type'];
        connectorMetric.unit = data['unit'];
        connectorMetric.preferredvalue = data['preferred_value'];
        connectorMetric.metricId = data['metric_id'];
        connectorMetric.snapshotdefs = data['snapshotdefs'];
        connectorMetric.downsamplingdefs = data['downsamplingdefs'];
        connectorMetric.updatedAt = data['updated_at'];
        connectorMetric.createdAt = data['created_at'];
        connectorMetric.unitValue = data['unit_value'];

        connectorMetric.metricId = data['metric_id'];
        connectorMetric.metricType = data['metric_type'];
        connectorMetric.metricUnit = data['unit'];
        connectorMetric.organizations = data['organizations'];
        connectorMetric.cachedreports = data['cached_reports'];
        connectorMetric.tags = data['tags'];

        return connectorMetric;
    }

}
