/**
 * Created by hirenparekh on 12/11/16.
 */

import {Component, OnInit} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {User} from "../../../user/models/user.model";
import {ProductSubscription} from "../../models/product-subscription.model";
import {Organization} from "../../models/organization.model";
import {AppRoutingConstants} from "../../../app-routing.constants";

@Component({
  selector:'org-service-list',
  templateUrl:'./organization-product.component.html',
  styleUrls:['./organization-product.component.css','../organizations.component.scss']
})
export class OrganizationProductComponent implements OnInit{

  organization: Organization;
  users: User[] = [];
  services: ProductSubscription[] = [];
  pageName:string;

  constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute,private router:Router) {

  }

   getOrganizationService(orgId){
    this.orgServices.getOrganizationById(orgId)
      .subscribe(
        response => {
          if (response.flag) {
            let data: any = response.data;
            this.organization = new Organization().getObject(data);

            data.product_subscriptions.forEach(prodSub => {
              this.services.push(new ProductSubscription().getObject(prodSub))
            });
          } else {
            console.log(response.message);
          }
        },
        error => {
          console.log(error)
        }
      );
   }

  addOrganizationServices(orgId){

      this.router.navigate([AppRoutingConstants.organizations_view_id,orgId,'subscriptions','addSubscription']);
  }

    deleteOrganizationService(id) {
        let confirmation = confirm("Are you sure to delete?");

        if(!confirmation)
            return;

        this.orgServices.deleteOrganizationService(id)
            .subscribe(
                response => {
                    if(response.flag)
                    {
                        for(let i = 0; i < this.services.length ; i++)
                        {
                            if(this.services[i].uniqueId === id)
                            {
                                this.services.splice(i,1);
                                break;
                            }
                        }
                    }else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

  ngOnInit(){
    let orgId:string;
    this.activatedRoute.params.forEach((param:Params) => {
      orgId = param['id'];
    });
    this.getOrganizationService(orgId)
  }

}
