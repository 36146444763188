import {Component, AfterViewInit} from "@angular/core";
/**
 * Created by hirenparekh on 08/12/16.
 */

@Component({
  selector:'loader',
  templateUrl:'./loader.component.html',
  styleUrls:['./loader.component.css']
})
export class LoaderComponent implements AfterViewInit {

  constructor() {

  }

  ngAfterViewInit() {

  }
}
