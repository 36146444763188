import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Connector} from "../../models/connector.model";
import {ConnectorsService} from "../../services/connectors.service";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Message} from "primeng/components/common/message";
/**
 * Created by hirenparekh on 28/12/16.
 */


@Component({
    selector: 'connector-form',
    templateUrl: './connector-form.component.html',
    styleUrls: ['./connector-form.component.css', '../connectors.component.scss']
})
export class ConnectorForm {
    error:any;
    connectorForm: FormGroup;
    scheduleOptForm: FormGroup;
    serviceProviders: any;
    is_active: boolean;
    selectedFile: any;
    //selectedFileData: any;
    valueSchedule: string[] = [];

    stages: SelectItem[];
    service_providers: SelectItem[];
    private _connector: Connector;
    msgs: Message[] = [];
    @Input()
    submitBtnLabel: string;

    @Input()
    selectedFileData: any;

    @Output()
    formSubmitEvent: EventEmitter<FormData> = new EventEmitter<FormData>();

    @Output()
    formCancelEvent: EventEmitter<FormData> = new EventEmitter<FormData>();

    getFormData(): FormData {
        this.getSelectedScheduleOptions();
        let formData = new FormData();
        console.log("Edit fo", this.connectorForm.value['connector_name']);
        formData.append('name', this.connectorForm.value['connector_name']);
        formData.append('description', this.connectorForm.value['description']);
        formData.append('class_namespace', this.connectorForm.value['class_namespace']);
        console.log(this.valueSchedule);
        for (var i = 0; i < this.valueSchedule.length; i++) {
            formData.append('schedule_options[]', this.valueSchedule[i]);
        }
        formData.append('is_active', this.connectorForm.value['is_active']);
        formData.append('service_provider_id', this.connectorForm.value['service_provider']);
        formData.append('stage', this.connectorForm.value['stage']);
        formData.append('fields', this.connectorForm.value['fields_metadata']);

        if (this.selectedFile)
            formData.append('icon', this.selectedFile);
        else if (this._connector)
            formData.append('icon', this._connector.icon);
        else
            formData.append('icon', null);

        console.log("getter : ", formData);
        console.log("this.valueSchedule", this.valueSchedule);
        return formData;

    }

    get connector(): Connector {
        return this._connector;
    }

    @Input()
    set connector(value: Connector) {
        console.log("connector:", value);

        this._connector = new Connector().getObject(value);
        if (this._connector)
            this.setValues();
    }

    constructor(private connectorService: ConnectorsService, private router: Router, private activeRoute: ActivatedRoute) {
        this.selectedFile = '';
        this.selectedFileData = '';

        this.scheduleOptForm = new FormGroup({
            minutely: new FormControl(this.valueSchedule),
            hourly: new FormControl(this.valueSchedule),
            daily: new FormControl(this.valueSchedule),
            weekly: new FormControl(this.valueSchedule),
            monthly: new FormControl(this.valueSchedule),
            quarterly: new FormControl(this.valueSchedule),
            yearly: new FormControl(this.valueSchedule)
        }, this.scheduleOptValidator);

        this.connectorForm = new FormGroup({
            connector_name: new FormControl("", Validators.required),
            service_provider: new FormControl(0, Validators.required),
            stage: new FormControl(0, Validators.required),
            class_namespace: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
            fields_metadata: new FormControl(""),
            is_active: new FormControl(false),
            schedule_availability: this.scheduleOptForm,
        });
        //this.valueSchedule = [];
        this.stages = [];
        this.stages.push({label: 'Select Stage', value: null});
        this.stages.push({label: 'Alpha', value: 1});
        this.stages.push({label: 'Beta', value: 2});
        this.stages.push({label: 'Production', value: 3});
        this.stages.push({label: 'Special', value: 4});
        this.getServiceProviders();
    }

    ngOnInit() {

    }

    getServiceProviders() {
        this.connectorService.getServiceProviders()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.serviceProviders = response.data;
                        this.service_providers = [];
                        this.service_providers.push({label: 'Select Service Providers', value: null});
                        this.serviceProviders.forEach(currnt => {
                            this.service_providers.push({label: currnt.name, value: currnt.unique_id});
                        })
                        console.log(" this.serviceProviders", this.serviceProviders)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    scheduleOptValidator(c: FormGroup): any {
        let error = {required: 'at least one option is required'}
        for (let key in c.value) {
            if (c.value[key]) {
                error = null;
            }
        }
        return error;
    }

    cancelForm() {
        this.formCancelEvent.emit();
    }

    resetForm() {
        this.connectorForm.reset();
        this.selectedFile = '';
        this.selectedFileData = '';
        this.connectorForm = new FormGroup({
            connector_name: new FormControl("", Validators.required),
            service_provider: new FormControl(0, Validators.required),
            stage: new FormControl(0, Validators.required),
            class_namespace: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
            schedule_availability: this.scheduleOptForm,
            fields_metadata: new FormControl(""),
            is_active: new FormControl(false)
        });
        this.router.navigate([AppRoutingConstants.connectors]);

    }

    setValues() {

        let scheduleOpt: any = {};
        console.log("this._connector.scheduleOptions", this._connector.scheduleOptions)
        if (this._connector.scheduleOptions) {
            for (let i = 0; i < this._connector.scheduleOptions.length; i++) {
                switch (this._connector.scheduleOptions[i]) {
                    case "1":
                        scheduleOpt['minutely'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "2":
                        scheduleOpt['hourly'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "3":
                        scheduleOpt['daily'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "4":
                        scheduleOpt['weekly'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "5":
                        scheduleOpt['monthly'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "6":
                        scheduleOpt['quarterly'] = [this._connector.scheduleOptions[i]];
                        break;
                    case "7":
                        scheduleOpt['yearly'] = [this._connector.scheduleOptions[i]];
                        break;
                }
            }
        }


        let value = {
            connector_name: this._connector.name,
            service_provider: this._connector.serviceProviderId,
            stage: this._connector.stage,
            class_namespace: this._connector.classNamespace,
            description: this._connector.description,
            fields_metadata: this._connector.fields,
            is_active: this._connector.isActive,
            schedule_availability: scheduleOpt,
        };
        console.log("scheduleOpt", scheduleOpt);
        this.connectorForm.reset(value);
        //this.valueSchedule = this._connector.scheduleOptions;
        console.log(" this.valueSchedule-", this.valueSchedule)
        // if (this._connector.isActive == "true" || this._connector.isActive == true)
        //     this._connector.isActive = true;
        // else
        //     this._connector.isActive = false;
    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedFile = file;
            console.log("this.selectedFile", this.selectedFile);
            this.selectedFileData = reader.result;
            console.log(" this.selectedFileData", this.selectedFileData);
        };
        reader.readAsDataURL(file);
    }


    addRemoveScheduleOptions(e, event) {
        console.log("event.target.value", event)
        var thisClass = this;
        var value1 = event;
        var value = value1.toString();
        console.log("value", value)
        if (thisClass.valueSchedule.indexOf(value) > -1) {
            thisClass.valueSchedule.splice(thisClass.valueSchedule.indexOf(value), 1);
        } else {
            thisClass.valueSchedule.push(value);
        }
        console.log(JSON.stringify(thisClass.valueSchedule));
    }

    getSelectedScheduleOptions() {
        let valueOfvalueSchedule = {};
        let valueSchedule = [];
        valueOfvalueSchedule = this.scheduleOptForm.value;
        console.log("this.valueSchedule", this.valueSchedule);
        console.log("this.scheduleOptForm.value", this.scheduleOptForm.value);

        console.log("curr", valueOfvalueSchedule);
        if (valueOfvalueSchedule['minutely'] && valueOfvalueSchedule['minutely'].length > 0) {
            valueSchedule.push('1');
        }
        if (valueOfvalueSchedule['hourly'] && valueOfvalueSchedule['hourly'].length > 0) {
            valueSchedule.push('2');
        }
        if (valueOfvalueSchedule['daily'] && valueOfvalueSchedule['daily'].length > 0) {
            valueSchedule.push('3');
        }
        if (valueOfvalueSchedule['weekly'] && valueOfvalueSchedule['weekly'].length > 0) {
            valueSchedule.push('4');
        }
        if (valueOfvalueSchedule['monthly'] && valueOfvalueSchedule['monthly'].length > 0) {
            valueSchedule.push('5');
        }
        if (valueOfvalueSchedule['quarterly'] && valueOfvalueSchedule['quarterly'].length > 0) {
            valueSchedule.push('6');
        }
        if (valueOfvalueSchedule['yearly'] && valueOfvalueSchedule['yearly'].length > 0) {
            valueSchedule.push('7');
        }

        this.valueSchedule = valueSchedule;
    }

    addNewConnectors(event) {
        this.formSubmitEvent.emit(this.getFormData());

    }

}