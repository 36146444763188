import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {PrimeNgComponentModules} from './primeng-components';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpModule} from '@angular/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HeaderComponent} from './header/header.component';
import {OverlayMenuComponent} from './menu/overlay-menu/overlay-menu.component';
import {FixMenuComponent} from './menu/fix-menu/fix-menu.component';
import {AppMenuComponent} from './menu/app-menu.component';
import {AppRoutingModule} from './app-routing.module';
import {OverviewComponent} from './dashboard/overview/overview.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {LoginComponent} from './login/components/login.component';
import {LoginService} from './login/services/login.service';
import {Environment} from './environment.model';
import {MetricsComponent} from './metrics/components/metrics.component';
import {MetricsOverviewComponent} from './metrics/components/overview/overview.component';
import {AddMetrics} from './metrics/components/add-metric/add-metric.component';
import {ViewMetrics} from './metrics/components/view-metrics/view-metrics.component';
import {BreadcrumbComponent} from './Breadcrumb_component/breadcrumb.component';
import {MetricsForm} from './metrics/components/metric-form/metric-form.component';
import {MetricsService} from './metrics/services/metrics.service';
import {AuthService} from './auth.service';
import {FileUploadService} from './common/services/file-upload.service';
import {LoaderComponent} from './common/components/loader/loader.component';
import {FilterPipe} from './ng-pipes/filter.pipe';
import {OnjectLengthFilter} from './ng-pipes/objectLength.pipe';
import {AddOrganizationUserComponent} from './organization/components/add-organization-user/add-organization-user.component';
import {OrganizationProductComponent} from './organization/components/organization-product/organization-product.component';
import {OrganizationUsersComponent} from './organization/components/organization-users/organization-users.component';
import {OrganizationDetailComponent} from './organization/components/organization-detail/organization-detail.component';
import {OrganizationComponent} from './organization/components/organizations.component';
import {EditOrganizationComponent} from './organization/components/edit-organization/edit-organization.component';
import {AddOrganizationComponent} from './organization/components/add-organization/add-organization.component';
import {AddOrganizationProductComponent} from './organization/components/add-organization-service/add-organization-product.component';
import {OrganizationsOverviewComponent} from './organization/components/overview/overview.component';
import {EditBizBrainServiceComponent} from './bizbrain-services/components/edit-service/edit-service.component';
import {AddBizBrainServiceComponent} from './bizbrain-services/components/add-service/add-service.component';
import {BizBrainServiceDetailComponent} from './bizbrain-services/components/service-detail/service-detail.component';
import {BizBrainServicesOverviewComponent} from './bizbrain-services/components/overview/overview.component';
import {BizBrainServiceComponent} from './bizbrain-services/components/bizbrain-service.component';
import {BizBrainServicesService} from './bizbrain-services/services/bizbrain-service.service';
import {OrganizationService} from './organization/services/organization.service';
import {ConnectorsComponent} from './connectors/components/connectors.component';
import {ConnectorOverviewComponent} from './connectors/components/overview/overview.component';
import {AddConnectorComponent} from './connectors/components/add-connector/add-connector.component';
import {EditConnectorComponent} from './connectors/components/edit-connector/edit-connector.component';
import {ConnectorForm} from './connectors/components/connector-form/connector-form.component';
import {ViewConnectorComponent} from './connectors/components/view-connector/view-connector.component';
import {ConnectlyProComponent} from './connectly-pro/connectly-pro.component';
import {AddComponent} from './connectors/components/view-connector/component-connector/add-component/add-component.component';
import {EditComponent} from './connectors/components/view-connector/component-connector/edit-component/edit-component.component';
import {ComponentForm} from './connectors/components/view-connector/component-connector/component-form/component-form.component';
import {AddMetric} from './connectors/components/view-connector/metric-connector/add-metric/add-metric.component';
import {MetricForm} from './connectors/components/view-connector/metric-connector/metric-form/metric-form.component';
import {EditMetric} from './connectors/components/view-connector/metric-connector/edit-metric/edit-metric.component';
import {ConnectorsService} from './connectors/services/connectors.service';
import {TextMaskModule} from 'angular2-text-mask';
import {ConfirmationService} from 'primeng/components/common/api';
import {SubscribeOrgConnectlyProComponent} from './connectly-pro/subscribed-org/subscribe-org-connectly-pro-component.component';
import {FreeGroupConnectlyProComponent} from './connectly-pro/free-group/free-group-connectly-pro-component.component';
import {MetricsDataComponent} from './metrics/components/metric-data/metric-data.component';
import {KeysPipe} from './ng-pipes/keyOfObject.pipe';
import {ViewSubscribeOrgConnectlyProComponent} from './connectly-pro/subscribed-org/view-subscribe-org/view-subscribe-org.component';
import {SubscribeOrgConnectlyProOverviewComponent} from './connectly-pro/subscribed-org/overview/overview.component';
import {OverviewViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/overview/overview.component';
import {MemberViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/member/member.component';
import {ConnectlyProService} from './connectly-pro/services/connectly-pro.service';
import {SubscribeOrgConnectlyProService} from './connectly-pro/services/subscribed-org.service';
import {AddSubscribeOrgComponent} from './connectly-pro/subscribed-org/add-subscribed-org/add-subscribed-org.component';
import {SubscribeOrgForm} from './connectly-pro/subscribed-org/subscribed-org-form/subscribed-org-form.component';
import {SubGroupViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/subGroup.component';
import {EditSubscribeOrgComponent} from './connectly-pro/subscribed-org/edit-subscribed-org/edit-subscribed-org.component';
import {ThemesConnectlyProComponent} from './connectly-pro/themes/themes-connectly-pro-component.component';
import {ThemesConnectlyProOverviewComponent} from './connectly-pro/themes/overview/overview.component';
import {AddThemeComponent} from './connectly-pro/themes/add-theme/add-theme.component';
import {EditThemeComponent} from './connectly-pro/themes/edit-theme/edit-theme.component';
import {ThemeForm} from './connectly-pro/themes/theme-form/theme-form.component';
import {AppsViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/apps/apps.component';
import {commonAppsForm} from './connectly-pro/subscribed-org/view-subscribe-org/apps/common/common-form.component';
import {OverviewAppsSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/apps/overview/overview.component';
import {AndroidAppsForm} from './connectly-pro/subscribed-org/view-subscribe-org/apps/android/android-form.component';
import {IOSAppsForm} from './connectly-pro/subscribed-org/view-subscribe-org/apps/ios/ios-form.component';
import {RolesViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/roles/roles.component';
import {overviewRoles} from './connectly-pro/subscribed-org/view-subscribe-org/roles/overview/overview.component';
import {AddRole} from './connectly-pro/subscribed-org/view-subscribe-org/roles/add-role/add-role.component';
import {EditRole} from './connectly-pro/subscribed-org/view-subscribe-org/roles/edit-role/edit-role.component';
import {RoleForm} from './connectly-pro/subscribed-org/view-subscribe-org/roles/role-form/role-form.component';
import {overviewMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/overview/overview.component';
import {
    JoiningFormViewSubscribeOrgComponent
} from './connectly-pro/subscribed-org/view-subscribe-org/joiningForm/joiningForm.component';
import {AddMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/add-member/add-member.component';
import {MemberForm} from './connectly-pro/subscribed-org/view-subscribe-org/member/member-form/member-form.component';
import {EditMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/edit-member/edit-member.component';
import {GroupMenuViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/group-menu/group-menu.component';
import {ModulesViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/modules/modules.component';
import {LicenseViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/license/license.component';
import {PremiumViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/premium/premium.component';
import {OverviewConnectlyProComponent} from './connectly-pro/overview/overview.component';
import {SubGroupForm} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/subGroup-form/subGroup-form.component';
import {overviewSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/overview/overview.component';
import {AddSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/add-subGroup/add-subGroup.component';
import {EditSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/edit-subGroup/edit-subGroup.component';
import {ViewSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/view-subGroup/view-subGroup.component';
import {ViewConnectorMetrics} from './connectors/components/view-connector/metric-connector/view-metrics/view-metrics.component';
import {EditMetrics} from './metrics/components/edit-metric/edit-metric.component';
import {EditBillingSKUForm} from './bizbrain-services/components/service-detail/billing-SKU/edit-billing-SKU/edit-billing-SKU.component';
import {AddBillingSKUForm} from './bizbrain-services/components/service-detail/billing-SKU/add-billing-SKU/add-billing-SKU.component';
import {BillingSKUForm} from './bizbrain-services/components/service-detail/billing-SKU/billing-SKU-form/billing-SKU-form.component';
import {AppBuildConnectlyProComponent} from './connectly-pro/app-build/app-build-connectly-pro-component.component';
import {AppBuildConnectlyProOverviewComponent} from './connectly-pro/app-build/overview/overview.component';
import {AppVersionPipe} from './ng-pipes/appVersion.pipe';
import {EventsOverviewComponent} from './events/components/overview/overview.component';
import {EventsComponent} from './events/components/events.component';
import {EventsService} from './events/services/events.service';
import {EventForm} from './events/components/event-form/event-form.component';
import {AddEvent} from './events/components/add-event/add-event.component';
import {EditEvent} from './events/components/edit-event/edit-event.component';
import {ViewEvent} from './events/components/view-events/view-events.component';
import {MomentModule} from 'angular2-moment';
import {TallyComponent} from './tally/tally.component'
import {OverviewTallyComponent} from './tally/overview/overview.component';
import {CustomersTallyComponent} from './tally/customers/customers.component';
import {TallyService} from './tally/services/tally.service';
import {ViewOrganizationDetailComponent} from './tally/OrganizationDetail/organizationDetail.component';
import {ShowAgentDetailComponent} from './tally/OrganizationDetail/agent-detail/agent-detail.component';
import {ThingsComponent} from './things/things.component';
import {OverviewThingsComponent} from './things/overview/overview.component';
import {ThingsService} from './things/services/things.service';
import {ProductsThingsComponent} from './things/products/products.component';
import {InventoryThingsComponent} from './things/inventory/inventory.component';


@NgModule({
    declarations: [
        AppComponent,
        FilterPipe,
        OnjectLengthFilter,
        KeysPipe,
        AppVersionPipe,
        HeaderComponent,
        OverlayMenuComponent,
        FixMenuComponent,
        BreadcrumbComponent,
        AppMenuComponent,
        DashboardComponent,
        OverviewComponent,
        LoginComponent,
        ViewMetrics,
        AddMetrics,
        EditMetrics,
        MetricsOverviewComponent,
        MetricsComponent,
        MetricsForm,
        LoaderComponent,
        AddOrganizationUserComponent,
        OrganizationProductComponent,
        OrganizationUsersComponent,
        OrganizationDetailComponent,
        OrganizationComponent,
        EditOrganizationComponent,
        AddOrganizationComponent,
        AddOrganizationProductComponent,
        OrganizationsOverviewComponent,
        EditBizBrainServiceComponent,
        AddBizBrainServiceComponent,
        BizBrainServiceDetailComponent,
        BizBrainServicesOverviewComponent,
        BizBrainServiceComponent,
        ConnectorsComponent,
        ConnectorOverviewComponent,
        AddConnectorComponent,
        EditConnectorComponent,
        ConnectorForm,
        ViewConnectorComponent,
        ConnectlyProComponent,
        FreeGroupConnectlyProComponent,
        SubscribeOrgConnectlyProComponent,
        AddComponent,
        EditComponent,
        ComponentForm,
        AddMetric,
        MetricForm,
        EditMetric,
        ViewConnectorMetrics,
        MetricsDataComponent,
        OverviewConnectlyProComponent,
        SubscribeOrgConnectlyProOverviewComponent,
        ViewSubscribeOrgConnectlyProComponent,
        OverviewViewSubscribeOrgComponent,
        MemberViewSubscribeOrgComponent,
        SubGroupViewSubscribeOrgComponent,
        AddSubscribeOrgComponent,
        SubscribeOrgForm,
        EditSubscribeOrgComponent,
        ThemesConnectlyProComponent,
        ThemesConnectlyProOverviewComponent,
        AddThemeComponent,
        EditThemeComponent,
        ThemeForm,
        AppsViewSubscribeOrgComponent,
        commonAppsForm,
        OverviewAppsSubscribeOrgComponent,
        AndroidAppsForm,
        IOSAppsForm,
        RolesViewSubscribeOrgComponent,
        overviewRoles,
        AddRole,
        EditRole,
        RoleForm,
        overviewMember,
        AddMember,
        EditMember,
        MemberForm,
        JoiningFormViewSubscribeOrgComponent,
        GroupMenuViewSubscribeOrgComponent,
        ModulesViewSubscribeOrgComponent,
        LicenseViewSubscribeOrgComponent,
        PremiumViewSubscribeOrgComponent,
        SubGroupForm,
        overviewSubGroup,
        AddSubGroup,
        EditSubGroup,
        ViewSubGroup,
        EditBillingSKUForm,
        AddBillingSKUForm,
        BillingSKUForm,
        AppBuildConnectlyProComponent,
        AppBuildConnectlyProOverviewComponent,
        EventsOverviewComponent,
        EventsComponent,
        EventForm,
        AddEvent,
        EditEvent,
        ViewEvent,
        TallyComponent,
        OverviewTallyComponent,
        CustomersTallyComponent,
        ViewOrganizationDetailComponent,
        ShowAgentDetailComponent,
        ThingsComponent,
        OverviewThingsComponent,
        ProductsThingsComponent,
        InventoryThingsComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot([]),
        PrimeNgComponentModules,
        TextMaskModule,
        AppRoutingModule,
        MomentModule
    ],
    providers: [
        Title,
        LoginService,
        AuthService,
        FileUploadService,
        MetricsService,
        OrganizationService,
        BizBrainServicesService,
        ConnectorsService,
        ConfirmationService,
        ConnectlyProService,
        SubscribeOrgConnectlyProService,
        EventsService,
        TallyService,
        ThingsService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        Environment.initialise(
            {

                /*dev*/
                /* loginUrl: "http://api.anant.io/superAdmin/v1/",
                 apiUrl: "http://api.anant.io/api/v1/",
                 connectlyApiUrl: "http://api.connectly.dev.bizbrain.in/v1/",*/

                /*live*/

                loginUrl: 'https://api.bizbrain.in/superAdmin/v1/',
                apiUrl: 'https://api.bizbrain.in/api/v1/',
                connectlyApiUrl: 'http://api.connectly.in/v1/',
                thingsApiUrl: 'https://things.iot.bizbrain.in/api/',

            });
    }
}
