import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './free-group-connectly-pro-component.component.html',
    styleUrls: ['./free-group-connectly-pro-component.component.scss'],
})
export class FreeGroupConnectlyProComponent implements AfterViewInit {


    constructor(private _titleService: Title) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Free Groups');

    }
}
