import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {BizBrainServicesService} from "../../../../services/bizbrain-service.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector:'billing-SKU-form',
    templateUrl:'./billing-SKU-form.component.html',
    styleUrls:['./billing-SKU-form.component.css']
})
export class BillingSKUForm{
    error:any;
    billingSKUForm:FormGroup;
    private _billingSKU:any;

    @Input()
    submitBtnLabel:string;

    @Output()
    formSubmitEvent:EventEmitter<any> = new EventEmitter<any>();



    getFormData(){
        let m_data = {
            skuLabel: this.billingSKUForm.value['label'],
            unit: this.billingSKUForm.value['unit']

        };
        return m_data;
    }

    get billingSKU(): any {
        return this._billingSKU;
    }

    @Input()
    set billingSKU(value: any) {
        console.log("component:",value);
        this._billingSKU = value;
        if(value)
            this.setValues();
    }

    constructor(private bizBrainServiceServices: BizBrainServicesService, private router: Router, private activeRoute: ActivatedRoute) {

    }

    ngOnInit(){

        this.billingSKUForm = new FormGroup({
            label: new FormControl("", Validators.required),
            unit: new FormControl("",Validators.required),
        });
    }


    resetForm(){
        this.billingSKUForm.reset();
    }

    setValues(){

        let value = {
            label:this._billingSKU.skuLabel,
            unit:this._billingSKU.unit
        };
        console.log("value",value);
        this.billingSKUForm.reset(value)
    }

    addNewBillingSKU(event){
        this.formSubmitEvent.emit(this.getFormData());
    }


}