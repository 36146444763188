import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {LoginService} from '../login/services/login.service';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';

@Component({
    selector: 'app-things-product',
    templateUrl: './things.component.html',
    styleUrls: ['./things.component.scss'],
})
export class ThingsComponent implements AfterViewInit {

    routes: Object[] = [{
        title: 'Things',
        route: '/',
        icon: 'things',
    }
    ];


    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == 'yes' ? true : false;

    constructor(private _titleService: Title, private loginService: LoginService,
                private route: ActivatedRoute,
                private router: Router) {
    }

    ngAfterViewInit(): void {

    }

    ngOnInit() {

    }
}
