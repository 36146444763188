import {Injectable} from "@angular/core";
import {Headers, Response, Http} from "@angular/http";
import {Environment} from "../../environment.model";
import {map} from "rxjs/operator/map";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";
import {Metric} from "../models/metrics.model";
import {Organization} from "../../organization/models/organization.model";

@Injectable()
export class MetricsService {

    headers: Headers;
    metricData: {};
    selectedMetric: Metric;
    organizations: Organization[];


    constructor(private http: Http, private router: Router) {

        this.metricData={};
        this.selectedMetric;
    }


    getHeaders(): Headers {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('token', localStorage.getItem('sessionId'));
        return this.headers;
    }

    getMetrics() {
        return this.http.get(Environment.apiUrl + 'metrics', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }
    getConnectorBySystemClass(systemClass) {
        return this.http.get(Environment.apiUrl + 'connectors/' +systemClass +'?field=class_namespace' ,{headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricsByCategory(data) {
        return this.http.post(Environment.apiUrl + 'metrics/byCategory', data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricsByCategoryCount(type) {
        return this.http.get(Environment.apiUrl + 'metrics/metricCountByType/' + type, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricsDataCount(data) {
        return this.http.post(Environment.apiUrl + 'metrics/retrieveMetricDataEntryCount', data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getMetricData(data) {
        return this.http.post(Environment.apiUrl + 'metrics/retrieveMetricDataEntry', data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewMetric(data) {
        return this.http.post(Environment.apiUrl + 'metrics', data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editNewMetric(data,id) {
        return this.http.put(Environment.apiUrl + 'metrics/'+id, data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteMetrics(data) {
        return this.http.delete(Environment.apiUrl + 'metrics/' + data, {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getmetricsById(data) {
        return this.http.get(Environment.apiUrl + 'metrics/' + data +'?field=metric_id', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getmetricsByKey(data) {
        return this.http.get(Environment.apiUrl + 'metrics/' + data +'?field=metric_key', {headers: this.getHeaders()})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    authorizedUserService(response) {
        if (response.flag == false) {
            if (response.code == 401) {
                localStorage.clear();
                //this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
