/**
 * Created by SonalPaghdal on 27/06/17.
 */
import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MetricsService} from "../../services/metrics.service";
import {Metric} from "../../models/metrics.model";
import {MetricsForm} from "../metric-form/metric-form.component";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {Organization} from "../../../organization/models/organization.model";
import {OverlayPanel} from "primeng/components/overlaypanel/overlaypanel";
import {Title} from "@angular/platform-browser";
import {OrganizationService} from "../../../organization/services/organization.service";
import {Connector} from "../../../connectors/models/connector.model";


declare var jQuery: any;

@Component({
    selector: 'metrics',
    templateUrl: './metric-data.component.html',
    styleUrls: ['./metric-data.component.css']
})
export class MetricsDataComponent {
    @Output() metricsAddedEvent: EventEmitter<Metric> = new EventEmitter<Metric>();
    @ViewChild('addMetrics') addMetrics: ElementRef;
    @ViewChild('metricsForm') metricsForm: MetricsForm;

    lastHourFlag: boolean = false;
    thisWeekFlag: boolean = false;
    lastWeekFlag: boolean = false;
    lastFifteenDaysFlag: boolean = false;
    lastMonthFlag: boolean = false;
    lastAllFlag: boolean = false;
    lastHundredFlag: boolean = false;
    lastFiftyFlag: boolean = false;
    lastTwentyFiveFlag: boolean = false;
    todayFlag: boolean = false;

    title: string;
    activeroute: string;
    activeTabIndex: number;
    activeTabData: string;
    data: Object = {name: ''};
    metricDataCounts: {};
    tags: {};
    metric: any;
    connector: any;
    metData: Array<any>[];
    selectedMetric: Metric;
    metricDataObject;
    metricsDataList: Array<any> = [];
    displayOrgnizationMetricDataPopup: boolean = false;
    organizations: Organization[] = [];
    org: Organization;
    timeLine: string;
    metricType: string;
    systemClass: string;

    constructor(private metricsService: MetricsService, private activeRoute: ActivatedRoute, private router: Router,
                private route: ActivatedRoute,
                private _titleService: Title, private orgServices: OrganizationService) {

    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page


        this._titleService.setTitle('Overview');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;

            })

    }

    resetView() {

    }

    getTimelineData(){
        this.lastHourFlag = false;
        this.thisWeekFlag = false;
        this.lastWeekFlag = false;
        this.lastFifteenDaysFlag = false;
        this.lastMonthFlag = false;

        let orgId: string;
        let metricKey: string;

        this.activeRoute.params.forEach(param => {orgId = param['id']});
        this.activeRoute.params.forEach(param => {metricKey = param['key']});

        if(this.activeroute.indexOf('/all') != -1){
            this.activeTabData='All';
            this.lastAllFlag = true;
            let m_data = {
                type: 'all',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }
        if (this.activeroute.indexOf('/lastHundred') != -1) {
            this.activeTabData = 'Last Hundred';
            this.lastHundredFlag= true;
            let m_data = {
                type: 'lastHundred',
                org_id: orgId,
                metric_key: metricKey
            }
            this.getMetricData(m_data);
        }
        if(this.activeroute.indexOf('/lastFifty') != -1){
            this.activeTabData='Last Fifty';
            this.lastFiftyFlag = true;
            let m_data = {
                type: 'lastFifty',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }
        if (this.activeroute.indexOf('/lastTwentyFive') != -1) {
            this.activeTabData = 'Last Twenty Five';
            this.lastTwentyFiveFlag = true;
            let m_data = {
                type: 'lastTwentyFive',
                org_id: orgId,
                metric_key: metricKey
            }
            this.getMetricData(m_data);
        }

        if(this.activeroute.indexOf('/today') != -1){
            this.activeTabData='Today';
            this.todayFlag = true;
            let m_data = {
                type: 'today',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }
        if (this.activeroute.indexOf('/lastHour') != -1) {
            this.activeTabData = 'Last Hour';
            this.lastHourFlag = true;
            let m_data = {
                type: 'lastHour',
                org_id: orgId,
                metric_key: metricKey
            }
            this.getMetricData(m_data);
        }

        if(this.activeroute.indexOf('/thisWeek') != -1){
            this.activeTabData='This Week';
            this.thisWeekFlag = true;
            let m_data = {
                type: 'thisWeek',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }

        if(this.activeroute.indexOf('/lastWeek') != -1){
            this.activeTabData='Last Week';
            this.lastWeekFlag = true;

            let m_data = {
                type: 'lastWeek',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }

        if(this.activeroute.indexOf('/lastFifteenDays') != -1){
            this.activeTabData='Last Fifteen Days';
            this.lastFifteenDaysFlag = true;
            let m_data = {
                type: 'lastFifteenDays',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }

        if(this.activeroute.indexOf('/lastMonth') != -1){
            this.activeTabData='This Month';
            this.lastMonthFlag = true;
            let m_data = {
                type: 'lastMonth',
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);
        }
    }

    ngOnInit() {


        let orgIds: string;
        let metricKey: string;
        let timeLine: string;
        this.activeRoute.params.forEach(param => {metricKey = param['key']});
        this.activeRoute.params.forEach(param => {this.metricType = param['type']});
        this.activeRoute.params.forEach(param => {orgIds = param['id']});
        this.activeRoute.params.forEach(param => {timeLine = param['timeline']});

        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('class')) {
                this.systemClass = param['class']
                this.getConnectorByClass();
            }
        });

        //console.log(orgIds, metricKey, timeLine);
        this.activeroute = this.router.url;
        this.getTimelineData();

        this.metricDataObject = {
            type: 0,
            org_id: orgIds,
            metric_key: metricKey
        }
        if (timeLine != undefined) {
            this.timeLine = timeLine;

            let m_data = {
                type: this.timeLine,
                org_id: this.metricDataObject.org_id,
                metric_key: this.metricDataObject.metric_key
            }
            //this.getMetricData(m_data);
        }
       // console.log("this.timeLine", this.timeLine);
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.organizations = [];
                        data.forEach(org => {
                            this.organizations.push(new Organization().getObject(org))
                        })
                        this.organizations.forEach(curr => {
                            if (curr.uniqueId == orgIds) {
                                this.org = curr;
                               // console.log(this.org);
                            }
                        })
                    }
                    else {

                    }
                },
                error => {
                    console.log(error)
                }
            );

        if (this.organizations) {

        }

        this.getMetricsDataCount(this.metricDataObject);
        this.getmetricsByKey(metricKey)
    }

    getConnectorByClass(){
        this.metricsService.getConnectorBySystemClass(this.systemClass)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connector = new Connector().getObject(response.data);
                        console.log("this.connector", this.connector);
                        console.log("this.connector.uniqueId", this.connector.uniqueId)

                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    getmetricsByKey(metricId: string): void {
        this.metricsService.getmetricsByKey(metricId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.metric = new Metric().getObject(data);
                        //console.log("this.metric.tags", data.tags)

                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            )
    }

    getMetricsDataCount(m_data) {
        this.metricsService.getMetricsDataCount(m_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metricDataCounts = response.data;
                        // console.log(this.metricDataCounts);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }


    getThishMetricData(data) {
        this.lastHourFlag = false;
        this.thisWeekFlag = false;
        this.lastWeekFlag = false;
        this.lastFifteenDaysFlag = false;
        this.lastMonthFlag = false;
        this.lastAllFlag = false;
        this.lastHundredFlag = false;
        this.lastFiftyFlag = false;
        this.lastTwentyFiveFlag = false;
        this.todayFlag = false;
        let orgId: string;
        let metricKey: string;
        this.activeRoute.params.forEach(param => {orgId = param['id']});
        this.activeRoute.params.forEach(param => {metricKey = param['key']});

        if (data == 'all') {
            this.lastAllFlag = true;
            this.activeTabData='All';
            this.openThishMetricData(data);

        }
        if (data == 'lastHundred') {
            this.lastHundredFlag = true;
            this.activeTabData='Last Hundred';
            this.openThishMetricData(data);

        }
        if (data == 'lastFifty') {
            this.lastFiftyFlag = true;
            this.activeTabData='Last Fifty';
            this.openThishMetricData(data);

        }
        if (data == 'lastTwentyFive') {
            this.lastTwentyFiveFlag = true;
            this.activeTabData='Last Twenty Five';
            this.openThishMetricData(data);

        }
        if (data == 'today') {
            this.todayFlag = true;
            this.activeTabData='Last Today';
            this.openThishMetricData(data);

        }
        if (data == 'lastHour') {
            this.lastHourFlag = true;
            this.activeTabData='Last Hour';
            this.openThishMetricData(data);

        }
        if (data == 'thisWeek') {
            this.thisWeekFlag = true;
            this.activeTabData='This Week';
            this.openThishMetricData(data);
        }
        if (data == 'lastWeek') {
            this.activeTabData='Last Week';
            this.lastWeekFlag = true;
            this.openThishMetricData(data);
        }
        if (data == 'lastFifteenDays') {
            this.activeTabData='Last Fifteen Days';
            this.lastFifteenDaysFlag = true;
            this.openThishMetricData(data);
        }
        if (data == 'lastMonth') {
            this.activeTabData='Last Month';
            this.lastMonthFlag = true;
            this.openThishMetricData(data);
        }

        if (orgId != '') {
            let m_data = {
                type: data,
                org_id: orgId,
                metric_key: metricKey
            }
             this.getMetricData(m_data);


        }


    }

    openThishMetricData(data){
        if(this.metricType != 'connectors'){
            this.router.navigate([AppRoutingConstants.metrics,this.metricType, this.metricDataObject.org_id, this.metricDataObject.metric_key, 'data', data]);
        }else{
            this.router.navigate([AppRoutingConstants.metrics,this.metricType,this.systemClass, this.metricDataObject.org_id, this.metricDataObject.metric_key, 'data', data]);
        }
    }

    getMetricData(m_data) {
        this.metricsService.getMetricData(m_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metricsDataList = response.data;
                        //this.displayOrgnizationMetricDataPopup=true;

                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            );
    }

    viewMetricData(metData) {
        this.metData = metData.data;
        this.displayOrgnizationMetricDataPopup = true;
        jQuery('#setFocus').focus();
    }

    displaySelectedTags(event, tags, overlaypanel: OverlayPanel) {
        this.tags = tags;
        console.log("tags", tags);
        overlaypanel.toggle(event);
    }

}