import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";

import {BasicFunctions} from "../../../functions";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";
import {EventsService} from "../../services/events.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class EventsOverviewComponent implements AfterViewInit {

    msgs: Message[] = [];
    events: any;

    constructor(private _titleService: Title, private router: Router, private eventsService: EventsService,
                private route: ActivatedRoute, private confirmationService: ConfirmationService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Overview');

    }

    ngOnInit() {
        this.getEvents();
    }

    getEvents() {
        this.eventsService.getEvents()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.events = data;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    onRowSelect(event) {
        this.viewEvent(event.data.unique_id);
    }

    addEvent() {
        this.router.navigate([AppRoutingConstants.events_add]);
    }

    editEvent(id) {
        this.router.navigate([AppRoutingConstants.events_edit_id, id]);
    }

    viewEvent(id) {
        this.router.navigate([AppRoutingConstants.events_view_id, id]);
    }

    deleteEvent(evnt) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this event?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.eventsService.deleteEvent(evnt.unique_id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.events = this.events.filter(val => val.unique_id !== evnt.unique_id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: evnt.name + ' Event Delete Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })


    }
}
