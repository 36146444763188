import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Message, MenuItem} from "primeng/components/common/api";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {SubscribeOrgConnectlyProService} from "../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ThemeForm} from "../theme-form/theme-form.component";

declare var jQuery: any;

@Component({
    selector: 'edit-theme',
    templateUrl: './edit-theme.component.html',
    styleUrls: ['./edit-theme.component.css']
})
export class EditThemeComponent {
    // @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('editTheme') editTheme: ElementRef;
    @ViewChild('themeForm') themeForm: ThemeForm;

    items: MenuItem[];

    msgs: Message[] = [];

    activeIndex: number = 0;
    themeId: string;
    theme: any;

    constructor(private _titleService: Title, private router: Router,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

    }

    ngOnInit() {

        this.activeRoute.params.forEach(param => {
            this.themeId = param['id']
        });
        this.getThemeById(this.themeId);
        console.log("this.themeId",this.themeId)
    }

    getThemeById(id){
        this.connectlyProService.getGroupTheme(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.theme = response.data;
                        this.themeForm.theme =this.theme;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    onFormSubmit(event: FormData) {
        // this.editNewTheme(event);
    }

    editNewTheme(formData) {
        this.connectlyProService.editNewTheme(formData, this.themeId)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.router.navigate([AppRoutingConstants.themes]);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.closeModal();
                }
            );
    }

    closeModal() {
        //jQuery(this.addConnector.nativeElement).modal('hide');
        if (this.themeForm) {
            this.themeForm.resetForm();
        }
    }

}