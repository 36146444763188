import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {AppRoutingConstants} from "../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './subscribe-org-connectly-pro-component.component.html',
    styleUrls: ['./subscribe-org-connectly-pro-component.component.scss'],
})
export class SubscribeOrgConnectlyProComponent implements AfterViewInit {


    constructor(private _titleService: Title, private router: Router, private activeRoute: ActivatedRoute) {

    }

    ngAfterViewInit(): void {

        this._titleService.setTitle('Subscribe Organization');

    }

}
