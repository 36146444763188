import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BillingSKUForm} from "../billing-SKU-form/billing-SKU-form.component";
import {BizBrainServicesService} from "../../../../services/bizbrain-service.service";
import {Router, ActivatedRoute} from "@angular/router";


declare var jQuery: any;

@Component({
    selector: 'add-billing-SKU',
    templateUrl: './add-billing-SKU.component.html',
    styleUrls: ['./add-billing-SKU.component.css']
})
export class AddBillingSKUForm {
    @Output() billingSKUAddedEvent: EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('addBillingSKU') addBillingSKU: ElementRef;
    @ViewChild('billingSKUForm') billingSKUForm: BillingSKUForm;

    productId: string;

    constructor(private bizBrainServiceServices: BizBrainServicesService, private router: Router, private activeRoute: ActivatedRoute) {

    }


    onFormSubmit(event: any) {
        event.product_id=this.productId;
        this.addNewBillingSKU(event);
    }

    addNewBillingSKU(data: any) {
        this.bizBrainServiceServices.addNewBillingSKU(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.billingSKUAddedEvent.emit(response.data);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.closeModal();
                }
            );
    }


    closeModal() {
        // jQuery(this.addComponent.nativeElement).modal('hide');
        if (this.billingSKUForm) {
            this.billingSKUForm.resetForm();
        }
    }

    resetView() {

    }

    ngOnInit() {

        this.activeRoute.params.forEach(param => {
            this.productId = param['id']
        });
        console.log("pro is", this.productId);
    }

}