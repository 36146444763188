/**
 * Created by hirenparekh on 10/11/16.
 */

import {Headers} from '@angular/http';

export const contentHeaders = new Headers();
contentHeaders.append('Accept', '*');
contentHeaders.append('Access-Control-Allow-Origin', '*');
contentHeaders.append('Content-Type', 'application/json');
