import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Connector} from "../../../../models/connector.model";
import {ConnectorsService} from "../../../../services/connectors.service";
import {ConnectorComponent} from "../../../../models/connectorComponent.model";

@Component({
    selector:'component-form',
    templateUrl:'./component-form.component.html',
    styleUrls:['./component-form.component.css']
})
export class ComponentForm{
    error:any;
    componentForm:FormGroup;
    private _connectorComponent:ConnectorComponent;

    @Input()
    submitBtnLabel:string;

    @Output()
    formSubmitEvent:EventEmitter<any> = new EventEmitter<any>();



    getFormData(){
        let m_data = {
            name: this.componentForm.value['name'],
            description: this.componentForm.value['description'],
            alias: this.componentForm.value['alias'],
            data: this.componentForm.value['data'],
            min_size: this.componentForm.value['min_size'],
            max_size: this.componentForm.value['max_size']
        };
        return m_data;
    }

    get connectorComponent(): ConnectorComponent {
        return this._connectorComponent;
    }

    @Input()
    set component(value: ConnectorComponent) {
        console.log("component:",value);
        this._connectorComponent = value;
        if(value)
            this.setValues();
    }

    constructor(private connectorService:ConnectorsService){

    }

    ngOnInit(){

        this.componentForm = new FormGroup({
            name: new FormControl("", Validators.required),
            description: new FormControl("",Validators.required),
            alias: new FormControl("",Validators.required),
            data: new FormControl("",Validators.required),
            min_size: new FormControl("",Validators.required),
            max_size: new FormControl("",Validators.required)
        });
    }


    resetForm(){
        this.componentForm.reset();
    }

    setValues(){

        let value = {
            name:this._connectorComponent.name,
            description:this._connectorComponent.description,
            alias:this._connectorComponent.alias,
            data:this._connectorComponent.data,
            min_size:this._connectorComponent.minSize,
            max_size:this._connectorComponent.maxSize,
        };
        console.log("value",value);
        this.componentForm.reset(value)
    }

    addNewComponent(event){
        this.formSubmitEvent.emit(this.getFormData());
    }


}