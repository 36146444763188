import {ISerializable} from "../../common/interface/serializable.interface";
import {MetricTag} from "./metrics-tags.model";
import {MetricCacheReport} from "./metrics-cache-report-model";

export class Metric implements ISerializable<Metric>{

    metricId:string;
    name: string;
    unitValue: string;
    metricKey: string;
    metricType:number;
    description: string;
    metricUnit:number;
    preferredvalue:number;
    tags:MetricTag[];
    dataType:number;
    data:{};
    snapshotdefs:  Array<any>;
    downsamplingdefs: Array<any>;
    cachedreports:MetricCacheReport[];
    updatedAt: string;
    createdAt: string;
    organizations:Array<any>[];

    getObject(data:any):Metric{

        let metric = new Metric();

        if(!data)
            return metric;

        metric.metricId = data['metric_id'];
        metric.name = data['name'];
        metric.description = data['description'];
        metric.metricKey = data['metric_key'];
        metric.metricUnit = data['unit'];
        metric.snapshotdefs = data['snapshotdefs'];
        metric.downsamplingdefs = data['downsamplingdefs'];
        metric.preferredvalue = data['preferred_value'];
        metric.unitValue = data['unit_value'];
        metric.updatedAt = data['updated_at'];
        metric.createdAt = data['created_at'];


        metric.metricId = data['metric_id'];
        metric.metricType = data['metric_type'];
        metric.dataType = data['data_type'];
        metric.metricUnit = data['unit'];
        metric.organizations = data['organizations'];
        metric.cachedreports = data['cached_reports'];
        metric.tags = data['tags'];

        return metric;
    }

}
