import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {commonConstants} from "../../../common/models/constants";
import {BasicFunctions} from "../../../functions";
import {ConfirmationService, Message} from "primeng/components/common/api";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class ThemesConnectlyProOverviewComponent implements AfterViewInit {
    allThemes:any;
    constants = commonConstants;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router,private confirmationService: ConfirmationService,
                private activeRoute: ActivatedRoute,private connectlyProService: ConnectlyProService
    ) {

    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Home Dashboard');

    }

    ngOnInit() {
        this.getThemes();
    }

    getThemes() {
        this.connectlyProService.getThemes()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.allThemes = response.data;
                        console.log("themes--", this.allThemes);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
    }
    onRowSelect(event) {

        //this.viewTheme(event.data.id);
    }

    viewTheme(id: string): void {
        this.router.navigate([AppRoutingConstants.themes_view_id, id]);
    }

    addTheme(){
        this.router.navigate([AppRoutingConstants.themes_add]);
    }

    editTheme(id){
        this.router.navigate([AppRoutingConstants.themes_edit_id,id]);
    }

    deleteTheme(theme){

        this.confirmationService.confirm({
            message: 'Do you want to delete this theme?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectlyProService.deleteTheme(theme.id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.allThemes = this.allThemes.filter(val => val.id !== theme.id);
                                this.msgs = [];
                                this.msgs.push({severity:'success', summary:'Success Message', detail: theme.name + ' Theme Delete Successfully'});
                            } else {
                                console.log(response.message);
                            }
                        },
                        error => {
                            console.log(error);
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

}
