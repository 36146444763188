import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 10/11/16.
 */

export class LastLogin implements ISerializable<LastLogin> {

  date: string;
  timezoneType: number;
  timezone: string;

  getObject(data: any): LastLogin {
    var lastLogin = new LastLogin();
    if (!data) {
      return lastLogin;
    }
    lastLogin.date = data['date'];
    lastLogin.timezoneType = data['timezone_type'];
    lastLogin.timezone = data['timezone'];

    return lastLogin;
  }

}
