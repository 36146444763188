/**
 * Created by hirenparekh on 12/11/16.
 */

import {Component, OnInit} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {User} from "../../../user/models/user.model";
import {ProductSubscription} from "../../models/product-subscription.model";
import {Organization} from "../../models/organization.model";
import {AppRoutingConstants} from "../../../app-routing.constants";

@Component({
  selector:'org-user-list',
  templateUrl:'./organization-users.component.html',
  styleUrls:['./organization-users.component.css','../organizations.component.scss']
})
export class OrganizationUsersComponent implements OnInit{

  organization: Organization;
  users: User[] = [];
  subscriptions: ProductSubscription[] = [];
  pageName:string;

  constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute,private router:Router) {

  }

  getOrganizationUsers(orgId){
    this.orgServices.getOrganizationById(orgId)
      .subscribe(
        response => {
          if (response.flag) {
            let data: any = response.data;
            this.organization = new Organization().getObject(data);
            data.users.forEach(user => {
              this.users.push(new User().getObject(user))
            });
            data.product_subscriptions.forEach(prodSub => {
              this.subscriptions.push(new ProductSubscription().getObject(prodSub))
            });
          } else {
            console.log(response.message);
          }
        },
        error => {
          console.log(error)
        }
      );
  }

  addOrganizationUser(orgId:string){
    this.router.navigate([AppRoutingConstants.organizations_view_id,orgId,'users','addUser']);
  }

  deleteOrganizationUser(id:string){

  }

  editOrganizationUser(id:string){

  }

  ngOnInit(){
    let orgId:string;
    this.activatedRoute.params.forEach((param:Params) => {
      orgId = param['id'];
    });
    this.getOrganizationUsers(orgId)
  }

}
