import {Component, Input} from "@angular/core";
import {MenuItem} from "primeng/components/common/api";
/**
 * Created by hirenparekh on 14/06/17.
 */


@Component({
  selector: 'app-fix-menu',
  templateUrl: './fix-menu.component.html',
  styleUrls: ['./fix-menu.component.css']
})
export class FixMenuComponent {
  menu="fixMenu";
  constructor() {

  }

  @Input() model ;
}
