import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MetricsService} from "../../services/metrics.service";
import {Metric} from "../../models/metrics.model";
import {MetricsForm} from "../metric-form/metric-form.component";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectorsService} from "../../../connectors/services/connectors.service";
import {Message} from "primeng/components/common/message";



declare var jQuery: any;

@Component({
    selector: 'metrics-edit',
    templateUrl: './edit-metric.component.html',
    styleUrls: ['./edit-metric.component.css']
})
export class EditMetrics {
    refreshPageVal: boolean = false;
    msgs: Message[] = [];
    metricType:string;
    metric:any;

    @Output() metricsAddedEvent:EventEmitter<Metric> = new EventEmitter<Metric>();
    @ViewChild('addMetrics') addMetrics: ElementRef;
    @ViewChild('metricsForm') metricsForm: MetricsForm;

    constructor(private metricsService: MetricsService, private activeRoute: ActivatedRoute,
                private connectorService: ConnectorsService,private router: Router) {

    }


    onFormSubmit(event: any) {
        this.editNewMetric(event);
    }

    onFormCancel(){
        if(this.metricType == 'overview'){
            this.router.navigate([AppRoutingConstants.metrics]);
        }else{
            this.router.navigate([AppRoutingConstants.metrics,this.metricType]);
        }

    }

    editNewMetric(data: any) {
        this.metricsService.editNewMetric(JSON.stringify(data),this.metric.metric_id)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.onFormCancel();
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                }
            );
    }


    resetView() {

    }

    ngOnInit() {

        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('type')) {
                this.metricType = param['type']
                 console.log("this.metricType",this.metricType);
            }
        });
        let key:string;
        this.activeRoute.params.forEach(param => {
            key = param['key']});
        this.getMetricsByKey(key);
    }

    getMetricsByKey(metric_key: string): void {
        let m_data = {
            "field": "metric_key"
        }
        this.connectorService.getMetricsByKey(metric_key)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;

                        this.metric = data;
                        console.log("this.connectorMetric", this.metric)
                        this.metricsForm.metric = this.metric;
                    } else {
                        console.log(response.message);
                        console.log("in service");
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

}