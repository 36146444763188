import {Component, Output, EventEmitter} from '@angular/core';
import {LoginService} from '../login/services/login.service';

/**
 * Created by hirenparekh on 13/06/17.
 */


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent {
    user: any;

    @Output() openOverlayMenuEvent: EventEmitter<boolean> = new EventEmitter<boolean>();

    constructor(private loginService: LoginService) {
        this.user = JSON.parse(localStorage.getItem('user'));
    }

    openOverlayMenu() {
        this.openOverlayMenuEvent.emit(true);
    }

    logout() {
        this.loginService.logOut();
    }
}
