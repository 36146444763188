import {Component, OnInit, ViewChild} from "@angular/core";
import {BizBrainServicesService} from "../../services/bizbrain-service.service";
import {BizBrainService} from "../../models/bizbrain-service.model";
import {Router, ActivatedRoute} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {AddBillingSKUForm} from "./billing-SKU/add-billing-SKU/add-billing-SKU.component";
import {EditBillingSKUForm} from "./billing-SKU/edit-billing-SKU/edit-billing-SKU.component";
import {BillingSKUForm} from "./billing-SKU/billing-SKU-form/billing-SKU-form.component";
import {ConfirmationService} from "primeng/components/common/confirmationservice";
/**
 * Created by hirenparekh on 15/11/16.
 */

@Component({
    selector: 'service-detail',
    templateUrl: './service-detail.component.html',
    styleUrls: ['./service-detail.component.css', '../bizbrain-service.component.scss']
})
export class BizBrainServiceDetailComponent implements OnInit {

    service: BizBrainService;
    serviceId: any='';
    displayEditBillingSKU: boolean = false;
    displayAddBillingSKU: boolean = false;
    product_skus:any;
    @ViewChild('addBillingSKUModal') addComponentModal: AddBillingSKUForm;
    @ViewChild('editBillingSKUModal') editBillingSKUModal: EditBillingSKUForm;
    @ViewChild('billingSKUForm') billingSKUForm: BillingSKUForm;
    constructor(private bizBrainServiceServices: BizBrainServicesService,
                private confirmationService: ConfirmationService,
                private router: Router, private activeRoute: ActivatedRoute) {

    }

    goToServices() {
        this.router.navigate([AppRoutingConstants.services]);
    }

    btnCancelClick() {
        this.goToServices();
    }

    getServiceById(serviceId: string): void {
        this.bizBrainServiceServices.getServicesById(serviceId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.service = new BizBrainService().getObject(data);
                        //this.service.product_skus=data.product_skus;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            )
    }

    ngOnInit() {
        let id: string;
        this.activeRoute.params.forEach(param => {
            this.serviceId = param['id']
        });
        this.getServiceById(this.serviceId);
        //this.getBillingSKUByProductId(id);
    }

    openAddBillingSkuPopup() {
        this.displayAddBillingSKU = true;
    }

    openEditBillingSkuPopup(billingSKU){
        this.displayEditBillingSKU = true;
        this.editBillingSKUModal.openModal(billingSKU);

        console.log(billingSKU);

    }
    resetBillingSKUForm(){
        this.displayAddBillingSKU = false;
    }

    onNewBillingSKUAdded(billingSKU){
        if (!this.service.product_skus) {
            this.service.product_skus = [];
        }
        this.getServiceById(this.serviceId);
        this.displayAddBillingSKU = false;
    }

    onNewBillingSKUEdited(billingSKU){
        if (!this.service.product_skus) {
            this.service.product_skus = [];
        }
        this.getServiceById(this.serviceId);
        /*let product_skus:Array<any> = this.service.product_skus;
        for (let i = 0;  i < product_skus.length; i++) {

            let currComponent = product_skus[i];
            console.log("currComponent",currComponent)
            if (currComponent.unique_id == billingSKU.unique_id) {
                product_skus.splice(i,1,billingSKU);
                break;
            }
        }*/

        this.displayEditBillingSKU=false;
    }

    deleteBillingSku(id: string) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this billing SKU?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.bizBrainServiceServices.deleteBillingSku(id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                let product_skus:Array<any> = this.service.product_skus;

                                product_skus = product_skus.filter(val => val.unique_id !== id);
                                /*for (let i = 0;  i < product_skus.length; i++) {

                                    let currComponent = product_skus[i];
                                    console.log("currComponent",currComponent)
                                    if (currComponent.unique_id == id) {
                                        currComponent.isActive=false;
                                        break;
                                    }
                                }*/

                            } else {
                                console.log(response.message);
                            }
                        },
                        error => {
                            console.log(error);
                        },
                        () => {

                        }
                    );
            },
            reject: () => {
            }
        });



    }

}