/**
 * Created by krishnaPipalia on 10/08/18.
 */

import {Injectable} from '@angular/core';
import {Http, Headers, Response} from '@angular/http';
import {Environment} from '../../environment.model';
import {Observable} from 'rxjs';
import {AppRoutingConstants} from '../../app-routing.constants';
import {Router} from '@angular/router';

@Injectable()
export class ThingsService {

    headers: Headers;

    constructor(private http: Http, private router: Router) {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        // this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('token', localStorage.getItem('sessionId'));
        console.log('localStorage.getItem(\'sessionId\')', localStorage.getItem('sessionId'))
    }


    getThingsProducts(): Observable<any> {
        return this.http.get(Environment.thingsApiUrl + 'product', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    getThingsInventory(): Observable<any> {
        return this.http.get(Environment.thingsApiUrl + 'inventory', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    deleteProduct(id: string): Observable<any> {
        return this.http.delete(Environment.thingsApiUrl + 'product/' + id, {headers: this.headers})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    deleteInventory(id: string): Observable<any> {
        return this.http.delete(Environment.thingsApiUrl + 'inventory/' + id, {headers: this.headers})
            .map(response => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err: Response) => {
                let details = err.json();
                this.authorizedUserService(details);
                return Observable.throw(details);
            });
    }

    authorizedUserService(response) {
        if (response.flag == false) {
            if (response.code == 401) {
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
