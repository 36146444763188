export class Environment {
    static loginUrl: string = '';
    static apiUrl: string = '';
    static connectlyApiUrl: string = '';
    static thingsApiUrl: string = '';

    static initialise(env: Object): void {
        this.loginUrl = env['loginUrl'];
        this.apiUrl = env['apiUrl'];
        this.thingsApiUrl = env['thingsApiUrl'];
        this.connectlyApiUrl = env['connectlyApiUrl'];
    }
}
