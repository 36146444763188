import {Component, Input, EventEmitter, Output, Inject, forwardRef} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {EventsService} from "../../services/events.service";



@Component({
    selector: 'event-form',
    templateUrl: './event-form.component.html',
    styleUrls: ['./event-form.component.css']
})
export class EventForm {
    error: any = [];
    eventForm: FormGroup;
    private _eventComponent: any;

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    formCancelEvent: EventEmitter<any> = new EventEmitter<any>();


    getFormData() {
        let m_data = {
            name: this.eventForm.value['name'],
            description: this.eventForm.value['description'],
            event_key: this.eventForm.value['event_key'],
        };
        return m_data;
    }

    get eventComponent() {
        return this._eventComponent;
    }

    @Input()
    set eventComponent(value) {
        console.log("component:", value);
        this._eventComponent = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private eventsService: EventsService) {
    }

    ngOnInit() {

        this.eventForm = new FormGroup({
            name: new FormControl("", Validators.required),
            description: new FormControl(""),
            event_key: new FormControl("", Validators.required),
        });
    }


    resetForm() {
        this.eventForm.reset();
        this.formCancelEvent.emit();
    }

    setValues() {

        let value = {
            name: this._eventComponent.name,
            description: this._eventComponent.description,
            event_key: this._eventComponent.event_key,
        };
        console.log("value", value);
        this.eventForm.reset(value)
    }

    addNewEvent(event) {
        this.error = [];

        if (this.eventForm.value['name'].length > 30) this.error.push("Maximum length of event name name is 30");

        if (this.error && this.error.length == 0) {
            this.formSubmitEvent.emit(this.getFormData());
        }
    }


}