import {Component, Input, EventEmitter, Output, NgModule} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
//import { TextMaskModule } from '../../../../../../../node_modules/angular2-text-mask/src/angular2TextMask';
import {Connector} from "../../../../models/connector.model";
import {ConnectorsService} from "../../../../services/connectors.service";
import {ConnectorMetric} from "../../../../models/connectorMetric.model";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {Router, ActivatedRoute} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Metric} from "../../../../../metrics/models/metrics.model";
import {MetricsService} from "../../../../../metrics/services/metrics.service";

declare var jQuery: any;

@Component({
    selector: 'metric-form',
    templateUrl: './metric-form.component.html',
    styleUrls: ['./metric-form.component.css']
})

export class MetricForm {

    error:any;
    metricForm: FormGroup;
    tagsForm: FormGroup;
    reportForm: FormGroup;
    filterForm: FormGroup;
    snapshotTimeForm: FormGroup;
    downsamplingTimeForm: FormGroup;

    connector: Connector;

    tags: Array<any> = [];
    tagsTable: Array<any> = [];
    filters: Array<any> = [];
    filtersTable: any;
    reports: Array<any> = [];

    snapshotdefs: Array<any>;
    snapshotdefsTable: any;
    time_string: any;
    aggregation: any;
    downsamplingdefs: Array<any>;
    downsamplingdefsTable: any;

    data_type: SelectItem[];
    preferred_value_boolean: SelectItem[];
    preferred_value_number: SelectItem[];
    tag_type: SelectItem[];
    filter_operator: SelectItem[];
    aggregations: SelectItem[];
    getUniqueId = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    };

    private _metric: Metric;

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    formResetEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private connectorService: ConnectorsService, private metricsService: MetricsService, private activeRoute: ActivatedRoute, private router: Router) {
        this.snapshotdefs = [];
        this.downsamplingdefs = [];
    }


    get metric(): Metric {
        return this._metric;
    }

    @Input()
    set metric(value: Metric) {
        console.log("metric:", value);
        this._metric = value;
        if (value) {
            this.setValues();
        }

    }

    ngOnInit() {

        let connectorId: string;
        let metrickey: string;
        this.activeRoute.params.forEach(param => {
            connectorId = param['id']
        });
        this.activeRoute.params.forEach(param => {
            metrickey = param['key']
        });
        this.getConnectorById(connectorId);

        this.metricForm = new FormGroup({
            name: new FormControl("", Validators.required),
            metric_key: new FormControl("", Validators.required),
            description: new FormControl(""),
            metric_data: new FormControl(""),
            data_type: new FormControl(null, Validators.required),
            metric_unit: new FormControl(""),
            unit_value: new FormControl(""),
            preferred_value: new FormControl(null, Validators.required),
        });
        this.tagsForm = new FormGroup({
            key: new FormControl("", Validators.required),
            type: new FormControl(null, Validators.required),
            label: new FormControl("", Validators.required),
        });

        this.reportForm = new FormGroup({
            report_key: new FormControl("", Validators.required),
            report_label: new FormControl("", Validators.required),
            report_start_time: new FormControl("", Validators.required),
            report_end_time: new FormControl("", Validators.required),
            cache_frequency: new FormControl("", Validators.required),
        });

        this.filterForm = new FormGroup({
            filter_key: new FormControl("", Validators.required),
            filter_label: new FormControl("", Validators.required),
            filter_operator: new FormControl(null, Validators.required),

        });

        this.snapshotTimeForm = new FormGroup({
            time_string: new FormControl("", Validators.required),
            aggregation: new FormControl(0, Validators.required),

        });
        this.downsamplingTimeForm = new FormGroup({
            time_string: new FormControl("", Validators.required),
            aggregation: new FormControl(0, Validators.required),
        });


        this.data_type = [];
        this.data_type.push({label: 'Select Data Type*', value: null});
        this.data_type.push({label: 'Boolean', value: 0});
        this.data_type.push({label: 'Number', value: 1});

        this.tag_type = [];
        this.tag_type.push({label: 'Select Tag Type*', value: null});
        this.tag_type.push({label: 'Text', value: 'Text'});
        this.tag_type.push({label: 'Number', value: 'Number'});
        this.tag_type.push({label: 'Boolean', value: 'Boolean'});
        this.tag_type.push({label: 'Date', value: 'Date'});
        this.tag_type.push({label: 'Time', value: 'Time'});
        this.tag_type.push({label: 'TimeStampSecs', value: 'TimeStampSecs'});
        this.tag_type.push({label: 'TimeStampUTC', value: 'TimeStampUTC'});

        this.preferred_value_number = [];
        this.preferred_value_number.push({label: 'Select Preferred Value', value: null});
        this.preferred_value_number.push({label: 'Higher', value: 0});
        this.preferred_value_number.push({label: 'Lower', value: 1});

        this.preferred_value_boolean = [];
        this.preferred_value_boolean.push({label: 'Select Preferred Value', value: null});
        this.preferred_value_boolean.push({label: 'True', value: 2});
        this.preferred_value_boolean.push({label: 'False', value: 3});

        this.filter_operator = [];
        this.filter_operator.push({label: 'Select Operator*', value: -1});
        this.filter_operator.push({label: 'Is', value: 0});
        this.filter_operator.push({label: 'In Between', value: 1});
        this.filter_operator.push({label: '<', value: 2});
        this.filter_operator.push({label: '>', value: 3});
        this.filter_operator.push({label: '=', value: 4});

        this.aggregations = [];
        this.aggregations.push({label: 'Select Aggregation', value: null});
        this.aggregations.push({label: 'Sum', value: 'sum'});
        this.aggregations.push({label: 'Average', value: 'average'});
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        console.log("data", data);

                        this.connector = new Connector().getObject(data);
                        // jQuery(this.viewConnector.nativeElement).modal('show');
                        // console.log("this.connector.uniqueId",this.connector.unique_id);

                    } else {
                        console.log(response.message);
                        console.log("in service");
                    }
                },
                error => {
                    console.log(error);
                }
            )
    }

    goToMetrics() {
        this.router.navigate([AppRoutingConstants.metrics]);
    }


    resetForm() {
        this.metricForm.reset();
        this.router.navigate([AppRoutingConstants.connectors_view_id, this.connector.uniqueId]);
    }

    setValues() {
        //console.log("in set value")
        let value = {
            name: this._metric.name,
            description: this._metric.description,
            metric_key: this._metric.metricKey,
        };
        //console.log("this._metric",this._metric)
        this.snapshotdefs = [];
        if (this._metric.snapshotdefs) {
            this.snapshotdefs = this._metric.snapshotdefs;
            this.snapshotdefsTable=this.snapshotdefs;
        }
        this.downsamplingdefs = [];
        if (this._metric.downsamplingdefs) {
            this.downsamplingdefs = this._metric.downsamplingdefs;
            this.downsamplingdefsTable=this.downsamplingdefs;
        }
        this.tags = [];
        if (this._metric.tags) {
            this.tags = this._metric.tags;
            this.tagsTable=this.tags;
        }
        this.filters = [];
        this.reports = [];
        if (this._metric.cachedreports) {
            this.reports = this._metric.cachedreports;
        }
        // console.log("this.reports",this.reports)
        this.metricForm.reset(value);

    }

    ngAfterViewInit() {

    }

    addNewMetric(event) {
        this.formSubmitEvent.emit(this.getFormData());
    }

    onChangeMetricUnit() {

    }

    addTags() {
        let m_data = {
            tag_id: this.getUniqueId(),
            key: this.tagsForm.value['key'],
            label: this.tagsForm.value['label'],
            dataType: this.tagsForm.value['type'],
        }
        console.log(m_data);

        this.tags.push(m_data);

        this.tagsForm = new FormGroup({
            key: new FormControl("", Validators.required),
            type: new FormControl(null, Validators.required),
            label: new FormControl("", Validators.required),
        });
        this.chnageTagsTable(this.tags)

    }

    chnageTagsTable(data) {
        this.tagsTable = [];
        this.tags=this.tagsTable;


        setTimeout(() => {
            this.tags=data;
            this.tagsTable= this.tags;
        },10)

    }
    addFilter() {
        let m_data = {
            filter_id: this.getUniqueId(),
            key: this.filterForm.value['filter_key'],
            label: this.filterForm.value['filter_label'],
            operator: this.filterForm.value['filter_operator'],
        }
        console.log(m_data);

        this.filters.push(m_data);

        this.filterForm = new FormGroup({
            filter_key: new FormControl("", Validators.required),
            filter_label: new FormControl("", Validators.required),
            filter_operator: new FormControl(-1, Validators.required)
        });
        this.chnageFiltersable(this.filters)
    }

    chnageFiltersable(data) {
        this.filtersTable = [];
        this.filters=this.filtersTable;


        setTimeout(() => {
            this.filters=data;
            this.filtersTable= this.filters;
        },10)

    }

    addReport() {
        let m_data = {
            report_id: this.getUniqueId(),
            key: this.reportForm.value['report_key'],
            label: this.reportForm.value['report_label'],
            start_time: this.reportForm.value['report_start_time'],
            end_time: this.reportForm.value['report_end_time'],
            cache_frequency: this.reportForm.value['cache_frequency'],
            filters: this.filters
        }
        this.reports.push(m_data);

        this.reportForm = new FormGroup({
            report_key: new FormControl("", Validators.required),
            report_label: new FormControl("", Validators.required),
            report_start_time: new FormControl("", Validators.required),
            report_end_time: new FormControl("", Validators.required),
            cache_frequency: new FormControl("", Validators.required),
        });
        this.filters = [];

    }

    deleteTag(tag) {
        for (let i = 0; this.tags, i < this.tags.length; i++) {
            let currTag = this.tags[i];
            if (currTag.tag_id == tag.tag_id) {
                this.tags.splice(i, 1);
                break;
            }
        }
    }

    deleteFilter(filter) {
        for (let i = 0; this.filters, i < this.filters.length; i++) {
            let currFilter = this.filters[i];
            if (currFilter.filter_id == filter.filter_id) {
                this.filters.splice(i, 1);
                break;
            }
        }
    }

    deleteReport(report) {
        for (let i = 0; this.reports, i < this.reports.length; i++) {
            let currReport = this.reports[i];
            if (currReport.report_id == report.report_id) {
                this.reports.splice(i, 1);
                break;
            }
        }
    }

    addNewMetrics() {
        this.formSubmitEvent.emit(this.getFormData());

    }

    goToConnectorView() {
        this.router.navigate([AppRoutingConstants.connectors_view_id, this.connector.uniqueId]);

    }

    getFormData() {

        for (let i = 0; this.tags, i < this.tags.length; i++) {
            let currTag = this.tags[i];
            delete currTag.tag_id;
        }
        for (let i = 0; this.filters, i < this.filters.length; i++) {
            let currFilter = this.filters[i];
            delete currFilter.filter_id;
        }
        for (let i = 0; this.reports, i < this.reports.length; i++) {
            let currReport = this.reports[i];
            delete currReport.report_id;

        }

        let m_data = {
            name: this.metricForm.value['name'],
            description: this.metricForm.value['description'],
            metric_key: this.metricForm.value['metric_key'],
            data_type: this.metricForm.value['data_type'],
            unit_value: this.metricForm.value['unit_value'],
            metric_type: 2,
            tags: this.tags,
            unit: this.metricForm.value['metric_unit'],
            preferred_value: this.metricForm.value['preferred_value'],
            snapshotdefs: this.snapshotdefs,
            downsamplingdefs: this.downsamplingdefs,
            cached_reports: this.reports
        };
        console.log("m_data", m_data);
        return m_data;
    }

    addSnapshotdefs() {
        var flag: boolean = false;
        if (this.snapshotTimeForm.value['time_string'] == "__:__:__" || this.snapshotTimeForm.value['time_string'] == "00:00:00") {
            this.snapshotTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });

        } else {
            if (this.snapshotdefs.length > 0) {

                for (let i = 0; i < this.snapshotdefs.length; i++) {
                    let time_string = this.snapshotdefs[i];
                    if (time_string.time_string == this.snapshotTimeForm.value['time_string']) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    this.snapshotdefs.push({
                        time_string: this.snapshotTimeForm.value['time_string'],
                        aggregation: this.snapshotTimeForm.value['aggregation'],
                    });
                }
            } else {
                this.snapshotdefs.push({
                    time_string: this.snapshotTimeForm.value['time_string'],
                    aggregation: this.snapshotTimeForm.value['aggregation'],
                });
            }


            this.snapshotTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });
        }
        this.chnageSnapshotTimeTable(this.snapshotdefs);


        console.log(this.snapshotdefs);
    }

    chnageSnapshotTimeTable(data) {
        this.snapshotdefsTable = [];
        this.snapshotdefs=this.snapshotdefsTable;


        setTimeout(() => {
            this.snapshotdefs=data;
            this.snapshotdefsTable= this.snapshotdefs;
        },10)

    }

    addDownsamplingdefs() {
        var flag1: boolean = false;
        if (this.downsamplingTimeForm.value['time_string'] == "__:__:__" || this.downsamplingTimeForm.value['time_string'] == "00:00:00") {
            this.downsamplingTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });

        } else {
            if (this.downsamplingdefs.length > 0) {

                for (let i = 0; i < this.downsamplingdefs.length; i++) {
                    let time_string = this.downsamplingdefs[i];
                    if (time_string.time_string == this.downsamplingTimeForm.value['time_string']) {
                        flag1 = true;
                        break;
                    }
                }
                if (!flag1) {
                    this.downsamplingdefs.push({
                        time_string: this.downsamplingTimeForm.value['time_string'],
                        aggregation: this.downsamplingTimeForm.value['aggregation']

                    });
                }
            } else {
                this.downsamplingdefs.push({
                    time_string: this.downsamplingTimeForm.value['time_string'],
                    aggregation: this.downsamplingTimeForm.value['aggregation']

                });
            }


            this.downsamplingTimeForm = new FormGroup({
                time_string: new FormControl("", Validators.required),
                aggregation: new FormControl(0, Validators.required),
            });
            this.chnageDownsamplingTimeTable(this.downsamplingdefs);
        }
    }

    chnageDownsamplingTimeTable(data) {
        this.downsamplingdefsTable = [];
        this.downsamplingdefs=this.downsamplingdefsTable;


        setTimeout(() => {
            this.downsamplingdefs=data;
            this.downsamplingdefsTable= this.downsamplingdefs;
        },10)

    }
    checkSnapshotTime() {

        let time1 = this.snapshotTimeForm.value['time_string'];
        let aggregation1 = this.snapshotTimeForm.value['aggregation'];
        if (time1 != null) {
            if (time1 == '00:00:00' || aggregation1 == 0) {
                return true;
            }
            return false;
        }

    }

    checkDownsamplingdefs() {
        let times = this.downsamplingTimeForm.value['time_string'];
        let aggregations = this.downsamplingTimeForm.value['aggregation'];
        if (times != null) {
            if (times == '00:00:00' || aggregations == 0) {
                return true;
            }
            return false;
        }
    }

    deleteSnapshotdefs(data) {

        let index = null;
        let flag = null;

        for (let i = 0; i < this.snapshotdefs.length; i++) {
            let snapshotdef = this.snapshotdefs[i];
            if (data == snapshotdef) {
                index = i;
                flag = true;
            }
        }

        if (flag) {
            this.snapshotdefs.splice(index, 1);
        }
    }

    deleteDownsamplingdefs(data) {

        let index = null;
        let flag = null;

        for (let i = 0; i < this.downsamplingdefs.length; i++) {
            let downsamplingdef = this.downsamplingdefs[i];
            if (data == downsamplingdef) {
                index = i;
                flag = true;
            }
        }

        if (flag) {
            this.downsamplingdefs.splice(index, 1);
        }
    }

    editSnapshotdefs(data) {
        this.snapshotTimeForm.reset(data);
        this.deleteSnapshotdefs(data);
    }

    editDownsamplingdefs(data) {
        this.downsamplingTimeForm.reset(data);
        this.deleteDownsamplingdefs(data);
    }

}