import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Title} from "@angular/platform-browser";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {commonConstants} from "../../../../../common/models/constants";
import {Message} from "primeng/components/common/message";
declare var jQuery: any;

@Component({
    selector: 'common-form',
    templateUrl: './common-form.component.html',
    styleUrls: ['./common-form.component.css']
})
export class commonAppsForm {
    error: any = [];
    constants = commonConstants;

    commonForm: FormGroup;
    scheduleOptForm: FormGroup;
    serviceProviders: any;
    is_active: boolean;
    selectedImageUrlFile: any;
    selectedImageUrlFileData: any;
    selectedOverviewImageUrlFile: any;
    selectedOverviewImageUrlFileData: any;
    selectedNotificationImageUrlFile: any;
    selectedNotificationImageUrlFileData: any;
    errorMessage: any;
    errorMessage2: any;
    errorMessage3: any;
    successMessage: any;
    successMessage2: any;
    successMessage3: any;
    image1Name: any;
    image2Name: any;
    image3Name: any;

    private _commonVal: any;
    buildTypeValue: string[] = [];

    uploadImageUrl: string = '';
    uploadImageUrl2: string = '';
    uploadImageUrl3: string = '';

    allThemes: any;
    all_Themes: SelectItem[];
    groupTypes: SelectItem[];
    organizations_all: SelectItem[];
    categoryTypes: SelectItem[];
    license: string;
    buildTypeValues: Array<any> = [];

    msgs: Message[] = [];

    @Input()
    submitBtnLabel: string;

    @Output()
    commonAddedEvent: EventEmitter<any> = new EventEmitter<any>();
    @Output()
    commonEditEvent: EventEmitter<any> = new EventEmitter<any>();

    get commonVal() {
        return this._commonVal;
    }

    @Input()
    set commonVal(value) {
        console.log("subscribeGroup:", value);

        this._commonVal = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router, private connectlyProService: ConnectlyProService,
                private activeRoute: ActivatedRoute,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

    }

    getFormData() {
        var licenseType: number;
        if (this.commonForm.value['licenseShared'] != '') {
            licenseType = 1;
        } else if (this.commonForm.value['licensePrivate'] != '') {
            licenseType = 2;
        } else {
            licenseType = 3;
        }
        if (this.commonForm.value['buildTypeBeta'].length != 0) {
            this.buildTypeValues.push(1);
        }
        if (this.commonForm.value['buildTypeProduction'].length != 0) {
            this.buildTypeValues.push(2);
        }

        var image1: any = {};
        var image2: any = {};
        var image3: any = {};
        if (this.uploadImageUrl != '') {
            image1 = {
                url: this.uploadImageUrl,
                height: 400,
                width: 400
            }
        } else {
            image1 = {}
        }
        if (this.uploadImageUrl2 != '') {
            image2 = {
                url: this.uploadImageUrl2,
                height: 96,
                width: 96
            }
        } else {
            image2 = {}
        }

        if (this.uploadImageUrl3 != '') {
            image3 = {
                url: this.uploadImageUrl3,
                height: 95,
                width: 95
            }
        } else {
            image3 = {}
        }

        let formData = {
            appName: this.commonForm.value['appName'],
            welcomeTitle: this.commonForm.value['welcomeTitle'],
            title1: this.commonForm.value['title1'],
            title2: this.commonForm.value['title2'],
            defaultProfileStatus: this.commonForm.value['defaultProfileStatus'],
            licenseType: licenseType,
            buildType: this.buildTypeValues,
            iconX400: image1,
            iconX400Name:this.image1Name,
            iconX96: image2,
            iconX96Name:this.image2Name,
            notificationIcon: image3,
            notificationIconName:this.image3Name
        }

        console.log("getter : ", formData);
        return formData;

    }

    ngOnInit() {

        // this.getThemes();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.selectedNotificationImageUrlFile = '';
        this.selectedNotificationImageUrlFileData = '';
        //this.license = '1';

        this.commonForm = new FormGroup({
            appName: new FormControl('', Validators.required),
            welcomeTitle: new FormControl('', Validators.required),
            title1: new FormControl('', Validators.required),
            title2: new FormControl("", Validators.required),
            defaultProfileStatus: new FormControl("", Validators.required),
            licenseShared: new FormControl(this.license, Validators.required),
            licensePrivate: new FormControl({value: this.license, disabled: true}),
            licenseCustom: new FormControl({value: this.license, disabled: true}),
            buildTypeBeta: new FormControl(this.buildTypeValue),
            buildTypeProduction: new FormControl(this.buildTypeValue),
        });


    }

    setValues() {

        this.license = '';
        if (this._commonVal.licenseType == 1) {
            this.license = '1';
        }


        let value = {
            appName: this._commonVal.appName,
            welcomeTitle: this._commonVal.welcomeTitle,
            title1: this._commonVal.title1,
            title2: this._commonVal.title2,
            defaultProfileStatus: this._commonVal.defaultProfileStatus,
            licenseShared: this.license,
            licensePrivate: this.license,
            licenseCustom: this.license,
            buildTypeBeta: [],
            buildTypeProduction: []
        };

        this.buildTypeValues = [];
        for (let i = 0; i < this._commonVal.buildType.length; i++) {
            let type = this._commonVal.buildType[i];
            if (type == 1) {
                value.buildTypeProduction.push('1')
            }
            if (type == 2) {
                value.buildTypeBeta.push('2')
            }
        }
        if(this._commonVal.iconX400) this.uploadImageUrl = this._commonVal.iconX400['url'];
        if(this._commonVal.iconX96) this.uploadImageUrl2 = this._commonVal.iconX96['url'];
        if(this._commonVal.notificationIcon) this.uploadImageUrl3 = this._commonVal.notificationIcon['url'];
        if(this._commonVal.iconX400Name) this.image1Name=this._commonVal.iconX400Name;
        if(this._commonVal.iconX96Name) this.image2Name=this._commonVal.iconX96Name;
        if(this._commonVal.notificationIconName) this.image3Name=this._commonVal.notificationIconName;
        this.commonForm.reset(value);
        console.log("value", value);
        console.log(" this.commonForm-", this.commonForm)

    }

    getCategoryId(e) {
        let id = e.value;
        //this.getThemesByCategory(id)
    }


    resetForm() {
        if(this.commonForm){
            this.commonForm.reset();
        }

        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.uploadImageUrl = '';
        this.uploadImageUrl2 = '';
        this.uploadImageUrl3 = '';
        this.successMessage = '';
        this.errorMessage = '';
        this.errorMessage2 = '';
        this.errorMessage3 = '';
        this.successMessage2 = '';
        this.successMessage3 = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.selectedNotificationImageUrlFile = '';
        this.selectedNotificationImageUrlFileData = '';

        this.commonForm = new FormGroup({
            appName: new FormControl('', Validators.required),
            welcomeTitle: new FormControl('', Validators.required),
            title1: new FormControl('', Validators.required),
            title2: new FormControl("", Validators.required),
            defaultProfileStatus: new FormControl("", Validators.required),
            licenseShared: new FormControl(this.license, Validators.required),
            licensePrivate: new FormControl({value: this.license, disabled: true}),
            licenseCustom: new FormControl({value: this.license, disabled: true}),
            buildTypeBeta: new FormControl(''),
            buildTypeProduction: new FormControl(''),
        });
        //this.router.navigate([AppRoutingConstants.subscribeOrganization]);


    }

    readURL400(event) {
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';
        this.uploadImageUrl = '';

        let fileInput = event.target;
        let file: any = fileInput.files[0];
        if (file) {
            var reader = new FileReader();
            this.selectedImageUrlFile = file;
            this.image1Name=this.selectedImageUrlFile.name;
            this.selectedImageUrlFileData = reader.result;
            reader.onload = function (e) {
                jQuery('#myImage400').attr('src', reader.result);
            };
            reader.readAsDataURL(file);
        }else{
            this.selectedImageUrlFile = '';
            this.image1Name='';
        }

        //this.upload400();
    }

    upload400() {
        var img = document.getElementById('myImage400');
        var width = img.clientWidth;
        var height = img.clientHeight;

        if (width == 400 && height == 400) {
            let formData = new FormData();
            formData.append('file', this.selectedImageUrlFile);

            this.connectlyProService.uploadAppIcons(formData)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.errorMessage = '';
                            this.successMessage = '';
                            this.successMessage = ' Launcher Icon 1 400 X 400 uploaded successfully';
                            this.uploadImageUrl = response.data;
                            //this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        } else {
            this.errorMessage = '';
            this.successMessage = '';
            this.errorMessage = "Dimensions of selected file should be 400 x 400 pixels."
        }

    }

    readURL96(event) {
        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';
        this.uploadImageUrl2 = '';

        let fileInput = event.target;
        let file: any = fileInput.files[0];
        if (file) {
            var reader = new FileReader();
            this.selectedOverviewImageUrlFile = file;
            this.image2Name=this.selectedOverviewImageUrlFile.name;
            this.selectedOverviewImageUrlFileData = reader.result;
            reader.onload = function (e) {
                jQuery('#myImage96').attr('src', reader.result);
            };
            reader.readAsDataURL(file);
        }else{
            this.selectedOverviewImageUrlFile = '';
            this.image2Name='';
        }
    }

    upload96() {
        var img = document.getElementById('myImage96');
        var width = img.clientWidth;
        var height = img.clientHeight;

        if (width == 96 && height == 96) {
            let formData = new FormData();
            formData.append('file', this.selectedOverviewImageUrlFile);

            this.connectlyProService.uploadAppIcons(formData)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.errorMessage2 = '';
                            this.successMessage2 = '';
                            this.successMessage2 = 'Launcher Icon 2  96 X 96 uploaded successfully';
                            this.uploadImageUrl2 = response.data;
                            //this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        } else {
            this.errorMessage2 = '';
            this.successMessage2 = '';
            this.errorMessage2 = "Dimensions of selected file should be 96 x 96 pixels."
        }

    }

    readURL95(event) {
        this.selectedNotificationImageUrlFile = '';
        this.selectedNotificationImageUrlFileData = '';
        this.uploadImageUrl3 = '';

        let fileInput = event.target;
        let file: any = fileInput.files[0];
        if (file) {
            var reader = new FileReader();
            this.selectedNotificationImageUrlFile = file;
            this.image3Name=this.selectedNotificationImageUrlFile.name;
            this.selectedNotificationImageUrlFileData = reader.result;
            reader.onload = function (e) {
                jQuery('#myImage95').attr('src', reader.result);
            };
            reader.readAsDataURL(file);
        }else{
            this.selectedNotificationImageUrlFile = '';
            this.image3Name='';
        }
    }

    upload95() {
        var img = document.getElementById('myImage95');
        var width = img.clientWidth;
        var height = img.clientHeight;

        if (width == 96 && height == 96) {
            let formData = new FormData();
            formData.append('file', this.selectedNotificationImageUrlFile);

            this.connectlyProService.uploadBWIcons(formData)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.errorMessage3 = '';
                            this.successMessage3 = '';
                            this.successMessage3 = 'Notification Icon 96 X 96 uploaded successfully';
                            this.uploadImageUrl3 = response.data;
                            //this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        } else {
            this.errorMessage3 = '';
            this.successMessage3 = '';
            this.errorMessage3 = "Dimensions of selected file should be 96 x 96 pixels."
        }

    }


    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w200';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    addNewCommonForm(event) {
        this.error = [];

        if (this.commonForm.value['appName'].length > 30) this.error.push("Maximum length of app name is 30");
        if (this.commonForm.value['welcomeTitle'].length > 50) this.error.push("Maximum length of welcome title is 50");
        if (this.commonForm.value['title1'].length > 25) this.error.push("Maximum length of title1 is 25");
        if (this.commonForm.value['title2'].length > 40) this.error.push("Maximum length of title2 is 40");


        if (this.error && this.error.length == 0) {
            if (this.submitBtnLabel == 'Add') this.commonAddedEvent.emit(this.getFormData());
            if (this.submitBtnLabel == 'Update') this.commonEditEvent.emit(this.getFormData());

        }


    }

}