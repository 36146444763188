import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TallyService} from '../services/tally.service';
import {commonConstants} from '../../common/models/constants';
import {ConfirmationService, Message} from 'primeng/components/common/api';
import {AppRoutingConstants} from '../../app-routing.constants';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ShowAgentDetailComponent} from '../OrganizationDetail/agent-detail/agent-detail.component'


@Component({
    selector: 'app-organization-view',
    templateUrl: './organizationDetail.component.html',
    styleUrls: ['./organizationDetail.component.scss'],
})
export class ViewOrganizationDetailComponent implements AfterViewInit {
    constants = commonConstants;
    ShowAgentDetailModel = false;
    organizationDetail: any;
    userDevices: any;
    activeTabIndex: number;
    msgs: Message[] = [];
    selectedOrganizationId: string;
    @ViewChild('showAgentDetail') showAgentDetail: ShowAgentDetailComponent;

    constructor(private _titleService: Title, private router: Router, private activatedRoute: ActivatedRoute,
                private confirmationService: ConfirmationService, private tallyService: TallyService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Tally');
    }

    openAgentDetailPopup(agentDetail) {
        this.ShowAgentDetailModel = true;
        console.log(agentDetail);

    }

    getOrganizationById(orgId: string) {
        this.organizationDetail = null;
        this.tallyService.getTallyOrganizationById(orgId)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.organizationDetail = response.data;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    getUserDevices(orgId: string) {
        this.userDevices = null;
        this.tallyService.getOrganizationUserDevices(orgId)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.userDevices = response.data;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    handleChange(e) {
        let index = e.index;
        switch (index) {
            case 0:
                this.getOrganizationById(this.selectedOrganizationId);
                break;

            case 1:
                this.getUserDevices(this.selectedOrganizationId);
                break;

        }

    }

    resentAgentKey(agent) {

        this.confirmationService.confirm({
            message: 'Do you want to resent Key?',
            header: 'Resend Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.tallyService.resentKey(agent.organizationId, agent.clientId)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: ' Key sent Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

    ngOnInit() {
        let orgId: string;
        this.activeTabIndex = 0;
        this.activatedRoute.params.forEach((params: Params) => {
            orgId = params['id'];
            this.selectedOrganizationId = params['id'];
        });
        this.getOrganizationById(orgId);
    }
}
