import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {NgModel} from "@angular/forms";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {OrganizationService} from "../../services/organization.service";
import {Organization} from "../../models/organization.model";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";


@Component({
    selector: 'connector-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OrganizationsOverviewComponent implements AfterViewInit {
    organizations: Organization[];
    msgs: Message[] = [];
    constructor(private _titleService: Title,
                private route: ActivatedRoute,
                private orgServices: OrganizationService,private router:Router,private confirmationService:ConfirmationService) {
        // Chart Single

        // Chart Multi
    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Overview');

    }

    onRowSelect(event) {
        //alert(event.data.productId);
        this.viewOrganization(event.data.uniqueId);
    }

    getOrganizations() {
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data:Array<any> = response.data;
                        this.organizations = [];
                        data.forEach(org => {this.organizations.push(new Organization().getObject(org))})
                    }
                    else {

                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    viewOrganization(id:string) {
        this.router.navigate([AppRoutingConstants.organizations_view_id,id]);
    }
    addOrganization(){
        this.router.navigate([AppRoutingConstants.organizations_add]);
    }

    editOrganization(orgId:string){
        this.router.navigate([AppRoutingConstants.organizations_edit_id,orgId])
    }

    deleteOrganization(org):void{
        this.confirmationService.confirm({
            message: 'Do you want to delete this organization?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.orgServices.deleteOrganization(org.uniqueId)
                    .subscribe(
                        response => {

                            if(response.flag)
                            {
                                this.organizations = this.organizations.filter(val => val.uniqueId !== org.uniqueId);
                               /* for(let i = 0; i < this.organizations.length ; i++)
                                {
                                    if(this.organizations[i].uniqueId === id)
                                    {
                                        this.organizations.splice(i,1);
                                        break;
                                    }
                                }*/
                                this.msgs = [];
                                this.msgs.push({severity:'success', summary:'Success Message', detail:org.name +' Organization Delete Successfully'});
                            }else {
                                console.log(response.message);
                            }
                        },
                        error => {
                            console.log(error);
                        }
                    )
            },
            reject: () => {

            }
        });


    }

    ngOnInit() {
        this.getOrganizations();
    }


}
