/**
 * Created by hirenparekh on 12/11/16.
 */

import {Component} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {User} from "../../../user/models/user.model";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {BizBrainServicesService} from "../../../bizbrain-services/services/bizbrain-service.service";
import {ProductSubscription} from "../../models/product-subscription.model";

import {BizBrainService} from "../../../bizbrain-services/models/bizbrain-service.model";
import {Organization} from "../../models/organization.model";
import {SelectItem} from "primeng/components/common/api";

@Component({
    selector: 'add-org-service',
    templateUrl: './add-organization-product.component.html',
    styleUrls: ['./add-organization-product.component.css', '../organizations.component.scss']
})
export class AddOrganizationProductComponent {
    formval: boolean = false;
    services: ProductSubscription[] = [];
    error: string;
    organization: Organization;
    users: User[] = [];
    service: BizBrainService[];
    serviceId: any;

    allServices: SelectItem[];
    constructor(private orgServices: OrganizationService, private serServices: BizBrainServicesService, private activatedRoute: ActivatedRoute, private router: Router) {

    }

    getServices() {
        this.serServices.getServices()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.service = [];
                        data.forEach(service => this.service.push(new BizBrainService().getObject(service)))
                       // console.log("all-", this.service);
                        this.allServices = [];
                        this.allServices.push({label:'Select Service', value:null});
                        this.service.forEach(curr => {
                            this.allServices.push({label:curr.name, value:curr.productId});
                        })
                    }
                },
                error => {
                    console.log(error)
                });
    }

    checkVal(e) {
        let val: string = e.value;

        if (this.services.length != 0) {

            for (let i = 0; this.services, i < this.services.length; i++) {
                let ser = this.services[i];
                console.log(ser.service);
                if (ser.service.productId == val) {
                    alert("Already added");
                    this.formval = true;
                    break;
                } else {
                    this.formval = false;
                }
            }
        } else {
            this.formval = true;
        }
    }

    addNewOrganizationService(valu: any) {
        var flag1: boolean = false;



            var orgId: string;
            this.activatedRoute.params.subscribe((params: Params) => {
                orgId = params['id']
            });

            console.log("orgId",orgId);


            let body = {
                "org_id": orgId,
                "service_id": this.serviceId

            };
            this.orgServices.addNewOrganizationService(JSON.stringify(body))
                .subscribe(
                    response => {
                        if (response.json().flag) {
                            this.viewOrganizationService();
                        } else {
                            this.error = response.json().message;
                        }
                    },
                    error => {
                        console.log(error);
                    }
                );


    }

    ngOnInit() {
        var orgId: string;

        this.activatedRoute.params.forEach((params: Params) => {
            orgId = params['id']
        });

        console.log("orgId",orgId);

        /*        this.serServices.getServices()
         .subscribe(
         response => {
         if (response.flag) {
         this.services = response.data.map(val => new BizBrainService().getObject(val));
         console.log("org-",this.services );
         } else {
         this.error = response.json().message;
         }
         },
         error => {
         console.log(error);
         }
         );*/

        this.orgServices.getOrganizationById(orgId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.organization = new Organization().getObject(data);

                        data.product_subscriptions.forEach(prodSub => {
                            this.services.push(new ProductSubscription().getObject(prodSub))
                        });
                        console.log("org-", this.services);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
        this.getServices();
    }

    getOrganizationService(orgId) {

    }

    viewOrganizationService() {
        let orgId: string;
        this.activatedRoute.params.forEach((params: Params) => {
            orgId = params['id']
        });
        this.router.navigate([AppRoutingConstants.organizations_view_id, orgId,'subscriptions']);
    }

    btnCancelClicked() {
        this.viewOrganizationService();
    }

}
