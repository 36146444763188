import {NgModule} from '@angular/core';
import {RouterModule, Router, Route} from '@angular/router';
//import {DashboardComponent} from "./dashboard/components/dashboard.component";
import {AuthService} from './auth.service';
import {LoginComponent} from './login/components/login.component';
import {AppRoutingConstants} from './app-routing.constants';
import {DashboardComponent} from './dashboard/dashboard.component';
import {OverviewComponent} from './dashboard/overview/overview.component';
import {ViewMetrics} from './metrics/components/view-metrics/view-metrics.component';
import {AddMetrics} from './metrics/components/add-metric/add-metric.component';
import {MetricsOverviewComponent} from './metrics/components/overview/overview.component';
import {MetricsComponent} from './metrics/components/metrics.component';
import {OrganizationComponent} from './organization/components/organizations.component';
import {OrganizationDetailComponent} from './organization/components/organization-detail/organization-detail.component';
import {OrganizationUsersComponent} from './organization/components/organization-users/organization-users.component';
import {OrganizationProductComponent} from './organization/components/organization-product/organization-product.component';
import {AddOrganizationUserComponent} from './organization/components/add-organization-user/add-organization-user.component';
import {AddOrganizationProductComponent} from './organization/components/add-organization-service/add-organization-product.component';
import {AddOrganizationComponent} from './organization/components/add-organization/add-organization.component';
import {EditOrganizationComponent} from './organization/components/edit-organization/edit-organization.component';
import {OrganizationsOverviewComponent} from './organization/components/overview/overview.component';
import {BizBrainServiceComponent} from './bizbrain-services/components/bizbrain-service.component';
import {BizBrainServicesOverviewComponent} from './bizbrain-services/components/overview/overview.component';
import {BizBrainServiceDetailComponent} from './bizbrain-services/components/service-detail/service-detail.component';
import {AddBizBrainServiceComponent} from './bizbrain-services/components/add-service/add-service.component';
import {EditBizBrainServiceComponent} from './bizbrain-services/components/edit-service/edit-service.component';
import {ConnectorsComponent} from './connectors/components/connectors.component';
import {ConnectorOverviewComponent} from './connectors/components/overview/overview.component';
import {ConnectlyProComponent} from './connectly-pro/connectly-pro.component';
import {AddConnectorComponent} from './connectors/components/add-connector/add-connector.component';
import {EditConnectorComponent} from './connectors/components/edit-connector/edit-connector.component';
import {ViewConnectorComponent} from './connectors/components/view-connector/view-connector.component';
import {AddComponent} from './connectors/components/view-connector/component-connector/add-component/add-component.component';
import {EditComponent} from './connectors/components/view-connector/component-connector/edit-component/edit-component.component';
import {AddMetric} from './connectors/components/view-connector/metric-connector/add-metric/add-metric.component';
import {EditMetric} from './connectors/components/view-connector/metric-connector/edit-metric/edit-metric.component';
import {SubscribeOrgConnectlyProComponent} from './connectly-pro/subscribed-org/subscribe-org-connectly-pro-component.component';
import {FreeGroupConnectlyProComponent} from './connectly-pro/free-group/free-group-connectly-pro-component.component';
import {MetricsDataComponent} from './metrics/components/metric-data/metric-data.component';
import {ViewSubscribeOrgConnectlyProComponent} from './connectly-pro/subscribed-org/view-subscribe-org/view-subscribe-org.component';
import {SubscribeOrgConnectlyProOverviewComponent} from './connectly-pro/subscribed-org/overview/overview.component';
import {OverviewViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/overview/overview.component';
import {MemberViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/member/member.component';
import {AddSubscribeOrgComponent} from './connectly-pro/subscribed-org/add-subscribed-org/add-subscribed-org.component';
import {EditSubscribeOrgComponent} from './connectly-pro/subscribed-org/edit-subscribed-org/edit-subscribed-org.component';
import {SubGroupViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/subGroup.component';
import {ThemesConnectlyProComponent} from './connectly-pro/themes/themes-connectly-pro-component.component';
import {ThemesConnectlyProOverviewComponent} from './connectly-pro/themes/overview/overview.component';
import {AddThemeComponent} from './connectly-pro/themes/add-theme/add-theme.component';
import {AppsViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/apps/apps.component';
import {commonAppsForm} from './connectly-pro/subscribed-org/view-subscribe-org/apps/common/common-form.component';
import {OverviewAppsSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/apps/overview/overview.component';
import {EditThemeComponent} from './connectly-pro/themes/edit-theme/edit-theme.component';
import {overviewRoles} from './connectly-pro/subscribed-org/view-subscribe-org/roles/overview/overview.component';
import {RolesViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/roles/roles.component';
import {AddRole} from './connectly-pro/subscribed-org/view-subscribe-org/roles/add-role/add-role.component';
import {EditRole} from './connectly-pro/subscribed-org/view-subscribe-org/roles/edit-role/edit-role.component';
import {overviewMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/overview/overview.component';
import {JoiningFormViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/joiningForm/joiningForm.component';
import {ModulesViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/modules/modules.component';
import {GroupMenuViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/group-menu/group-menu.component';
import {LicenseViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/license/license.component';
import {PremiumViewSubscribeOrgComponent} from './connectly-pro/subscribed-org/view-subscribe-org/premium/premium.component';
import {OverviewConnectlyProComponent} from './connectly-pro/overview/overview.component';
import {AddMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/add-member/add-member.component';
import {EditMember} from './connectly-pro/subscribed-org/view-subscribe-org/member/edit-member/edit-member.component';
import {overviewSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/overview/overview.component';
import {AddSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/add-subGroup/add-subGroup.component';
import {EditSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/edit-subGroup/edit-subGroup.component';
import {ViewSubGroup} from './connectly-pro/subscribed-org/view-subscribe-org/subGroup/view-subGroup/view-subGroup.component';
import {ViewConnectorMetrics} from './connectors/components/view-connector/metric-connector/view-metrics/view-metrics.component';
import {EditMetrics} from './metrics/components/edit-metric/edit-metric.component';
import {AppBuildConnectlyProComponent} from './connectly-pro/app-build/app-build-connectly-pro-component.component';
import {AppBuildConnectlyProOverviewComponent} from './connectly-pro/app-build/overview/overview.component';
import {EventsComponent} from './events/components/events.component';
import {EventsOverviewComponent} from './events/components/overview/overview.component';
import {AddEvent} from './events/components/add-event/add-event.component';
import {EditEvent} from './events/components/edit-event/edit-event.component';
import {ViewEvent} from './events/components/view-events/view-events.component';
import {TallyComponent} from './tally/tally.component';
import {OverviewTallyComponent} from './tally/overview/overview.component';
import {CustomersTallyComponent} from './tally/customers/customers.component';
import {ViewOrganizationDetailComponent} from './tally/OrganizationDetail/organizationDetail.component';
import {ThingsComponent} from './things/things.component';
import {OverviewThingsComponent} from './things/overview/overview.component';
import {ProductsThingsComponent} from './things/products/products.component';
import {InventoryThingsComponent} from './things/inventory/inventory.component';


@NgModule({
    imports: [
        RouterModule.forRoot([

            {path: AppRoutingConstants.login, data: {breadcrumb: 'Login'}, component: LoginComponent},
            {
                path: '',
                canActivate: [AuthService],
                component: DashboardComponent,
                data: {breadcrumb: 'Home'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewComponent
                    }
                ]
            },
            {
                path: AppRoutingConstants.metrics, canActivate: [AuthService],
                data: {breadcrumb: 'Metrics'},
                component: MetricsComponent, children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'all',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'All'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'system',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Systems'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'connectors',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Connectors'},
                        component: MetricsOverviewComponent,
                    }, {
                        path: 'organizations',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Organizations'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'groups',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Groups'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'users',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Users'},
                        component: MetricsOverviewComponent
                    }, {
                        path: ':type' + '/add',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Metrics'},
                        component: AddMetrics
                    }, {
                        path: ':type' + '/edit' + '/:key',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit Metrics'},
                        component: EditMetrics
                    }, {
                        path: ':type' + '/view' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics'},
                        component: ViewMetrics
                    }, {
                        path: ':type' + '/:id' + '/:key' + '/data',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics Data'},
                        component: MetricsDataComponent
                    }, {
                        path: ':type' + '/:class' + '/:id' + '/:key' + '/data',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics Data'},
                        component: MetricsDataComponent
                    }, {
                        path: ':type' + '/:id' + '/:key' + '/data' + '/:timeline',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics Data'},
                        component: MetricsDataComponent
                    }, {
                        path: ':type' + '/:class' + '/:id' + '/:key' + '/data' + '/:timeline',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics Data'},
                        component: MetricsDataComponent
                    }, {
                        path: 'connectors' + '/:class',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Connectors'},
                        component: MetricsOverviewComponent
                    }, {
                        path: 'organizations' + '/:orgId',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Organizations'},
                        component: MetricsOverviewComponent
                    }, {
                        path: ':type' + '/:id' + '/:key' + '/data' + '/:timeline' + '/:startTime' + '/:endTime',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metrics Data'},
                        component: MetricsDataComponent
                    }
                ]
            },
            {
                path: AppRoutingConstants.organizations,
                canActivate: [AuthService],
                data: {breadcrumb: 'Organizations'},
                component: OrganizationComponent,
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OrganizationsOverviewComponent
                    },
                    {
                        path: 'view' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Organization'},
                        component: OrganizationDetailComponent
                    },
                    {
                        path: 'view' + '/:id' + '/users',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Users'},
                        component: OrganizationUsersComponent
                    },
                    {
                        path: 'view' + '/:id' + '/subscriptions',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Subscriptions'},
                        component: OrganizationProductComponent
                    },
                    {
                        path: 'view' + '/:id' + '/users' + '/addUser',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add User'},
                        component: AddOrganizationUserComponent
                    },
                    {
                        path: 'view' + '/:id' + '/subscriptions' + '/addSubscription',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Subscriptions'},
                        component: AddOrganizationProductComponent
                    },
                    {
                        path: 'add',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Organization'},
                        component: AddOrganizationComponent
                    },
                    {
                        path: 'edit' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit Organization'},
                        component: EditOrganizationComponent
                    }
                ]
            },
            {
                path: AppRoutingConstants.services, canActivate: [AuthService], data: {breadcrumb: 'Products'},
                component: BizBrainServiceComponent,
                children: [
                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: BizBrainServicesOverviewComponent
                    },
                    {
                        path: 'view' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Products'},
                        component: BizBrainServiceDetailComponent
                    },
                    {
                        path: 'add',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Products'},
                        component: AddBizBrainServiceComponent
                    },
                    {
                        path: 'edit' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit Products'},
                        component: EditBizBrainServiceComponent
                    }
                ]
            },
            {
                path: AppRoutingConstants.connectors,
                canActivate: [AuthService],
                data: {breadcrumb: 'Connectors'},
                component: ConnectorsComponent, children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: ConnectorOverviewComponent
                    },
                    {
                        path: 'add',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Connector'},
                        component: AddConnectorComponent
                    },
                    {
                        path: 'edit' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit Connector'},
                        component: EditConnectorComponent
                    },
                    {
                        path: 'view' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Connector'},
                        component: ViewConnectorComponent
                    }, {
                        path: 'view' + '/:id' + '/addmetric',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add Metric'},
                        component: AddMetric
                    }, {
                        path: 'view' + '/:id' + '/editmetric' + '/:key',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit Metric'},
                        component: EditMetric
                    }, {
                        path: 'view' + '/:connectorid' + '/viewmetric' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View Metric'},
                        component: ViewConnectorMetrics
                    }

                ]
            },
            {
                path: 'connectly', canActivate: [AuthService], data: {breadcrumb: 'Connectly'},
                component: ConnectlyProComponent,
                children: [
                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewConnectlyProComponent
                    },
                    {
                        path: 'proApps' + '/:block',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Pro Apps'},
                        component: SubscribeOrgConnectlyProComponent,
                        children: [
                            {
                                path: '',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Overview'},
                                component: SubscribeOrgConnectlyProOverviewComponent
                            }, {
                                path: 'add',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Add'},
                                component: AddSubscribeOrgComponent
                            }, {
                                path: 'edit' + '/:id',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Edit'},
                                component: EditSubscribeOrgComponent
                            }, {
                                path: 'view' + '/:id',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'View'},
                                component: ViewSubscribeOrgConnectlyProComponent,
                                children: [
                                    {
                                        path: 'overview',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Overview'},
                                        component: OverviewViewSubscribeOrgComponent
                                    },
                                    {
                                        path: 'members',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Member'},
                                        component: MemberViewSubscribeOrgComponent,
                                        children: [
                                            {
                                                path: '',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Overview'},
                                                component: overviewMember
                                            }, {
                                                path: 'add',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Add'},
                                                component: AddMember
                                            }, {
                                                path: 'edit' + '/:memberId',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Edit'},
                                                component: EditMember
                                            }
                                        ]
                                    }, {
                                        path: 'subGroups',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'SubGroup'},
                                        component: SubGroupViewSubscribeOrgComponent,
                                        children: [
                                            {
                                                path: '',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Overview'},
                                                component: overviewSubGroup
                                            }, {
                                                path: 'add',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Add'},
                                                component: AddSubGroup
                                            }, {
                                                path: 'edit' + '/:subGroupId',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Edit'},
                                                component: EditSubGroup
                                            }, {
                                                path: 'view' + '/:subGroupId',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'View'},
                                                component: ViewSubGroup
                                            }
                                        ]
                                    }, {
                                        path: 'modules',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Modules'},
                                        component: ModulesViewSubscribeOrgComponent
                                    }, {
                                        path: 'roles',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Roles'},
                                        component: RolesViewSubscribeOrgComponent,
                                        children: [
                                            {
                                                path: '',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Overview'},
                                                component: overviewRoles
                                            }, {
                                                path: 'add',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Add'},
                                                component: AddRole
                                            }, {
                                                path: 'edit' + '/:roleId',
                                                canActivate: [AuthService],
                                                data: {breadcrumb: 'Edit'},
                                                component: EditRole
                                            }
                                        ]
                                    }, {
                                        path: 'joiningform',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Joining Form'},
                                        component: JoiningFormViewSubscribeOrgComponent
                                    }, {
                                        path: 'menu',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Menu'},
                                        component: GroupMenuViewSubscribeOrgComponent
                                    }, {
                                        path: 'premium',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Premium'},
                                        component: PremiumViewSubscribeOrgComponent
                                    }, {
                                        path: 'license',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'License'},
                                        component: LicenseViewSubscribeOrgComponent
                                    }, {
                                        path: 'apps',
                                        canActivate: [AuthService],
                                        data: {breadcrumb: 'Apps'},
                                        component: AppsViewSubscribeOrgComponent,
                                        /*children: [
                                         {
                                         path: '',
                                         canActivate: [AuthService],
                                         data: {breadcrumb: 'Overview'},
                                         component: OverviewAppsSubscribeOrgComponent
                                         },{
                                         path: 'common',
                                         canActivate: [AuthService],
                                         data: {breadcrumb: 'Common'},
                                         component: commonAppsForm
                                         }
                                         ]*/
                                    }, /*{
                                     path: 'apps'+'/common',
                                     canActivate: [AuthService],
                                     data: {breadcrumb: 'Common'},
                                     component: commonAppsForm
                                     }*/
                                ]
                            }
                        ]
                    },
                    {
                        path: 'buildManagement',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'App Build'},
                        component: AppBuildConnectlyProComponent,
                        children: [
                            {
                                path: '',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Overview'},
                                component: AppBuildConnectlyProOverviewComponent
                            }
                        ]
                    },
                    {
                        path: 'freeGroup',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Free Group'},
                        component: FreeGroupConnectlyProComponent
                    },
                    {
                        path: 'themes',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Themes'},
                        component: ThemesConnectlyProComponent,
                        children: [
                            {
                                path: '',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Overview'},
                                component: ThemesConnectlyProOverviewComponent
                            }, {
                                path: 'add',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Add'},
                                component: AddThemeComponent
                            }, {
                                path: 'edit' + '/:id',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'Edit'},
                                component: EditThemeComponent
                            }, {
                                path: 'view' + '/:id',
                                canActivate: [AuthService],
                                data: {breadcrumb: 'View'},
                                component: ViewSubscribeOrgConnectlyProComponent,
                            }
                        ]
                    }
                ]
            },
            {
                path: 'pipes',
                canActivate: [AuthService],
                component: DashboardComponent,
                data: {breadcrumb: 'Pipes'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewComponent
                    }
                ]
            },
            {
                path: 'events',
                canActivate: [AuthService],
                component: EventsComponent,
                data: {breadcrumb: 'Events'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: EventsOverviewComponent

                    }, {
                        path: 'add',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Add'},
                        component: AddEvent

                    }, {
                        path: 'edit' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Edit'},
                        component: EditEvent

                    }, {
                        path: 'view' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View'},
                        component: ViewEvent

                    }
                ]
            },
            {
                path: 'meraCrm',
                canActivate: [AuthService],
                component: DashboardComponent,
                data: {breadcrumb: 'Mera CRM'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewComponent
                    }
                ]
            },
            {
                path: 'tally',
                canActivate: [AuthService],
                component: TallyComponent,
                data: {breadcrumb: 'Tally App'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewTallyComponent
                    },
                    {
                        path: 'organization' + '/:id',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'View'},
                        component: ViewOrganizationDetailComponent
                    },
                    {
                        path: 'customers',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Customers'},
                        component: CustomersTallyComponent,
                    }
                ]
            },
            {
                path: 'things',
                canActivate: [AuthService],
                component: ThingsComponent,
                data: {breadcrumb: 'Things App'},
                children: [

                    {
                        path: '',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Overview'},
                        component: OverviewThingsComponent
                    },
                    {
                        path: 'products',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Products'},
                        component: ProductsThingsComponent,
                    },
                    {
                        path: 'inventory',
                        canActivate: [AuthService],
                        data: {breadcrumb: 'Inventory'},
                        component: InventoryThingsComponent,
                    }
                ]
            },
        ])
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthService
    ]
})
export class AppRoutingModule {

}
