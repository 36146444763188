import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 16/11/16.
 */

export class ConnectorComponent implements ISerializable<ConnectorComponent>{

  connectorId: string;
  name: string;
  description: string;
  alias: string;
  data: string[];
  minSize: boolean;
  maxSize: string;
  uniqueId: string;
  updatedAt: string;
  createdAt: string;


  getObject(data:any):ConnectorComponent{

    let connectorComponent = new ConnectorComponent();

    if(!data)
        return connectorComponent;

    connectorComponent.connectorId = data['connector_id'];
    connectorComponent.name = data['name'];
    connectorComponent.description = data['description'];
    connectorComponent.alias = data['alias'];
    connectorComponent.data = data['data'];
    connectorComponent.minSize = data['min_size'];
    connectorComponent.maxSize = data['max_size'];
    connectorComponent.uniqueId = data['unique_id'];
    connectorComponent.updatedAt = data['updated_at'];
    connectorComponent.createdAt = data['created_at'];

    return connectorComponent;
  }

}
