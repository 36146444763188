import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Title} from "@angular/platform-browser";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {commonConstants} from "../../../../../common/models/constants";
import {Message} from "primeng/components/common/message";

@Component({
    selector: 'android-form',
    templateUrl: './android-form.component.html',
    styleUrls: ['./android-form.component.css']
})
export class AndroidAppsForm {
    error:any = [];
    constants = commonConstants;

    androidForm:FormGroup;
    scheduleOptForm:FormGroup;
    serviceProviders:any;
    is_active:boolean;
    selectedImageUrlFile:any;
    selectedImageUrlFileData:any;

    selectedImageUrlFile1:any;
    selectedImageUrlFileData1:any;


    private _androidVal:any;
    valueSchedule:string[] = [];
    uploadImageUrl:string;
    uploadImageUrl1:string;
    groupServiceFileName:string;
    playStoreFileName:string;

    allThemes:any;
    all_Themes:SelectItem[];
    groupTypes:SelectItem[];
    organizations_all:SelectItem[];
    categoryTypes:SelectItem[];

    msgs:Message[] = [];

    @Input()
    submitBtnLabel:string;

    @Output()
    androidAddedEvent:EventEmitter<any> = new EventEmitter<any>();

    @Output()
    androidFormCancelEvent:EventEmitter<any> = new EventEmitter<any>();

    getFormData() {

        if (this.androidForm.value['themeId'] == null) {
            this.androidForm.value['themeId'] = '';
        }
        if (this.androidForm.value['categoryId'] == null) {
            this.androidForm.value['categoryId'] = '';
        }
        let signingKey
        if (this._androidVal == null) {
            signingKey = '';
        }else {
            signingKey = this._androidVal.signingKey;
        }



        let formData = {
            appId: this.androidForm.value['appId'],
            appNamespace: this.androidForm.value['appNamespace'],
            keyPassword: 'Avinashi',
            storePassword: 'Avinashi',
            messagingKey: this.androidForm.value['messagingKey'],
            googleServiceFile: this.uploadImageUrl,
            googleServiceFileName: this.groupServiceFileName,
            playStoreFile: this.uploadImageUrl1,
            playStoreFileName: this.playStoreFileName,
            signingKey: signingKey
        }

        console.log("getter : ", formData);
        return formData;

    }

    get androidVal() {
        return this._androidVal;
    }

    @Input()
    set androidVal(value) {
        console.log("subscribeGroup:", value);

        this._androidVal = value;
        console.log("this._androidVal", this._androidVal)
        if (value)
            this.setValues();
    }

    constructor(private _titleService:Title, private router:Router, private connectlyProService:ConnectlyProService,
                private activeRoute:ActivatedRoute,
                private subscribeOrgConnectlyProService:SubscribeOrgConnectlyProService) {

        // this.getThemes();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedImageUrlFile1 = '';
        this.selectedImageUrlFileData1 = '';


        this.androidForm = new FormGroup({
            appId: new FormControl("", Validators.required),
            appNamespace: new FormControl("", Validators.required),
            // keyPassword: new FormControl('', Validators.required),
            //storePassword: new FormControl('', Validators.required),
            messagingKey: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {


    }

    setValues() {

        let value = {
            appId: this._androidVal.appId,
            appNamespace: this._androidVal.appNamespace,
            // keyPassword: this._androidVal.keyPassword,
            //storePassword: this._androidVal.storePassword,
            messagingKey: this._androidVal.messagingKey,
        };
        this.uploadImageUrl = this._androidVal.googleServiceFile;
        this.groupServiceFileName = this._androidVal.googleServiceFileName;
        this.uploadImageUrl1 = this._androidVal.uploadImageUrl1;
        this.playStoreFileName = this._androidVal.playStoreFileName;
        this.androidForm.reset(value);

        console.log(" this.androidForm-", this.androidForm)

    }


    getCategoryId(e) {
        let id = e.value;
        //this.getThemesByCategory(id)
    }


    resetForm() {
        this.androidForm.reset();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.androidForm = new FormGroup({
            appId: new FormControl("", Validators.required),
            appNamespace: new FormControl("", Validators.required),
            //keyPassword: new FormControl('', Validators.required),
            //storePassword: new FormControl('', Validators.required),
            messagingKey: new FormControl('', Validators.required),
        });

        this.androidFormCancelEvent.emit();

    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file:any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile = file;
            this.groupServiceFileName = this.selectedImageUrlFile.name;

            console.log("this.selectedImageUrlFile", this.selectedImageUrlFile.name);
            this.selectedImageUrlFileData = reader.result;
            this.uploadImage(this.selectedImageUrlFile);
        };
        reader.readAsDataURL(file);
    }

    uploadImage(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl = response.data;
                        console.log("uploadImageUrl",this.uploadImageUrl)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    onFileSelected1(event) {
        this.showMyImage1(event);
    }

    showMyImage1(fileEvent) {

        let fileInput = fileEvent.target;
        let file:any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile1 = file;
            this.playStoreFileName = this.selectedImageUrlFile1.name;

            console.log("this.selectedImageUrlFile", this.selectedImageUrlFile1.name);
            this.selectedImageUrlFileData1 = reader.result;
            this.uploadImage1(this.selectedImageUrlFile1);
        };
        reader.readAsDataURL(file);
    }

    uploadImage1(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl1 = response.data;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }


    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w200';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    addNewAndroidForm(event) {
        this.error = [];
        if (!this.constants.appIdPattern.test(this.androidForm.value['appId'])) {
            this.error.push("App ID must be valid");
        }

        if (!this.constants.appNamespacePattern.test(this.androidForm.value['appNamespace'])) {
            this.error.push("AppNamespace must be valid");
        }

        if (this.androidForm.value['appId'].length > 255) this.error.push("Maximum length of app Id is 255");


        if (this.error && this.error.length == 0) {
            this.androidAddedEvent.emit(this.getFormData());

        }

    }

}