import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Message} from "primeng/components/common/api";
import {ActivatedRoute, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {ConnectlyProService} from "../../services/connectly-pro.service";
import {SubscribeOrgConnectlyProService} from "../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ThemeForm} from "../theme-form/theme-form.component";

declare var jQuery: any;

@Component({
    selector: 'add-theme',
    templateUrl: './add-theme.component.html',
    styleUrls: ['./add-theme.component.css']
})
export class AddThemeComponent {
    // @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('addTheme') addTheme: ElementRef;
    @ViewChild('themeForm') themeForm: ThemeForm;

    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

    }

    ngOnInit() {
    }

    onFormSubmit(event: FormData) {
        this.addNewTheme(event);
    }

    addNewTheme(formData) {
        this.connectlyProService.addNewTheme(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.router.navigate([AppRoutingConstants.themes]);
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                },
                () => {
                    this.closeModal();
                }
            );
    }

    closeModal() {
        //jQuery(this.addConnector.nativeElement).modal('hide');
        if (this.themeForm) {
            this.themeForm.resetForm();
        }
    }

}