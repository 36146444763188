import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {SubscribeOrgConnectlyProService} from "../../../services/subscribed-org.service";
import {ConnectlyProService} from "../../../services/connectly-pro.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'subGroup-view-subscribe-org',
    templateUrl: './subGroup.component.html',
    styleUrls: ['./subGroup.component.scss'],
})
export class SubGroupViewSubscribeOrgComponent implements AfterViewInit {

    groupId: string;
    group: any;
    theme: any;

    constants = commonConstants;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
        this.subscribeOrgConnectlyProService.groupId = this.groupId;
        this.connectlyProService.groupId = this.groupId;
    }

    ngAfterViewInit(): void {
        // this._titleService.setTitle('Home Dashboard');
        this.getGroupById(this.groupId);
    }

    getGroupById(groupId: string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.group = response.data;
                        this.subscribeOrgConnectlyProService.groupOrgId = this.group.org_id;
                        this.connectlyProService.groupOrgId = this.group.org_id;
                        console.log("this.connectlyProService.groupOrgId ",this.connectlyProService.groupOrgId )
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

}
