import {LastLogin} from "../../login/models/last-login.model";
import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 10/11/16.
 */

export class User implements ISerializable<User> {
  fname: string;
  lname: string;
  mname: string;
  email: string;
  mobile: string;
  userName: string;
  password:string;
  userType: number;
  alias: string;
  gender: number;
  birthDate: string;
  orgId: string;
  updatedAt: string;
  createdAt: string;
  lastLogin: LastLogin;
  rememberToken: string;
  uniqueId: string;
  //defaultOrg: Organization;

  getObject(data:any): User {

    let user = new User();
    if(!data)
    {
      return user;
    }
    user.fname = data['fname'];
    user.mname = data['mname'];
    user.lname = data['lname'];
    user.email = data['email'];
    user.mobile = data['mobile'];
    user.userName = data['username'];
    user.password = data['password'];
    user.userType = data['user_type'];
    user.alias = data['alias'];
    user.gender = data['gender'];
    user.birthDate = data['birth_date'];
    user.orgId = data['org_id'];
    user.updatedAt = data['updated_at'];
    user.createdAt = data['created_at'];
    user.uniqueId = data['unique_id'];
    //user.defaultOrg = new Organization().getObject(data['defaultorganization']);
    user.lastLogin = new LastLogin().getObject(data['last_login']);
    user.rememberToken = data['remember_token'];

    return user;
  }

}

