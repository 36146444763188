/**
 * Created by hirenparekh on 10/11/16.
 */

import {Injectable, Output, EventEmitter} from "@angular/core";
import {Headers, Http} from "@angular/http";
import {Environment} from "../../environment.model";
import {Router} from "@angular/router";
import {contentHeaders} from "../../common/models/headers";
import {AppRoutingConstants} from "../../app-routing.constants";
import {User} from "../../user/models/user.model";
import {Message} from "primeng/components/common/api";

@Injectable()
export class LoginService {
  constructor(private http:Http,private router:Router){

  }

  @Output()
  loginEvent:EventEmitter<any> = new EventEmitter<any>();

  login(userName: string, password: string) {

    let body = JSON.stringify({username: userName, password: password});

      return this.http.post(Environment.loginUrl + 'login', body, {headers:contentHeaders})
        .map(response => response.json());

  }

  logOut()
  {
    this.http.get(Environment.apiUrl + 'authentication/logout/'+localStorage.getItem('sessionId'), {headers:contentHeaders})
        .subscribe(
            response => {
              if (response.json().flag) {
                localStorage.removeItem('sessionId');
                localStorage.removeItem('user');
                this.loginEvent.emit(false);
                //window.location.href='';
                this.router.navigate([AppRoutingConstants.login]);
              }
            },
            error => {
              console.log(error);
            });



  }

  getNewSession()
  {
    this.http.get(Environment.apiUrl + 'authentication/sessionAlive/'+localStorage.getItem('sessionId'), {headers:contentHeaders})
      .subscribe(
        response => {
          if (!response.json().flag) {
            localStorage.removeItem('sessionId');
            localStorage.removeItem('user');
            this.loginEvent.emit(false);
            window.location.href='';
            this.router.navigate([AppRoutingConstants.login]);
          }
        },
        error => {
          console.log(error);
        });



  }

}
