import {BizBrainServicesService} from "../../services/bizbrain-service.service";
import {Router, ActivatedRoute} from "@angular/router";
import {Component, OnInit} from "@angular/core";
import {BizBrainService} from "../../models/bizbrain-service.model";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConfirmationService, Message} from "primeng/components/common/api";
/**
 * Created by hirenparekh on 15/11/16.
 */


@Component({
    selector: 'edit-service',
    templateUrl: './edit-service.component.html',
    styleUrls: ['./edit-service.component.css', '../bizbrain-service.component.scss']
})
export class EditBizBrainServiceComponent implements OnInit {

    service:BizBrainService;
    msgs: Message[] = [];

    constructor(private bizBrainServiceServices: BizBrainServicesService, private router: Router, private activeRoute: ActivatedRoute) {


    }

    getServiceById(serviceId: string): void {
        this.bizBrainServiceServices.getServicesById(serviceId)
            .subscribe(
                response => {
                    if(response.flag)
                    {
                        let data = response.data;
                        this.service = new BizBrainService().getObject(data);
                    }else{
                        console.log(response.message);
                        console.log("in service");
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    btnCancelClicked() {

        this.goToBizBrainService();
    }

    editServices(srv) {
        if (srv) {
            console.log(srv.name);
            let body = {
                name: srv.name,
                code: srv.code,
                urls: [srv.urls],
                skus: [srv.skus],
                emails: [srv.emails],
                phones: [srv.phones],
                op_metrics: [srv.opMetrics],
                login_fields: [srv.loginFields]
            };
            this.bizBrainServiceServices.editServices(this.service.productId,body)
                .subscribe(
                    response => {
                        if (response.json().flag) {
                          //  this.service = new BizBrainService().getObject(response.json().data);
                            this.goToBizBrainService();
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        } else {
        }
    }

    goToBizBrainService() {
        console.log("in view service");
        this.router.navigate([AppRoutingConstants.services]);
    }

    ngOnInit() {

        let serviceId: string;
        this.activeRoute.params.forEach(param => {serviceId = param['id']});
        this.getServiceById(serviceId);

    }

}
