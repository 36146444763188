import {Component, Input, EventEmitter, Output, NgModule} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Metric} from "../../models/metrics.model";
import {MetricsService} from "../../services/metrics.service";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Connector} from "../../../connectors/models/connector.model";
import {Organization} from "../../../organization/models/organization.model";
import {ConnectorsService} from "../../../connectors/services/connectors.service";
import {OrganizationService} from "../../../organization/services/organization.service";
import {Message} from "primeng/components/common/message";
//import { TextMaskModule } from '../../../../../../../node_modules/angular2-text-mask/src/angular2TextMask';

declare var jQuery: any;

@Component({
    selector: 'form-metrics',
    templateUrl: './metric-form.component.html',
    styleUrls: ['./metric-form.component.css']
})
export class MetricsForm {
    error: any;
    refreshPageVal: boolean = false;
    msgs: Message[] = [];

    metricForm: FormGroup;
    tagsForm: FormGroup;
    reportForm: FormGroup;
    filterForm: FormGroup;
    snapshotTimeForm: FormGroup;
    downsamplingTimeForm: FormGroup;

    tags: Array<any> = [];
    tagsTable: Array<any> = [];
    filters: Array<any> = [];
    filtersTable: any;
    reports: Array<any> = [];

    connectors: Connector[];
    connector: Connector;
    organizations: Organization[];
    org: Organization;

    snapshotdefs: Array<any>;
    snapshotdefsTable: any;
    time_string: any;
    aggregation: any;
    downsamplingdefs: Array<any>;
    downsamplingdefsTable: any;

    data_type: SelectItem[];
    metric_Type: SelectItem[];
    preferred_value_boolean: SelectItem[];
    preferred_value_number: SelectItem[];
    tag_type: SelectItem[];
    filter_operator: SelectItem[];
    aggregations: SelectItem[];
    metric_connectors: SelectItem[];
    metric_orgs: SelectItem[];
    getUniqueId = function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
    };

    private _metric: any;

    @Input()
    submitBtnLabel: string;

    @Input()
    metricType: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    formCancelEvent: EventEmitter<any> = new EventEmitter<any>();

    constructor(private metricsService: MetricsService, private activatedRoute: ActivatedRoute
        , private connectorService: ConnectorsService,
                private orgServices: OrganizationService, private router: Router) {
        this.snapshotdefs = [];
        this.downsamplingdefs = [];

    }


    get metric() {
        return this._metric;
    }

    @Input()
    set metric(value) {
        console.log("metric:", value);
        this._metric = value;
        if (value) {
            this.setValues();
        }

    }

    ngOnInit() {
        this.getOrganizations();
        this.getConnectors();
        //console.log("metricType-",this.metricType)
        let metricTypeVal:number;
        switch (this.metricType) {
            case "overview":
               metricTypeVal=null;
                break;
            case "all":
               metricTypeVal=null;
                break;
            case "system":
               metricTypeVal=1;
                break;
            case "connectors":
               metricTypeVal=2;
                break;
            case "organizations":
               metricTypeVal=3;
                break;
            case "groups":
               metricTypeVal=4;
                break;
            case "users":
               metricTypeVal=5;
                break;
        }
        this.metricForm = new FormGroup({
            name: new FormControl("", Validators.required),
            metric_key: new FormControl("", Validators.required),
            metric_type: new FormControl(metricTypeVal, Validators.required),
            description: new FormControl(""),
            metric_data: new FormControl(""),
            data_type: new FormControl(null, Validators.required),
            metric_unit: new FormControl(""),
            unit_value: new FormControl(""),
            preferred_value: new FormControl(null, Validators.required),
            connector_id: new FormControl(),
            org_id: new FormControl(),
        });
        this.tagsForm = new FormGroup({
            key: new FormControl("", Validators.required),
            type: new FormControl(null, Validators.required),
            label: new FormControl("", Validators.required),
        });

        this.reportForm = new FormGroup({
            report_key: new FormControl("", Validators.required),
            report_label: new FormControl("", Validators.required),
            report_start_time: new FormControl("", Validators.required),
            report_end_time: new FormControl("", Validators.required),
            cache_frequency: new FormControl("", Validators.required),
        });

        this.filterForm = new FormGroup({
            filter_key: new FormControl("", Validators.required),
            filter_label: new FormControl("", Validators.required),
            filter_operator: new FormControl(null, Validators.required),

        });

        this.snapshotTimeForm = new FormGroup({
            time_string: new FormControl("", Validators.required),
            aggregation: new FormControl(0, Validators.required),

        });
        this.downsamplingTimeForm = new FormGroup({
            time_string: new FormControl("", Validators.required),
            aggregation: new FormControl(0, Validators.required),
        });


        this.data_type = [];
        this.data_type.push({label: 'Select Data Type*', value: null});
        this.data_type.push({label: 'Boolean', value: 0});
        this.data_type.push({label: 'Number', value: 1});

        this.tag_type = [];
        this.tag_type.push({label: 'Select Tag Type*', value: null});
        this.tag_type.push({label: 'Text', value: 'Text'});
        this.tag_type.push({label: 'Number', value: 'Number'});
        this.tag_type.push({label: 'Boolean', value: 'Boolean'});
        this.tag_type.push({label: 'Date', value: 'Date'});
        this.tag_type.push({label: 'Time', value: 'Time'});
        this.tag_type.push({label: 'TimeStampSecs', value: 'TimeStampSecs'});
        this.tag_type.push({label: 'TimeStampUTC', value: 'TimeStampUTC'});

        this.preferred_value_number = [];
        this.preferred_value_number.push({label: 'Select Preferred Value*', value: null});
        this.preferred_value_number.push({label: 'Higher', value: 0});
        this.preferred_value_number.push({label: 'Lower', value: 1});

        this.preferred_value_boolean = [];
        this.preferred_value_boolean.push({label: 'Select Preferred Value*', value: null});
        this.preferred_value_boolean.push({label: 'True', value: 2});
        this.preferred_value_boolean.push({label: 'False', value: 3});


        this.metric_Type = [];
        this.metric_Type.push({label: 'Select Metric Type*', value: null});
        this.metric_Type.push({label: 'System Metric', value: 1});
        this.metric_Type.push({label: 'Connector Metric', value: 2});
        this.metric_Type.push({label: 'Organization Metric', value: 3});
        this.metric_Type.push({label: 'Group Metric', value: 4});
        this.metric_Type.push({label: 'User Metric', value: 5});

        this.filter_operator = [];
        this.filter_operator.push({label: 'Select Operator*', value: -1});
        this.filter_operator.push({label: 'Is', value: 0});
        this.filter_operator.push({label: 'In Between', value: 1});
        this.filter_operator.push({label: '<', value: 2});
        this.filter_operator.push({label: '>', value: 3});
        this.filter_operator.push({label: '=', value: 4});

        this.aggregations = [];
        this.aggregations.push({label: 'Select Aggregation', value: null});
        this.aggregations.push({label: 'Sum', value: 'sum'});
        this.aggregations.push({label: 'Average', value: 'average'});
    }

    getConnectors() {
        this.connectorService.getConnectors()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connectors = [];
                        this.connectors = response.data.map(val => new Connector().getObject(val));
                        this.metric_connectors = [];
                        this.metric_connectors.push({label: 'Select Connector*', value: null});
                        this.connectors.forEach(currnt => {
                            //console.log("currnt", currnt)
                            this.metric_connectors.push({label: currnt.name, value: currnt.uniqueId});
                        })
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getOrganizations() {
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.organizations = [];
                        this.organizations = data.map(val => new Organization().getObject(val));
                        this.metric_orgs = [];
                        this.metric_orgs.push({label: 'Select Organization*', value: null});
                        this.organizations.forEach(currnt => {
                            //console.log("currnt", currnt)
                            this.metric_orgs.push({label: currnt.name, value: currnt.uniqueId});
                        })
                    }
                    else {

                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    goToMetrics() {
        this.formCancelEvent.emit();
    }


    resetForm() {
        this.metricForm.reset();

    }

    setValues() {
        console.log("in set value")
        let value = {
            name: this._metric.name,
            description: this._metric.description,
            metric_key: this._metric.metric_key,
            metric_type: this._metric.metric_type,
            data_type: this._metric.data_type,
            metric_unit: this._metric.metric_unit,
            unit_value: this._metric.unit_value,
            preferred_value: this._metric.preferred_value,
            connector_id: this._metric.connector_id,
            org_id: this._metric.org_id
        };

        //console.log("this._metric",this._metric)
        this.snapshotdefs = [];
        if (this._metric.snapshotdefs) {
            this.snapshotdefs = this._metric.snapshotdefs;
            this.snapshotdefsTable=this.snapshotdefs;
        }
        this.downsamplingdefs = [];
        if (this._metric.downsamplingdefs) {
            this.downsamplingdefs = this._metric.downsamplingdefs;
            this.downsamplingdefsTable=this.downsamplingdefs
        }
        this.tags = [];
        if (this._metric.tags) {
            this.tags = this._metric.tags;
            this.tagsTable=this.tags;
        }
        this.filters = [];
        this.reports = [];
        if (this._metric.cached_reports) {
            this.reports = this._metric.cached_reports;
        }
        // console.log("this.reports",this.reports)

        this.metricForm.reset(value);

    }

    ngAfterViewInit() {

    }

    addNewMetric(event) {
        this.formSubmitEvent.emit(this.getFormData());
    }

    onChangeMetricUnit() {

    }

    addTags() {
        let m_data = {
            tag_id: this.getUniqueId(),
            key: this.tagsForm.value['key'],
            label: this.tagsForm.value['label'],
            dataType: this.tagsForm.value['type'],
        }
        console.log(m_data);

        this.tags.push(m_data);

        this.tagsForm = new FormGroup({
            key: new FormControl("", Validators.required),
            type: new FormControl(null, Validators.required),
            label: new FormControl("", Validators.required),
        });

        this.chnageTagsTable(this.tags)

    }

    chnageTagsTable(data) {
        this.tagsTable = [];
        this.tags=this.tagsTable;


        setTimeout(() => {
            this.tags=data;
            this.tagsTable= this.tags;
        },10)

    }

    addFilter() {
        let m_data = {
            filter_id: this.getUniqueId(),
            key: this.filterForm.value['filter_key'],
            label: this.filterForm.value['filter_label'],
            operator: this.filterForm.value['filter_operator'],
        }
        console.log(m_data);

        this.filters.push(m_data);

        this.filterForm = new FormGroup({
            filter_key: new FormControl("", Validators.required),
            filter_label: new FormControl("", Validators.required),
            filter_operator: new FormControl(null, Validators.required)
        });
        this.chnageFiltersable(this.filters)
    }

    chnageFiltersable(data) {
        this.filtersTable = [];
        this.filters=this.filtersTable;


        setTimeout(() => {
            this.filters=data;
            this.filtersTable= this.filters;
        },10)

    }

    addReport() {
        let m_data = {
            report_id: this.getUniqueId(),
            key: this.reportForm.value['report_key'],
            label: this.reportForm.value['report_label'],
            start_time: this.reportForm.value['report_start_time'],
            end_time: this.reportForm.value['report_end_time'],
            cache_frequency: this.reportForm.value['cache_frequency'],
            filters: this.filters
        }
        this.reports.push(m_data);

        this.reportForm = new FormGroup({
            report_key: new FormControl("", Validators.required),
            report_label: new FormControl("", Validators.required),
            report_start_time: new FormControl("", Validators.required),
            report_end_time: new FormControl("", Validators.required),
            cache_frequency: new FormControl("", Validators.required),
        });
        this.filters = [];

    }

    deleteTag(tag) {
        for (let i = 0; this.tags, i < this.tags.length; i++) {
            let currTag = this.tags[i];
            if (currTag.tag_id == tag.tag_id) {
                this.tags.splice(i, 1);
                break;
            }
        }
    }

    deleteFilter(filter) {
        for (let i = 0; this.filters, i < this.filters.length; i++) {
            let currFilter = this.filters[i];
            if (currFilter.filter_id == filter.filter_id) {
                this.filters.splice(i, 1);
                break;
            }
        }
    }

    deleteReport(report) {
        for (let i = 0; this.reports, i < this.reports.length; i++) {
            let currReport = this.reports[i];
            if (currReport.report_id == report.report_id) {
                this.reports.splice(i, 1);
                break;
            }
        }
    }

    addNewMetrics() {
        this.formSubmitEvent.emit(this.getFormData());

    }

    getFormData() {

        for (let i = 0; this.tags, i < this.tags.length; i++) {
            let currTag = this.tags[i];
            delete currTag.tag_id;
        }
        for (let i = 0; this.filters, i < this.filters.length; i++) {
            let currFilter = this.filters[i];
            delete currFilter.filter_id;
        }
        for (let i = 0; this.reports, i < this.reports.length; i++) {
            let currReport = this.reports[i];
            delete currReport.report_id;

        }

        let m_data = {
            name: this.metricForm.value['name'],
            description: this.metricForm.value['description'],
            metric_key: this.metricForm.value['metric_key'],
            data_type: this.metricForm.value['data_type'],
            unit_value: this.metricForm.value['unit_value'],
            metric_type: this.metricForm.value['metric_type'],
            tags: this.tags,
            unit: this.metricForm.value['metric_unit'],
            preferred_value: this.metricForm.value['preferred_value'],
            snapshotdefs: this.snapshotdefs,
            downsamplingdefs: this.downsamplingdefs,
            cached_reports: this.reports,
            connector_id: this.metricForm.value['connector_id'],
            org_id: this.metricForm.value['org_id'],
        };
        console.log("m_data", m_data);
        return m_data;
    }

    addSnapshotdefs() {
        var flag: boolean = false;
        if (this.snapshotTimeForm.value['time_string'] == "__:__:__" || this.snapshotTimeForm.value['time_string'] == "00:00:00") {
            this.snapshotTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });

        } else {
            if (this.snapshotdefs.length > 0) {

                for (let i = 0; i < this.snapshotdefs.length; i++) {
                    let time_string = this.snapshotdefs[i];
                    if (time_string.time_string == this.snapshotTimeForm.value['time_string']) {
                        flag = true;
                        break;
                    }
                }
                if (!flag) {
                    this.snapshotdefs.push({
                        time_string: this.snapshotTimeForm.value['time_string'],
                        aggregation: this.snapshotTimeForm.value['aggregation'],
                    });
                }
            } else {
                this.snapshotdefs.push({
                    time_string: this.snapshotTimeForm.value['time_string'],
                    aggregation: this.snapshotTimeForm.value['aggregation'],
                });
            }


            this.snapshotTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });
        }

        this.chnageSnapshotTimeTable(this.snapshotdefs);


        console.log(this.snapshotdefs);
    }

    chnageSnapshotTimeTable(data) {
        this.snapshotdefsTable = [];
        this.snapshotdefs=this.snapshotdefsTable;


        setTimeout(() => {
            this.snapshotdefs=data;
            this.snapshotdefsTable= this.snapshotdefs;
        },10)

    }


    addDownsamplingdefs() {
        var flag1: boolean = false;
        if (this.downsamplingTimeForm.value['time_string'] == "__:__:__" || this.downsamplingTimeForm.value['time_string'] == "00:00:00") {
            this.downsamplingTimeForm = new FormGroup({
                time_string: new FormControl(""),
                aggregation: new FormControl(0),
            });

        } else {
            if (this.downsamplingdefs.length > 0) {

                for (let i = 0; i < this.downsamplingdefs.length; i++) {
                    let time_string = this.downsamplingdefs[i];
                    if (time_string.time_string == this.downsamplingTimeForm.value['time_string']) {
                        flag1 = true;
                        break;
                    }
                }
                if (!flag1) {
                    this.downsamplingdefs.push({
                        time_string: this.downsamplingTimeForm.value['time_string'],
                        aggregation: this.downsamplingTimeForm.value['aggregation']

                    });
                }
            } else {
                this.downsamplingdefs.push({
                    time_string: this.downsamplingTimeForm.value['time_string'],
                    aggregation: this.downsamplingTimeForm.value['aggregation']

                });
            }


            this.downsamplingTimeForm = new FormGroup({
                time_string: new FormControl("", Validators.required),
                aggregation: new FormControl(0, Validators.required),
            });

            this.chnageDownsamplingTimeTable(this.downsamplingdefs);
        }
    }

    chnageDownsamplingTimeTable(data) {
        this.downsamplingdefsTable = [];
        this.downsamplingdefs=this.downsamplingdefsTable;


        setTimeout(() => {
            this.downsamplingdefs=data;
            this.downsamplingdefsTable= this.downsamplingdefs;
        },10)

    }

    checkSnapshotTime() {

        let time1 = this.snapshotTimeForm.value['time_string'];
        let aggregation1 = this.snapshotTimeForm.value['aggregation'];
        if (time1 != null) {
            if (time1 == '00:00:00' || aggregation1 == 0) {
                return true;
            }
            return false;
        }

    }

    checkDownsamplingdefs() {
        let times = this.downsamplingTimeForm.value['time_string'];
        let aggregations = this.downsamplingTimeForm.value['aggregation'];
        if (times != null) {
            if (times == '00:00:00' || aggregations == 0) {
                return true;
            }
            return false;
        }
    }

    deleteSnapshotdefs(data) {

        let index = null;
        let flag = null;

        for (let i = 0; i < this.snapshotdefs.length; i++) {
            let snapshotdef = this.snapshotdefs[i];
            if (data == snapshotdef) {
                index = i;
                flag = true;
            }
        }

        if (flag) {
            this.snapshotdefs.splice(index, 1);
        }
    }

    deleteDownsamplingdefs(data) {

        let index = null;
        let flag = null;

        for (let i = 0; i < this.downsamplingdefs.length; i++) {
            let downsamplingdef = this.downsamplingdefs[i];
            if (data == downsamplingdef) {
                index = i;
                flag = true;
            }
        }

        if (flag) {
            this.downsamplingdefs.splice(index, 1);
        }
    }

    editSnapshotdefs(data) {
        this.snapshotTimeForm.reset(data);
        this.deleteSnapshotdefs(data);
    }

    editDownsamplingdefs(data) {
        this.downsamplingTimeForm.reset(data);
        this.deleteDownsamplingdefs(data);
    }

}