import {Injectable} from "@angular/core";
import {Observable, Subscriber} from "rxjs";
import {Headers} from "@angular/http";
//import {LocalStorageConstants} from "../../local-storage.constants";
import {Cookie} from 'ng2-cookies';

@Injectable()
export class FileUploadService {

    private progress$: Observable<number>;

    private progress: number = 0;

    private progressObserver: Subscriber<number>;

    constructor() {
        this.progress$ = new Observable<number>(observer => {
            this.progressObserver = observer
        });
    }

    public getObserver(): Observable<number> {
        return this.progress$;
    }

    /**
     * Upload files through XMLHttpRequest
     *
     * @param url
     * @param files
     * @param key
     * @param headers
     * @returns {Observable<any>}
     */
    public upload(url: string, files: File[], key: string): Observable<any> {
        return Observable.create(observer => {
            let formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();

            for (let i = 0; i < files.length; i++) {
                formData.append(key, files[i]);
            }

            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };

            //FileUploadService.setUploadUpdateInterval(20);

            xhr.upload.onprogress = (event) => {
                this.progress = Math.round(event.loaded / event.total * 100);
                this.progressObserver.next(this.progress);
            };

            xhr.open('POST', url, true);
            xhr.setRequestHeader('Accept', "*");
            xhr.setRequestHeader('Access-Control-Allow-Origin', "*");
            if (localStorage.getItem('sessionId') != "") {
                xhr.setRequestHeader('token', localStorage.getItem('sessionId'));
            } else {
                xhr.setRequestHeader('token', Cookie.get('sessionId'));
            }


            xhr.send(formData);
            console.log("done");
        });
    }

    /**
     * Set interval for frequency with which Observable inside Promise will share data with subscribers.
     *
     * @param interval
     */
    private static setUploadUpdateInterval(interval: number): void {
        setInterval(() => {
        }, interval);
    }
}
