import {Component, Input, EventEmitter, Output, Inject, forwardRef} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {SelectItem} from "primeng/components/common/selectitem";
import {Organization} from "../../../../../organization/models/organization.model";
import {OrganizationService} from "../../../../../organization/services/organization.service";
import {commonConstants} from "../../../../../common/models/constants";
import {Message} from "primeng/components/common/message";


@Component({
    selector: 'subGroup-form',
    templateUrl: './subGroup-form.component.html',
    styleUrls: ['./subGroup-form.component.css']
})
export class SubGroupForm {

    subGroupForm: FormGroup;
    private _subGroupComponent: any;
    constants=commonConstants;

    error:any = [];

    serviceProviders: any;
    is_active: boolean;
    selectedImageUrlFile: any;
    selectedImageUrlFileData: any;
    selectedOverviewImageUrlFile: any;
    selectedOverviewImageUrlFileData: any;

    private _subscribeGroup: any;
    valueSchedule: string[] = [];
    uploadImageUrl: string;
    uploadImageUrl2: string;

    allThemes: any;
    all_Themes: SelectItem[];
    groupTypes: SelectItem[];
    organizations_all: SelectItem[];
    categoryTypes: SelectItem[];
    organizations: Organization[];

    msgs:Message[] =[];

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    formCancelEvent: EventEmitter<any> = new EventEmitter<any>();


    getFormData() {

        if (this.subGroupForm.value['themeId'] == null) {
            this.subGroupForm.value['themeId'] = '';
        }
        if (this.subGroupForm.value['categoryId'] == null) {
            this.subGroupForm.value['categoryId'] = '';
        }

        let formData = {
            name: this.subGroupForm.value['name'],
            groupDescription: this.subGroupForm.value['description'],
            messageExpiryPeriod: 0,
            category: this.subGroupForm.value['categoryId'],
            themeId: this.subGroupForm.value['themeId'],
            imageUrl: this.uploadImageUrl,
            groupOverviewImage: this.uploadImageUrl2,
            location: [],
            type: this.subGroupForm.value['type'],
            maxMembersCount: this.subGroupForm.value['maxMembersCount'],
        }

        console.log("getter : ", formData);
        return formData;

    }

    get subGroupComponent() {
        return this._subGroupComponent;
    }

    @Input()
    set subGroupComponent(value) {
        console.log("component:", value);
        this._subGroupComponent = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router, private orgServices: OrganizationService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
    }

    ngOnInit() {
        //this.getOrganizations();
        // this.getThemes();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.subGroupForm = new FormGroup({
            name: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            description: new FormControl(""),
            categoryId: new FormControl("",Validators.required),
            themeId: new FormControl(""),
            maxMembersCount: new FormControl("", Validators.required),
        });

        this.groupTypes = [];
        this.groupTypes.push({label: 'Select Group Type*', value: null});
        this.groupTypes.push({label: 'Public Group', value: 1});
        this.groupTypes.push({label: 'Close Group', value: 2});
        this.groupTypes.push({label: 'Private Group', value: 3});


        this.categoryTypes = [];
        this.categoryTypes.push({label: 'Select Category Type*', value: null});
        this.categoryTypes.push({value: 1, label: 'Friends'});
        this.categoryTypes.push({value: 2, label: 'Family'});
        this.categoryTypes.push({value: 3, label: 'Neighbours'});
        this.categoryTypes.push({value: 4, label: 'Business'});
        this.categoryTypes.push({value: 5, label: 'Classmetes'});
        this.categoryTypes.push({value: 6, label: 'Association'});
        this.categoryTypes.push({value: 7, label: 'Project'});
        this.categoryTypes.push({value: 8, label: 'Community'});
        this.categoryTypes.push({value: 9, label: 'Interest'});
        this.categoryTypes.push({value: 10, label: 'Locality'});
        this.categoryTypes.push({value: 11, label: 'Smart City'});
        this.categoryTypes.push({value: 12, label: 'Custom'});

        this.all_Themes = [];
        this.all_Themes.push({label: 'Select Theme', value: null});
    }


    resetForm() {
        this.subGroupForm.reset();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.subGroupForm = new FormGroup({
            name: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            description: new FormControl(""),
            categoryId: new FormControl(""),
            themeId: new FormControl(""),
        });
       this.formCancelEvent.emit();


    }

    setValues() {

        let value = {
            name: this._subGroupComponent.name,
            type: this._subGroupComponent.type,
            description: this._subGroupComponent.groupDescription,
            categoryId: this._subGroupComponent.category,
            themeId: this._subGroupComponent.themeId,
            maxMembersCount: this._subGroupComponent.maxMembersCount,

        };
        this.uploadImageUrl = this._subGroupComponent.imageUrl;
        this.uploadImageUrl2 = this._subGroupComponent.groupOverviewImage;

        this.selectedImageUrlFileData = this.convertUrlToImageUrl(this._subGroupComponent.imageUrl);
        this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this._subGroupComponent.groupOverviewImage);

        this.subGroupForm.reset(value);
        if (this._subGroupComponent.category != '') {
            this.getThemesByCategory(this._subGroupComponent.category);
        }

        console.log(" this.subGroupForm-", this.subGroupForm)

    }

    getOrganizations() {
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.organizations = [];
                        data.forEach(org => {
                            this.organizations.push(new Organization().getObject(org))
                        })

                        this.organizations_all = [];
                        this.organizations_all.push({label: 'Select Organization*', value: null});
                        this.organizations.forEach(currnt => {
                            this.organizations_all.push({label: currnt.name, value: currnt.uniqueId});
                        })
                    }
                    else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getCategoryId(e) {
        let id = e.value;
        this.getThemesByCategory(id)
    }

    getThemesByCategory(id) {

        if (id != '' || id != null) {
            this.connectlyProService.getThemesByCategory(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.allThemes = response.data;
                            if(this.allThemes.length <=0){
                                this.all_Themes = [];
                                this.all_Themes.push({label: 'Select Theme', value: null});
                            }else{
                                this.all_Themes = [];
                                this.all_Themes.push({label: 'Select Theme', value: null});
                                this.allThemes.forEach(currnt => {
                                    this.all_Themes.push({label: currnt.name, value: currnt.id});
                                })
                            }


                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        }
    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedImageUrlFile);
            this.selectedImageUrlFileData = reader.result;
            this.uploadImage(this.selectedImageUrlFile);
        };
        reader.readAsDataURL(file);
    }


    onFileSelected2(event) {
        this.showMyImage2(event);
    }

    showMyImage2(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedOverviewImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedOverviewImageUrlFile);
            this.selectedOverviewImageUrlFileData = reader.result;
            this.uploadImage2(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }

    uploadImage(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl = response.data;
                        this.selectedImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    uploadImage2(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl2 = response.data;
                        this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl2);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    convertUrlToImageUrl(url) {
        if (url != '') {
            var array = url.split("/");
            for (let i = 0; i < array.length; i++) {
                let currarray = array[i];
                if (currarray.indexOf('__w-') != -1) {
                    array[i] = [];
                    array[i] = 'w200';
                }
            }

            let imageUrl = array.join('/');

            return imageUrl;
        }

    }

    addNewSubscribeGroup(event) {
        this.error = [];

        if (this.subGroupForm.value['maxMembersCount'] <= 1) {
            this.error.push("Member limit must be greater or equal to 2");
        }

        if (this.subGroupForm.value['maxMembersCount'] > 100000) {
            this.error.push("Member limit can not exceeds 100000.");
        }

        if (this.error && this.error.length == 0) this.formSubmitEvent.emit(this.getFormData());

    }


}