/**
 * Created by hirenparekh on 28/12/16.
 */

import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'objectLength',
    pure:false
})
export class OnjectLengthFilter implements PipeTransform {

    transform(item){
        if (!item) {
            return 0;
        }
        return Object.keys(item).length;
    }
}