import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from "@angular/router";
import {Injectable} from "@angular/core";
import {AppRoutingConstants} from "./app-routing.constants";

@Injectable()
export class AuthService implements CanActivate{

  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    return this.checkLogin(url);
  }

  checkLogin(url: string): boolean {
    if(localStorage.getItem('sessionId')) {
      return true;
    }
    this.router.navigate([AppRoutingConstants.login]);
    return false;
  }
}
