import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';

import {ConfirmationService, Message} from "primeng/components/common/api";
import {commonConstants} from "../../../../../common/models/constants";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class overviewMember implements AfterViewInit {

    members: any;
    groupId: string;
    constants = commonConstants;
    msgs: Message[] = [];
    groupMember: any;
    groupMembers: any;
    subscribedMembers: any;
    groupMemberCount: any;

    searchVal:string;
    displayLoader:boolean=false;
    searchBlock:boolean=false;


    constructor(private _titleService: Title, private router: Router, private confirmationService: ConfirmationService,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Home Dashboard');

    }

    ngOnInit() {
        let skip = 0;
        let limit = this.constants.pageRows;
        this.groupId = this.subscribeOrgConnectlyProService.groupId;
        this.getGroupMember(this.groupId,skip,limit);
        this.getGroupMemberCount(this.groupId)
    }

    paginate(event) {
        //console.log(event);
        this.getGroupMember(this.groupId,event.first, event.rows);
    }

    getGroupMemberCount(groupId: string) {
        this.subscribeOrgConnectlyProService.getGroupMemberCount(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.groupMemberCount = response.data;
                        //console.log("this.groupMember", this.groupMemberCount)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getGroupMember(groupId: string,skip,limit) {

        this.subscribeOrgConnectlyProService.getGroupMember(groupId, skip, limit)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.groupMember = response.data;
                        console.log("this.groupMember", this.groupMember)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    viewMember(id: string): void {
       // this.router.navigate([AppRoutingConstants.subscribeOrganization_view_id, this.groupId, 'members', 'view', id]);
    }

    addMember() {
        //this.router.navigate([AppRoutingConstants.subscribeOrganization_view_id, this.groupId, 'members', 'add']);
    }

    editMember(id) {
        //this.router.navigate([AppRoutingConstants.subscribeOrganization_view_id, this.groupId, 'members', 'edit', id]);
    }

    serachMember(event) {
        this.displayLoader=true;
        let temp_data = {
            groupId:this.groupId,
            text: this.searchVal,
            skip: 0,
            limit: 100
        };
        //console.log("this.searchVal",this.searchVal.length)
        if(this.searchVal.length > 2){
            this.searchBlock=true;
            this.subscribeOrgConnectlyProService.searchMember(temp_data)
                .subscribe(
                    response => {
                        if (response.flag) {
                            //this.groupMember=[];
                            this.displayLoader=false;

                            let data: any = response.data;
                            this.groupMembers = response.data;
                            this.groupMemberCount = this.groupMembers.length;
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        }

        if(this.searchVal.length <= 2){
            this.getGroupMemberCount(this.groupId);
            this.displayLoader=false;
            this.searchBlock=false;
        }

    }

    deleteMember(member) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this member ?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectlyProService.deleteMember(member.id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.members = this.members.filter(val => val.id !== member.id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: member.name + ' Theme Delete Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

}
