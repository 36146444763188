import {Component, Input, EventEmitter, Output} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {AppRoutingConstants} from '../../../app-routing.constants';
import {ActivatedRoute, Router} from '@angular/router';
import {SelectItem} from 'primeng/components/common/api';
import {Connector} from '../../../connectors/models/connector.model';
import {ConnectorsService} from '../../../connectors/services/connectors.service';
import {ConnectlyProService} from '../../services/connectly-pro.service';
import {Title} from '@angular/platform-browser';
import {SubscribeOrgConnectlyProService} from '../../services/subscribed-org.service';
import {Organization} from '../../../organization/models/organization.model';
import {OrganizationService} from '../../../organization/services/organization.service';
import {commonConstants} from '../../../common/models/constants';
import {Message} from 'primeng/components/common/message';

@Component({
    selector: 'subscribed-org-form',
    templateUrl: './subscribed-org-form.component.html',
    styleUrls: ['./subscribed-org-form.component.css']
})
export class SubscribeOrgForm {
    Upadte:any;
    error:any = [];
    constants=commonConstants;
    subscribeOrgForm: FormGroup;
    scheduleOptForm: FormGroup;
    serviceProviders: any;
    is_active: boolean;
    selectedImageUrlFile: any;
    selectedImageUrlFileData: any;
    selectedOverviewImageUrlFile: any;
    selectedOverviewImageUrlFileData: any;

    private _subscribeGroup: any;
    valueSchedule: string[] = [];
    uploadImageUrl: string;
    uploadImageUrl2: string;

    activeBlock: any;
    allThemes: any;
    all_Themes: SelectItem[];
    groupTypes: SelectItem[];
    organizations_all: SelectItem[];
    categoryTypes: SelectItem[];
    organizations: Organization[];

    msgs: Message[] = [];
    selectedDeploymentValue: string;

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();

    getFormData() {

        if (this.subscribeOrgForm.value['themeId'] == null) {
            this.subscribeOrgForm.value['themeId'] = '';
        }
        if (this.subscribeOrgForm.value['categoryId'] == null) {
            this.subscribeOrgForm.value['categoryId'] = '';
        }

        let formData = {
            name: this.subscribeOrgForm.value['name'],
            orgId: this.subscribeOrgForm.value['orgId'],
            groupDescription: this.subscribeOrgForm.value['description'],
            messageExpiryPeriod: 0,
            category: this.subscribeOrgForm.value['categoryId'],
            themeId: this.subscribeOrgForm.value['themeId'],
            imageUrl: this.uploadImageUrl,
            groupOverviewImage: this.uploadImageUrl2,
            location: [],
            type: this.subscribeOrgForm.value['type'],
            maxMembersCount: this.subscribeOrgForm.value['maxMembersCount'],
            deploymentType: this.subscribeOrgForm.value['deploymentOption']
        }

        console.log('getter : ', formData);
        return formData;

    }

    get subscribeGroup() {
        return this._subscribeGroup;
    }

    @Input()
    set subscribeGroup(value) {
        console.log('subscribeGroup:', value);

        this._subscribeGroup = value;
        if (value) {
            this.setValues();
        }
    }

    constructor(private _titleService: Title, private router: Router, private connectlyProService: ConnectlyProService,
                private activeRoute: ActivatedRoute, private orgServices: OrganizationService,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

    }

    ngOnInit() {

        // this.getThemes();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';
        //this.selectedDeploymentValue= '1';
        this.subscribeOrgForm = new FormGroup({
            orgId: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            description: new FormControl(''),
            categoryId: new FormControl('', Validators.required),
            themeId: new FormControl(''),
            maxMembersCount: new FormControl('', Validators.required),
            deploymentOption: new FormControl(''),

        });

        this.groupTypes = [];
        this.groupTypes.push({label: 'Select Group Visibility*', value: null});
        this.groupTypes.push({label: 'Public Group', value: 1});
        this.groupTypes.push({label: 'Closed Group', value: 2});
        this.groupTypes.push({label: 'Private Group', value: 3});


        this.categoryTypes = [];
        this.categoryTypes.push({label: 'Select Group Category*', value: null});
        this.categoryTypes.push({value: 1, label: 'Friends'});
        this.categoryTypes.push({value: 2, label: 'Family'});
        this.categoryTypes.push({value: 3, label: 'Neighbours'});
        this.categoryTypes.push({value: 4, label: 'Business'});
        this.categoryTypes.push({value: 5, label: 'Classmates'});
        this.categoryTypes.push({value: 6, label: 'Association'});
        this.categoryTypes.push({value: 7, label: 'Project'});
        this.categoryTypes.push({value: 8, label: 'Community'});
        this.categoryTypes.push({value: 9, label: 'Interest'});
        this.categoryTypes.push({value: 10, label: 'Locality'});
        this.categoryTypes.push({value: 11, label: 'Smart City'});
        this.categoryTypes.push({value: 12, label: 'Custom'});

        this.all_Themes = [];
        this.all_Themes.push({label: 'Select Group Theme', value: null});

        this.organizations_all = [];
        this.organizations_all.push({label: 'Select Organization*', value: null});
        this.getOrganizations();
    }

    setValues() {

        let value = {
            name: this._subscribeGroup.name,
            orgId: this._subscribeGroup.orgId,
            type: this._subscribeGroup.type,
            description: this._subscribeGroup.groupDescription,
            categoryId: this._subscribeGroup.category,
            themeId: this._subscribeGroup.themeId,
            maxMembersCount: this._subscribeGroup.maxMembersCount,
            deploymentOption: this._subscribeGroup.deploymentOption
        };
        this.uploadImageUrl = this._subscribeGroup.imageUrl;
        this.uploadImageUrl2 = this._subscribeGroup.groupOverviewImage;

        this.selectedImageUrlFileData = this.convertUrlToImageUrl(this._subscribeGroup.imageUrl);
        this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this._subscribeGroup.groupOverviewImage);

        this.subscribeOrgForm.reset(value);
        if (this._subscribeGroup.category != '') {
            this.getThemesByCategory(this._subscribeGroup.category);
        }

        console.log(' this.subscribeOrgForm-', this.subscribeOrgForm)

    }

    getOrganizations() {
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.organizations = [];
                        data.forEach(org => {
                            this.organizations.push(new Organization().getObject(org))
                        });


                        this.organizations.forEach(currnt => {
                            this.organizations_all.push({label: currnt.name, value: currnt.uniqueId});
                        });
                        if (this._subscribeGroup) {
                            this.setValues();
                        }
                    }
                    else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getCategoryId(e) {
        this.all_Themes = [];
        let id = e.value;
        this.getThemesByCategory(id)
    }

    getThemesByCategory(id) {

        if (id != '' || id != null) {
            this.connectlyProService.getThemesByCategory(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.allThemes = response.data;
                            if (this.allThemes.length <= 0) {
                                this.all_Themes = [];
                                this.all_Themes.push({label: 'No Themes Available', value: null});
                            } else {
                                this.all_Themes = [];
                                this.all_Themes.push({label: 'Select Theme', value: null});
                                this.allThemes.forEach(currnt => {
                                    this.all_Themes.push({label: currnt.name, value: currnt.id});
                                })
                            }


                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        }
    }


    resetForm() {
        this.subscribeOrgForm.reset();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.subscribeOrgForm = new FormGroup({
            orgId: new FormControl('', Validators.required),
            name: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            description: new FormControl(''),
            categoryId: new FormControl(''),
            themeId: new FormControl(''),
        });
        this.router.navigate([AppRoutingConstants.subscribeOrganization, this.activeBlock]);


    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile = file;
            console.log('this.selectedImageUrlFile', this.selectedImageUrlFile);
            this.selectedImageUrlFileData = reader.result;
            this.uploadImage(this.selectedImageUrlFile);
        };
        reader.readAsDataURL(file);
    }


    onFileSelected2(event) {
        this.showMyImage2(event);
    }

    showMyImage2(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedOverviewImageUrlFile = file;
            console.log('this.selectedImageUrlFile', this.selectedOverviewImageUrlFile);
            this.selectedOverviewImageUrlFileData = reader.result;
            this.uploadImage2(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }

    uploadImage(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl = response.data;
                        // this.selectedImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    uploadImage2(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl2 = response.data;
                        // this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl2);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    convertUrlToImageUrl(url) {
        if (url != '') {
            var array = url.split('/');
            for (let i = 0; i < array.length; i++) {
                let currarray = array[i];
                if (currarray.indexOf('__w-') != -1) {
                    array[i] = [];
                    array[i] = 'w200';
                }
            }

            let imageUrl = array.join('/');

            return imageUrl;
        }

    }

    addNewSubscribeGroup(event) {
        this.error = [];

        /* if (!this.constants.appNamePattern.test(this.subscribeOrgForm.value['name'])) {
             this.error.push("App Name must be valid");
         }*/
        if (this.subscribeOrgForm.value['maxMembersCount'] <= 1) {
            this.error.push('Member limit must be greater or equal to 2.');
        }
        if (this.subscribeOrgForm.value['maxMembersCount'] > 100000) {
            this.error.push('Member limit can not exceeds 100000.');
        }

        if (this.error && this.error.length == 0) this.formSubmitEvent.emit(this.getFormData());

    }

}