import {Component, ViewChild, ViewChildren,AfterViewInit} from "@angular/core";
import {Connector} from "../models/connector.model";
import {ConnectorsService} from "../services/connectors.service";
import {AddConnectorComponent} from "./add-connector/add-connector.component";
import {EditConnectorComponent} from "./edit-connector/edit-connector.component";
import {BasicFunctions} from "../../functions";
import {ViewConnectorComponent} from "./view-connector/view-connector.component";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {LoginService} from "../../login/services/login.service";


@Component({
    selector: 'connectors',
    templateUrl: './connectors.component.html',
    styleUrls: ['./connectors.component.scss']
})
export class ConnectorsComponent {
    pageName = "Connectors";
    connectors: Connector[];
    searchStr: string = "";
    connectorsLength: number = 0;
    displayAddConnector:boolean=false;

    routes: Object[] = [{
        title: 'Overview',
        route: '/connectors',
        icon: 'dashboard',
    }
    ];
    activeroute:string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;


    constructor(private router: Router,private connectorService: ConnectorsService,private _titleService: Title, private loginService: LoginService,
                private route: ActivatedRoute) {

    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page

        this._titleService.setTitle('Dashboard');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
                this.activeroute=this.router.url;
            })
    }

    display: boolean = false;

    showDialog() {
        this.display = true;
    }

    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }

}