import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {TallyService} from '../services/tally.service';
import {Organization} from '../../organization/models/organization.model';
import {commonConstants} from '../../common/models/constants';
import {ConfirmationService, Message} from 'primeng/components/common/api';
import {AppRoutingConstants} from '../../app-routing.constants';
import {Router} from '@angular/router';


@Component({
    selector: 'app-tally-customers',
    templateUrl: './customers.component.html',
    styleUrls: ['./customers.component.scss'],
})
export class CustomersTallyComponent implements AfterViewInit {
    constants = commonConstants;
    customersCount: any;
    customers: any;
    pageFirst: any;
    pageRows: any;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router, private tallyService: TallyService) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Tally');
    }

    paginate(event) {
        console.log(event);
        this.pageFirst = event.first;
        this.pageRows = event.rows;
        this.getCustomers(event.first, event.rows);

    }

    onRowSelect(event) {
        this.viewCustomerDetail(event.data.unique_id);
    }

    viewCustomerDetail(customerId: string): void {
        this.router.navigate([AppRoutingConstants.tally_organization_detail, customerId]);
    }

    getCustomers(skip, limit) {
        this.tallyService.getCustomers(skip, limit)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.customers = response.data;
                    }
                    else {

                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    getCustomerCount() {

        this.tallyService.getCustomerCount()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.customersCount = response.data;
                    } else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                });

    }

    ngOnInit() {
        this.pageFirst = 0;
        this.pageRows = this.constants.pageRows;
        this.getCustomerCount();
        this.getCustomers(this.pageFirst, this.pageRows);
    }
}
