import {Component, AfterViewInit, Input, Injector} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router, Params} from "@angular/router";
import {Message} from "primeng/components/common/api";
import {commonConstants} from "../../../../../common/models/constants";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'overview-apps-subscribe-org',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewAppsSubscribeOrgComponent implements AfterViewInit {
    groupId: string;
    GroupProfile: string;
    GroupOverview: string;
    selectedThemeId: string;
    GroupTheme: string;
    displayThemeBlock: boolean = false;
    group: any;
    theme: any;
    allThemes: any;
    allThemesImages: any = [];
    constants = commonConstants;
    msgs: Message[] = [];
    constructor(private _titleService: Title, private router: Router,private _injector: Injector,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
        console.log("block--",activeRoute.snapshot.parent.params['block']);
    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Home Dashboard');

    }

    ngOnInit() {
        //this.groupId = this.activeRoute.snapshot.parent.params['id'];
        /*this.activeRoute.url.forEach(param => {
            this.groupId = param['id']});*/

        console.log("==this.groupId",this.groupId);
        //this.getGroupById(this.groupId)
    }

    getGroupById(groupId: string) {

        this.connectlyProService.getGroupById(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.group = response.data;

                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error)
                }
            );
    }

    openCommonFrom(){
        //this.router.navigate([AppRoutingConstants.subscribeOrganization_view_id, this.groupId, 'apps','common']);
    }

    displayMessage(msg){
        this.msgs = [];
        this.msgs.push({severity:'error', summary:'Error Message', detail:msg});
    }

}
