import {Component, OnInit} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {Organization} from "../../models/organization.model";
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
/**
 * Created by hirenparekh on 14/11/16.
 */

@Component({
    selector:'edit-organization',
    templateUrl:'./edit-organization.component.html',
    styleUrls:['./edit-organization.component.css','../organizations.component.scss']
})
export class EditOrganizationComponent implements OnInit{

    organization: Organization;

    constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute, private router: Router) {

    }

    editOrganization(org:Organization):void
    {
        this.orgServices.editOrganization(org.uniqueId,JSON.stringify(org))
            .subscribe(
                response => {
                    if(response.flag){
                        this.goToOrganization();
                    }else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            )
    }

    getOrgById(orgId:string):void
    {
        this.orgServices.getOrganizationById(orgId)
            .subscribe(
                response => {
                    if(response.flag){
                        let data: any = response.data;
                        this.organization = new Organization().getObject(data);
                    }else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                }
            )
    }

    goToOrganization(){
        this.router.navigate([AppRoutingConstants.organizations]);
    }

    ngOnInit(){
        let orgId:string;
        this.activatedRoute.params.forEach(param => {orgId = param['id']});
        this.getOrgById(orgId);
    }

}
