/**
 * Created by hirenparekh on 14/06/17.
 */
import {Component, Input, OnInit, EventEmitter, ViewChild, Inject, forwardRef, Output} from '@angular/core';
import {trigger, state, style, transition, animate} from '@angular/animations';
import {Location} from '@angular/common';
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {MenuItem} from 'primeng/primeng';

@Component({
  selector: 'app-menu',
  templateUrl: './app-menu.component.html',
  animations: [
    trigger('children', [
      state('hidden', style({
        height: '0px',
        padding: '0 1em 0 2em'
      })),
      state('visible', style({
        height: '*',
        padding: '.5em 1em .5em 2em'
      })),
      transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
      transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ]
})
export class AppMenuComponent implements OnInit {

  @Input() reset: boolean;

  @Input() model: any[];
  @Input() menu: string;

  activeroute: any;

  popupStyle: any;


  ngOnInit() {

  }
  ngAfterViewInit() {
    this.router.events
        .filter(event => event instanceof NavigationEnd)
        .subscribe(event => {
          this.activeroute = this.router.url;
          console.log("this.activeroute--", this.activeroute)
        })

  }

  @Input() item: MenuItem;

  @Input() root: boolean;

  @Input() visible: boolean;

  @Output()
  overlayMenuClickEvent: EventEmitter<any> = new EventEmitter<any>();

  activeItem: any;

  headerMenu: Array<any> = [{
    label: 'Home',
    routerLink: [''],
    icon: 'biz-home',
    isActiveMenu: false,
    items: [
      {label: 'Overview', icon: 'biz-overview', routerLink: ['']}]
  }];

  mainHeader = [{
    label: 'Favorites',
    items: this.headerMenu
  }]

  constructor(public router: Router, public location: Location) {
    console.log(this.mainHeader);
    console.log(this.model);
  }

  OnOverlayMenuClick(currSubChild) {
    //console.log("overlayMenuClickEvent");
    //console.log("overlayMenuClickEvent",currSubChild);
    this.overlayMenuClickEvent.emit(currSubChild);
  }

  onPinClick(item) {
    item.isPined = !item.isPined;
    let data = {
      label: item.label,
      routerLink: item.routerLink,
      icon: item.icon,
      items: item.items,
      isActiveMenu: item.isActiveMenu,
    }

    if (item.isPined) {
      item.isActiveMenu = true;
      data.isActiveMenu = true;


      this.headerMenu.push(data);
    } else {
      item.isActiveMenu = false;
      data.isActiveMenu = false;
      this.headerMenu.forEach((currentObj, index) => {
        console.log("currentObj", currentObj)
        if (currentObj.routerLink === data.routerLink) {
          this.headerMenu.splice(index, 1)
        }
      })
    }

  }

  /*itemClick(event: Event, item: MenuItem, index: number) {
    //avoid processing disabled items
    if (item.disabled) {
      event.preventDefault();
      return true;
    }

    //execute command
    if (item.command) {
      if (!item.eventEmitter) {
        item.eventEmitter = new EventEmitter();
        item.eventEmitter.subscribe(item.command);
      }

      item.eventEmitter.emit({
        originalEvent: event,
        item: item
      });
    }

    //prevent hash change
    if (item.items || (!item.url && !item.routerLink)) {
      event.preventDefault();
    }

  }*/

  isActive(item: any): boolean {
    return this.activeItem == item;
  }

  onMainMenuItemClick(item: MenuItem) {
    item.expanded = !item.expanded;
  }

  popupItems: any;
  activeSubChild:any;
  openPopupMenu(event, menu: any) {
    this.activeSubChild = menu;
    this.popupItems = menu.items;
    let domeRect: any = event.target.getBoundingClientRect();
    this.popupStyle = {
      display: 'block',
      top: (domeRect.top - 64) + 'px',
      left: (domeRect.left + domeRect.width) + 'px',
    }
  }

  closePopupMenu() {
    this.popupItems = [];
    this.popupStyle = {
      display: 'none'
    }
  }
}
