import {Component, AfterViewInit} from '@angular/core';

import {Title} from '@angular/platform-browser';

import {LoginService} from "../login/services/login.service";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";

@Component({
    selector: 'qs-dashboard-product',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements AfterViewInit {

    routes: Object[] = [{
        title: 'Dashboard',
        route: '/',
        icon: 'dashboard',
    }
    ];


    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;
    constructor(private _titleService: Title, private loginService: LoginService,
                private route: ActivatedRoute,
                private router: Router) {
    }

  ngOnInit() {

    this.loginService.getNewSession()
  }

    ngAfterViewInit(): void {
       /* // broadcast to all listener observables when loading the page

        this._titleService.setTitle('Dashboard');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
            })*/
    }

    /*logout() {
        this.loginService.logOut();
    }

    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }*/
}
