import {ActivatedRoute, Router} from "@angular/router";
import {BizBrainServicesService} from "../../services/bizbrain-service.service";
import {Component} from "@angular/core";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {commonConstants} from "../../../common/models/constants";
/**
 * Created by hirenparekh on 15/11/16.
 */

@Component({
    selector: 'add-service',
    templateUrl: './add-service.component.html',
    styleUrls: ['./add-service.component.css', '../bizbrain-service.component.scss']
})
export class AddBizBrainServiceComponent {
    name:any;
    code:any;
    urls:any;
    skus:any;
    emails:any;
    phones:any;
    op_metrics:any;
    login_fields:any;

    constants = commonConstants;
    error:any = [];
    constructor(private bizBrainServiceServices: BizBrainServicesService, private router: Router, private activeRoute: ActivatedRoute) {

    }

    addNewService(value, valid) {
        this.validateRequiredField(value, valid);

    }

    validateRequiredField(value, valid) {
        this.error = [];


        if (!value.emails || value.emails == "") {
            this.error.push("Email cannot be blank.");
        }
        if (value.emails && !this.constants.emailPattern.test(value.emails)) {
            this.error.push("Email must be valid");
        }


        if (this.error && this.error.length == 0) {
            let body = {
                name: value.name,
                code: value.code,
                urls: [value.urls],
                skus: [value.skus],
                emails: [value.emails],
                phones: [value.phones],
                op_metrics: [value.op_metrics],
                login_fields: [value.login_fields]
            };
            this.bizBrainServiceServices.addServices(JSON.stringify(body))
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.goToBizBrainService();
                        } else {
                            console.log(response.message);
                        }
                    },
                    error => {
                        console.log(error);
                    }
                )

        }

    }

    btnCancelClick() {
        this.goToBizBrainService();
    }

    goToBizBrainService() {
        this.router.navigate([AppRoutingConstants.services]);
    }

}