/**
 * Created by hirenparekh on 11/11/16.
 */

import {Injectable} from "@angular/core";
import {Http, Headers, Response} from "@angular/http";
import {Environment} from "../../environment.model";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";

@Injectable()
export class OrganizationService{

  headers:Headers;

  constructor(private http:Http,private router: Router) {
    this.headers = new Headers();
    this.headers.append('Accept', '*');
   // this.headers.append('Access-Control-Allow-Origin', '*');
    this.headers.append('Content-Type', 'application/json');
    this.headers.append('token',localStorage.getItem('sessionId'));
  console.log("localStorage.getItem('sessionId')",localStorage.getItem('sessionId'))
  }



  getOrganizations():Observable<any>{
    return this.http.get(Environment.apiUrl+'organizations', {headers: this.headers})
      .map((response:Response) => response.json())
        .do((response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
      });
  }

  getOrganizationById(id:string):Observable<any> {
    return this.http.get(Environment.apiUrl+'organizations/' + id, {headers: this.headers})
      .map((response:Response) => response.json())
        .do((response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  addOrganization(body:string):Observable<any> {
    return this.http.post(Environment.apiUrl+'organizations', body, {headers: this.headers})
        .do((response:Response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  editOrganization(id,body):Observable<any> {
    return this.http.put(Environment.apiUrl+'organizations/'+id, body, {headers: this.headers})
        .map((response:Response) => response.json())
        .do((response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  deleteOrganization(id):Observable<any> {
    return this.http.delete(Environment.apiUrl+'organizations/' + id, {headers: this.headers})
        .map((response:Response) => response.json())
        .do((response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  addNewOrganizationUser(body:string):Observable<any> {
    return this.http.post(Environment.apiUrl+'users',body, {headers: this.headers})
        .do((response:Response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  addNewOrganizationService(body):Observable<any> {
    return this.http.post(Environment.apiUrl +'serviceSubscriptions',body, {headers: this.headers})
        .do((response:Response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  deleteOrganizationService(id):Observable<any> {
    return this.http.delete(Environment.apiUrl +'serviceSubscriptions/'+id, {headers: this.headers})
        .do((response:Response) => {
          this.authorizedUserService(response)
        })
        .catch((err:Response) => {
            let details = err.json();
            this.authorizedUserService(details)
            return Observable.throw(details);
        });
  }

  authorizedUserService(response){
    if (response.flag == false) {
      if(response.code == 401){
          localStorage.clear();
        this.router.navigate([AppRoutingConstants.login]);
      }
    }
  }

}
