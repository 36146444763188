import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';


@Component({
    selector: 'app-things-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewThingsComponent implements AfterViewInit {


    constructor(private _titleService: Title) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Things');

    }
}
