import {Component, AfterViewInit, ViewChild} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {Metric} from "../../models/metrics.model";
import {ViewMetrics} from "../view-metrics/view-metrics.component";
import {MetricsService} from "../../services/metrics.service";
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";
import {BasicFunctions} from "../../../functions";
import {AddMetrics} from "../add-metric/add-metric.component";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {ConnectorsService} from "../../../connectors/services/connectors.service";
import {Connector} from "../../../connectors/models/connector.model";
import {Organization} from "../../../organization/models/organization.model";
import {OrganizationService} from "../../../organization/services/organization.service";
import {ConfirmationService, SelectItem, Message} from "primeng/components/common/api";
import {commonConstants} from "../../../common/models/constants";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class MetricsOverviewComponent implements AfterViewInit {
    refreshPageVal: boolean = false;
    metricsCount: any;
    constants = commonConstants;

    pageName = "Metrics";
    metrics: Metric[];
    selectedMetric: Metric;
    selectedMetricOrgId: string;
    metricOrgs: Array<any>;
    searchStr: string = "";
    connectorSelectForm: FormGroup;
    organizationSelectForm: FormGroup;
    metricOrgForm: FormGroup;
    displayOrgnizationPopup = false;
    displayOrgnizationMetricDataPopup = false;
    routes: Object[] = [{
        title: 'Overview',
        route: '/metrics',
        icon: 'dashboard',
    }
    ];
    activeroute: string;
    activeTabIndex: number;
    activeTabData: string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;
    connectors: Connector[];
    connector: Connector;
    organizations: Organization[];
    org: Organization;
    metricDataCounts: {};
    metricsDataList: Array<any>;
    systemClass: string = '';
    routeOrgId: string = '';

    metric_orgs: SelectItem[];
    msgs: Message[] = [];
    @ViewChild('viewMetricModal') viewMetricModal: ViewMetrics;
    @ViewChild('addMetricsModal') addMetricsModal: AddMetrics;


    constructor(private metricsService: MetricsService, private router: Router, private activeRoute: ActivatedRoute, private _titleService: Title,
                private route: ActivatedRoute, private connectorService: ConnectorsService,
                private orgServices: OrganizationService, private confirmationService: ConfirmationService) {

    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Overview');
        this.title = this._titleService.getTitle();

    }

    getConnectors() {
        this.connectorService.getConnectors()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connectors = response.data.map(val => new Connector().getObject(val));
                        if (this.connectors && this.connectors.length > 0) {

                        }
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getOrganizations() {
        this.orgServices.getOrganizations()
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: Array<any> = response.data;
                        this.organizations = [];
                        data.forEach(org => {
                            this.organizations.push(new Organization().getObject(org))
                        })
                        if (this.organizations.length > 0 && this.routeOrgId != '') {
                            this.organizations.forEach(currnt => {
                                if (currnt.uniqueId == this.routeOrgId) {
                                    this.org = currnt;
                                }
                            })
                        }
                    }
                    else {

                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    getMetricsByCategoryCount() {
        let type: any;
        if (this.activeTabIndex != 0) {
            type = this.activeTabIndex - 1;
        } else {
            type = 0
        }

        this.metricsService.getMetricsByCategoryCount(type)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metricsCount = response.data;
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getActiveTabData() {
        if (this.activeroute == '/metrics') {
            this.activeTabIndex = 0;
            this.activeTabData = 'Overview';
            this.metrics = [];
        }
        if (this.activeroute == '/metrics/all') {
            //this.getMetricsByCategoryCount();
            this.activeTabData = 'All';

            var m_data = {
                type: 0,
                count: this.constants.pageRows,
                start: 0
            }
            this.activeTabIndex = 1;
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute == '/metrics/system') {
            this.activeTabData = 'System';
            var m_data = {
                type: 1,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 2;
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute.indexOf('/metrics/connectors') != -1) {
            this.getConnectors();
            this.activeTabData = 'Connectors';
            this.activeTabIndex = 3;
            this.getConnectorMetrics();
        }
        if (this.activeroute.indexOf('/metrics/organizations') != -1) {

            this.getOrganizations();

            this.activeTabData = 'Organizations';
            this.activeTabIndex = 4;

            this.getOrgMetrics();
        }
        if (this.activeroute == '/metrics/groups') {
            this.activeTabData = 'Groups';
            var m_data = {
                type: 4,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 5;
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute == '/metrics/users') {
            this.activeTabData = 'Users';
            var m_data = {
                type: 5,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 6;
            this.getMetricsByCategory(m_data);
        }
    }

    paginate(event) {
        if (this.activeroute == '/metrics') {
            this.metrics = [];
        }
        if (this.activeroute == '/metrics/all') {
            var m_data = {
                type: 0,
                count: event.rows,
                start: event.first
            }
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute == '/metrics/system') {
            var m_data = {
                type: 1,
                count: event.rows,
                start: event.first
            }
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute.indexOf('/metrics/connectors') != -1) {
            this.getConnectorMetrics();
        }
        if (this.activeroute.indexOf('/metrics/organizations') != -1) {
            this.getOrgMetrics();
        }
        if (this.activeroute == '/metrics/groups') {
            var m_data = {
                type: 4,
                count: event.rows,
                start: event.first
            }
            this.getMetricsByCategory(m_data);
        }
        if (this.activeroute == '/metrics/users') {
            var m_data = {
                type: 5,
                count: event.rows,
                start: event.first
            }
            this.getMetricsByCategory(m_data);
        }
    }

    onRowSelect(event) {
        //alert(event.data.productId);
        this.openViewMetrics(event.data);
    }

    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }

    ngOnInit() {
        this.connectorSelectForm = new FormGroup({
            connector: new FormControl(0, Validators.required),
        });
        this.organizationSelectForm = new FormGroup({
            organization: new FormControl(0, Validators.required),
        });

        this.metricOrgForm = new FormGroup({
            metricOrg: new FormControl(0, Validators.required),
        });

        this.activeroute = this.router.url;
        //console.log("this.activeroute",this.activeroute)

        this.getActiveTabData();
        this.getMetricsByCategoryCount();
        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('class')) {
                this.systemClass = param['class']
            }
        });
        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('orgId')) {
                this.routeOrgId = param['orgId']
            }
        });
        // console.log("this.systemClass", this.systemClass);
        //console.log("this.routeOrgId", this.routeOrgId);

        if (!this.systemClass || this.systemClass === undefined || this.systemClass == '') {

        } else {
            let m_data = {
                "field": "class_namespace"
            }
            this.metricsService.getConnectorBySystemClass(this.systemClass)
                .subscribe(
                    response => {
                        if (response.flag) {
                            this.connector = new Connector().getObject(response.data);
                            console.log("this.connector", this.connector);
                            console.log("this.connector.uniqueId", this.connector.uniqueId)

                            let m_data = {
                                type: 2,
                                count: 25,
                                start: 0,
                                connector_id: this.connector.uniqueId
                            };
                            this.getMetricsByCategory(m_data);

                        } else {
                            console.log(response.message);
                        }
                    },
                    error => {
                        console.log("error--", error);
                        this.refreshPageVal=true;
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );
        }

        if (!this.routeOrgId || this.routeOrgId === undefined || this.routeOrgId == '') {

        } else {
            let m_data = {
                type: 3,
                count: 25,
                start: 0,
                org_id: this.routeOrgId
            };

            this.getMetricsByCategory(m_data);
        }

    }


    handleChange(e) {
        var index = e.index;
        //console.log("index",index)
        if (index == 0) {
            this.metrics = [];
            this.activeTabIndex = 0;
            this.router.navigate([AppRoutingConstants.metrics]);
        }
        if (index == 1) {
            var m_data = {
                type: 0,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 0;
            this.router.navigate([AppRoutingConstants.metrics, 'all']);
            // this.getMetricsByCategory(m_data);
        }
        if (index == 2) {
            var m_data = {
                type: 1,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 1;
            this.router.navigate([AppRoutingConstants.metrics, 'system']);
            //this.getMetricsByCategory(m_data);
        }
        if (index == 3) {
            this.activeTabIndex = 2;
            this.router.navigate([AppRoutingConstants.metrics, 'connectors']);
            this.getConnectorMetrics();

        }
        if (index == 4) {
            this.activeTabIndex = 3;
            this.router.navigate([AppRoutingConstants.metrics, 'organizations']);
            this.getOrgMetrics();
        }
        if (index == 5) {
            var m_data = {
                type: 4,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 4;
            this.router.navigate([AppRoutingConstants.metrics, 'groups']);
            //this.getMetricsByCategory(m_data);
        }
        if (index == 6) {
            var m_data = {
                type: 5,
                count: 25,
                start: 0
            }
            this.activeTabIndex = 5;
            this.router.navigate([AppRoutingConstants.metrics, 'users']);
            //this.getMetricsByCategory(m_data);
        }

    }

    selectedConnector(systemClass, id) {
        //console.log(e.target.value);
        let m_data = {
            type: 2,
            connector_id: id,
            count: 25,
            start: 0
        };
        this.router.navigate([AppRoutingConstants.metrics, 'connectors', systemClass]);
        this.getMetricsByCategory(m_data);
        this.connectors.forEach(currnt => {
            if (currnt.uniqueId == id) {
                this.connector = currnt;
            }
        })
    }

    selectedOrganization(id) {
        // console.log("orgid", id);
        let m_data = {
            type: 3,
            org_id: id,
            count: 25,
            start: 0
        };
        this.router.navigate([AppRoutingConstants.metrics, 'organizations', id]);
        this.getMetricsByCategory(m_data);
        this.organizations.forEach(currnt => {
            if (currnt.uniqueId == id) {
                this.org = currnt;
            }
        })
    }

    getConnectorMetrics() {
        this.metrics = [];
    }

    getOrgMetrics() {
        this.metrics = [];
    }

    getMetricsByCategory(m_data) {
        this.metricsService.getMetricsByCategory(m_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metrics = response.data.map(val => new Metric().getObject(val));
                        //console.log("this.metrics-", this.metrics)
                        new BasicFunctions().insertionSort(this.metrics, 'createdAt');
                    } else {
                        console.log("response.message---", response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getMetricsDataCount(m_data) {
        this.metricsService.getMetricsDataCount(m_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metricDataCounts = response.data;
                        console.log(this.metricDataCounts)
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    openViewMetrics(metric: Metric) {
        //this.viewMetricModal.openModal(metric);
        if (this.activeroute == '/metrics/all') {
            this.router.navigate(['metrics/all/view', metric.metricId]);
        }
        if (this.activeroute == '/metrics/system') {
            this.router.navigate(['metrics/system/view', metric.metricId]);
        }
        if (this.activeroute.indexOf('/metrics/connectors') != -1) {
            this.router.navigate(['metrics/connectors/view', metric.metricId]);
        }
        if (this.activeroute.indexOf('/metrics/organizations') != -1) {
            this.router.navigate(['metrics/organizations/view', metric.metricId]);
        }
        if (this.activeroute == '/metrics/groups') {
            this.router.navigate(['metrics/groups/view', metric.metricId]);
        }
        if (this.activeroute == '/metrics/users') {
            this.router.navigate(['metrics/users/view', metric.metricId]);
        }
    }

    openAddMetric(activedata) {
        if (activedata == 'Overview') this.router.navigate([AppRoutingConstants.metrics, 'overview', 'add']);
        if (activedata == 'All') this.router.navigate([AppRoutingConstants.metrics, 'all', 'add']);
        if (activedata == 'System') this.router.navigate([AppRoutingConstants.metrics, 'system', 'add']);
        if (activedata == 'Connectors') this.router.navigate([AppRoutingConstants.metrics, 'connectors', 'add']);
        if (activedata == 'Organizations') this.router.navigate([AppRoutingConstants.metrics, 'organizations', 'add']);
        if (activedata == 'Groups') this.router.navigate([AppRoutingConstants.metrics, 'groups', 'add']);
        if (activedata == 'Users') this.router.navigate([AppRoutingConstants.metrics, 'users', 'add']);
    }

    editMetric(key, activedata) {
        if (activedata == 'Overview') this.router.navigate([AppRoutingConstants.metrics, 'overview', 'edit', key]);
        if (activedata == 'All') this.router.navigate([AppRoutingConstants.metrics, 'all', 'edit', key]);
        if (activedata == 'System') this.router.navigate([AppRoutingConstants.metrics, 'system', 'edit', key]);
        if (activedata == 'Connectors') this.router.navigate([AppRoutingConstants.metrics, 'connectors', 'edit', key]);
        if (activedata == 'Organizations') this.router.navigate([AppRoutingConstants.metrics, 'organizations', 'edit', key]);
        if (activedata == 'Groups') this.router.navigate([AppRoutingConstants.metrics, 'groups', 'edit', key]);
        if (activedata == 'Users') this.router.navigate([AppRoutingConstants.metrics, 'users', 'edit', key]);
    }


    onNewMetricAdded() {

    }

    OpenOrgPopup(met) {
        this.metricOrgs = [];
        this.metricDataCounts = {};
        this.metricsDataList = [];
        this.selectedMetricOrgId = '';
        this.displayOrgnizationPopup = true;
        this.metricOrgs = met.organizations;
        this.selectedMetric = met;
        this.metricsService.selectedMetric = met;

        this.metric_orgs = [];
        this.metric_orgs.push({label: 'Select Organization', value: null});
        this.metricOrgs.forEach(currnt => {
            //console.log("currnt", currnt)
            this.metric_orgs.push({label: currnt.name, value: currnt.org_id});
        })
    }

    OpenOrgPopupFromOrg(met) {
        this.metricOrgs = [];
        this.metricDataCounts = {};
        this.metricsDataList = [];
        this.selectedMetricOrgId = '';
        this.metricOrgs = met.organizations;
        this.selectedMetric = met;
        this.metricsService.selectedMetric = met;

        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('orgId')) {
                this.routeOrgId = param['orgId']
            }
        });

        let m_data = {
            type: 3,
            org_id: this.routeOrgId,
            metric_key: this.selectedMetric.metricKey
        }
        this.metricsService.metricData = m_data;
        this.router.navigate([AppRoutingConstants.metrics, 'organizations', m_data.org_id, this.selectedMetric.metricKey, 'data']);


        /*this.metric_orgs = [];
         this.metric_orgs.push({label: 'Select Organization', value: null});

         this.metricOrgs.forEach(currnt => {
         if (currnt.org_id == this.routeOrgId) {
         this.metric_orgs.push({label: currnt.name, value: currnt.org_id});
         }
         })*/


    }

    selectedMetricOrg(e) {
        // console.log(e.target.value);

        if (e.target.value != "0") {
            this.selectedMetricOrgId = e.target.value;
        } else {
            this.metricDataCounts = {};
        }

    }

    openMetricData(activedata) {
        let m_data = {
            type: 0,
            org_id: this.metricOrgForm.controls['metricOrg'].value,
            metric_key: this.selectedMetric.metricKey
        }
        this.metricsService.metricData = m_data;
        if (activedata == "Connectors") {
            console.log("this.systemClass-", this.systemClass)
        }

        //this.router.navigate([AppRoutingConstants.metrics, m_data.orgId, this.selectedMetric.metricKey, 'data']);
        console.log(activedata);
        if (activedata == 'Overview') this.router.navigate([AppRoutingConstants.metrics, 'overview', m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'All') this.router.navigate([AppRoutingConstants.metrics, 'all', m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'System') this.router.navigate([AppRoutingConstants.metrics, 'system', m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'Connectors') this.router.navigate([AppRoutingConstants.metrics, 'connectors', this.systemClass, m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'Organization') this.router.navigate([AppRoutingConstants.metrics, 'organizations', m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'Groups') this.router.navigate([AppRoutingConstants.metrics, 'groups', m_data.org_id, this.selectedMetric.metricKey, 'data']);
        if (activedata == 'Users') this.router.navigate([AppRoutingConstants.metrics, 'users', m_data.org_id, this.selectedMetric.metricKey, 'data']);

        //this.getMetricsDataCount(m_data);
    }

    getlastMonthMetricData() {
        if (this.selectedMetricOrgId != '') {
            let m_data = {
                type: "lastMonth",
                org_id: this.selectedMetricOrgId,
                metric_key: this.selectedMetric.metricKey
            }
            this.getMetricData(m_data);

        }

    }

    getMetricData(m_data) {
        this.metricsService.getMetricData(m_data)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.metricsDataList = response.data;
                        this.displayOrgnizationMetricDataPopup = true;

                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    deleteMetric(met) {

        this.confirmationService.confirm({
            message: 'Do you want to delete this metric?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.metricsService.deleteMetrics(met.metricId)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.metrics = this.metrics.filter(val => val.metricId !== met.metricId);
                                this.metricsCount = this.metricsCount - 1;
                                new BasicFunctions().insertionSort(this.metrics, 'createdAt');
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: met.name + ' Metric Delete Successfully'
                                });
                            } else {
                                console.log(response.message);
                            }
                        },
                        error => {
                            console.log("error--", error);
                            this.refreshPageVal=true;
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        }
                    );
            },
            reject: () => {
            }
        });


    }

    refreshPage() {
        location.reload();
    }

    resetOrgnizationPopup(){
        this.displayOrgnizationPopup=false;
    }

}
