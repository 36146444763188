import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';

import {ConfirmationService, Message} from "primeng/components/common/api";
import {commonConstants} from "../../../../../common/models/constants";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'qs-product-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class overviewSubGroup implements AfterViewInit {
    activeBlock: any;
    groupTopicsCount: any;

    groupId: string;
    GroupProfile: string;

    selectedThemeId: string;
    GroupTheme: string;

    groupTopics: any;
    theme: any;

    constants = commonConstants;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router, private confirmationService: ConfirmationService,
                private activeRoute: ActivatedRoute, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
         private connectlyProService: ConnectlyProService) {
       // this.groupId = activeRoute.snapshot.parent.params['id'];
        //console.log("this.groupId",this.groupId);
        this.activeBlock=this.connectlyProService.activeBlock;
    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Home Dashboard');

    }

    ngOnInit() {
        let skip = 0;
        let limit = this.constants.pageRows;
        this.groupId=this.connectlyProService.groupId;
        this.getGroupTopics(this.groupId,skip,limit);
        this.getGroupTopicsCount(this.groupId);
    }

    paginate(event) {
        //console.log(event);
        this.getGroupTopics(this.groupId,event.first, event.rows);
    }

    getGroupTopics(groupId: string,skip,limit) {

        this.subscribeOrgConnectlyProService.getGroupTopics(groupId,skip,limit)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.groupTopics = response.data;
                        //console.log("this.groupTopics", this.groupTopics)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getGroupTopicsCount(groupId: string) {

        this.subscribeOrgConnectlyProService.getGroupTopicsCount(groupId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data: any = response.data;
                        this.groupTopicsCount = response.data;
                        //console.log("this.groupTopics", this.groupTopicsCount)
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    onRowSelect(event) {

        this.viewSubGroup(event.data.id);
    }


    viewSubGroup(id: string): void {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId,'subGroups','view',id]);
    }

    addSubGroup() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId,'subGroups','add']);
    }

    editSubGroup(id) {
        console.log("editSubGroup click",id);
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view',this.groupId,'subGroups','edit',id]);
    }

    deleteSubGroup(subGroup) {
       let temp_data={
            groupId:subGroup.id
        }

        this.confirmationService.confirm({
            message: 'Do you want to delete this group?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.subscribeOrgConnectlyProService.deleteNewSubscribedOrg(temp_data)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.groupTopics = this.groupTopics.filter(val => val.id !== subGroup.id);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'success',
                                    summary: 'Success Message',
                                    detail: subGroup.name + ' SubGroup Delete Successfully'
                                });
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {

            }
        })
    }

}
