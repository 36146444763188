import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {MemberForm} from "../member-form/member-form.component";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'edit-member',
    templateUrl: './edit-member.component.html',
    styleUrls: ['./edit-member.component.css']
})
export class EditMember {
    //@Output() componentEditedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('editMember') editMember: ElementRef;
    @ViewChild('memberForm') memberForm: MemberForm;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    member: any;
    groupId: any;

    msgs:Message[] =[];
    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
    }

   /* openModal(connectorComponent: ConnectorComponent ,connector :Connector) {
        this.resetView();
       // jQuery(this.editComponent.nativeElement).modal('show');
        this.connector =connector;
        this.connectorComponent = connectorComponent;
        this.memberForm.component = connectorComponent;
    }*/

    closeModal() {
        //jQuery(this.editComponent.nativeElement).modal('hide');
    }


    ngOnInit() {
        this.groupId = this.connectlyProService.groupId;
        let id = this.activeRoute.snapshot.parent.params['memberId'];
        console.log("memberId",id);
       // this.getMemberById(id);
    }

    getMemberById(id){

           /* this.connectlyProService.getMemberById(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data: any = response.data;
                            this.member = response.data;
                            this.memberForm.memberComponent =this.member;
                        } else {
                            console.log(response.message);
                        }
                    },
                    error => {
                        console.log(error)
                    }
                );*/

    }

    onFormSubmit(data:any){
        data.appId = this.groupId;
        this.connectlyProService.editNewMember(this.member.id,data)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                   // this.componentEditedEvent.emit(new ConnectorComponent().getObject(response.data))
                }else{
                    console.log(response.message);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: response.message
                    });
                }
            },
            error => {
                console.log(error);
                this.msgs = [];
                this.msgs.push({
                    severity: 'error',
                    summary: 'Error Message',
                    detail: ' Oops! Something Went Wrong'
                });
            },
            () => {
                this.closeModal();
            }
        );
    }

}