import {Component, OnInit} from '@angular/core';
import {LoginService} from "../services/login.service";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";
import {User} from "../../user/models/user.model";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    msgs = [];
    error: string;
    checkboxValues: string[]
    constructor(private loginService: LoginService, private router: Router) {

    }

    ngOnInit() {
        if (localStorage.getItem('sessionId') != "") {
            console.log(localStorage.getItem('sessionId'));
            this.router.navigate(['/']);
        } else {
            this.router.navigate(['/login']);
        }

    }

    login(event, username, password) {
        event.preventDefault();
        this.msgs = [];
        if (username == '') {
            this.msgs.push('Please enter user name.');
        }

        if (password == '') {
            this.msgs.push('Please enter password.');
        }

        this.loginService.login(username, password)
            .subscribe(response => {
                    if (response.flag) {
                        let data = response.data;
                        let user: User = new User().getObject(data.user);
                        localStorage.setItem('sessionId', data.sessionId);
                        localStorage.setItem('user', JSON.stringify(data.user));
                        console.log("localStorage--",localStorage.getItem('sessionId'));
                        //this.loginEvent.emit(true);
                        window.location.href='/';
                        //this.router.navigate(["/"]);
                    }else{
                        this.msgs.push(response.message);
                    }
                },
                error => {
                    console.log(error);
                });
    }
}
