import {Component} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Router} from "@angular/router";
import {Organization} from "../../models/organization.model";
import {Response} from "@angular/http";
import {AppRoutingConstants} from "../../../app-routing.constants";
/**
 * Created by hirenparekh on 14/11/16.
 */


@Component({
    selector: 'add-organization',
    templateUrl: './add-organization.component.html',
    styleUrls: ['./add-organization.component.css', '../organizations.component.scss']
})
export class AddOrganizationComponent {

    orgName:any;
    orgDomain:any;
    orgUscope:any;
    orgHtype:any;
    orgCname:any;
    orgSid:any;
    fname:any;
    mname:any;
    lname:any;
    username:any;
    password:any;
    cpassword:any;
    user_type:any;
    email:any;
    mobile:any;
    birth_date:any;
    gender:any;
    organization: Organization;
    maxDate: Date;
    constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute, private router: Router) {
        this.organization = new Organization();
        this.maxDate = new Date();
       // console.log(this.maxDate)
    }

    addNewOrganization(value, valid): void {
        let body = {
            name: value.orgName,
            domain: value.orgDomain,
            user_scope: value.orgUscope,
            hash_type: value.orgHtype,
            company: {
                name: value.orgCname,
                short_id: value.orgSid,
            },
            admin_user: {
                fname: value.fname,
                lname: value.lname,
                mname: value.mname,
                email: value.email,
                mobile: value.mobile,
                username: value.username,
                password: value.password,
                user_type: value.user_type,
                gender: value.gender,
                birth_date: value.birth_date,
                is_local:true
            }
        };

        this.orgServices.addOrganization(JSON.stringify(body))
            .subscribe(
                (response) => {
                    if (response.json().flag) {
                        this.goToOrganization();
                    } else {
                        console.log(response.message);
                    }
                },
                error => {
                    console.log(error);
                })
    }

    goToOrganization() {
        this.router.navigate([AppRoutingConstants.organizations]);
    }
}