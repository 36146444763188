import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {ConnectorsService} from "../../services/connectors.service";
import {Connector} from "../../models/connector.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ConnectorForm} from "../connector-form/connector-form.component";
import {Message} from "primeng/components/common/api";
/**
 * Created by hirenparekh on 26/12/16.
 */

declare var jQuery: any;

@Component({
    selector: 'add-connector',
    templateUrl: './add-connector.component.html',
    styleUrls: ['./add-connector.component.css', '../connectors.component.scss']
})
export class AddConnectorComponent {
    @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('addConnector') addConnector: ElementRef;
    @ViewChild('connectorForm') connectorForm: ConnectorForm;
    scheduleOptForm: FormGroup;
    displayAddConnector:boolean=true;
    msgs: Message[] = [];
    constructor(private connectorService: ConnectorsService) {

    }

   /* @Input()
    displayVisiblity:string;*/

    onFormSubmit(event: FormData) {
        this.addNewConnectors(event);
    }

    addNewConnectors(formData: FormData) {
        this.connectorService.addNewConnector(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.connectorAddedEvent.emit(new Connector().getObject(response.data));
                       /* this.msgs = [];
                        this.msgs.push({severity:'success', summary:'Success Message', detail:'Connector Added Successfully'});*/
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }

    openModal() {
        this.resetView();
       // jQuery(this.addConnector.nativeElement).modal('show');
    }

    closeModal() {
        //jQuery(this.addConnector.nativeElement).modal('hide');
        if(this.connectorForm){
            this.connectorForm.resetForm();
        }
    }

    resetView() {

    }

    ngOnInit() {
    }

}