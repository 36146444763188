/**
 * Created by hirenparekh on 11/11/16.
 */

import {Component, OnInit} from "@angular/core";
import {OrganizationService} from "../../services/organization.service";
import {ActivatedRoute, Params, Router} from "@angular/router";
import {Organization} from "../../models/organization.model";
import {User} from "../../../user/models/user.model";
import {ProductSubscription} from "../../models/product-subscription.model";
import {AppRoutingConstants} from "../../../app-routing.constants";


@Component({
  selector: 'org-details',
  templateUrl: './organization-detail.component.html',
  styleUrls: ['./organization-detail.component.css','../organizations.component.scss']
})
export class OrganizationDetailComponent implements OnInit {

  organization: Organization;
  users: User[] = [];
  subscriptions: ProductSubscription[] = [];

  constructor(private orgServices: OrganizationService, private activatedRoute: ActivatedRoute,private router:Router) {

  }

  getOrgById(orgId: string) {

    this.orgServices.getOrganizationById(orgId)
      .subscribe(
        response => {
          if (response.flag) {
            let data: any = response.data;
            this.organization = new Organization().getObject(data);
            data.users.forEach(user => {
              this.users.push(new User().getObject(user))
            });
            data.product_subscriptions.forEach(prodSub => {
              this.subscriptions.push(new ProductSubscription().getObject(prodSub))
            });
          } else {
              console.log(response.message);
          }
        },
        error => {
          console.log(error)
        }
      );
  }

  goToOrganizationUsers(orgId:string){
    this.router.navigate([AppRoutingConstants.organizations_view_id,orgId,'users']);
  }

  goToOrganizationServices(orgId:string){
    this.router.navigate([AppRoutingConstants.organizations_view_id,orgId,'subscriptions']);
  }

  goToOrganization():void
  {
    this.router.navigate([AppRoutingConstants.organizations]);
  }



  ngOnInit() {
    let orgId: string;
    this.activatedRoute.params.forEach((params: Params) => {
      orgId = params['id'];
    });
    this.getOrgById(orgId);
  }
}
