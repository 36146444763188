import {Component, Input, EventEmitter, Output} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {SelectItem} from "primeng/components/common/api";
import {Title} from "@angular/platform-browser";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {commonConstants} from "../../../../../common/models/constants";
import {Message} from "primeng/components/common/message";

@Component({
    selector: 'ios-form',
    templateUrl: './ios-form.component.html',
    styleUrls: ['./ios-form.component.css']
})
export class IOSAppsForm {
    error: any = [];
    constants = commonConstants;
    iosForm: FormGroup;
    scheduleOptForm: FormGroup;
    serviceProviders: any;
    is_active: boolean;
    selectedImageUrlFile: any;
    selectedImageUrlFileData: any;
    selectedOverviewImageUrlFile: any;
    selectedOverviewImageUrlFileData: any;

    private _iosVal: any;
    valueSchedule: string[] = [];
    uploadImageUrl: string;
    uploadImageUrl2: string;

    allThemes: any;
    all_Themes: SelectItem[];
    groupTypes: SelectItem[];
    organizations_all: SelectItem[];
    categoryTypes: SelectItem[];

    msgs: Message[] = [];
    @Input()
    submitBtnLabel: string;

    @Output()
    iosAddedEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    iosEditEvent: EventEmitter<any> = new EventEmitter<any>();

    @Output()
    iosFormCancelEvent: EventEmitter<any> = new EventEmitter<any>();

    getFormData() {

        let formData = {
            appId: this.iosForm.value['appId'],
            appStorePassword: this.iosForm.value['appStorePassword'],
            appStoreEmail: this.iosForm.value['appStoreEmail'],
        }

        console.log("getter : ", formData);
        return formData;

    }

    get iosVal() {
        return this._iosVal;
    }

    @Input()
    set iosVal(value) {
        console.log("iosVal:", value);

        this._iosVal = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router, private connectlyProService: ConnectlyProService,
                private activeRoute: ActivatedRoute,
                private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

        this.iosForm = new FormGroup({
            appId: new FormControl("", Validators.required),
            appStoreEmail: new FormControl("", Validators.required),
            appStorePassword: new FormControl("", Validators.required),
            appName: new FormControl(''),
        });

    }

    ngOnInit() {


    }

    setValues() {

        let value = {
            appId: this._iosVal.appId,
            appStoreEmail: this._iosVal.appStoreEmail,
            appStorePassword: this._iosVal.appStorePassword,
        };

        this.iosForm.reset(value);

        console.log(" this.iosForm-", this.iosForm)

    }


    getCategoryId(e) {
        let id = e.value;
        //this.getThemesByCategory(id)
    }


    resetForm() {
        this.iosForm.reset();
        this.selectedImageUrlFile = '';
        this.selectedImageUrlFileData = '';

        this.selectedOverviewImageUrlFile = '';
        this.selectedOverviewImageUrlFileData = '';

        this.iosForm = new FormGroup({
            appId: new FormControl('', Validators.required),
            appName: new FormControl('', Validators.required),
        });

        this.iosFormCancelEvent.emit();

    }

    onFileSelected(event) {
        this.showMyImage(event);
    }

    showMyImage(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedImageUrlFile);
            this.selectedImageUrlFileData = reader.result;
            this.uploadImage(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }


    onFileSelected2(event) {
        this.showMyImage2(event);
    }

    showMyImage2(fileEvent) {

        let fileInput = fileEvent.target;
        let file: any = fileInput.files[0];
        let reader = new FileReader();
        reader.onload = (ev) => {
            this.selectedOverviewImageUrlFile = file;
            console.log("this.selectedImageUrlFile", this.selectedOverviewImageUrlFile);
            this.selectedOverviewImageUrlFileData = reader.result;
            this.uploadImage2(this.selectedOverviewImageUrlFile);
        };
        reader.readAsDataURL(file);
    }

    uploadImage(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl = response.data;
                        this.selectedImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    uploadImage2(file) {
        let formData = new FormData();
        formData.append('file', file);

        this.connectlyProService.uploadImage(formData)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.uploadImageUrl2 = response.data;
                        this.selectedOverviewImageUrlFileData = this.convertUrlToImageUrl(this.uploadImageUrl);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );

    }

    convertUrlToImageUrl(url) {
        var array = url.split("/");
        for (let i = 0; i < array.length; i++) {
            let currarray = array[i];
            if (currarray.indexOf('__w-') != -1) {
                array[i] = [];
                array[i] = 'w200';
            }
        }

        let imageUrl = array.join('/');

        return imageUrl;
    }

    addNewIosForm(e) {
        this.error=[];
        let appId = this.iosForm.value['appId'];
        if (!this.constants.appIdPattern.test(appId)) {
            this.error.push("App ID must be valid");
        }
        if (this.iosForm.value['appId'].length > 255) {
            this.error.push("Maximum length of app Id is 255");
        }

        if (this.iosForm.value['appStoreEmail'] != '' && !this.constants.emailPattern.test(this.iosForm.value['appStoreEmail'])) {
            this.error.push("App store email must be valid");
        }


        if (this.error && this.error.length == 0) {
            this.iosAddedEvent.emit(this.getFormData());

        }


    }

}