import {Component, ViewChild, ElementRef, EventEmitter, Output} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";

import {EventForm} from "../event-form/event-form.component";

import {Message} from "primeng/components/common/message";
import {EventsService} from "../../services/events.service";
import {AppRoutingConstants} from "../../../app-routing.constants";


declare var jQuery: any;

@Component({
    selector: 'edit-event',
    templateUrl: './edit-event.component.html',
    styleUrls: ['./edit-event.component.css']
})
export class EditEvent {
    //@Output() componentEditedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('editEvent') editEvent: ElementRef;
    @ViewChild('eventForm') eventForm: EventForm;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    event: any;
    groupId: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private eventsService: EventsService) {
    }



    ngOnInit() {
        let id='';

        this.activeRoute.params.subscribe(param => {
                id = param['id'];
            }
        );
        console.log("eventId",id);
        this.getEventById(id);
    }

    getEventById(id){

            this.eventsService.getEventById(id)
                .subscribe(
                    response => {
                        if (response.flag) {
                            let data: any = response.data;
                            this.event = response.data;
                            this.eventForm.eventComponent =this.event;
                        } else {
                            console.log(response.message);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: response.message
                            });
                        }
                    },
                    error => {
                        console.log(error);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }
                );

    }

    onFormSubmit(data:any){
        this.eventsService.editNewEvent(this.event.unique_id,data)
        .subscribe(
            response => {
                if(response.flag){
                    console.log("updated",response.data);
                    this.closeModal();
                }else{
                    console.log(response.message);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: response.message
                    });
                }
            },
            error => {
                console.log(error);
                if(error.code == 422){
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: 'The event key has been already taken.'
                    });
                }else {
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            },
            () => {

            }
        );
    }


    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.events]);
    }


}