import {LoginField} from "../../login/models/login-field.model";
import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 11/11/16.
 */

export class BizBrainService implements ISerializable<BizBrainService> {

  name: string;
  serviceCode: string;
  urls: string[];
  skus: string[];
  emails: string[];
  phones: string[];
  opMetrics: any[];
  product_skus: any[];
  code: string;
  
  loginFields: LoginField[];
  productId: string;
  updatedAt: string;
  createdAt: string;

  getObject(data: any): BizBrainService {
    let bizBrainService = new BizBrainService();
    bizBrainService.name = data['name'];
    bizBrainService.serviceCode = data['code'];
    bizBrainService.urls = data['urls'];
    bizBrainService.skus = data['skus'];
    bizBrainService.code = data['code'];
    bizBrainService.emails = data['emails'];
    bizBrainService.phones = data['phones'];
    bizBrainService.opMetrics = data['op_metrics'];
    bizBrainService.loginFields = data['login_fields'];
    bizBrainService.productId = data['product_id'];
    bizBrainService.updatedAt = data['updated_at'];
    bizBrainService.createdAt = data['created_at'];
    bizBrainService.product_skus = data['product_skus'];
    return bizBrainService;
  }

}
