import {Component, OnInit,AfterViewInit} from "@angular/core";
import {BizBrainService} from "../models/bizbrain-service.model";
import {BizBrainServicesService} from "../services/bizbrain-service.service";
import {AppRoutingConstants} from "../../app-routing.constants";

import {Title} from '@angular/platform-browser';

import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {LoginService} from "../../login/services/login.service";

/**
 * Created by hirenparekh on 11/11/16.
 */

@Component({
    selector: 'bizbrain-service',
    templateUrl: './bizbrain-service.component.html',
    styleUrls: ['./bizbrain-service.component.scss']
})
export class BizBrainServiceComponent implements OnInit {

    routes: Object[] = [{
        title: 'Overview',
        route: '/services',
        icon: 'dashboard',
    }
    ];
    activeroute:string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;
    pageName: string = "Services";

    services: BizBrainService[];

    constructor(private bizBrainServiceServices: BizBrainServicesService,
                private router: Router, private activeRoute: ActivatedRoute,private _titleService: Title, private loginService: LoginService,
               private route: ActivatedRoute) {

    }
    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page

        this._titleService.setTitle('Dashboard');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
                this.activeroute=this.router.url;
            })
    }


    onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }

    ngOnInit() {

    }

}
