import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";

import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {SubscribeOrgConnectlyProService} from "../../../../services/subscribed-org.service";
import {SubGroupForm} from "../subGroup-form/subGroup-form.component";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'add-subGroup',
    templateUrl: './add-subGroup.component.html',
    styleUrls: ['./add-subGroup.component.css']
})
export class AddSubGroup {
    //@Output() componentAddedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('addSubGroup') addSubGroup: ElementRef;
    @ViewChild('subGroupForm') subGroupForm: SubGroupForm;
    subGroup: any;
    activeBlock: any;
    groupId: any;
    groupOrgId: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService,
                private connectlyProService: ConnectlyProService) {
        this.activeBlock=this.connectlyProService.activeBlock;
    }

    ngOnInit() {
        this.groupId=this.connectlyProService.groupId;
        this.groupOrgId=this.connectlyProService.groupOrgId;
        console.log("gorupid--", this.activeRoute.params)
    }

    onFormSubmit(event: any) {
        console.log("event.connector_id", event)
        event.appId = this.groupId;
        this.addNewsubGroup(event);
    }

    addNewsubGroup(data: any) {
        data.parentId=this.groupId;
        data.groupOrgId=this.groupOrgId;
        this.subscribeOrgConnectlyProService.addNewSubgroup(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.closeModal();
                        // this.componentAddedEvent.emit(new ConnectorComponent().getObject(response.data));
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {

                }
            );
    }

    onFormCencel(data:any){
        this.closeModal();
    }

    closeModal() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view', this.groupId, 'subGroups']);
    }

    resetView() {

    }


}