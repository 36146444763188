
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
    name: 'appVersion',
    pure:false
})
export class AppVersionPipe implements PipeTransform {

    transform(items: any){
        let v1=Math.floor(items/10000);

        let modulo1=items%10000;

        let v2=Math.floor(modulo1/1000);

        let modulo2=items%1000;

        let v3=Math.floor(modulo2/100);

        let version =v1+'.'+v2+'.'+v3;
        return version;
    }
}