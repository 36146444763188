import {Component, ViewChild, ViewChildren, AfterViewInit} from "@angular/core";

import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {LoginService} from "../../login/services/login.service";
import {EventsService} from "../services/events.service";


@Component({
    selector: 'events',
    templateUrl: './events.component.html',
    styleUrls: ['./events.component.scss']
})
export class EventsComponent {


    constructor(private router: Router, private eventsService: EventsService, private _titleService: Title, private loginService: LoginService,
                private route: ActivatedRoute) {

    }

    ngAfterViewInit(): void {

        this._titleService.setTitle('Events');


    }


}