const mask: Array<string | RegExp> = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];


export const commonConstants = {

    gender: {
        MALE: 'Male',
        FEMALE: 'Female'
    },

    emailPattern: new RegExp(/^[+a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/i),
    imageUrlPattern: new RegExp(/^[_]+[w-]+[\d]+[-]+[\d]+[_]$/g),
    URLPattern: new RegExp(/^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/i),
    appIdPattern: new RegExp(/^[a-zA-Z0-9]+[.]+[a-zA-Z-0-9]/),
    appNamePattern: new RegExp(/^[a-zA-Z0-9]+$/),
    appNamespacePattern: new RegExp(/^[a-z0-9]+$/),
    senderIdPattern: new RegExp(/^[A-Za-z]/),
    passwordPattern: new RegExp(/[a-zA-Z0-9@#$*!^]/),
    smsUserNamePattern: new RegExp(/^[a-zA-Z0-9.]+$/),

    phoneMaskOption: {mask: mask, guide: false},
    connectlyImageUrl: 'api.connectly.in',
    pageLinkSize: 5,
    pageRows: 100,
    rowsPerPageOptions: [100, 150, 200],


    Metric_Type_const: {
        0: 'All Metric',
        1: 'System Metric',
        2: 'Connector Metric',
        3: 'Organization Metric',
        4: 'Group Metric',
        5: 'User Metric'
    },

    connectly_group_Type_const: {
        1: 'Public Group',
        2: 'Close Group',
        3: 'Private Group'
    },

    connectly_group_category_const: {
        1: 'Friends',
        2: 'Family',
        3: 'Neighbours',
        4: 'Business',
        5: 'Classmete',
        6: 'Association',
        7: 'Project',
        8: 'Community',
        9: 'Internet',
        10: 'Locality',
        11: 'Smart City',
        12: 'Custom'
    },

    connectly_join_group_const: {
        1: 'Text',
        2: 'Number',
        3: 'Date',
        4: 'True/False',
        5: 'Single Choice List',
        6: 'Multiple Choice List',
        7: 'Date And Time',
        8: 'Time',
        9: 'Note',
        10: 'Url',
        11: 'Month',
        12: 'Month And Year',
        13: 'Year'
    },

    connectly_group_theme_const: {
        0: 'Default Theme',
    },

    process_Stage: {
        Initializing: 1,
        Containerizing: 2,
        Configuring: 3,
        DomainMapping: 4,
        Testing: 5,
        Live: 6

    },

    provisionStatus: {
        'PROVISIONING': 1,
        'PROVISIONED': 2,
    },

    buidType: {
        1: 'staging',
        2: 'production'
    },
    buidTypeValue: {
        'STAGING': 1,
        'PRODUCTION': 2
    },

    buildProcess: {
        1: 'autoBuild',
        2: 'menualBuild'
    },
    buildProcessValue: {
        'AUTOBUILD': 1,
        'MANUALBUILS': 2
    },
    buildplatform: {
        1: 'android',
        2: 'ios'
    },
    buildPlatformvallue: {
        'ANDROID': 1,
        'IOS': 2
    },

    proAppStatus: [
        {satus: '1', name: 'Provisioning Apps'},
        {satus: '2', name: 'Live Apps'},
        {satus: '5', name: 'Terminated Apps'},
        {satus: '7', name: 'Suspended Apps'},
        {satus: '0', name: 'All Apps'}
    ],

    agentStatusConst: {
        1: 'Unliked',
        2: 'Online',
        3: 'Offline',
    },

    agentStatus: [
        {id: 1, label: 'Unliked'},
        {id: 2, label: 'Online'},
        {id: 3, label: 'Offline'},
    ],

    thingsProductStatusConst: {
        1: 'Alpha',
        2: 'Beta',
        3: 'Growing',
        4: 'Production',
        5: 'Declining',
        6: 'Deprecated',
        7: 'On Hold',
    },
    thingsProductStatus: [
        {id: 1, label: 'Alpha', value: 'Alpha'},
        {id: 2, label: 'Beta', value: 'Beta'},
        {id: 3, label: 'Growing', value: 'Growing'},
        {id: 4, label: 'Production', value: 'Production'},
        {id: 5, label: 'Declining', value: 'Declining'},
        {id: 6, label: 'Deprecated', value: 'Deprecated'},
        {id: 7, label: 'On Hold', value: 'OnHold'},
    ],

    thingsInventoryConst: {
        1: 'Unsold',
        2: 'Sold',
        3: 'In Repair',
        4: 'Reparied',
        5: 'Returned'
    },

    thingsInventoryStatus: [
        {id: 1, label: 'Unsold', value: 'Unsold'},
        {id: 2, label: 'Sold', value: 'Sold'},
        {id: 3, label: 'In Repair', value: 'InRepair'},
        {id: 4, label: 'Reparied', value: 'Reparied'},
        {id: 5, label: 'Returned', value: 'Returned'}
    ],

    thingsInventoryTypeConst: {
        1: 'Fresh',
        2: 'Refurbished'
    },

    thingsInventoryType: [
        {id: 1, label: 'Fresh', value: 'Fresh'},
        {id: 2, label: 'Refurbished', value: 'Refurbished'}
    ],

};
