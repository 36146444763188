import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {MetricsService} from "../../services/metrics.service";
import {Metric} from "../../models/metrics.model";
import {MetricsForm} from "../metric-form/metric-form.component";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {Router, ActivatedRoute} from "@angular/router";
import {Message} from "primeng/components/common/message";



declare var jQuery: any;

@Component({
    selector: 'metrics-add',
    templateUrl: './add-metric.component.html',
    styleUrls: ['./add-metric.component.css']
})
export class AddMetrics {
    refreshPageVal: boolean = false;
    msgs: Message[] = [];

    metricType:string;
    error:Array<any> =[];

    @Output() metricsAddedEvent:EventEmitter<Metric> = new EventEmitter<Metric>();
    @ViewChild('addMetrics') addMetrics: ElementRef;
    @ViewChild('metricsForm') metricsForm: MetricsForm;

    constructor(private metricsService: MetricsService, private activeRoute: ActivatedRoute, private router: Router) {

    }


    onFormSubmit(event: any) {
       this.addNewMetric(event);
    }

    onFormCancel(){
        if(this.metricType == 'overview'){
            this.router.navigate([AppRoutingConstants.metrics]);
        }else{
            this.router.navigate([AppRoutingConstants.metrics,this.metricType]);
        }

    }

    addNewMetric(data: any) {
        this.metricsService.addNewMetric(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                        this.onFormCancel();


                    } else {
                       // this.error=[];
                       // this.error=response.errors;
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log("error--", error);
                    this.refreshPageVal=true;
                    if(error.code == 422){
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: error.errors[0]
                        });
                    }else {
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: ' Oops! Something Went Wrong'
                        });
                    }

                },
                () => {
                }
            );
    }


    resetView() {

    }

    ngOnInit() {

        this.activeRoute.params.forEach(param => {
            if (param.hasOwnProperty('type')) {
                this.metricType = param['type']
           // console.log("this.metricType",this.metricType);
            }
        });
    }

}