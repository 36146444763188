import {Component, ViewChild, ElementRef, EventEmitter, Output, Input} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";

import {BillingSKUForm} from "../billing-SKU-form/billing-SKU-form.component";
import {BizBrainServicesService} from "../../../../services/bizbrain-service.service";
import {Router, ActivatedRoute} from "@angular/router";

declare var jQuery: any;

@Component({
    selector: 'edit-billing-SKU',
    templateUrl: './edit-billing-SKU.component.html',
    styleUrls: ['./edit-billing-SKU.component.css']
})
export class EditBillingSKUForm {
    @Output() billingSKUEditedEvent:EventEmitter<any> = new EventEmitter<any>();
    @ViewChild('editBillingSKU') editBillingSKU: ElementRef;
    @ViewChild('billingSKUForm') billingSKUForm: BillingSKUForm;
    serviceProviders: any;

    productId: string;
    _billingSKU: any;
    billingSku: any;

    constructor(private bizBrainServiceServices: BizBrainServicesService, private router: Router, private activeRoute: ActivatedRoute) {

    }
    openModal(billingSKU) {
        this.billingSku=billingSKU;
        console.log("this.billingSku",this.billingSku);
        this.billingSKUForm.billingSKU = billingSKU;
    }

    closeModal() {
        //jQuery(this.editComponent.nativeElement).modal('hide');
    }

    resetView() {
       // this.connectorComponent = null;

    }

    ngOnInit() {
        this.activeRoute.params.forEach(param => {
            this.productId = param['id']
        });
        console.log("pro is", this.productId);
    }

    onFormSubmit(data){
        this.bizBrainServiceServices.updateBillingSKU(this.billingSku.unique_id,data)
        .subscribe(
            response => {
                if(response.json().flag){
                    console.log("updated",response.data);
                   this.billingSKUEditedEvent.emit(response.json().data);
                }else{
                    console.log(response.message);
                }
            },
            error => {
                console.log(error);
            },
            () => {
                this.closeModal();
            }
        );
    }

}