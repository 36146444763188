export const AppRoutingConstants = {
    dashboard: 'dashboard',
    login: 'login',

    connectors: 'connectors',
    connectors_add: 'connectors/add',
    connectors_view_id: 'connectors/view',
    connectors_edit_id: 'connectors/edit',

    services: 'products',
    services_add: 'products/add',
    services_view_id: 'products/view',
    services_edit_id: 'products/edit',

    organizations: 'organizations',
    organizations_view_id: 'organizations/view',
    organizations_add: 'organizations/add',
    organizations_edit_id: 'organizations/edit',
    organizations_users_id: 'organizations/users',
    organizations_services_id: 'organizations/services',
    organizations_add_user_id: 'organizations/addUser',
    organizations_add_service_id: 'organizations/addService',

    events: 'events',
    events_add: 'events/add',
    events_edit_id: 'events/edit',
    events_view_id: 'events/view',


    metrics: 'metrics',
    metrics_add: 'metrics/add',
    metrics_view_id: 'metrics/view',
    metrics_edit_id: 'metrics/edit',
    metrics_view_data: 'metrics/viewData',

    subscribeOrganization: 'connectly/proApps',
    subscribeOrganization_add: 'connectly/proApps/add',
    subscribeOrganization_view_id: 'connectly/proApps/view',
    subscribeOrganization_edit_id: 'connectly/proApps/edit',

    themes: 'connectly/themes',
    themes_add: 'connectly/themes/add',
    themes_view_id: 'connectly/themes/view',
    themes_edit_id: 'connectly/themes/edit',

    tally: 'tally',
    tally_organization_detail: 'tally/organization'


};
