/**
 * Created by hirenparekh on 11/11/16.
 */
import {Injectable} from "@angular/core";
import {Http, Headers, Response} from "@angular/http";
import {Environment} from "../../environment.model";
import {Observable} from "rxjs";
import {AppRoutingConstants} from "../../app-routing.constants";
import {Router} from "@angular/router";

@Injectable()
export class BizBrainServicesService  {

    headers: Headers;

    constructor(private http: Http,private router: Router) {
        this.headers = new Headers();
        this.headers.append('Accept', '*');
        //this.headers.append('Access-Control-Allow-Origin', '*');
        this.headers.append('Content-Type', 'application/json');
        this.headers.append('token', localStorage.getItem('sessionId'));
    }

    getServices(): Observable<any> {
        return this.http.get(Environment.apiUrl + 'anantProducts', {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
               this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    getServicesById(id): Observable<any> {
        return this.http.get(Environment.apiUrl + 'anantProducts/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

  /*  getBillingSKUByProductId(id): Observable<any> {
        return this.http.get(Environment.apiUrl + 'anantProducts/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }*/

    addServices(body): Observable<any> {
        return this.http.post(Environment.apiUrl + 'anantProducts', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    addNewBillingSKU(body) {
        return this.http.post(Environment.apiUrl + 'billingSKU', body, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    editServices(id, body): Observable<any> {
        return this.http.put(Environment.apiUrl + 'anantProducts/' + id, body, {headers: this.headers})
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    updateBillingSKU(id,body): Observable<any> {
        return this.http.put(Environment.apiUrl + 'billingSKU/' + id, body, {headers: this.headers})
            .do((response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteServices(id): Observable<any> {
        return this.http.delete(Environment.apiUrl + 'anantProducts/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response:Response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    deleteBillingSku(id): Observable<any> {
        return this.http.delete(Environment.apiUrl + 'billingSKU/' + id, {headers: this.headers})
            .map((response: Response) => response.json())
            .do((response:Response) => {
                this.authorizedUserService(response)
            })
            .catch((err:Response) => {
                let details = err.json();
                this.authorizedUserService(details)
                return Observable.throw(details);
            });
    }

    authorizedUserService(response){
        if (response.flag == false) {
            if(response.code == 401){
                localStorage.clear();
                this.router.navigate([AppRoutingConstants.login]);
            }
        }
    }

}
