import {Component, ViewChild, ElementRef,AfterViewInit} from "@angular/core";
import {Metric} from "../models/metrics.model";
import {MetricsService} from "../services/metrics.service";
import {BasicFunctions} from "../../functions";
import {ViewMetrics} from "./view-metrics/view-metrics.component";
import {Router, ActivatedRoute, NavigationEnd} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {LoginService} from "../../login/services/login.service";

declare var jQuery: any;

@Component({
    selector: 'metrics',
    templateUrl: '../../metrics/components/metrics.component.html',
    styleUrls: ['../../metrics/components/metrics.component.scss']
})
export class MetricsComponent {
    pageName = "Metrics";
    metrics: Metric[];
    searchStr: string = "";

    routes: Object[] = [{
        title: 'Overview',
        route: '/metrics',
        icon: 'dashboard',
    }
    ];
    activeroute:string;
    title: string;
    data: Object = {name: ''};
    staticMenu: boolean = localStorage.getItem('staticMenu') == "yes" ? true : false;

    @ViewChild('viewMetricModal') viewMetricModal: ViewMetrics;


    constructor(private metricsService: MetricsService, private router: Router, private activeRoute: ActivatedRoute,private _titleService: Title, private loginService: LoginService,
     private route: ActivatedRoute) {

    }

    ngAfterViewInit(): void {
        // broadcast to all listener observables when loading the page


       /* this._titleService.setTitle('Dashboard');
        this.title = this._titleService.getTitle();

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .subscribe(event => {
                let currentRoute = this.route.root;
                while (currentRoute.children[0] !== undefined) {
                    currentRoute = currentRoute.children[0];
                }
                this.data = currentRoute.snapshot.data;
                this.activeroute=this.router.url;
            })*/
    }

    /*onStaticMenuClick() {
        this.staticMenu = !this.staticMenu;
        let staticMenu = (this.staticMenu == true ? "yes" : "no");
        localStorage.setItem('staticMenu', staticMenu);
    }

    ngOnInit() {

    }
*/


}