import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {ConnectorsService} from "../../services/connectors.service";
import {Connector} from "../../models/connector.model";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BasicFunctions} from "../../../functions";
import {ConnectorComponent} from "../../models/connectorComponent.model";
import {AddComponent} from "./component-connector/add-component/add-component.component";
import {ComponentForm} from "./component-connector/component-form/component-form.component";
import {EditComponent} from "./component-connector/edit-component/edit-component.component";
import {ConnectorMetric} from "../../models/connectorMetric.model";
import {AddMetric} from "./metric-connector/add-metric/add-metric.component";
import {EditMetric} from "./metric-connector/edit-metric/edit-metric.component";
import {MetricForm} from "./metric-connector/metric-form/metric-form.component";
import {Router, ActivatedRoute} from "@angular/router";
import {AppRoutingConstants} from "../../../app-routing.constants";
import {ConnectorForm} from "../connector-form/connector-form.component";
import {ConfirmationService, Message} from "primeng/components/common/api";

declare var jQuery: any;

@Component({
    selector: 'view-connector',
    templateUrl: './view-connector.component.html',
    styleUrls: ['./view-connector.component.css', '../connectors.component.scss']
})
export class ViewConnectorComponent {
    // @Output() connectorAddedEvent:EventEmitter<Connector> = new EventEmitter<Connector>();
    @ViewChild('viewConnector') viewConnector: ElementRef;
    @ViewChild('addComponentModal') addComponentModal: AddComponent;
    @ViewChild('componentForm') componentForm: ComponentForm;
    @ViewChild('editComponentModal') editComponentModal: EditComponent;
    @ViewChild('addMetricModal') addMetricModal: AddMetric;
    @ViewChild('editMetricModal') editMetricModal: EditMetric;
    @ViewChild('metricForm') metricForm: MetricForm;

    @ViewChild('connectorForm') connectorForm: ConnectorForm ;
    //scheduleOptForm: FormGroup;
    serviceProviders: any;
    selectedFile: any;
    selectedFileData: any;
    connector:any;
    connectorComponents: ConnectorComponent[];
    connectorMetrics: ConnectorMetric[];
    schedules: any;
    serviceProviderName: any;

    msgs: Message[] = [];

    editConnectorMode: boolean=false;
    viewConnectorMode: boolean=false;
    displayAddComponent:boolean=false;
    displayEditComponent:boolean=false;
    displayAddMetrics:boolean=false;
    displayEditMetrics:boolean=false;

    constructor(private connectorService: ConnectorsService,
                private router: Router, private activeRoute: ActivatedRoute,
                private confirmationService: ConfirmationService) {
        this.schedules = {
            1: "Minutely",
            2: "Hourly",
            3: "Daily",
            4: "Weekly",
            5: "Monthly",
            6: "Quaterly",
            7: "Yearly"
        };
    }

    gotoConnector(){
        this.router.navigate([AppRoutingConstants.connectors]);
    }

    openEditConnector(){

        this.editConnectorMode=true;
    }

    onFormCancel(){
        this.editConnectorMode=false;
    }

    onConnectorFormSubmit(event){
        this.connectorService.updateConnector(this.connector.unique_id,event)
            .subscribe(
                response => {
                    if(response.flag){
                        console.log("updated",response.data);
                        /*this.connectorEditedEvent.emit(new Connector().getObject(response.data));
                        this.router.navigate([AppRoutingConstants.connectors]);*/
                        this.connector=response.data;
                        this.editConnectorMode=false;
                    }else{
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }

    openModal(connector: Connector) {
        this. getServiceProviders();
       // jQuery(this.viewConnector.nativeElement).modal('show');
        this.connector = connector;
        this.getConnectorComponents(this.connector.unique_id);
        this.getConnectorMetrics(this.connector.unique_id);
        jQuery('.collapse').on('show.bs.collapse', function (e) {
            jQuery('.collapse').not(e.target).removeClass('in');
        });
    }

    closeModal() {
      //  jQuery(this.viewConnector.nativeElement).modal('hide');

    }


    openClosePopup(){
        jQuery('.collapse').on('show.bs.collapse', function (e) {
            jQuery('.collapse').not(e.target).removeClass('in');
        });
    }

    ngOnInit() {
        let connectorId: string;
        this.activeRoute.params.forEach(param => {connectorId = param['id']});
        this.getConnectorById(connectorId);
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if(response.flag)
                    {
                        let data = response.data;
                        console.log("data",data);

                        this. getServiceProviders();
                        this.connector = data;
                        // jQuery(this.viewConnector.nativeElement).modal('show');
                       // console.log("this.connector.uniqueId",this.connector.unique_id);
                        this.getConnectorComponents(this.connector.unique_id);
                        this.getConnectorMetrics(this.connector.unique_id);
                    }else{
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    getServiceProviders() {
        this.connectorService.getServiceProviders()
            .subscribe(
                response => {
                    if (response.flag) {
                        this.serviceProviders = response.data;
                        this.serviceProviders.forEach((opt, index) => {
                            if (opt.unique_id === this.connector.service_provider_id) {
                               this.serviceProviderName =opt.name;
                               console.log("---",this.serviceProviderName);
                            }
                        });
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getConnectorComponents(id) {
        this.connectorService.getConnectorComponents(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connectorComponents = response.data.map(val => new ConnectorComponent().getObject(val));
                        new BasicFunctions().insertionSort(this.connectorComponents, 'createdAt');

                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    getConnectorMetrics(id) {
        this.connectorService.getConnectorMetrics(id)
            .subscribe(
                response => {
                    if (response.flag) {
                        this.connectorMetrics = response.data.map(val => new ConnectorMetric().getObject(val));
                        console.log(this.connectorMetrics);
                        new BasicFunctions().insertionSort(this.connectorMetrics, 'createdAt');
                        console.log("after sort", this.connectorMetrics);
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            );
    }

    deleteComponent(id: string) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this component?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectorService.deleteComponent(id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.connectorComponents = this.connectorComponents.filter(val => val.uniqueId !== id);
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {
            }
        });



    }

    openAddConnectorComponentPopup() {
        this.displayAddComponent=true;
        this.addComponentModal.openModal(this.connector);
       // let url = 'connecors/view/'+this.connector.uniqueId+'/addcomponent'
        //console.log()
        //this.router.navigate(['connecors/view',this.connector.unique_id,'addcomponent']);
    }

    openEditConnectorComponentPopup(comonent) {
        this.displayEditComponent=true;
        this.editComponentModal.openModal(comonent, this.connector);
    }

    onNewComponentAdded(connectorComponent: ConnectorComponent) {
        /*console.log("connectorComponent",connectorComponent)
        if (!this.connectorComponents) {
            this.connectorComponents = [];
        }
        this.connectorComponents.push(connectorComponent);*/
        this.getConnectorComponents(this.connector.unique_id);
        this.displayAddComponent=false;
    }

    onNewComponentEdited(connectorComponent: ConnectorComponent) {
        if (!this.connectorComponents) {
            this.connectorComponents = [];
        }

        for (let i = 0; this.connectorComponents, i < this.connectorComponents.length; i++) {
            let currComponent = this.connectorComponents[i];
            if (currComponent.uniqueId == connectorComponent.uniqueId) {
                this.connectorComponents.splice(i,1,connectorComponent);
                break;
            }
        }
        new BasicFunctions().insertionSort(this.connectorComponents, 'createdAt');
        this.displayEditComponent=false;
    }

    onNewMetricAdded(connectorMetric: ConnectorMetric) {
        if (!this.connectorMetrics) {
            this.connectorMetrics = [];
        }
        this.connectorMetrics.push(connectorMetric);
        new BasicFunctions().insertionSort(this.connectorMetrics, 'createdAt');
        this.displayAddMetrics=false;
    }

    onNewmetricEdited(connectorMetric: ConnectorMetric) {
        if (!this.connectorMetrics) {
            this.connectorMetrics = [];
        }

        for (let i = 0; this.connectorMetrics, i < this.connectorMetrics.length; i++) {
            let currMetric = this.connectorMetrics[i];
            if (currMetric.metricId == connectorMetric.metricId) {
                this.connectorMetrics.splice(i,1,connectorMetric);
                break;
            }
        }

        new BasicFunctions().insertionSort(this.connectorMetrics, 'createdAt');
        this.displayEditMetrics=false;
    }


    openAddConnectorMetricPopup() {
        this.displayAddMetrics=true;
        //this.addMetricModal.openModal(this.connector);
        this.router.navigate([AppRoutingConstants.connectors_view_id,this.connector.unique_id,'addmetric']);
    }

    openEditConnectorMetricPopup(metric) {
        this.displayEditMetrics=true;
        this.editMetricModal.openModal(metric, this.connector);
        this.router.navigate([AppRoutingConstants.connectors_view_id,this.connector.unique_id,'editmetric',metric.metricKey]);
    }

    deleteMetrics(id: string) {
        this.confirmationService.confirm({
            message: 'Do you want to delete this metric?',
            header: 'Delete Confirmation',
            icon: 'fa ui-icon-warning',
            accept: () => {
                this.connectorService.deleteMetric(id)
                    .subscribe(
                        response => {
                            if (response.flag) {
                                this.connectorMetrics = this.connectorMetrics.filter(val => val.metricId !== id);
                            } else {
                                console.log(response.message);
                                this.msgs = [];
                                this.msgs.push({
                                    severity: 'error',
                                    summary: 'Error Message',
                                    detail: response.message
                                });
                            }
                        },
                        error => {
                            console.log(error);
                            this.msgs = [];
                            this.msgs.push({
                                severity: 'error',
                                summary: 'Error Message',
                                detail: ' Oops! Something Went Wrong'
                            });
                        },
                        () => {

                        }
                    );
            },
            reject: () => {
            }
        });

    }

    onRowSelect(event) {
        //alert(event.data.productId);
        this.viewMetrics(event.data.metricId);
    }

    viewMetrics(id){
        this.router.navigate([AppRoutingConstants.connectors_view_id,this.connector.unique_id,'viewmetric',id]);
    }


}