import {Component, Input, EventEmitter, Output, Inject, forwardRef} from "@angular/core";
import {FormGroup, FormControl, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AddMember} from "../add-member/add-member.component";


@Component({
    selector: 'member-form',
    templateUrl: './member-form.component.html',
    styleUrls: ['./member-form.component.css']
})
export class MemberForm {
    error:any;
    memberForm: FormGroup;
    private _memberComponent: any;

    @Input()
    submitBtnLabel: string;

    @Output()
    formSubmitEvent: EventEmitter<any> = new EventEmitter<any>();


    getFormData() {
        let m_data = {
            name: this.memberForm.value['name'],
            description: this.memberForm.value['description'],
        };
        return m_data;
    }

    get memberComponent() {
        return this._memberComponent;
    }

    @Input()
    set memberComponent(value) {
        console.log("component:", value);
        this._memberComponent = value;
        if (value)
            this.setValues();
    }

    constructor(private _titleService: Title, private router: Router,
                @Inject(forwardRef(() => AddMember)) private _parent: AddMember,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
    }

    ngOnInit() {

        this.memberForm = new FormGroup({
            name: new FormControl("", Validators.required),
            description: new FormControl("", Validators.required),
        });
    }


    resetForm() {
        this.memberForm.reset();
        this._parent.closeModal();
    }

    setValues() {

        let value = {
            name: this._memberComponent.name,
            description: this._memberComponent.description,
        };
        console.log("value", value);
        this.memberForm.reset(value)
    }

    addNewMember(event) {
        this.formSubmitEvent.emit(this.getFormData());
    }


}