import {Component, AfterViewInit} from '@angular/core';
import {Title}     from '@angular/platform-browser';
import {ActivatedRoute, Router} from "@angular/router";
import {AppRoutingConstants} from "../../../../app-routing.constants";
import {commonConstants} from "../../../../common/models/constants";
import {Message} from "primeng/components/common/api";
import {SubscribeOrgConnectlyProService} from "../../../services/subscribed-org.service";

//import {ItemsService, UsersService} from '../../../services';

@Component({
    selector: 'premium-view-subscribe-org',
    templateUrl: './premium.component.html',
    styleUrls: ['./premium.component.scss'],
})
export class PremiumViewSubscribeOrgComponent implements AfterViewInit {

    groupId: string;
    GroupProfile: string;

    selectedThemeId: string;
    GroupTheme: string;

    groupTopics: any;
    theme: any;

    constants = commonConstants;
    msgs: Message[] = [];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private subscribeOrgConnectlyProService: SubscribeOrgConnectlyProService) {

        this.groupId = activeRoute.snapshot.parent.params['id'];
    }

    ngAfterViewInit(): void {
        //this._titleService.setTitle('Home Dashboard');

    }


    ngOnInit() {
       // this.getGroupTopics(this.groupId)
    }


    displayMessage(msg) {
        this.msgs = [];
        this.msgs.push({severity: 'error', summary: 'Error Message', detail: msg});
    }
}
