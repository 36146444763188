import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {ActivatedRoute, Router, Params} from "@angular/router";
import {Metric} from "../../../../../metrics/models/metrics.model";
import {MetricsService} from "../../../../../metrics/services/metrics.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {Connector} from "../../../../models/connector.model";
import {ConnectorsService} from "../../../../services/connectors.service";
import {ConfirmationService, Message} from "primeng/components/common/api";
declare var jQuery: any;

@Component({
    selector: 'metrics-view',
    templateUrl: './view-metrics.component.html',
    styleUrls: ['./view-metrics.component.css']
})
export class ViewConnectorMetrics {
    @ViewChild('viewMetric') viewMetric: ElementRef;
    msgs: Message[] = [];
    metric: Metric;
    type: string;
    connectorId: string;
    connector: Connector;
    constructor(private connectorService: ConnectorsService,private metricService: MetricsService, private activeRoute: ActivatedRoute, private router: Router) {

    }

    getMetricById(metricId: string): void {
        this.metricService.getmetricsById(metricId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        this.metric = new Metric().getObject(data);
                        console.log("this.metric.tags", data.tags)
                        /*this.metric.tags = new MetricTag().getObject(data.tags);*/
                        this.metric.tags = data.tags;
                        this.metric.cachedreports = data.cached_reports;
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    ngOnInit() {
        let id: string;
        this.activeRoute.params.forEach(param => {
            id = param['id']
        });
        this.activeRoute.params.forEach(param => {
            this.type = param['type']
        });
        this.activeRoute.params.forEach(params => {
            if (params.hasOwnProperty('connectorid')) {
                this.connectorId = params['connectorid'];
            }
        });
        this.getMetricById(id);
        this.getConnectorById(this.connectorId);
    }

    getConnectorById(connectorId: string): void {
        this.connectorService.getConnectorById(connectorId)
            .subscribe(
                response => {
                    if (response.flag) {
                        let data = response.data;
                        console.log("data", data);

                        this.connector = new Connector().getObject(data);
                        // jQuery(this.viewConnector.nativeElement).modal('show');
                        // console.log("this.connector.uniqueId",this.connector.unique_id);

                    } else {
                        console.log(response.message);
                        console.log("in service");
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                }
            )
    }

    goToMetrics() {

        this.activeRoute.params
            .subscribe((params: Params) => {
                if (params.hasOwnProperty('connectorid')) {
                    this.connectorId = params['connectorid'];
                    this.router.navigate([AppRoutingConstants.connectors_view_id, this.connectorId]);
                } else {
                    this.router.navigate([AppRoutingConstants.metrics, this.type]);
                }
            });
    }

}