import {Component, AfterViewInit} from '@angular/core';
import {Title} from '@angular/platform-browser';


@Component({
    selector: 'app-tally-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.scss'],
})
export class OverviewTallyComponent implements AfterViewInit {


    constructor(private _titleService: Title) {

    }

    ngAfterViewInit(): void {
        this._titleService.setTitle('Tally');

    }
}
