import {ISerializable} from "../../common/interface/serializable.interface";
/**
 * Created by hirenparekh on 16/11/16.
 */

export class Connector implements ISerializable<Connector>{

  name: string;
  description: string;
  classNamespace: string;
  scheduleOptions: string[];
  isActive: boolean;
  serviceProviderId: string;
  stage: string;
  fields: string;
  icon: string;
  createdBy: string;
  uniqueId: string;
  updatedAt: string;
  createdAt: string;
  serviceProvider: {};

  getObject(data:any):Connector{

    let connector = new Connector();

    if(!data)
        return connector;

    connector.name = data['name'];
    connector.description = data['description'];
    connector.classNamespace = data['class_namespace'];
    connector.scheduleOptions = data['schedule_options'];

    if (data['is_active'] == "true")
      connector.isActive = true;
    else
      connector.isActive = false;

    connector.serviceProviderId = data['service_provider_id'];
    connector.stage = data['stage'];
    connector.icon = data['icon'];
    connector.createdBy = data['created_by'];
    connector.uniqueId = data['unique_id'];
    connector.updatedAt = data['updated_at'];
    connector.createdAt = data['created_at'];
    connector.serviceProvider = data['service_provider'];
    connector.fields = data['fields'];

    return connector;
  }

}
