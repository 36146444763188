import {Component, ViewChild, ElementRef, Input, Output, EventEmitter} from "@angular/core";

import {FormControl, FormGroup, Validators} from "@angular/forms";
import {Title} from "@angular/platform-browser";
import {Router, ActivatedRoute} from "@angular/router";
import {ConnectlyProService} from "../../../../services/connectly-pro.service";
import {AppRoutingConstants} from "../../../../../app-routing.constants";
import {MemberForm} from "../member-form/member-form.component";
import {Message} from "primeng/components/common/message";


declare var jQuery: any;

@Component({
    selector: 'add-member',
    templateUrl: './add-member.component.html',
    styleUrls: ['./add-member.component.css']
})
export class AddMember {
    //@Output() componentAddedEvent:EventEmitter<ConnectorComponent> = new EventEmitter<ConnectorComponent>();
    @ViewChild('addMember') addMember: ElementRef;
    @ViewChild('memberForm') memberForm: MemberForm;
    member: any;
    groupId: any;
    activeBlock: any;

    msgs:Message[] =[];

    constructor(private _titleService: Title, private router: Router,
                private activeRoute: ActivatedRoute, private connectlyProService: ConnectlyProService) {
    }

    ngOnInit() {
        this.groupId = this.connectlyProService.groupId;
        this.activeBlock=this.connectlyProService.activeBlock;
        console.log("this.activeBlock",this.activeBlock)
    }

    onFormSubmit(event: any) {
        console.log("event.connector_id", event)
        event.appId = this.groupId;
        this.addNewmember(event);
    }

    addNewmember(data: any) {
        this.connectlyProService.addNewMember(JSON.stringify(data))
            .subscribe(
                response => {
                    if (response.flag) {
                        console.log(response.message);
                        console.log(response.data);
                       // this.componentAddedEvent.emit(new ConnectorComponent().getObject(response.data));
                    } else {
                        console.log(response.message);
                        this.msgs = [];
                        this.msgs.push({
                            severity: 'error',
                            summary: 'Error Message',
                            detail: response.message
                        });
                    }
                },
                error => {
                    console.log(error);
                    this.msgs = [];
                    this.msgs.push({
                        severity: 'error',
                        summary: 'Error Message',
                        detail: ' Oops! Something Went Wrong'
                    });
                },
                () => {
                    this.closeModal();
                }
            );
    }


    closeModal() {
        this.router.navigate([AppRoutingConstants.subscribeOrganization,this.activeBlock,'view',this.groupId,'members']);
    }

    resetView() {

    }


}