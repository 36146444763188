
import {ButtonModule} from "primeng/components/button/button";
import {InputTextModule} from "primeng/components/inputtext/inputtext";
import {ConfirmDialogModule} from "primeng/components/confirmdialog/confirmdialog";
import {CheckboxModule} from "primeng/components/checkbox/checkbox";
import {DropdownModule} from "primeng/components/dropdown/dropdown";
import {InputTextareaModule} from "primeng/components/inputtextarea/inputtextarea";
import {RadioButtonModule} from "primeng/components/radiobutton/radiobutton";
import {CalendarModule} from "primeng/components/calendar/calendar";
import {DialogModule} from "primeng/components/dialog/dialog";
import {BreadcrumbModule} from "primeng/components/breadcrumb/breadcrumb";
import {PasswordModule} from "primeng/components/password/password";
import {TooltipModule} from "primeng/components/tooltip/tooltip";
import {TabViewModule} from "primeng/components/tabview/tabview";
import {DataTableModule} from "primeng/components/datatable/datatable";
import {SharedModule} from "primeng/components/common/shared";
import {DataListModule} from "primeng/components/datalist/datalist";
import {OverlayPanelModule} from "primeng/components/overlaypanel/overlaypanel";
import {ChartModule} from "primeng/components/chart/chart";
import {GrowlModule} from "primeng/components/growl/growl";
import {StepsModule} from "primeng/components/steps/steps";
import {SplitButtonModule} from "primeng/components/splitbutton/splitbutton";
import {ColorPickerModule} from "primeng/components/colorpicker/colorpicker";
import {FieldsetModule} from "primeng/components/fieldset/fieldset";
import {AutoCompleteModule} from "primeng/components/autocomplete/autocomplete";
import {MenuModule} from "primeng/components/menu/menu";
import {PanelModule} from "primeng/components/panel/panel";
import {DragDropModule} from "primeng/components/dragdrop/dragdrop";
import {PaginatorModule} from "primeng/components/paginator/paginator";
import {ProgressBarModule} from "primeng/components/progressbar/progressbar";
import {ToggleButtonModule} from "primeng/components/togglebutton/togglebutton";
import {InputSwitchModule} from "primeng/components/inputswitch/inputswitch";

export const PrimeNgComponentModules = [
    ButtonModule,
    InputTextModule,
    PasswordModule,
    ConfirmDialogModule,
    CheckboxModule,
    DropdownModule,
    InputTextareaModule,
    RadioButtonModule,
    CalendarModule,
    DialogModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    BreadcrumbModule,
    TooltipModule,
    TabViewModule,
    DataTableModule,
    SharedModule,
    DataListModule,
    ChartModule,
    GrowlModule,
    StepsModule,
    SplitButtonModule,
    ColorPickerModule,
    FieldsetModule,
    AutoCompleteModule,
    MenuModule,
    PanelModule,
    DragDropModule,
    PaginatorModule,
    ProgressBarModule,
    ToggleButtonModule,
    InputSwitchModule
];
